import styled from 'styled-components';

export const OrganizationName = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const QuitPage = styled.span`
  font-weight: bold;
`;
