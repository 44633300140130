import { createDonutChartOptions } from 'lib/agCharts/charts';
import { WaitingLine } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ChartCard from '../ChartCard';

interface TotalTransactionsCardProps {
  balanceTransactions: BalanceTransactionSplits[];
  waitingLines: WaitingLine[];
  defaultLocale: Languages;
}

function TotalTransactionsCard(props: TotalTransactionsCardProps) {
  const { balanceTransactions, waitingLines, defaultLocale } = props;
  const intl = useIntl();

  const transactionsQueueData = useMemo(() => {
    if (!balanceTransactions || balanceTransactions.length === 0) {
      return [];
    }

    return waitingLines.map((waitingLine) => ({
      asset: waitingLine.name[intl.locale as Languages] || waitingLine.name[defaultLocale],
      amount: balanceTransactions.filter(
        (transaction: any) => transaction.metadata.waitingLineId === waitingLine.id,
      ).length,
    }));
  }, [waitingLines, intl.locale, defaultLocale, balanceTransactions]);

  const transactionsDonutOptions = createDonutChartOptions(transactionsQueueData, intl.locale, intl.formatMessage({ id: 'transactions' }));

  return (
    <ChartCard title="total_transactions" chartOptions={transactionsDonutOptions} />
  );
}

export default TotalTransactionsCard;
