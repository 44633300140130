import { GetTicketItem } from 'lib/graphql/entities/tickets/types';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import {
  EventStatus,
  useGetAllEventWaitingLinesQuery,
  useValidateTicketMutation,
} from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import useEvent from 'providers/event/useEvent';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'wouter';

import { QRAlertProps } from '../components/QRAlert/types';
import { OperatingStatusCode } from '../statusCodes';
import QrReaderContext, { RecentScan } from './EventQrReaderContext';

function EventQrReaderProvider(props: PropsWithChildren) {
  const { children } = props;
  const event = useEvent();
  const [, setLocation] = useLocation();
  const [validateTicket] = useValidateTicketMutation();
  const [operatingWaitingLine, setOperatingWaitingLineState] = useState<string>('');
  const setOperatingWaitingLine = useCallback((value: string) => {
    if (value === operatingWaitingLine) return;
    setOperatingWaitingLineState(value);
    setLocation(`/operations?tab=qr&waitingLine=${value}`);
  }, [operatingWaitingLine, setLocation]);
  const [recentScans, setRecentScans] = useState<RecentScan[]>([]);
  const [alertProps, setAlertProps] = useState<QRAlertProps | null>(null);

  useEffect(() => {
    if (event.status === EventStatus.Draft) setLocation('/edit');
  }, [event, setLocation]);

  // TODO: Replace by infinite query
  const { data: eventWaitingLinesData } = useGetAllEventWaitingLinesQuery({
    variables: { eventId: event.id },
  });

  const handleSetAlertProps = useCallback((
    status: OperatingStatusCode | null,
    tickets?: GetTicketItem[],
    remainingTickets?: number | 'unknown',
  ) => {
    setAlertProps(status ? { status, tickets, remainingTickets } : null);
  }, []);

  const closeAlert = useCallback(() => {
    setAlertProps(null);
  }, []);

  const waitingLines = useMemo<EventWaitingLine[]>(() => {
    const tempWaitingLines = eventWaitingLinesData?.getAllEventWaitingLines.waitingLines;
    if (tempWaitingLines) {
      return eventWaitingLinesData.getAllEventWaitingLines.waitingLines;
    }
    return [];
  }, [eventWaitingLinesData]);

  useEffect(() => {
    if (!operatingWaitingLine) {
      const params = new URLSearchParams(window.location.search);

      // Tab param has priority over waitingLine param
      const tab = params.get('tab');
      if (tab === 'operations') return;

      const waitingLine = params.get('waitingLine');
      if (waitingLine) setOperatingWaitingLine(waitingLine);
      else setOperatingWaitingLine(waitingLines[0]?.id || '');
    } else {
      const waitingLine = waitingLines.find((line) => line.id === operatingWaitingLine);
      if (!waitingLine) {
        setOperatingWaitingLine(waitingLines[0]?.id || '');
      }
    }
  }, [operatingWaitingLine, setOperatingWaitingLine, waitingLines]);

  const handleValidateTicket = useCallback(async (tickets: GetTicketItem[]) => {
    if (tickets) {
      await Promise.all(tickets.map(async (ticket) => {
        await validateTicket({
          variables: {
            waitingLineId: ticket.waitingLineId,
            ticketId: ticket.id,
          },
        });
      }));
    }
  }, [validateTicket]);

  const addRecentScan = useCallback((
    nbrOfTickets: number,
    waitingLineId: string,
    remainingTickets: number | 'unknown',
  ) => {
    setRecentScans((prev: any) => {
      const newScan = {
        tickets: nbrOfTickets,
        waitingLineName: waitingLines.find((waitingLine) => (
          waitingLine.id === waitingLineId
        ))?.name || waitingLineId,
        remainingTickets,
        timestamp: DateTime.now(),
      };
      const newScans = [...prev, newScan];
      if (newScans.length > 3) {
        newScans.shift();
      }
      return newScans;
    });
  }, [waitingLines]);

  const contextValue = useMemo(() => ({
    recentScans,
    addRecentScan,
    validateTickets: handleValidateTicket,
    operatingWaitingLine,
    setOperatingWaitingLine,
    alertProps,
    setAlertProps: handleSetAlertProps,
    closeAlert,
    waitingLines,
  }), [
    recentScans,
    addRecentScan,
    handleValidateTicket,
    operatingWaitingLine,
    setOperatingWaitingLine,
    alertProps,
    handleSetAlertProps,
    closeAlert,
    waitingLines,
  ]);

  return (
    <QrReaderContext.Provider value={contextValue}>
      {children}
    </QrReaderContext.Provider>
  );
}

export default EventQrReaderProvider;
