import { List as MuiList, Typography } from '@mui/material';
import { styled } from 'styled-components';

export const List = styled(MuiList)`
  width: 60vw;
`;

export const NoOrganizationText = styled(Typography).attrs({
  fontWeight: 'medium',
})`
  color: #b8b8b8;
`;
