import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { EventStatus, EventUpdateInput, useUpdateEventMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import settings from 'lib/settings';
import getSlugPart from 'lib/urlShortener/getSlugPart';
import { DateTime } from 'luxon';
import useEvent from 'providers/event/useEvent';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import EventForm from './EventForm';

function UpdateEvent() {
  useEventDocumentTitle('information');
  const intl = useIntl();
  const feedback = useFeedback();
  const event = useEvent();
  const { projectName, cloudName } = settings.cloudinary;
  const [, setLocation] = useLocation();

  const [updateEvent] = useUpdateEventMutation();

  const methods = useCustomForm<Omit<EventUpdateInput, 'startTime' | 'endTime'> & { startTime: DateTime, endTime: DateTime }>({
    defaultValues: {
      ...event,
      startTime: event.startTime.setZone(event.timezone),
      endTime: event.endTime.setZone(event.timezone),
      ...(event.shortUrl ? { shortUrl: getSlugPart(event.shortUrl) } : {}),
    },
    trim: true,
  });

  const { setError } = methods;

  const onSubmit: SubmitHandler<EventUpdateInput> = async (formData: any) => {
    if (!event) return;
    const { name, description } = formData;

    let image = null;
    // Only an instance of File if it is a new image selected. Otherwise, it is a string.
    if (formData.image instanceof File) {
      const imageFormData = new FormData();
      imageFormData.append('file', formData.image as string);
      imageFormData.append('upload_preset', projectName);

      const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, {
        method: 'POST',
        body: imageFormData,
      });
      const responseData = await response.json();
      image = responseData.public_id;
    }

    const { __typename: nameTypename, ...names } = name;
    const { __typename: descriptionTypename, ...descriptions } = description;

    try {
      await updateEvent({
        variables: {
          id: event.id,
          event: {
            location: formData.location,
            startTime: formData.startTime,
            endTime: formData.endTime,
            timezone: formData.timezone,
            languages: formData.languages,
            image: image !== null ? image : event.image,
            name: names,
            shortUrl: formData.shortUrl,
            description: descriptions,
          },
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="update_event_success" />),
        severity: 'success',
      });
      if (event.status === EventStatus.Draft) {
        setLocation('/waitingLines');
      }
    } catch (error) {
      const errorMessage = (error as Error).message;

      const regex = /The (.+?) field must have a value for the languages: (.+)/;
      const match = errorMessage.match(regex);

      if (match) {
        const field = match[1];
        const languages = match[2].split(',').map((lang) => lang.trim()) as Languages[];
        const languagesWithErrors: string[] = [];

        languages.forEach((language: Languages) => {
          const translatedLanguage = intl.formatMessage({ id: `language_${language}` });

          setError(`${field}.${language}` as keyof EventUpdateInput, { message: 'field_required' });

          languagesWithErrors.push(translatedLanguage);
        });

        const concatenatedLanguages = languagesWithErrors.join(', ');

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_error_missing_translation" values={{ languages: concatenatedLanguages, count: languagesWithErrors.length }} />),
          severity: 'error',
        });
      } else {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_event_error" />),
          severity: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <EventForm
        title="update"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}

export default UpdateEvent;
