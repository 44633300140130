import {
  Grid,
  MenuItem,
  Select,
  TextFieldProps,
} from '@mui/material';
import FormField, { FormFieldProps } from 'components/FormFields/FormField';
import { currencies, Currency } from 'lib/constants/currencies';
import {
  useController,
} from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

type CustomMUITextFieldProps = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name' | 'inputProps' | 'type'>;
interface FormCurrencyFieldProps extends FormFieldProps, CustomMUITextFieldProps {}

function FormCurrencyField(props: FormCurrencyFieldProps) {
  const {
    name,
    helperTextId,
    labelId,
    fieldDescription,
  } = props;

  const { field, formState } = useController({ name });

  return (
    <FormField
      name={name}
      helperTextId={helperTextId}
      labelId={labelId}
      fieldDescription={fieldDescription}
    >
      <Grid container>
        <Grid item xs={12}>
          <Select
            size="small"
            ref={field.ref}
            label={<FormattedMessage id="event_timezone" />}
            value={field.value || formState.defaultValues?.[name]}
            sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
          >
            {currencies.map((currency: Currency) => (
              <MenuItem
                key={currency.code}
                value={currency.code}
                onClick={() => field.onChange(currency.code)}
              >
                {`${currency.code}, ${currency.name}`}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
    </FormField>
  );
}

export default FormCurrencyField;
