import QRCodeReader from './EventQrReader';
import { EventQrReaderProvider } from './provider';

function EventQrCodeReader() {
  return (
    <EventQrReaderProvider>
      <QRCodeReader />
    </EventQrReaderProvider>
  );
}

export default EventQrCodeReader;
