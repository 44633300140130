import { Card, dialogClasses, Grid } from '@mui/material';
import BkgLogin from 'assets/images/bkg-login.jpg';
import MovOnVIP from 'assets/images/logo/movon-logo-horizontal-small.png';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import styled from 'styled-components';
import { ContainedLoadingButton, TextButton } from 'styles/styles';

import { AppTypeBackground } from '../../styles/AppThemeProvider';

export const FullWidthItem = styled(Grid).attrs(() => ({
  item: true,
  xs: 12,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BackgroundImage = styled.img.attrs({
  src: BkgLogin,
  alt: '',
})`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
`;

export const Logo = styled.img.attrs({
  src: MovOnVIP,
  alt: 'MovOn.VIP',
})`
  user-select: none;
`;

export const TopLeftLogo = styled(Logo)`
  position: absolute;
  margin: 2rem;
`;

export const ActionButton = styled(ContainedLoadingButton)`
  padding-right: 3rem;
  padding-left: 3rem;
`;

interface FooterContainerProps {
  $isSmall: boolean
}

export const FooterContainer = styled.div<FooterContainerProps>`
  width: 100%;
  padding: 12px 8px;
  display: flex;
  ${({ $isSmall }) => $isSmall && 'flex-direction: column;'}
  align-items: center;
  justify-content: center;
  gap: 8px;
`;

export const SeparatingDot = styled.div`
  display: flex;
  flex-basis: 5px;
  width: 5px;
  height: 5px;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.secondary.main};
`;

export const FooterButton = styled(TextButton)`
  font-size: 0.8rem;
`;

export const BigStyledDialog = styled(CustomDialog)`
  & .${dialogClasses.paper} {
    max-width: 900px;
    background: ${({ theme }) => theme.palette.background.default};
  }
`;

export const AccountNeeded = styled(Card)`
  background: ${({ theme }) => (theme.palette.background as AppTypeBackground).dark};
  width: 100%;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
`;
