import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { Menu, MenuItem, Typography } from '@mui/material';
import { Languages } from 'lib/i18n/i18n';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useCallback, useContext, useState } from 'react';
import { FormattedDisplayName } from 'react-intl';

import { ChevronButton, ChevronIcon } from './styles';

function LanguageSelector() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const setLocale = useCallback((newLocale: Languages) => {
    handleCloseMenu();
    setLanguage(newLocale);
  }, [setLanguage]);

  return (
    <>
      <ChevronButton
        variant="text"
        onClick={handleOpenMenu}
        endIcon={<ChevronIcon icon={anchorEl ? faChevronUp : faChevronDown} />}
        disableRipple
      >
        <Typography variant="button">
          <FormattedDisplayName type="language" value={language} />
        </Typography>
      </ChevronButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{ sx: { py: 0 } }}
      >
        <MenuItem
          selected={language === Languages.FR}
          onClick={() => setLocale(Languages.FR)}
        >
          Français
        </MenuItem>
        <MenuItem
          selected={language === Languages.EN}
          onClick={() => setLocale(Languages.EN)}
        >
          English
        </MenuItem>
        <MenuItem
          selected={language === Languages.ES}
          onClick={() => setLocale(Languages.ES)}
        >
          Español
        </MenuItem>
      </Menu>
    </>
  );
}

export default LanguageSelector;
