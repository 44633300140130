import { Typography } from '@mui/material';
import Tab from '@mui/material/Tab';
import GenericTabs from 'components/GenericTabs/GenericTabs';
import TabPanel from 'components/GenericTabs/TabPanel';
import PageLayout from 'components/PageLayout';
import useIsMobile from 'lib/useIsMobile';
import EventOperations from 'modules/Events/EventOperator/components/EventOperations/EventOperations';
import EventQRReader from 'modules/Events/EventOperator/components/EventQrReader';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SwipeableViews from 'react-swipeable-views';
import { MobileTitle, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import { EventOperatorMobileHeader } from '../styles';
import { MobileDescriptionContainer } from './styles';

export default function EventOperator() {
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [location, setLocation] = useLocation();

  const intl = useIntl();
  const isMobile = useIsMobile();

  // Parse URL query parameters to determine initial tab index
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (tab === 'qr') {
      setTabIndex(0);
    } else if (tab === 'operations') {
      setTabIndex(1);
    }
  }, [location, setLocation]);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setLocation(`${location}?tab=${newValue === 0 ? 'qr' : 'operations'}`);
  }, [setLocation, location]);

  const handleSwipe = useCallback((index: number) => {
    setTabIndex(index);
    setLocation(`${location}?tab=${index === 0 ? 'qr' : 'operations'}`);
  }, [setLocation, location]);

  const headerComponents = useMemo<React.ReactNode>(() => (
    [
      isMobile ? (
        <>
          <MobileTitle>
            <FormattedMessage id="operator" />
          </MobileTitle>
          <MobileDescriptionContainer>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="operator_description" />
            </Typography>
          </MobileDescriptionContainer>
        </>
      ) : (
        <>
          <Title>
            <FormattedMessage id="operator" />
          </Title>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="operator_description" />
          </Typography>
        </>
      ),
      (
        <GenericTabs
          value={tabIndex}
          onChange={handleChange}
        >
          <Tab label={intl.formatMessage({ id: 'qr_reader' })} />
          <Tab label={intl.formatMessage({ id: 'operations' })} />
        </GenericTabs>
      ),
    ]
  ), [intl, handleChange, tabIndex, isMobile]);

  return (
    <PageLayout>
      {isMobile ? (
        <EventOperatorMobileHeader>
          {headerComponents}
        </EventOperatorMobileHeader>
      ) : (
        headerComponents
      )}
      <SwipeableViews index={tabIndex} onChangeIndex={handleSwipe}>
        <TabPanel value={tabIndex} index={0}>
          <EventQRReader />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}>
          <EventOperations />
        </TabPanel>
      </SwipeableViews>
    </PageLayout>
  );
}
