import { ApolloError } from '@apollo/client';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import Loader from 'components/Loading/Loader';
import { useValidateEmailMutation } from 'lib/graphql/graphql';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'wouter';

import ConfirmEmailError from './ConfirmEmailError';

function ConfirmEmailLanding() {
  const [, setLocation] = useLocation();
  const [validateEmail] = useValidateEmailMutation();
  const [error, setError] = useState<ApolloError>();
  const { confirmAccount } = useContext(AuthenticatorContext);

  useEffect(() => {
    async function executeEmailValidationAsync() {
      const searchParams = new URLSearchParams(window.location.search);
      const token = searchParams.get('token');

      if (token) {
        try {
          await validateEmail({
            variables: {
              token,
            },
          });
          await confirmAccount();
        } catch (err) {
          console.error(err);
          setError(err as ApolloError);
        }
      }
    }
    executeEmailValidationAsync();
  }, [confirmAccount, setLocation, validateEmail]);

  return (
    <Loader isLoading={!error}>
      <ConfirmEmailError />
    </Loader>
  );
}

export default ConfirmEmailLanding;
