import {
  createContext,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import { HookReturnValue, Router, useLocation } from 'wouter';
import { BrowserLocationHook } from 'wouter/use-browser-location';

export type Middleware = () => boolean | Promise<boolean>;

interface IRouterMiddlewareContext {
  middlewares: MutableRefObject<Middleware[]>
  addMiddleware: (middleware: Middleware) => Middleware
  removeMiddleware: (middleware: Middleware) => void
}
const defaultRouterMiddlewareContext: IRouterMiddlewareContext = {
  middlewares: { current: [] },
  addMiddleware: () => () => false,
  removeMiddleware: () => {},
};
export const RouterMiddlewareContext = createContext(defaultRouterMiddlewareContext);

const defaultOriginalLocationContext: HookReturnValue<BrowserLocationHook> = ['', () => {}];
const OriginalLocationContext = createContext(defaultOriginalLocationContext);

function useInterceptLocation() {
  const { middlewares: middlewaresRef } = useContext(RouterMiddlewareContext);
  const [location, setLocation] = useContext(OriginalLocationContext);

  const navigate = useCallback(async (to: string | URL, { replace = false } = {}) => {
    const middlewares = middlewaresRef.current;
    if (middlewares.length > 0) {
      const results = await Promise.allSettled(middlewares.map((m) => m()));
      const prevented = results.some((r) => {
        if (r.status === 'fulfilled') {
          return !r.value;
        }
        return false;
      });
      if (!prevented) {
        setLocation(to, { replace });
      }
    } else {
      setLocation(to, { replace });
    }
  }, [middlewaresRef, setLocation]);

  return useMemo<HookReturnValue<BrowserLocationHook>>(() => ([
    location,
    navigate,
  ]), [location, navigate]);
}

function InterceptableRouter(props: PropsWithChildren) {
  const { children } = props;

  const originalLocation = useLocation();
  const middlewares = useRef<Middleware[]>([]);

  const addMiddleware = useCallback((middleware: Middleware) => {
    middlewares.current.push(middleware);
    return middleware;
  }, []);

  const removeMiddleware = useCallback((middleware: Middleware) => {
    middlewares.current = middlewares.current.filter((m) => m !== middleware);
  }, []);

  const middlewareContext = useMemo<IRouterMiddlewareContext>(() => ({
    addMiddleware,
    middlewares,
    removeMiddleware,
  }), [addMiddleware, removeMiddleware]);

  return (
    <RouterMiddlewareContext.Provider value={middlewareContext}>
      <OriginalLocationContext.Provider value={originalLocation}>
        <Router hook={useInterceptLocation}>
          {children}
        </Router>
      </OriginalLocationContext.Provider>
    </RouterMiddlewareContext.Provider>
  );
}

export default InterceptableRouter;
