import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import { RoleEnum } from 'lib/graphql/graphql';
import { useCallback, useContext } from 'react';

export type Roles = `${RoleEnum}`;

type ValidateFn = (requiredRole: Roles) => boolean;

function useValidateRole(): ValidateFn {
  const { role } = useContext(AuthenticatorContext);

  return useCallback<ValidateFn>((requiredRole) => {
    if (role === 'ADMIN') {
      return true;
    }
    return requiredRole === role;
  }, [role]);
}

export default useValidateRole;
