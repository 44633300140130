import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Content = styled.div`
  padding-bottom: 1rem;
`;

export const ChartsGrid = styled(Grid)`
  overflow-x: auto;
  margin: 1.5rem 0;
`;
