import { faCircleQuestion } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  Toolbar,
  Tooltip,
  useTheme,
} from '@mui/material';
import MovOnVIP from 'assets/images/logo/movon-logo-horizontal-small.png';
import LanguageSelector from 'components/LanguageSelector/Navigation/LanguageSelector';
import settings from 'lib/settings';
import useIsMobile from 'lib/useIsMobile';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import Avatar from './Avatar';
import { AppBar, AppLogo, AppLogoContainer } from './styles';

function Header() {
  const supportURL = settings.support;
  const [, setLocation] = useLocation();
  const { language } = useContext(LanguageContext);

  const theme = useTheme();
  const isMobile = useIsMobile();

  const handleSupport = () => {
    window.open(supportURL[language], '_blank');
  };

  return (
    <AppBar elevation={0} enableColorOnDark>
      <Toolbar>
        <AppLogoContainer>
          <AppLogo src={MovOnVIP} onClick={() => setLocation('~/')} />
        </AppLogoContainer>
        <Tooltip arrow title={<FormattedMessage id="support" />}>
          <IconButton onClick={handleSupport}>
            <FontAwesomeIcon icon={faCircleQuestion} color={theme.palette.primary.contrastText} />
          </IconButton>
        </Tooltip>
        <LanguageSelector />
        <Avatar hasText={!isMobile} hasMenu />
      </Toolbar>
    </AppBar>
  );
}

export default Header;
