import { Grid, Typography } from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import NoEvents from 'assets/images/event/no_events.png';
import EventCard from 'components/Events/EventCard';
import Loader from 'components/Loading/Loader';
import Search from 'components/Search';
import { GetAllEventsForOrganizationItem } from 'lib/graphql/entities/event/types';
import { useGetAllEventsForOrganizationLazyQuery } from 'lib/graphql/graphql';
import LanguageContext from 'providers/i18n/LanguageContext';
import useOrganization from 'providers/organization/useOrganization';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Subtitle } from 'styles/styles';

import { EventListHeaderLayout } from './styles';

function EventList() {
  const { organizationId } = useOrganization();
  const [searchValue, setSearchValue] = useState('');
  const { language } = useContext(LanguageContext);

  const [getAllEventsOrganizationQuery] = useGetAllEventsForOrganizationLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.lastEvaluated,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllEventsOrganizationQuery({
        fetchPolicy: 'network-only',
        variables: {
          organizationId: organizationId || '',
          start: pageParam,
        },
      });
      return result.data?.getAllEventsForOrganization;
    },
    queryKey: ['events'],
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const rows: GetAllEventsForOrganizationItem[] = useMemo(() => {
    const events = data?.pages.flatMap(
      (page) => page?.events || [],
    ) || [];
    return events.sort((
      a: GetAllEventsForOrganizationItem,
      b: GetAllEventsForOrganizationItem,
    // eslint-disable-next-line max-len
    ) => a.name[language]!.localeCompare(b.name[language]!));
  }, [data?.pages, language]);

  const shownEvents = useMemo<GetAllEventsForOrganizationItem[]>(() => {
    if (rows) {
      // eslint-disable-next-line max-len
      return rows.filter((event) => (event.name[language] || '').toLowerCase().includes(searchValue.toLowerCase()));
    }
    return [];
  }, [language, rows, searchValue]);

  if (error) return <div>TODO</div>;

  return (
    <>
      <EventListHeaderLayout>
        <Subtitle>
          <FormattedMessage id="event_my_events" />
        </Subtitle>
        <Search searchValue={searchValue} setSearchValue={setSearchValue} />
      </EventListHeaderLayout>
      <Grid container direction="column" gap={2} marginTop="1rem">
        <Loader isLoading={isLoading}>
          {shownEvents.map((event) => (
            <EventCard event={event} key={event.id} />
          ))}
          {(!shownEvents || shownEvents.length === 0) && (
            <Grid container direction="column" xs={12} textAlign="center" m={5}>
              <Grid item>
                <img src={NoEvents} alt="" />
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight="medium" color="#b8b8b8">
                  <FormattedMessage id="event_no_events" />
                </Typography>
              </Grid>
            </Grid>
          )}
        </Loader>
      </Grid>
    </>
  );
}

export default EventList;
