import { Grid, MenuItem } from '@mui/material';
import { IDetectedBarcode, Scanner } from '@yudiel/react-qr-scanner';
import { GetTicketItem } from 'lib/graphql/entities/tickets/types';
import {
  TicketStatus,
  useGetTicketLazyQuery,
  useGetWaitingLineOperatingInfoLazyQuery,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useCallback, useContext, useState } from 'react';

import { FullWidthSelect, SelectContainer } from '../../styles';
import LastReadQrs from './components/LastReadQRs/LastReadQrs';
import QRAlert from './components/QRAlert';
import { useEventQrReader } from './provider';
import {
  FullPage,
  LimitDesktopWidth,
  ScannerContainer,
} from './styles';

function QRCodeReader() {
  useEventDocumentTitle('qr_reader');
  const [getTicket] = useGetTicketLazyQuery({ fetchPolicy: 'network-only' });

  const [scanning, setScanning] = useState<boolean>(false);
  const { language } = useContext(LanguageContext);
  const event = useEvent();

  const {
    addRecentScan,
    operatingWaitingLine,
    waitingLines,
    setOperatingWaitingLine,
    alertProps,
    validateTickets,
    setAlertProps,
  } = useEventQrReader();

  const [getRemainingTickets] = useGetWaitingLineOperatingInfoLazyQuery();

  const handleValidateTickets = useCallback(async (tickets: GetTicketItem[]) => {
    try {
      await validateTickets(tickets);
      const { data } = await getRemainingTickets({
        variables: {
          id: tickets[0].waitingLineId,
        },
        fetchPolicy: 'network-only',
      });
      setAlertProps(
        'SUCCESS',
        tickets,
        data?.getWaitingLine?.metadata.depth ?? undefined,
      );
      addRecentScan(
        tickets.length,
        tickets[0].waitingLineId,
        data?.getWaitingLine?.metadata.depth ?? 'unknown',
      );
    } catch (err) {
      const error = err as Error;
      switch (error.message) {
        case 'Ticket already used':
          setAlertProps('ERR_ALREADY_USED');
          break;
        case 'Invalid ticket':
          setAlertProps('ERR_UNKNOWN');
          break;
        case 'Ticket not found':
          setAlertProps('ERR_NOT_FOUND');
          break;
        case 'Ticket has not been called yet':
          setAlertProps('WARN_NOT_CALLED');
          break;
        case 'Premium ticket has not been called yet':
          setAlertProps('WARN_NOT_CALLED_PREMIUM');
          break;
        default:
          setAlertProps('ERR_UNKNOWN');
          break;
      }
    }
  }, [validateTickets, setAlertProps, addRecentScan, getRemainingTickets]);

  const handleScan = useCallback(async (readCodes: IDetectedBarcode[]) => {
    if (alertProps) return;
    if (readCodes.length === 1) {
      const qrData = readCodes[0].rawValue;
      if (qrData) {
        setScanning(true);
        try {
          const { waitingLineId, ids } = JSON.parse(qrData);
          const fetchedTickets = await Promise.all(
            ids.map((id: string) => getTicket({ variables: { waitingLineId, ticketId: id } })
              .then((res) => res.data?.getTicket)),
          ) as GetTicketItem[];
          if (fetchedTickets.some((ticket) => !ticket)) {
            setAlertProps('ERR_NOT_FOUND');
            setScanning(false);
            return;
          }
          const validatedTickets = fetchedTickets.filter((ticket) => (
            ticket?.status === TicketStatus.Validated
          ));
          const invalidTickets = fetchedTickets.filter((ticket) => (
            ticket?.status === TicketStatus.Activated
          ));
          if (validatedTickets.length) {
            if (validatedTickets.length === fetchedTickets.length) {
              setAlertProps('ERR_ALREADY_USED');
              setScanning(false);
              return;
            }
            setAlertProps('ERR_VALIDATION_MIXED', invalidTickets);
            setScanning(false);
            return;
          }
          if (waitingLineId === operatingWaitingLine) {
            handleValidateTickets(fetchedTickets);
          } else {
            setAlertProps('WARN_INVALID_WAITING_LINE', fetchedTickets);
          }
        } catch (err) {
          setAlertProps('ERR_UNKNOWN');
          console.error(err);
        }
        setScanning(false);
      }
    } else {
      setAlertProps('ERR_MULTIPLE');
    }
  }, [alertProps, operatingWaitingLine, handleValidateTickets,
    setAlertProps, getTicket, setScanning]);

  const eventLanguage = event.languages[0] as Languages;

  return (
    <FullPage>
      <LimitDesktopWidth container rowSpacing={1}>
        <Grid item justifyContent="center" width="100%" display="flex">
          <SelectContainer>
            <FullWidthSelect
              value={operatingWaitingLine}
              onChange={(e) => {
                setOperatingWaitingLine(e.target.value as string);
              }}
              fullWidth
            >
              {waitingLines.length && waitingLines.map((waitingLine) => (
                <MenuItem value={waitingLine.id} key={waitingLine.id}>
                  {waitingLine.name[language] || waitingLine.name[eventLanguage] || ''}
                </MenuItem>
              ))}
            </FullWidthSelect>
          </SelectContainer>
        </Grid>
        <ScannerContainer item $boxBorderLength={7}>
          <Scanner
            // This is a workaround to reset the scanner when the waiting line changes
            key={operatingWaitingLine}
            scanDelay={5000}
            onScan={handleScan}
            constraints={{ facingMode: 'environment' }}
            formats={['qr_code']}
            components={{ onOff: false }}
            allowMultiple
          />
        </ScannerContainer>
        <Grid item flex={1} width="100%" display="flex" flexDirection="column">
          {alertProps
            ? (
              <QRAlert
                {...alertProps}
                handleValidateTicket={handleValidateTickets}
              />
            ) : <LastReadQrs scanning={scanning} />}
        </Grid>
      </LimitDesktopWidth>
    </FullPage>
  );
}

export default QRCodeReader;
