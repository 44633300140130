import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import InterceptableRouter from 'lib/routing/InterceptableRouter';
import ForgotPassword from 'modules/Auth/ForgotPassword';
import Register from 'modules/Auth/Register';
import ResetPassword from 'modules/Auth/ResetPassword';
import { useContext } from 'react';
import { Route, Switch } from 'wouter';

import LoggedInSwitch from './components/LoggedInSwitch';

function Routes() {
  const { loggedIn } = useContext(AuthenticatorContext);

  return (
    <InterceptableRouter>
      <Switch>
        {!loggedIn && (
          <>
            <Route path="/register" component={Register} />
            <Route path="/reset" component={ForgotPassword} />
            <Route path="/reset-password" component={ResetPassword} />
          </>
        )}
        <Route component={LoggedInSwitch} />
      </Switch>
    </InterceptableRouter>
  );
}

export default Routes;
