import { GetWaitingLineItem } from 'lib/graphql/entities/waitingLines/types';
import { WaitingLineStatus, WaitingLineType } from 'lib/graphql/graphql';
import { createContext } from 'react';

export type IWaitingLineContext = NonNullable<GetWaitingLineItem>;

export const DefaultWaitingLineContext: IWaitingLineContext = {
  id: '',
  name: {
    en: '',
    es: '',
    fr: '',
  },
  maxGroupSize: 0,
  price: 0,
  currency: 'CAD',
  quantity: 0,
  isFreemium: false,
  waitingLineType: WaitingLineType.Silver,
  status: WaitingLineStatus.Inactive,
  limitedQuantity: true,
  usedTickets: 0,
  activatedTickets: 0,
  statementDescriptor: '',
  description: {
    en: '',
    es: '',
    fr: '',
  },
  productDescription: {
    en: '',
    es: '',
    fr: '',
  },
};

const WaitingLineContext = createContext<IWaitingLineContext>(DefaultWaitingLineContext);

export default WaitingLineContext;
