import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck, faCircleExclamation, faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  AlertTitle,
  Breadcrumbs,
  Checkbox,
  Grid,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import FormAddressField from 'components/FormFields/FormAddressField/FormAddressField';
import FormButtons from 'components/FormFields/FormButtons';
import FormDateTimeField from 'components/FormFields/FormDateTimeField';
import FormLanguagesField from 'components/FormFields/FormLanguagesField/FormLanguagesField';
import FormMediaField from 'components/FormFields/FormMediaField/FormMediaField';
import FormTextField from 'components/FormFields/FormTextField';
import FormTimezoneField from 'components/FormFields/FormTimezoneField';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import { useGetSlugAvailabilityLazyQuery } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { getCoordinatesFromAddress, getTimezoneFromLatLng } from 'lib/maps/timezones';
import UnsavedFormQuitConfirmation from 'lib/routing/UnsavedFormQuitConfirmation';
import settings from 'lib/settings';
import { DateTime } from 'luxon';
import TranslationsTabs from 'modules/Forms/TranslationsTabs';
import {
  BaseSyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Breadcrumb,
  BreadcrumbsContainer,
  ContainedButton,
  NeutralOutlinedButton,
  PreviewText,
  Subtitle,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import {
  DevWarningContainer,
  ErrorIcon,
  ShortUrlAdornment,
  ShortUrlAdornmentContent,
  ShortUrlTextField,
  SuccessIcon,
} from './styles';

interface EventFormProps {
  title: String
  onSubmit: SubmitHandler<any>
}

function EventForm(props: EventFormProps) {
  const feedback = useFeedback();
  const { title, onSubmit } = props;
  const timer = useRef<NodeJS.Timeout | null>(null);
  const [isCheckingSlug, setIsCheckingSlug] = useState<boolean>(false);
  const [getSlugAvailability] = useGetSlugAvailabilityLazyQuery();
  const [shortUrlEnabledInDev, setShortUrlEnabledInDev] = useState<boolean>(false);
  const {
    watch,
    getValues,
    setValue,
    formState,
    trigger,
    getFieldState,
  } = useFormContext();
  const { errors, defaultValues: initialValues } = formState;
  const defaultValues = getValues();

  const [, setLocation] = useLocation();

  const [tabIndex, setTabIndex] = useState<number>(0);
  const [lang, setLang] = useState<string>(defaultValues?.languages![0] || 'en');

  const startTime = watch('startTime');
  const languages = watch('languages') as Languages[];

  const originalDesc = watch(`description.${languages![0] as Languages}`);
  const originalName = watch(`name.${languages![0] as Languages}`);

  const location = watch('location');
  const eventTimezone = watch('timezone');
  const shortUrl = watch('shortUrl');
  const shortUrlState = getFieldState('shortUrl', formState);

  const validateShortUrl = useCallback(async (value: string) => {
    if (value) {
      if (initialValues && value === initialValues.shortUrl) {
        setIsCheckingSlug(false);
        return true;
      }
      const isValid = await getSlugAvailability({
        variables: {
          fields: {
            slug: value,
          },
        },
      });
      setIsCheckingSlug(false);
      return isValid.data?.getSlugAvailability || false;
    }
    return true;
  }, [getSlugAvailability, initialValues]);

  useEffect(() => {
    if (shortUrl) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      setIsCheckingSlug(true);
      timer.current = setTimeout(async () => {
        trigger('shortUrl');
      }, 500);
    }
  }, [shortUrl, trigger]);

  const miniumEndTime = useMemo(() => {
    if (startTime) {
      if (startTime < DateTime.now()) {
        return DateTime.now();
      }
      return startTime;
    }
    return DateTime.now();
  }, [startTime]);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setLang(languages![newValue]!);
  }, [languages]);

  useEffect(() => {
    if (languages && (languages[tabIndex] !== lang || tabIndex >= languages.length)) {
      setTabIndex(0);
      setLang(languages[0]);
    }
  }, [lang, languages, tabIndex]);

  // Update timezone after clicking item from PlacesAutocomplete dropdown (FormAddressField)
  const handleSelectAddress = async (address: string) => {
    if (!location) return;

    try {
      const { lat, lng } = await getCoordinatesFromAddress(address);
      const newTimezone = await getTimezoneFromLatLng(lat, lng);
      if (newTimezone && newTimezone !== eventTimezone) {
        setValue('timezone', newTimezone);
      }
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="automatic_timezone_failed" />),
        severity: 'error',
      });
    }
  };

  const [currentPage, setCurrentPage] = useState<number>(0);

  const validateForm = async (onValid: () => void) => {
    const isValid = await trigger();

    if (isValid) {
      onValid();
    } else {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="errors_in_form" />),
        severity: 'error',
      });
    }
  };

  const handleNext = async () => {
    await validateForm(() => {
      setCurrentPage((prevPage) => prevPage + 1);
    });
  };

  const handleToTranslations = async () => {
    if (currentPage === 1) return;
    await validateForm(() => {
      setCurrentPage(1);
    });
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleShortUrlEnabledInDev = useCallback((evt: BaseSyntheticEvent) => {
    setShortUrlEnabledInDev(evt.target.checked);
  }, []);

  const isDev = import.meta.env.MODE === 'development';

  return (
    <PageLayout>
      <UnsavedFormQuitConfirmation />
      {title === 'create' && (
        <Grid container mt={2} mb={2}>
          <NeutralOutlinedButton
            onClick={() => setLocation('/')}
            startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
          >
            <FormattedMessage id="back_to_dashboard" />
          </NeutralOutlinedButton>
        </Grid>
      )}
      {languages.length > 1 && (
        <BreadcrumbsContainer>
          <Breadcrumbs separator="—">
            <Breadcrumb
              $isClickable
              color={currentPage === 0 ? 'secondary' : 'textSecondary'}
              onClick={() => setCurrentPage(0)}
            >
              <FormattedMessage id={`event_${title}`} />
            </Breadcrumb>
            <Breadcrumb
              $isClickable
              color={currentPage === 1 ? 'secondary' : 'textSecondary'}
              onClick={handleToTranslations}
            >
              <FormattedMessage id="translations" />
            </Breadcrumb>
          </Breadcrumbs>
        </BreadcrumbsContainer>
      )}
      <Grid container direction="column" gap={2}>
        <Title>
          <FormattedMessage id={`event_${title}`} />
        </Title>
        {currentPage === 0 && (
        <>
          <FormLanguagesField
            required
            name="languages"
            labelId="event_languages"
            fieldDescription="event_languages_description"
          />
          <FormTextField
            required
            labelId="event_name"
            name={`name.${languages[0]}`}
            key={`name.${languages[0]}`}
            rules={{ maxLength: 50 }}
          />
          {isDev && (
            <Alert severity="warning" elevation={2}>
              <AlertTitle>
                <FormattedMessage id="short_url_dev_warning" />
              </AlertTitle>
              <DevWarningContainer>
                <FormattedMessage id="short_url_dev_warning_description" />
                <div>
                  <FormattedMessage id="short_url_dev_warning_checkbox" />
                  <Checkbox
                    checked={shortUrlEnabledInDev}
                    onChange={handleShortUrlEnabledInDev}
                  />
                </div>
              </DevWarningContainer>
            </Alert>
          )}
          <ShortUrlTextField
            labelId="short_url"
            name="shortUrl"
            key="shortUrl"
            fieldDescription="event_short_url_description"
            disabled={!shortUrlEnabledInDev && isDev}
            InputProps={{
              startAdornment: (
                <ShortUrlAdornment position="start">
                  <ShortUrlAdornmentContent>
                    <FontAwesomeIcon icon={faLink} />
                    {settings.urlShortener.rootUrl}
                  </ShortUrlAdornmentContent>
                </ShortUrlAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  {shortUrl && (
                    <>
                      {isCheckingSlug && (
                        <CenteredSpinner size="1rem" />
                      )}
                      {!isCheckingSlug && shortUrlState.invalid && (
                        <Tooltip title={<FormattedMessage id="short_url_taken" />} arrow>
                          <ErrorIcon icon={faCircleExclamation} />
                        </Tooltip>
                      )}
                      {!isCheckingSlug && !shortUrlState.invalid && (
                        <Tooltip title={<FormattedMessage id="short_url_available" />} arrow>
                          <SuccessIcon icon={faCircleCheck} />
                        </Tooltip>
                      )}
                    </>
                  )}
                </InputAdornment>
              ),
            }}
            rules={{
              maxLength: 50,
              validate: {
                isAvailable: async (value) => {
                  const isAvailable = await validateShortUrl(value);
                  if (!isAvailable) {
                    return 'short_url_taken';
                  }
                  return true;
                },
              },
            }}
          />
          <FormTextField
            fullWidth
            multiline
            rows={3}
            labelId="description"
            name={`description.${languages[0]}`}
            key={`description.${languages[0]}`}
            fieldDescription="create_event_short_description"
            rules={{
              maxLength: 300,
            }}
          />
          <FormMediaField
            name="image"
            labelId="event_image"
            fieldDescription="event_image_description"
            required
          />
          <FormAddressField
            name="location"
            required
            labelId="event_location"
            onSelectAddress={handleSelectAddress}
          />
          <FormTimezoneField
            name="timezone"
            required
            labelId="event_timezone"
          />
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              <FormDateTimeField
                name="startTime"
                labelId="event_begin"
                required
                rules={{
                  validate: (value) => {
                    if (!value?.isLuxonDateTime || !value.isValid) {
                      return 'event_date_invalid';
                    }
                    return true;
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormDateTimeField
                minDateTime={miniumEndTime}
                name="endTime"
                labelId="event_end"
                required
                rules={{
                  validate: (value) => {
                    if (!value?.isLuxonDateTime || !value.isValid) {
                      return 'event_date_invalid';
                    }
                    if (value <= startTime) {
                      return 'event_end_error';
                    } if (value < DateTime.now()) {
                      return 'event_end_error_future';
                    }
                    return true;
                  },
                }}
              />
            </Grid>
          </Grid>
        </>
        )}
        {currentPage === 1 && (
        <>
          <TranslationsTabs
            errors={errors}
            tabIndex={tabIndex}
            handleChange={handleChange}
            formState={formState}
            languages={languages}
          />
          <Subtitle>
            <FormattedMessage id="translations" />
          </Subtitle>
          <FormTextField
            required
            labelId="event_name"
            name={`name.${lang}`}
            key={`name.${lang}`}
            rules={{ maxLength: 50 }}
          />
          {tabIndex > 0 && originalName && (
            <PreviewText>
              {originalName}
            </PreviewText>
          )}
          <FormTextField
            fullWidth
            multiline
            rows={3}
            labelId="description"
            name={`description.${lang}`}
            key={`description.${lang}`}
            fieldDescription="create_event_short_description"
            rules={{
              maxLength: 300,
            }}
          />
          {tabIndex > 0 && originalDesc && (
            <PreviewText>
              {originalDesc}
            </PreviewText>
          )}
        </>
        )}
        <Grid container justifyContent="flex-end" gap={1}>
          {currentPage > 0 && (
            <NeutralOutlinedButton
              onClick={handlePrevious}
              startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
            >
              <FormattedMessage id="previous" />
            </NeutralOutlinedButton>
          )}
          <EnsureRole requiredRole="ADMIN">
            {languages && (
              languages.length > 1 && currentPage === 0 ? (
                <ContainedButton
                  color="secondary"
                  onClick={handleNext}
                  endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  <FormattedMessage id="next" />
                </ContainedButton>
              ) : (
                <FormButtons noGrid={languages.length > 1} submit={onSubmit} />
              )
            )}
          </EnsureRole>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default EventForm;
