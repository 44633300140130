import { DateTimePickerToolbar } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import styled from 'styled-components';

// eslint-disable-next-line import/prefer-default-export
export const DateToolbar = styled(DateTimePickerToolbar<DateTime>)`
  .MuiPickersToolbar-root {
    pointer-events: none;
  }
`;
