import { ListItemButton as MUIListItemButton } from '@mui/material';
import styled from 'styled-components';

interface ListItemButtonProps {
  $active?: boolean
  $disabled?: boolean
}

// eslint-disable-next-line import/prefer-default-export
export const ListItemButton = styled(MUIListItemButton).attrs({
  disableRipple: true,
}) <ListItemButtonProps>`
  border-radius: 0.5rem;
  width: 45px;
  height: 45px;
  justify-content: center;

  ${({ $active }) => $active && `
    background-color: #eee;
  `}

  ${({ $disabled }) => $disabled && `
    opacity: 0.5;
    pointer-events: none;
  `}
`;

export const CheckContainer = styled.div`
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #74c365;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
`;
