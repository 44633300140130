import { IndicatorSpan, StyledTabs } from './styles';

interface GenericTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export default function GenericTabs(props: GenericTabsProps) {
  return (
    <StyledTabs
      variant="fullWidth"
      TabIndicatorProps={{ children: <IndicatorSpan /> }}
      {...props}
    />
  );
}
