import { Switch, SwitchProps } from '@mui/material';
import {
  FieldValues,
  RegisterOptions,
  useController,
} from 'react-hook-form';

import FormField, { FormFieldProps } from './FormField';

type CustomSwitchProps = Omit<SwitchProps, 'onChange' | 'onBlur' | 'inputRef' | 'checked' | 'required' | 'name' | 'inputProps' | 'type' | 'defaultChecked'>;

interface FormBooleanProps extends FormFieldProps, CustomSwitchProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'min' | 'max'>
  sideBySide?: boolean
}

function FormBooleanField(props: FormBooleanProps) {
  const {
    labelId,
    name,
    rules,
    sideBySide = false,
    helperTextId,
    required,
    ...switchProps
  } = props;

  const { field, formState } = useController({ name, rules });

  return (
    <FormField
      name={name}
      labelId={labelId}
      sideBySide={sideBySide}
      required={required}
      helperTextId={helperTextId}
    >
      <Switch
        onBlur={field.onBlur}
        onChange={field.onChange}
        inputRef={field.ref}
        checked={field.value !== undefined ? field.value : formState.defaultValues?.[name]}
        {...switchProps}
      />
    </FormField>
  );
}

export default FormBooleanField;
