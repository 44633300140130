import {
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from '@mui/material';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import FormPasswordField from 'components/FormFields/FormPasswordField';
import FormTextField from 'components/FormFields/FormTextField';
import { useCustomForm } from 'lib/form/useCustomForm';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import InviteTokenContext from 'providers/token/InviteTokenContext';
import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  ErrorMessage,
  TextButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import AuthForm from './AuthForm';
import { AccountNeeded, ActionButton } from './styles';

interface LoginInputs {
  email: string
  password: string
}

function Login() {
  useFormattedDocumentTitle('login');
  const {
    error,
    login,
    performingLogin,
  } = useContext(AuthenticatorContext);
  const { inviteToken, userEmail } = useContext(InviteTokenContext);
  const formControl = useCustomForm<LoginInputs>();
  const { handleSubmit, setValue } = formControl;

  const [, setLocation] = useLocation();

  useEffect(() => {
    if (inviteToken) {
      setValue('email', userEmail!);
    }
  }, [inviteToken, setValue, userEmail]);

  const onSubmit: SubmitHandler<LoginInputs> = useCallback(async (formData) => {
    const { email, password } = formData;
    try {
      await login(email, password, inviteToken);
    } catch (err) {
      console.error(err);
    }
  }, [inviteToken, login]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formControl}>
        <AuthForm>
          <Container>
            <Grid container justifyContent="center" alignContent="center">
              <Grid container direction="column" width="30rem">
                <CardHeader
                  title={(
                    <Title>
                      <FormattedMessage id="login" />
                    </Title>
                  )}
                  subheader={(
                    <span>
                      <FormattedMessage id="dont_have_an_account" />
                      <TextButton color="secondary" onClick={() => setLocation('~/register')}>
                        <FormattedMessage id="sign_up" />
                      </TextButton>
                    </span>
                  )}
                />
                <CardContent>
                  {inviteToken && (
                    <Grid container gap={2} marginBottom="1rem">
                      <AccountNeeded>
                        <span>
                          <FormattedMessage id="account_needed" />
                        </span>
                      </AccountNeeded>
                    </Grid>
                  )}
                  <Grid container gap={2}>
                    <FormTextField
                      type="email"
                      innerFieldLabel="enter_email"
                      name="email"
                      autoComplete="email"
                      required
                      labelId="email"
                      size="medium"
                      defaultValue={userEmail}
                    />
                    <FormPasswordField
                      name="password"
                      autoComplete="current-password"
                      required
                      labelId="password"
                      innerFieldLabel="enter_password"
                      size="medium"
                    />
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <TextButton color="secondary" onClick={() => setLocation('~/reset')}>
                        <FormattedMessage id="forgot_password" />
                      </TextButton>
                    </Grid>
                    {error && error.message === 'Failed to authenticate user' && (
                      <ErrorMessage>
                        <FormattedMessage id="invalid_credentials" />
                      </ErrorMessage>
                    )}
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <ActionButton
                    color="secondary"
                    disabled={performingLogin}
                    loading={performingLogin}
                    type="submit"
                  >
                    <FormattedMessage id="login" />
                  </ActionButton>
                </CardActions>
              </Grid>
            </Grid>
          </Container>
        </AuthForm>
      </FormProvider>
    </form>
  );
}

export default Login;
