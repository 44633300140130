import { Card } from '@mui/material';
import { GridSpacing, ResponsiveStyleValue } from '@mui/system';
import { PropsWithChildren } from 'react';

import { ClickableCard, PaddedGrid } from './styles';

interface ListCardProps {
  clickable?: boolean
  onClick?: () => void
  spacing?: ResponsiveStyleValue<GridSpacing>
  className?: string
}

function ListCard(props: PropsWithChildren<ListCardProps>) {
  const {
    children,
    clickable,
    onClick,
    spacing = 2,
    className,
  } = props;

  const content = (
    <PaddedGrid container spacing={spacing} className={className}>
      {children}
    </PaddedGrid>
  );

  return (
    <Card variant="outlined">
      {clickable ? (
        <ClickableCard
          role="button"
          onClick={onClick}
        >
          {content}
        </ClickableCard>
      ) : content}
    </Card>
  );
}

export default ListCard;
