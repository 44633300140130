import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvitationsTable from 'components/Invitations/InvitationsTable';
import { ListCard, ListCardSection, ListCardTitle } from 'components/ListCard';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import {
  EventRoleInput,
  InvitationStatusEnum,
  RoleEnum,
  useGetAllEventsForOrganizationLazyQuery,
  useGetOrganizationDetailsQuery,
} from 'lib/graphql/graphql';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BackgroundlessIconButton,
  CollapseSection,
  FullWidthCollapse,
} from '../Events/EventWaitingLines/styles';
import InvitationItem from './InvitationItem';
import { EndSection, FullWidthPaper, StyledChip } from './styles';

type Invitation = {
  events: EventRoleInput[];
  organizationId: string;
  status: InvitationStatusEnum;
  userId?: string | null;
  id: string;
};

interface InvitationCardProps {
  invitation: Invitation
  userEmail: string;
}

function InvitationCard(props: InvitationCardProps) {
  const { invitation, userEmail } = props;
  const [allEvents, setAllEvents] = useState<any[]>([]);

  const [getAllEventsForOrganization, {
    loading: eventsLoading,
  }] = useGetAllEventsForOrganizationLazyQuery();

  const [showEvents, setShowEvents] = useState<boolean>(false);

  const { data, loading } = useGetOrganizationDetailsQuery({
    variables: {
      id: invitation.organizationId,
    },
  });
  const orgName = useMemo(() => data?.getOrganizationDetails?.name || '', [data?.getOrganizationDetails?.name]);

  const fetchAllEvents = useCallback(async () => {
    const orgEventsData = await getAllEventsForOrganization({
      variables: {
        organizationId: invitation.organizationId,
      },
      fetchPolicy: 'network-only',
    });

    const tempAllEvents = orgEventsData.data?.getAllEventsForOrganization?.events.map(
      (event) => ({
        eventId: event.id,
        role: RoleEnum.Admin,
        organizationId: invitation.organizationId,
        userId: invitation.userId,
        id: invitation.id,
        userEmail,
      }),
    );
    setAllEvents(tempAllEvents || []);
  }, [getAllEventsForOrganization, invitation, userEmail]);

  useEffect(() => {
    if (invitation.events.length > 0) {
      if (invitation.events[0]!.eventId === 'all' && invitation.events[0]!.role === RoleEnum.Admin) {
        fetchAllEvents();
      } else {
        const events = invitation.events.map((event) => ({
          eventId: event?.eventId,
          role: event?.role,
          organizationId: invitation.organizationId,
          userId: invitation.userId,
          id: invitation.id,
          userEmail,
        }));
        setAllEvents(events);
      }
    }
  }, [fetchAllEvents, invitation, userEmail]);

  if (loading || eventsLoading) return <CenteredSpinner />;

  return (
    <FullWidthPaper elevation={showEvents ? 3 : 0}>
      <ListCard spacing={4}>
        <ListCardSection lg={4.5}>
          <ListCardTitle title={orgName} />
        </ListCardSection>
        <ListCardSection lg={3.5}>
          <StyledChip
            label={<FormattedMessage id={`invitation_status_${invitation.status.toLowerCase()}`} />}
            color={invitation.status === InvitationStatusEnum.Accepted ? 'success' : 'default'}
          />
        </ListCardSection>
        <ListCardSection lg={2}>
          <EndSection>
            <BackgroundlessIconButton
              onClick={() => setShowEvents((current: boolean) => !current)}
              color="primary"
              size="small"
              disableRipple
              disableFocusRipple
              disableTouchRipple
            >
              <FontAwesomeIcon icon={showEvents ? faChevronDown : faChevronRight} />
            </BackgroundlessIconButton>
          </EndSection>
        </ListCardSection>
        <CollapseSection xs={12}>
          <FullWidthCollapse
            in={showEvents}
            mountOnEnter
            unmountOnExit
          >
            <InvitationsTable
              headers={['event', 'role', 'actions']}
              items={allEvents}
              ItemComponent={InvitationItem}
            />
          </FullWidthCollapse>
        </CollapseSection>
      </ListCard>
    </FullWidthPaper>
  );
}

export default InvitationCard;
