import { AgChartOptions, AgDonutSeriesOptions } from 'ag-charts-community';
import {
  AG_CHARTS_LOCALE_EN_US,
  AG_CHARTS_LOCALE_ES_ES,
  AG_CHARTS_LOCALE_FR_FR,
} from 'ag-charts-locale';
import chroma from 'chroma-js';
import getTextColor from 'lib/mui/getTextColor';

// Map locale from useIntl to AG Charts locale
const getAgChartsLocale = (language: string) => {
  switch (language) {
    case 'fr':
      return AG_CHARTS_LOCALE_FR_FR;
    case 'es':
      return AG_CHARTS_LOCALE_ES_ES;
    case 'en':
    default:
      return AG_CHARTS_LOCALE_EN_US;
  }
};

const generateColorPalette = (numColors: number) => chroma.scale(['#c6dbdc', '#3c8896', '#35608b']).mode('lch').colors(numColors);

// eslint-disable-next-line import/prefer-default-export
export function createDonutChartOptions(
  chartData: any[],
  locale: string,
  innerLabel: string,
): AgChartOptions & { series: AgDonutSeriesOptions[] } {
  const colors = generateColorPalette(chartData.length);

  const totalAmount = chartData.reduce((sum, item) => sum + item.amount, 0);

  const hasUnits = chartData.some((item) => item.unit);
  const { unit } = chartData[0] || '';

  return {
    background: {
      fill: 'transparent',
    },
    data: chartData,
    series: [
      {
        type: 'donut',
        angleKey: 'amount',
        calloutLabelKey: 'amount',
        calloutLabel: {
          enabled: true,
          formatter: (params) => {
            const value = (params.datum.amount / totalAmount) * 100;
            const percentage = value % 1 === 0 ? value.toFixed(0) : parseInt(value.toFixed(2), 10);
            return `${percentage}%`;
          },
          fontSize: 16,
          color: 'black',
          fontFamily: 'Montserrat, sans-serif',
        },
        calloutLine: {
          enabled: true,
          colors: ['transparent'],
        },
        sectorLabelKey: 'asset',
        sectorLabel: {
          enabled: false,
        },
        innerRadiusRatio: 0.6,
        outerRadiusRatio: 0.75,
        innerLabels: [
          {
            text: `${hasUnits ? totalAmount.toFixed(2) + unit : totalAmount}`,
            spacing: 4,
            fontSize: 20,
            fontWeight: 'bolder',
            color: 'black',
            fontFamily: 'Montserrat, sans-serif',
          },
          {
            text: innerLabel,
            color: 'black',
            fontSize: 16,
            fontFamily: 'Montserrat, sans-serif',
          },
        ],
        tooltip: {
          enabled: true,
          renderer: (params) => {
            const { color, datum } = params;
            const value = (datum.amount / totalAmount) * 100;
            const percentage = value % 1 === 0 ? value.toFixed(0) : parseInt(value.toFixed(2), 10);
            return {
              title: `
                <div style="color: ${getTextColor(color)}">
                  ${datum.asset}
                </div>`,
              content: `
                <div>
                  ${datum.unit ? datum.amount.toFixed(2) + datum.unit : datum.amount} (${percentage}%)
                </div>`,
            };
          },
        },
        highlightStyle: {
          item: {
            fill: 'transparent',
            strokeWidth: 0,
          },
          series: {
            dimOpacity: 0,
            strokeWidth: 0,
            enabled: false,
          },
        },
        title: {
          enabled: false,
        },
        label: {
          enabled: false,
        },
        cornerRadius: 10,
        fills: colors,
      },
    ],
    legend: {
      enabled: true,
      position: 'right',
      toggleSeries: false,
      item: {
        label: {
          fontSize: 16,
          fontFamily: 'Montserrat, sans-serif',
          color: 'black',
        },
        marker: {
          size: 20,
          shape: 'circle',
        },
      },
    },
    locale: {
      localeText: getAgChartsLocale(locale),
    },
  };
}
