import { RadioGroup } from '@mui/material';
import { PropsWithChildren } from 'react';
import {
  FieldPath,
  FieldValues,
  RegisterOptions,
  useController,
} from 'react-hook-form';

interface FormRadioGroupFieldProps<
TFieldValues extends FieldValues,
TName extends FieldPath<TFieldValues>,
> extends PropsWithChildren {
  name: TName
  rules?: Omit<RegisterOptions<TFieldValues, TName>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
}

function FormRadioGroupField<
TFieldValues extends FieldValues = FieldValues,
TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(props: FormRadioGroupFieldProps<TFieldValues, TName>) {
  const { children, name, rules } = props;

  const { field } = useController({ name, rules });

  return (
    <RadioGroup
      {...field}
    >
      {children}
    </RadioGroup>
  );
}

export default FormRadioGroupField;
