import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Grid,
  IconButton,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import LanguageSelector from 'components/LanguageSelector/Auth/LanguageSelector';
import settings from 'lib/settings';
import useIsMobile from 'lib/useIsMobile';
import LegalAgreements from 'modules/LegalAgreements/LegalAgreements';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  BackgroundImage,
  BigStyledDialog,
  FooterButton,
  FooterContainer,
  Logo,
  SeparatingDot,
  TopLeftLogo,
} from './styles';

interface AuthFormProps {
  children: React.ReactNode;
}

function AuthForm(props: AuthFormProps) {
  const { children } = props;

  const supportURL = settings.support;
  const { language } = useContext(LanguageContext);

  const theme = useTheme();
  const [modalOpen, setModalOpen] = useState(false);
  const isMobile = useIsMobile();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [title, setTitle] = useState('');
  const handleSelect = (selection: string) => {
    setTitle(selection);
    setModalOpen(true);
  };

  const handleSupport = () => {
    window.open(supportURL[language], '_blank');
  };

  return (
    <>
      <Grid container direction="row" height="100vh">
        {!isMobile && (
        <>
          <Grid item xs={0} sm={6} direction="column" position="relative">
            <BackgroundImage />
          </Grid>
          <TopLeftLogo width="200px" />
        </>
        )}
        <Grid container item xs={12} md={6} direction="column">
          <Grid item container justifyContent="flex-end">
            <div>
              <Tooltip arrow title={<FormattedMessage id="support" />}>
                <IconButton onClick={handleSupport}>
                  <FontAwesomeIcon icon={faQuestionCircle} color={theme.palette.text.secondary} />
                </IconButton>
              </Tooltip>
              <LanguageSelector />
            </div>
          </Grid>
          {isMobile && (
          <Grid item container justifyContent="center">
            <Logo width="250px" />
          </Grid>
          )}
          <Grid item container alignItems="center" flexGrow={1}>
            { children }
          </Grid>
          <Grid item container>
            <FooterContainer $isSmall={isSmall}>
              <FooterButton color="secondary" onClick={() => handleSelect('terms_conditions')}>
                <FormattedMessage id="terms_conditions" />
              </FooterButton>
              {!isSmall && <SeparatingDot />}
              <FooterButton color="secondary" onClick={() => handleSelect('privacy_policy')}>
                <FormattedMessage id="privacy_policy" />
              </FooterButton>
            </FooterContainer>
          </Grid>
        </Grid>
      </Grid>
      <BigStyledDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <LegalAgreements title={title} />
      </BigStyledDialog>
    </>
  );
}

export default AuthForm;
