import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import styled from 'styled-components';

export const ChevronButton = styled(Button)`
  padding: 1rem;
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.primary.light};
  font-size: small !important;
`;
