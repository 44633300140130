import { faChevronLeft } from '@fortawesome/pro-light-svg-icons';
import { faSync } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import Loader from 'components/Loading/Loader';
import PageLayout from 'components/PageLayout';
import StripeAccountBanner from 'components/Stripe/Accounts/StripeAccountBanner';
import BankTransfersCard from 'components/Stripe/Payouts/BankTransfersCard';
import PayoutCards from 'components/Stripe/Payouts/PayoutCards';
import {
  Organization,
  useGetOrganizationQuery,
  useGetOrganizationStripeBalanceQuery,
  useGetOrganizationStripePayoutsQuery,
} from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import useOrganization from 'providers/organization/useOrganization';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Stripe from 'stripe';
import {
  ContainedLoadingButton, Content, NeutralOutlinedButton, Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

interface Total {
  [currency: string]: number;
}

interface Balance extends Stripe.Balance {
  total: Total;
}

function EventPayouts() {
  useFormattedDocumentTitle('payouts');
  const [, setLocation] = useLocation();
  const { organizationId } = useOrganization();

  const {
    data: organizationData,
    loading: loadingOrganization,
    refetch: refetchOrganization,
  } = useGetOrganizationQuery({
    variables: {
      id: organizationId!,
    },
    fetchPolicy: 'network-only',
  });
  const organization = organizationData?.getOrganization as Organization;

  const {
    data: balancesData,
    loading: loadingBalances,
    refetch: refetchBalances,
  } = useGetOrganizationStripeBalanceQuery({
    variables: { id: organization?.id },
    skip: !organization,
  });

  const stripeBalances = balancesData?.getOrganizationStripeBalance as Balance;
  const total = stripeBalances?.total || {};

  const balances = useMemo(() => {
    const totals: Record<string, { available: number; pending: number }> = {};

    if (stripeBalances) {
      const allBalances = [...stripeBalances.available, ...stripeBalances.pending];

      allBalances.forEach((balance) => {
        const currency = balance.currency.toUpperCase();
        if (!totals[currency]) {
          totals[currency] = { available: 0, pending: 0 };
        }
        if (stripeBalances.available.includes(balance)) {
          totals[currency].available += balance.amount;
        } else if (stripeBalances.pending.includes(balance)) {
          totals[currency].pending += balance.amount;
        }
      });
    }

    return totals;
  }, [stripeBalances]);

  const {
    data: payoutsData,
    loading: loadingPayouts,
    refetch: refetchPayouts,
  } = useGetOrganizationStripePayoutsQuery({
    variables: { id: organization?.id },
    skip: !organization,
  });

  const payouts = payoutsData?.getOrganizationStripePayouts as Stripe.Payout[];

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleRefresh = () => {
    setIsRefreshing(true);
    refetchOrganization();
    refetchBalances();
    refetchPayouts();
    setIsRefreshing(false);
  };

  return (
    <Loader isLoading={loadingOrganization || loadingBalances || loadingPayouts}>
      <PageLayout>
        <NeutralOutlinedButton
          onClick={() => setLocation('/')}
          startIcon={<FontAwesomeIcon size="1x" icon={faChevronLeft} />}
        >
          <FormattedMessage id="back_to_dashboard" />
        </NeutralOutlinedButton>
        <Content>
          <Grid container justifyContent="space-between">
            <Title>
              <FormattedMessage id="payouts" />
            </Title>
            <ContainedLoadingButton
              loading={isRefreshing}
              onClick={handleRefresh}
              startIcon={<FontAwesomeIcon icon={faSync} />}
            >
              <FormattedMessage id="refresh" />
            </ContainedLoadingButton>
          </Grid>
          <Grid container mt={2} mb={2}>
            {organization && (
              <StripeAccountBanner organization={organization} onRefetch={refetchOrganization} />
            )}
          </Grid>
          <Grid container direction="column" mt={4} mb={4}>
            <PayoutCards total={total} balances={balances} />
          </Grid>

          <Grid container direction="column" mt={2} mb={2}>
            <BankTransfersCard payouts={payouts} />
          </Grid>
        </Content>
      </PageLayout>
    </Loader>
  );
}

export default EventPayouts;
