import {
  createTheme,
  PaletteOptions,
  paperClasses,
  ThemeProvider,
} from '@mui/material';
import { Theme, TypeBackground } from '@mui/material/styles';
import muiLocales from 'lib/mui/locales';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo } from 'react';

export interface AppThemeProps {
  children: React.ReactNode
}

export interface AppTypeBackground extends TypeBackground {
  dark: string;
  darker: string;
}
export interface AppPaletteOptions extends PaletteOptions {
  background: Partial<AppTypeBackground>;
}

function AppThemeProvider(props: AppThemeProps) {
  const { children } = props;
  const { language } = useContext(LanguageContext);

  const theme: Theme = useMemo(() => {
    // TODO: Add real color palettes

    /*
     Light palette
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const light: AppPaletteOptions = {
      mode: 'light',
      text: {
        primary: '#000000',
        secondary: '#777777',
        disabled: '#c2c2c2',
      },
      background: {
        default: '#fafafa',
        paper: '#ffffff',
        dark: '#e0e0e0',
        darker: '#c0c0c0',
      },
      primary: {
        main: '#001f3e',
        light: '#486b94',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#3c8996',
        light: '#7cc6c9',
        contrastText: '#ffffff',
      },
      error: {
        main: '#e85050',
        contrastText: '#ffffff',
      },
      warning: {
        main: '#f67044',
        contrastText: '#ffffff',
      },
      success: {
        main: '#72d59c',
        contrastText: '#ffffff',
      },
      info: {
        main: '#3c8996',
        contrastText: '#ffffff',
        dark: '#e0e0e0',
      },
    };

    /*
    Dark palette
    */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const dark: PaletteOptions = {
      mode: 'dark',
      // TODO: Add dark mode colors
    };

    const palette: AppPaletteOptions = light;
    return createTheme(
      {
        drawer: {
          minWidth: 80,
        },
        mixins: {
          toolbar: {
            minHeight: 64,
          },
        },
        palette,
        typography: {
          fontFamily: 'Montserrat, sans-serif',
          fontWeightLight: 400,
          fontWeightRegular: 500,
          fontWeightMedium: 600,
          fontWeightBold: 700,
        },
        components: {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                borderRadius: '10px',
              },
            },
          },
          MuiInputBase: {
            styleOverrides: {
              root: {
                background: 'white',
              },
              multiline: {
                paddingTop: '0 !important',
                paddingBottom: '0 !important',
              },
              inputSizeSmall: {
                fontSize: '0.9rem',
                paddingTop: '10px !important',
                paddingBottom: '10px !important',
              },
            },
          },
          MuiPopper: {
            styleOverrides: {
              root: {
                [`& .${paperClasses.root}`]: {
                  borderRadius: '0.5rem',
                  boxShadow: 'none',
                  border: '1px solid #e0e0e0',
                },
              },
            },
          },
          MuiMenu: {
            styleOverrides: {
              paper: {
                borderRadius: '0.5rem',
                boxShadow: 'none',
                border: '1px solid #e0e0e0',
              },
            },
          },
        },
      },
      ...muiLocales[language],
    );
  }, [language]);

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
}

export default AppThemeProvider;
