import { Typography } from '@mui/material';
import styled from 'styled-components';

export const SectionContainer = styled.div`
  background: ${({ theme }) => theme.palette.background.paper};
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  height: auto;
  display: flex;
  padding: 1rem;
`;

export const StripeSection = styled.div`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 1rem;
  text-align: center;
  flex-direction: column;
`;

export const RoundCircleBackground = styled.div`
  background-color: #c8d4d4;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  padding: 10px;
`;

export const CenteredIcon = styled.div`
  height: 100%;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: center;
`;

export const StripeDescription = styled(Typography).attrs({
  variant: 'body2',
  color: 'textSecondary',
})`
  padding: 0.3rem 0 0;
  text-align: center;
`;

