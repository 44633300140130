import { TextField } from '@mui/material';
import styled from 'styled-components';

export const ControlsLayout = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const NoControllerTextField = styled(TextField)`
  & input::-webkit-outer-spin-button,
  & input::-webkit-inner-spin-button {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  /* stylelint-disable-next-line selector-attribute-quotes */
  & input[type=number] {
    /* stylelint-disable-next-line property-no-vendor-prefix */
    -moz-appearance: textfield;
    appearance: textfield;
  }
`;
