import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  ContainedButton,
  RoundCircleBackground,
  RoundCircleImage,
  Separator,
  SmallTitle,
} from 'styles/styles';

import { CauseOptionCardContainer, CauseOptionCardContent, Label } from './styles';

interface CauseOptionCardProps {
  icon: string;
  title: string;
  description: string;
  label: string;
  onClick: () => void;
}

function CauseOptionCard(props: CauseOptionCardProps) {
  const {
    icon,
    title,
    description,
    label,
    onClick,
  } = props;

  return (
    <CauseOptionCardContainer variant="outlined">
      <CauseOptionCardContent>
        <Grid container direction="column" justifyContent="space-between" textAlign="center">
          <Grid item marginBottom={2}>
            <RoundCircleBackground>
              <RoundCircleImage src={icon} />
            </RoundCircleBackground>
          </Grid>
          <Grid item>
            <SmallTitle marginBottom="1rem">
              <FormattedMessage id={title} />
            </SmallTitle>
            <Typography variant="body2">
              <FormattedMessage id={description} />
            </Typography>
          </Grid>
          <Grid item>
            <Separator />
            <ContainedButton
              color="secondary"
              endIcon={<FontAwesomeIcon icon={faChevronRight} />}
              onClick={onClick}
              fullWidth
            >
              <Label>
                <FormattedMessage id={label} />
              </Label>
            </ContainedButton>
          </Grid>
        </Grid>
      </CauseOptionCardContent>
    </CauseOptionCardContainer>
  );
}

export default CauseOptionCard;
