import { useTheme } from '@mui/material/styles';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContainedButton } from 'styles/styles';

import { useEventQrReader } from '../../provider';
import { getCodeInfo, OperatingStatusCode } from '../../statusCodes';
import { ItemPassInfo } from '../../styles';
import { LeftInWaitingLine } from '../LastReadQRs/styles';
import {
  AlertButtonsContainer,
  AlertContainer,
  ContentContainer,
  Icon,
  IconContainer,
  LinearCountdown,
  QRAlertTitle,
  QRAlertTitleContainer,
  TickerNumber,
  WaitingLineName,
  ZeroSpaceContainer,
} from './styles';
import { QRAlertProps } from './types';

function QRAlert(props: QRAlertProps) {
  const {
    status,
    tickets,
    handleValidateTicket,
    remainingTickets,
  } = props;
  const theme = useTheme();
  const { closeAlert, waitingLines, operatingWaitingLine } = useEventQrReader();
  const intl = useIntl();
  const [alertStatus, setAlertStatus] = useState<OperatingStatusCode>(status);
  const event = useEvent();
  const { language } = useContext(LanguageContext);

  const statusColor: { [key: string]: string } = useMemo(() => ({
    success: theme.palette.success.main,
    error: theme.palette.error.main,
    warning: theme.palette.text.secondary,
  }), [theme]);

  const codeInfo = useMemo(() => getCodeInfo(alertStatus), [alertStatus]);
  const color = statusColor[codeInfo.severity];

  useEffect(() => {
    setAlertStatus(status);
  }, [status]);

  useEffect(() => {
    const timerInterval = setTimeout(closeAlert, codeInfo.duration * 1000);

    return () => clearTimeout(timerInterval);
  }, [closeAlert, codeInfo.duration]);

  const handleOtherWaitingLineValidate = useCallback(async () => {
    if (handleValidateTicket && tickets) {
      await handleValidateTicket(tickets);
    }
  }, [tickets, handleValidateTicket]);

  const handleMixedValidation = useCallback(async () => {
    if (handleValidateTicket && tickets) {
      if (tickets[0].waitingLineId !== operatingWaitingLine) {
        setAlertStatus('WARN_INVALID_WAITING_LINE');
      } else {
        await handleOtherWaitingLineValidate();
      }
    }
  }, [handleValidateTicket, handleOtherWaitingLineValidate, operatingWaitingLine, tickets]);

  const ticketWaitingLine = useMemo<EventWaitingLine | null>(() => {
    if (!waitingLines.length || !tickets || !tickets.length) {
      return null;
    }
    const waitingLine = waitingLines.find((q) => q.id === tickets[0].waitingLineId);
    if (!waitingLine) {
      return null;
    }
    return waitingLine;
  }, [waitingLines, tickets]);

  return (
    <AlertContainer>
      <ZeroSpaceContainer>
        <IconContainer $color={color}>
          <Icon icon={codeInfo.icon} size="xl" />
        </IconContainer>
      </ZeroSpaceContainer>
      <ContentContainer>
        <QRAlertTitleContainer>
          <QRAlertTitle $color={color}>
            <FormattedMessage id={codeInfo.title} />
          </QRAlertTitle>
          {codeInfo.severity === 'success' && (
            <WaitingLineName>
              {ticketWaitingLine?.name[language] ?? ticketWaitingLine?.name[event.languages[0] as Languages] ?? ''}
            </WaitingLineName>
          )}
        </QRAlertTitleContainer>
        {codeInfo.severity === 'success' ? (
          <ItemPassInfo>
            <div>
              <FormattedMessage id="qr_reader_scanned_passes" values={{ nbr: tickets?.length ?? 0 }} />
            </div>
            <LeftInWaitingLine label={intl.formatMessage({ id: 'qr_reader_pass_left' }, { nbr: remainingTickets })} />
          </ItemPassInfo>
        ) : (
          <span>
            <FormattedMessage
              id={codeInfo.message}
              values={
                {
                  number: (
                    <TickerNumber>
                      {tickets?.length ?? 0}
                    </TickerNumber>
                  ),
                  count: tickets?.length ?? 0,
                }
              }
            />
          </span>
        )}
        {codeInfo.severity === 'warning' && (
          <>
            {alertStatus === 'WARN_INVALID_WAITING_LINE' && (
              <AlertButtonsContainer>
                <ContainedButton color="error" onClick={closeAlert} fullWidth>
                  <FormattedMessage id="general_no" />
                </ContainedButton>
                <ContainedButton color="secondary" onClick={handleOtherWaitingLineValidate} fullWidth>
                  <FormattedMessage id="general_yes" />
                </ContainedButton>
              </AlertButtonsContainer>
            )}
            {alertStatus === 'ERR_VALIDATION_MIXED' && (
              <AlertButtonsContainer>
                <ContainedButton color="error" onClick={closeAlert} fullWidth>
                  <FormattedMessage id="general_no" />
                </ContainedButton>
                <ContainedButton color="secondary" onClick={handleMixedValidation} fullWidth>
                  <FormattedMessage id="general_yes" />
                </ContainedButton>
              </AlertButtonsContainer>
            )}
          </>
        )}
      </ContentContainer>
      <LinearCountdown key={alertStatus} $duration={codeInfo.duration} $color={color} />
    </AlertContainer>
  );
}

export default QRAlert;
