import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardHeader, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import { ListCard, ListCardSection, ListCardTitle } from 'components/ListCard';
import EnsureRole from 'lib/access/EnsureRole';
import { GetAllEventsForOrganizationItem } from 'lib/graphql/entities/event/types';
import { EventStatus, useDeleteEventMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { DateTime } from 'luxon';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, DeleteButton } from 'styles/styles';
import { useLocation } from 'wouter';

import EventHiddenButton from './EventHiddenButton';
import EventStatusChip from './EventStatusChip';

interface EventCardProps {
  event: NonNullable<GetAllEventsForOrganizationItem>;
}

function EventCard(props: EventCardProps) {
  const { event } = props;

  const feedback = useFeedback();
  const [, setLocation] = useLocation();

  const { language } = useContext(LanguageContext);

  const [modalOpen, setModalOpen] = useState(false);

  // TODO: Error / loading handling
  const queryClient = useQueryClient();
  const [deleteEvent] = useDeleteEventMutation();
  const onDelete = async () => {
    await deleteEvent({ variables: { id: event.id } });
    queryClient.invalidateQueries({ queryKey: ['events'], exact: true });

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="delete_event_success" />),
      severity: 'success',
    });
  };

  const startDate = useMemo(() => (
    DateTime.fromISO(event.startTime).toFormat('DDD t')
  ), [event.startTime]);
  const endDate = useMemo(() => (
    DateTime.fromISO(event.endTime).toFormat('DDD t')
  ), [event.endTime]);

  const directToInfo = () => {
    if (event.status === EventStatus.Draft) {
      setLocation(`/event/${event.id}/edit`);
    } else {
      setLocation(`/event/${event.id}/operations`);
    }
  };

  return (
    <ListCard>
      <ListCardSection flexGrow={1}>
        <CardHeader
          title={(
            <ListCardTitle
              title={event.name[language] || event.name[event.languages[0] as Languages] as string}
            />
          )}
          subheader={(
            <>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="event_starts" />
                {' '}
                {startDate}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                <FormattedMessage id="event_ends" />
                {' '}
                {endDate}
              </Typography>
            </>
          )}
        />
      </ListCardSection>
      <ListCardSection flexGrow={1} isCentered>
        <EventStatusChip status={event.status} />
      </ListCardSection>
      <ListCardSection flexGrow={1} isRight>
        <EnsureRole requiredRole="ADMIN">
          {event.status === EventStatus.Draft && (
            <DeleteButton onClick={() => setModalOpen(true)}>
              <FontAwesomeIcon icon={faTrash} size="lg" />
            </DeleteButton>
          )}
          {event.status !== EventStatus.Draft && (
            <EventHiddenButton event={event} />
          )}
        </EnsureRole>
        <ContainedButton
          onClick={directToInfo}
          color="secondary"
        >
          <FormattedMessage id="view_details" />
        </ContainedButton>
      </ListCardSection>
      <ConfirmModal
        title="delete_confirm"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => onDelete()}
        closeText="cancel"
        confirmColor="error"
        confirmText="delete"
      >
        <FormattedMessage id="delete_irreversible" />
      </ConfirmModal>
    </ListCard>
  );
}

export default EventCard;
