import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
  UUID: { input: string; output: string; }
  Void: { input: void; output: void; }
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  authToken: Scalars['String']['output'];
  user: User;
};

export type Cause = {
  __typename?: 'Cause';
  hasObnls?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type ClaimFreeTicketsInput = {
  contactInfo: ContactInfoInput;
  eventId: Scalars['UUID']['input'];
  quantity: Scalars['Int']['input'];
  waitingLineId: Scalars['UUID']['input'];
};

export enum CommunicationChannel {
  Email = 'EMAIL',
  Sms = 'SMS'
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  optInNotifications: Scalars['Boolean']['output'];
  type: ContactInfoType;
  value?: Maybe<Scalars['String']['output']>;
};

export type ContactInfoInput = {
  optInNotifications: Scalars['Boolean']['input'];
  type: ContactInfoType;
  value: Scalars['String']['input'];
};

export enum ContactInfoType {
  Email = 'EMAIL',
  Phone = 'PHONE'
}

export type CreateOrganizationResponse = {
  __typename?: 'CreateOrganizationResponse';
  invitationId: Scalars['String']['output'];
  organization: Organization;
};

export enum Device {
  Apple = 'APPLE',
  Google = 'GOOGLE'
}

export type DisplayTicket = {
  __typename?: 'DisplayTicket';
  displayId: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  status: TicketStatus;
};

export type Event = {
  __typename?: 'Event';
  allowObnlChoice: Scalars['Boolean']['output'];
  description?: Maybe<NullableText>;
  endTime: Scalars['String']['output'];
  id: Scalars['String']['output'];
  image?: Maybe<Scalars['String']['output']>;
  languages: Array<Scalars['String']['output']>;
  location: Scalars['String']['output'];
  name: NullableText;
  organizationId: Scalars['String']['output'];
  shortUrl?: Maybe<Scalars['String']['output']>;
  startTime: Scalars['String']['output'];
  status: EventStatus;
  timezone: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type EventCreationInput = {
  description?: InputMaybe<NullableTextInput>;
  endTime: Scalars['String']['input'];
  image: Scalars['String']['input'];
  languages: Array<Scalars['String']['input']>;
  location: Scalars['String']['input'];
  name: NullableTextInput;
  organizationId: Scalars['String']['input'];
  shortUrl?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['String']['input'];
  status?: InputMaybe<EventStatus>;
  timezone: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type EventObnl = {
  __typename?: 'EventObnl';
  eventId: Scalars['String']['output'];
  obnlId: Scalars['String']['output'];
};

export type EventRole = {
  __typename?: 'EventRole';
  eventId: Scalars['String']['output'];
  role: RoleEnum;
};

export type EventRoleInput = {
  eventId: Scalars['String']['input'];
  role: RoleEnum;
};

export enum EventStatus {
  Draft = 'DRAFT',
  Hidden = 'HIDDEN',
  Published = 'PUBLISHED'
}

export type EventUpdateInput = {
  allowObnlChoice?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<NullableTextInput>;
  endTime: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  languages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location?: InputMaybe<Scalars['String']['input']>;
  name: NullableTextInput;
  shortUrl?: InputMaybe<Scalars['String']['input']>;
  startTime: Scalars['String']['input'];
  status?: InputMaybe<EventStatus>;
  timezone: Scalars['String']['input'];
};

export type GetSlugAvailabilityInput = {
  service?: InputMaybe<Scalars['String']['input']>;
  slug: Scalars['String']['input'];
};

export type Invitation = {
  __typename?: 'Invitation';
  events: Array<EventRole>;
  id: Scalars['UUID']['output'];
  invalidEmail?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['String']['output'];
  status: InvitationStatusEnum;
  userEmail: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type InvitationInput = {
  event: EventRoleInput;
  language: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  status?: InputMaybe<InvitationStatusEnum>;
  userEmail: Scalars['String']['input'];
};

export enum InvitationStatusEnum {
  Accepted = 'ACCEPTED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Revoked = 'REVOKED'
}

export type LinkInviteReturn = {
  __typename?: 'LinkInviteReturn';
  eventId: Scalars['String']['output'];
  invitationId: Scalars['String']['output'];
  isPublished: Scalars['Boolean']['output'];
  organizationId: Scalars['String']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  addObnlToEvent?: Maybe<Scalars['Void']['output']>;
  aseLogin?: Maybe<Scalars['String']['output']>;
  buyTickets: TicketBought;
  cancelTickets?: Maybe<Scalars['Void']['output']>;
  changeEventShowObnlChoice: Event;
  changeEventVisibility: Event;
  changePassword?: Maybe<Scalars['Void']['output']>;
  changeWaitingLineStatus?: Maybe<Scalars['Void']['output']>;
  claimFreeTickets: Scalars['UUID']['output'];
  createConnectionToken: StripeConnectionToken;
  createEvent: Event;
  createInvitation: Invitation;
  createOrganization: CreateOrganizationResponse;
  createPaymentIntent: Scalars['String']['output'];
  createUser: User;
  createWaitingLine: WaitingLine;
  deleteEvent?: Maybe<Scalars['Void']['output']>;
  deleteInvitation?: Maybe<Scalars['Void']['output']>;
  deleteWaitingLine?: Maybe<Scalars['Void']['output']>;
  generatePass: Scalars['String']['output'];
  linkInvitationToUser: LinkInviteReturn;
  login: AuthPayload;
  loginMagicToken: AuthPayload;
  loginOrganization: AuthPayload;
  logout: Scalars['Boolean']['output'];
  moveTicketsToCalledQueue: Array<Ticket>;
  publishEvent: Event;
  refreshAuth: AuthPayload;
  removeObnlFromEvent?: Maybe<Scalars['Void']['output']>;
  requestResetPassword?: Maybe<Scalars['Void']['output']>;
  reserveTickets: TicketCheckoutSession;
  resetPassword?: Maybe<Scalars['Void']['output']>;
  sendMagicLoginLink?: Maybe<Scalars['Void']['output']>;
  sendNewEmailValidation: User;
  sendSupportMessage?: Maybe<Scalars['Void']['output']>;
  sendTickets?: Maybe<Scalars['Void']['output']>;
  updateEvent: Event;
  updateOrganization: Organization;
  updateWaitingLine: WaitingLine;
  validateEmail?: Maybe<Scalars['Void']['output']>;
  validateTicket: Scalars['Boolean']['output'];
};


export type MutationAddObnlToEventArgs = {
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
};


export type MutationBuyTicketsArgs = {
  contactInfo?: InputMaybe<SessionContactInfo>;
  locale: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
  sessionToken: Scalars['String']['input'];
  transactionId?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCancelTicketsArgs = {
  sessionToken: Scalars['String']['input'];
};


export type MutationChangeEventShowObnlChoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangeEventVisibilityArgs = {
  id: Scalars['String']['input'];
};


export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationChangeWaitingLineStatusArgs = {
  eventId: Scalars['UUID']['input'];
  fields: UpdateWaitingLineStatusInput;
  id: Scalars['UUID']['input'];
};


export type MutationClaimFreeTicketsArgs = {
  fields: ClaimFreeTicketsInput;
};


export type MutationCreateEventArgs = {
  fields: EventCreationInput;
};


export type MutationCreateInvitationArgs = {
  fields: InvitationInput;
};


export type MutationCreateOrganizationArgs = {
  fields: OrganizationInput;
};


export type MutationCreatePaymentIntentArgs = {
  amount: Scalars['Int']['input'];
};


export type MutationCreateUserArgs = {
  email: Scalars['String']['input'];
  locale: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationCreateWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WaitingLineInput;
};


export type MutationDeleteEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteInvitationArgs = {
  eventId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type MutationDeleteWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
};


export type MutationGeneratePassArgs = {
  device: Device;
  ticket: TicketInput;
  transactionId: Scalars['String']['input'];
};


export type MutationLinkInvitationToUserArgs = {
  token: Scalars['UUID']['input'];
};


export type MutationLoginArgs = {
  invitationToken?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationLoginMagicTokenArgs = {
  token: Scalars['String']['input'];
};


export type MutationLoginOrganizationArgs = {
  invitationId: Scalars['String']['input'];
};


export type MutationMoveTicketsToCalledQueueArgs = {
  id: Scalars['UUID']['input'];
};


export type MutationPublishEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationRefreshAuthArgs = {
  invitationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRemoveObnlFromEventArgs = {
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
};


export type MutationRequestResetPasswordArgs = {
  locale: Scalars['String']['input'];
  username: Scalars['String']['input'];
};


export type MutationReserveTicketsArgs = {
  fields: TicketsReservationInput;
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type MutationSendMagicLoginLinkArgs = {
  channel: CommunicationChannel;
  id: Scalars['String']['input'];
};


export type MutationSendNewEmailValidationArgs = {
  locale: Scalars['String']['input'];
};


export type MutationSendSupportMessageArgs = {
  fields: SupportInput;
};


export type MutationSendTicketsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transactionId: Scalars['String']['input'];
};


export type MutationUpdateEventArgs = {
  fields: EventUpdateInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateOrganizationArgs = {
  fields: OrganizationInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateWaitingLineArgs = {
  eventId: Scalars['UUID']['input'];
  fields: WaitingLineUpdateInput;
  id: Scalars['UUID']['input'];
};


export type MutationValidateEmailArgs = {
  token: Scalars['String']['input'];
};


export type MutationValidateTicketArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};

export type NullableText = {
  __typename?: 'NullableText';
  en?: Maybe<Scalars['String']['output']>;
  es?: Maybe<Scalars['String']['output']>;
  fr?: Maybe<Scalars['String']['output']>;
};

export type NullableTextInput = {
  en?: InputMaybe<Scalars['String']['input']>;
  es?: InputMaybe<Scalars['String']['input']>;
  fr?: InputMaybe<Scalars['String']['input']>;
};

export type Obnl = {
  __typename?: 'Obnl';
  cause: Scalars['String']['output'];
  description?: Maybe<NullableText>;
  id: Scalars['String']['output'];
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ObnlInput = {
  cause: Scalars['String']['input'];
  description?: InputMaybe<NullableTextInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type Organization = {
  __typename?: 'Organization';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  stripeAccountId: Scalars['String']['output'];
  stripeAccountLinked: Scalars['Boolean']['output'];
  validatedByMovon: Scalars['Boolean']['output'];
};

export type OrganizationDetails = {
  __typename?: 'OrganizationDetails';
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type OrganizationInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type PaginatedCauses = {
  __typename?: 'PaginatedCauses';
  causes: Array<Cause>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedDisplayTickets = {
  __typename?: 'PaginatedDisplayTickets';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  tickets: Array<DisplayTicket>;
};

export type PaginatedEventObnls = {
  __typename?: 'PaginatedEventObnls';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  obnls: Array<Scalars['String']['output']>;
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  events: Array<Event>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedInvitations = {
  __typename?: 'PaginatedInvitations';
  invitations: Array<Invitation>;
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
};

export type PaginatedObnls = {
  __typename?: 'PaginatedObnls';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  obnls: Array<Obnl>;
};

export type PaginatedOrganizations = {
  __typename?: 'PaginatedOrganizations';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  organizations: Array<Organization>;
};

export type PaginatedPendingTransfers = {
  __typename?: 'PaginatedPendingTransfers';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  pendingTransfers: Array<PendingTransfer>;
};

export type PaginatedTickets = {
  __typename?: 'PaginatedTickets';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  tickets: Array<Ticket>;
};

export type PaginatedWaitingLines = {
  __typename?: 'PaginatedWaitingLines';
  lastEvaluated?: Maybe<Scalars['JSON']['output']>;
  stripePage?: Maybe<Scalars['String']['output']>;
  waitingLines: Array<WaitingLine>;
};

export type PendingTransfer = {
  __typename?: 'PendingTransfer';
  chargeId: Scalars['String']['output'];
  eventId: Scalars['String']['output'];
  obnlId: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
  transferId: Scalars['String']['output'];
  waitingLineId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  getAllAcceptedInvitationsForUser: PaginatedInvitations;
  getAllCalledWaitingLineTickets: PaginatedDisplayTickets;
  getAllCauses: PaginatedCauses;
  getAllEventWaitingLines: PaginatedWaitingLines;
  getAllEventsForOrganization: PaginatedEvents;
  getAllEventsForUser: PaginatedEvents;
  getAllInvitationsForEvent: PaginatedInvitations;
  getAllInvitationsForOrganization: PaginatedInvitations;
  getAllInvitationsForUser: PaginatedInvitations;
  getAllObnls: PaginatedObnls;
  getAllObnlsByCause: PaginatedObnls;
  getAllObnlsIdsForEvent: PaginatedEventObnls;
  getAllOrganizationsForUser: PaginatedOrganizations;
  getAllWaitingLineTickets: PaginatedTickets;
  getCause?: Maybe<Cause>;
  getEvent?: Maybe<Event>;
  getEventStripeTransactions?: Maybe<Scalars['JSON']['output']>;
  getInvitation?: Maybe<Invitation>;
  getInvitationById?: Maybe<Invitation>;
  getObnl?: Maybe<Obnl>;
  getOrganization?: Maybe<Organization>;
  getOrganizationAccountLink?: Maybe<Scalars['String']['output']>;
  getOrganizationDetails?: Maybe<OrganizationDetails>;
  getOrganizationStripeAccount?: Maybe<Scalars['JSON']['output']>;
  getOrganizationStripeBalance?: Maybe<Scalars['JSON']['output']>;
  getOrganizationStripeDashboardLink?: Maybe<Scalars['String']['output']>;
  getOrganizationStripePayouts?: Maybe<Scalars['JSON']['output']>;
  getSlugAvailability: Scalars['Boolean']['output'];
  getTicket: Ticket;
  getTicketFromTransaction: PaginatedTickets;
  getTicketWaitTime: Scalars['Int']['output'];
  getUser?: Maybe<User>;
  getWaitingLine?: Maybe<WaitingLine>;
  hasOrganizations: Scalars['Boolean']['output'];
};


export type QueryGetAllAcceptedInvitationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllCalledWaitingLineTicketsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetAllCausesArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventWaitingLinesArgs = {
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
  stripePage?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetAllEventsForOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllEventsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForEventArgs = {
  eventId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForOrganizationArgs = {
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllInvitationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllObnlsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllObnlsByCauseArgs = {
  cause: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllObnlsIdsForEventArgs = {
  eventId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllOrganizationsForUserArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
};


export type QueryGetAllWaitingLineTicketsArgs = {
  start?: InputMaybe<Scalars['JSON']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetCauseArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetEventStripeTransactionsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetInvitationArgs = {
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};


export type QueryGetInvitationByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetObnlArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationAccountLinkArgs = {
  organizationId: Scalars['String']['input'];
  stripeAccountId: Scalars['String']['input'];
};


export type QueryGetOrganizationDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeAccountArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeBalanceArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetOrganizationStripeDashboardLinkArgs = {
  organizationId: Scalars['String']['input'];
  stripeAccountId: Scalars['String']['input'];
};


export type QueryGetOrganizationStripePayoutsArgs = {
  id: Scalars['String']['input'];
};


export type QueryGetSlugAvailabilityArgs = {
  fields: GetSlugAvailabilityInput;
};


export type QueryGetTicketArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetTicketFromTransactionArgs = {
  transactionId: Scalars['String']['input'];
};


export type QueryGetTicketWaitTimeArgs = {
  ticketId: Scalars['UUID']['input'];
  waitingLineId: Scalars['UUID']['input'];
};


export type QueryGetWaitingLineArgs = {
  id: Scalars['UUID']['input'];
};

export type Queue = {
  __typename?: 'Queue';
  depth: Scalars['Int']['output'];
};

export enum QueueType {
  Called = 'CALLED',
  Free = 'FREE',
  Premium = 'PREMIUM'
}

export type Queueable = {
  queueItemId?: Maybe<Scalars['String']['output']>;
};

export enum RoleEnum {
  Admin = 'ADMIN',
  Staff = 'STAFF'
}

export type SessionContactInfo = {
  optInNotifications: Scalars['Boolean']['input'];
  type: ContactInfoType;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type StripeConnectionToken = {
  __typename?: 'StripeConnectionToken';
  location?: Maybe<Scalars['String']['output']>;
  secret: Scalars['String']['output'];
};

export type SupportInput = {
  email: Scalars['String']['input'];
  language: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
  subject: Scalars['String']['input'];
};

export type Text = {
  __typename?: 'Text';
  en: Scalars['String']['output'];
  es: Scalars['String']['output'];
  fr: Scalars['String']['output'];
};

export type TextInput = {
  en: Scalars['String']['input'];
  es: Scalars['String']['input'];
  fr: Scalars['String']['input'];
};

export type Ticket = {
  __typename?: 'Ticket';
  contactInfo?: Maybe<ContactInfo>;
  displayId: Scalars['Int']['output'];
  eventId: Scalars['UUID']['output'];
  expiresAt: Scalars['String']['output'];
  id: Scalars['UUID']['output'];
  obnlId?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<Scalars['String']['output']>;
  queueType?: Maybe<QueueType>;
  status: TicketStatus;
  transactionId: Scalars['UUID']['output'];
  waitingLineId: Scalars['UUID']['output'];
};

export type TicketBought = {
  __typename?: 'TicketBought';
  clientSecret: Scalars['String']['output'];
  returnUrl: Scalars['String']['output'];
};

export type TicketCheckoutSession = {
  __typename?: 'TicketCheckoutSession';
  sessionToken: Scalars['String']['output'];
  timer?: Maybe<Scalars['String']['output']>;
};

export type TicketInput = {
  eventId: Scalars['UUID']['input'];
  ids: Array<Scalars['UUID']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};

export type TicketQueueTypePayload = {
  __typename?: 'TicketQueueTypePayload';
  queueType: QueueType;
};

export enum TicketStatus {
  Activated = 'ACTIVATED',
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Refunded = 'REFUNDED',
  Reserved = 'RESERVED',
  Validated = 'VALIDATED'
}

export type TicketsReservationInput = {
  eventId: Scalars['UUID']['input'];
  quantity: Scalars['Int']['input'];
  transactionId?: InputMaybe<Scalars['String']['input']>;
  waitingLineId: Scalars['UUID']['input'];
};

export type Transfer = {
  __typename?: 'Transfer';
  amount: Scalars['Float']['output'];
  currency: Scalars['String']['output'];
  destination: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type UpdateWaitingLineStatusInput = {
  message?: InputMaybe<NullableTextInput>;
  status: WaitingLineStatus;
};

export type User = {
  __typename?: 'User';
  channel?: Maybe<CommunicationChannel>;
  confirmedAccount: Scalars['Boolean']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type WaitingLine = {
  __typename?: 'WaitingLine';
  activatedTickets: Scalars['Int']['output'];
  callSize?: Maybe<Scalars['Int']['output']>;
  calledQueueWaitingTime: Scalars['Int']['output'];
  currency: Scalars['String']['output'];
  description: NullableText;
  eventId: Scalars['UUID']['output'];
  freeQueueWaitingTime: Scalars['Int']['output'];
  id: Scalars['UUID']['output'];
  isFreemium: Scalars['Boolean']['output'];
  limitedQuantity: Scalars['Boolean']['output'];
  maxGroupSize: Scalars['Int']['output'];
  message?: Maybe<NullableText>;
  metadata: Queue;
  name: NullableText;
  paidTickets: Scalars['Int']['output'];
  premiumQueueWaitingTime: Scalars['Int']['output'];
  price: Scalars['Float']['output'];
  productDescription: NullableText;
  quantity?: Maybe<Scalars['Int']['output']>;
  statementDescriptor: Scalars['String']['output'];
  status: WaitingLineStatus;
  usedTickets: Scalars['Int']['output'];
  waitingLineType: WaitingLineType;
};

export type WaitingLineInput = {
  callSize?: InputMaybe<Scalars['Int']['input']>;
  currency: Scalars['String']['input'];
  description: NullableTextInput;
  isFreemium: Scalars['Boolean']['input'];
  limitedQuantity: Scalars['Boolean']['input'];
  maxGroupSize: Scalars['Int']['input'];
  name: NullableTextInput;
  price: Scalars['Float']['input'];
  productDescription: NullableTextInput;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  statementDescriptor: Scalars['String']['input'];
  waitingLineType: WaitingLineType;
};

export enum WaitingLineStatus {
  Active = 'ACTIVE',
  Hidden = 'HIDDEN',
  Inactive = 'INACTIVE'
}

export enum WaitingLineType {
  Gold = 'GOLD',
  Silver = 'SILVER'
}

export type WaitingLineUpdateInput = {
  activatedTickets?: InputMaybe<Scalars['Int']['input']>;
  callSize?: InputMaybe<Scalars['Int']['input']>;
  currency?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<NullableTextInput>;
  isFreemium: Scalars['Boolean']['input'];
  limitedQuantity?: InputMaybe<Scalars['Boolean']['input']>;
  maxGroupSize?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<NullableTextInput>;
  price?: InputMaybe<Scalars['Float']['input']>;
  productDescription?: InputMaybe<NullableTextInput>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  statementDescriptor: Scalars['String']['input'];
  usedTickets?: InputMaybe<Scalars['Int']['input']>;
  waitingLineType?: InputMaybe<WaitingLineType>;
};

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ChangePasswordMutation = { __typename?: 'Mutation', changePassword?: void | null };

export type LoginMutationVariables = Exact<{
  username: Scalars['String']['input'];
  password: Scalars['String']['input'];
  invitationToken?: InputMaybe<Scalars['String']['input']>;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthPayload', authToken: string, user: { __typename?: 'User', id: string, confirmedAccount: boolean } } };

export type ValidateEmailMutationVariables = Exact<{
  token: Scalars['String']['input'];
}>;


export type ValidateEmailMutation = { __typename?: 'Mutation', validateEmail?: void | null };

export type RefreshAuthMutationVariables = Exact<{
  invitationId?: InputMaybe<Scalars['String']['input']>;
}>;


export type RefreshAuthMutation = { __typename?: 'Mutation', refreshAuth: { __typename?: 'AuthPayload', authToken: string, user: { __typename?: 'User', id: string, confirmedAccount: boolean } } };

export type RequestResetPasswordMutationVariables = Exact<{
  username: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type RequestResetPasswordMutation = { __typename?: 'Mutation', requestResetPassword?: void | null };

export type ResetPasswordMutationVariables = Exact<{
  token: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword?: void | null };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { __typename?: 'Mutation', logout: boolean };

export type LoginOrganizationMutationVariables = Exact<{
  invitationId: Scalars['String']['input'];
}>;


export type LoginOrganizationMutation = { __typename?: 'Mutation', loginOrganization: { __typename?: 'AuthPayload', authToken: string, user: { __typename?: 'User', id: string, confirmedAccount: boolean } } };

export type GetCauseQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetCauseQuery = { __typename?: 'Query', getCause?: { __typename?: 'Cause', id: string, name: string } | null };

export type GetAllCausesQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllCausesQuery = { __typename?: 'Query', getAllCauses: { __typename?: 'PaginatedCauses', lastEvaluated?: any | null, causes: Array<{ __typename?: 'Cause', id: string, name: string, hasObnls?: boolean | null }> } };

export type CreateEventMutationVariables = Exact<{
  event: EventCreationInput;
}>;


export type CreateEventMutation = { __typename?: 'Mutation', createEvent: { __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, organizationId: string, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, name: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null }, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null } };

export type UpdateEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
  event: EventUpdateInput;
}>;


export type UpdateEventMutation = { __typename?: 'Mutation', updateEvent: { __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, status: EventStatus, organizationId: string, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, name: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null }, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null } };

export type DeleteEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent?: void | null };

export type PublishEventMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type PublishEventMutation = { __typename?: 'Mutation', publishEvent: { __typename?: 'Event', id: string, status: EventStatus } };

export type ChangeEventVisibilityMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ChangeEventVisibilityMutation = { __typename?: 'Mutation', changeEventVisibility: { __typename?: 'Event', id: string, status: EventStatus } };

export type ChangeEventShowObnlChoiceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type ChangeEventShowObnlChoiceMutation = { __typename?: 'Mutation', changeEventShowObnlChoice: { __typename?: 'Event', id: string, allowObnlChoice: boolean } };

export type GetEventQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEventQuery = { __typename?: 'Query', getEvent?: { __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, status: EventStatus, organizationId: string, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, shortUrl?: string | null, name: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null }, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null } | null };

export type GetAllEventsForUserQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllEventsForUserQuery = { __typename?: 'Query', getAllEventsForUser: { __typename?: 'PaginatedEvents', lastEvaluated?: any | null, events: Array<{ __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, status: EventStatus, organizationId: string, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, name: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null }, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null }> } };

export type GetAllEventsForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllEventsForOrganizationQuery = { __typename?: 'Query', getAllEventsForOrganization: { __typename?: 'PaginatedEvents', lastEvaluated?: any | null, events: Array<{ __typename?: 'Event', id: string, userId: string, location: string, startTime: string, endTime: string, timezone: string, status: EventStatus, organizationId: string, image?: string | null, languages: Array<string>, allowObnlChoice: boolean, name: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null }, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null }> } };

export type GetEventStripeTransactionsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetEventStripeTransactionsQuery = { __typename?: 'Query', getEventStripeTransactions?: any | null };

export type AddObnlToEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
}>;


export type AddObnlToEventMutation = { __typename?: 'Mutation', addObnlToEvent?: void | null };

export type RemoveObnlFromEventMutationVariables = Exact<{
  eventId: Scalars['String']['input'];
  obnlId: Scalars['String']['input'];
}>;


export type RemoveObnlFromEventMutation = { __typename?: 'Mutation', removeObnlFromEvent?: void | null };

export type GetAllObnlsIdsForEventQueryVariables = Exact<{
  eventId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllObnlsIdsForEventQuery = { __typename?: 'Query', getAllObnlsIdsForEvent: { __typename?: 'PaginatedEventObnls', obnls: Array<string>, lastEvaluated?: any | null } };

export type CreateInvitationMutationVariables = Exact<{
  invitation: InvitationInput;
}>;


export type CreateInvitationMutation = { __typename?: 'Mutation', createInvitation: { __typename?: 'Invitation', userId?: string | null, organizationId: string, status: InvitationStatusEnum, id: string, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> } };

export type DeleteInvitationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
}>;


export type DeleteInvitationMutation = { __typename?: 'Mutation', deleteInvitation?: void | null };

export type LinkInvitationToUserMutationVariables = Exact<{
  token: Scalars['UUID']['input'];
}>;


export type LinkInvitationToUserMutation = { __typename?: 'Mutation', linkInvitationToUser: { __typename?: 'LinkInviteReturn', organizationId: string, eventId: string, isPublished: boolean, invitationId: string } };

export type GetInvitationQueryVariables = Exact<{
  id: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type GetInvitationQuery = { __typename?: 'Query', getInvitation?: { __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> } | null };

export type GetInvitationByIdQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetInvitationByIdQuery = { __typename?: 'Query', getInvitationById?: { __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, organizationId: string, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> } | null };

export type GetAllInvitationsForEventQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  eventId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllInvitationsForEventQuery = { __typename?: 'Query', getAllInvitationsForEvent: { __typename?: 'PaginatedInvitations', lastEvaluated?: any | null, invitations: Array<{ __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, organizationId: string, id: string, invalidEmail?: boolean | null, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> }> } };

export type GetAllInvitationsForUserQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllInvitationsForUserQuery = { __typename?: 'Query', getAllInvitationsForUser: { __typename?: 'PaginatedInvitations', lastEvaluated?: any | null, invitations: Array<{ __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, organizationId: string, id: string, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> }> } };

export type GetAllAcceptedInvitationsForUserQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllAcceptedInvitationsForUserQuery = { __typename?: 'Query', getAllAcceptedInvitationsForUser: { __typename?: 'PaginatedInvitations', lastEvaluated?: any | null, invitations: Array<{ __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, organizationId: string, id: string, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> }> } };

export type GetAllInvitationsForOrganizationQueryVariables = Exact<{
  organizationId: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllInvitationsForOrganizationQuery = { __typename?: 'Query', getAllInvitationsForOrganization: { __typename?: 'PaginatedInvitations', lastEvaluated?: any | null, invitations: Array<{ __typename?: 'Invitation', userId?: string | null, userEmail: string, status: InvitationStatusEnum, id: string, events: Array<{ __typename?: 'EventRole', eventId: string, role: RoleEnum }> }> } };

export type GetObnlQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetObnlQuery = { __typename?: 'Query', getObnl?: { __typename?: 'Obnl', id: string, name: string, cause: string, logo?: string | null, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null } | null };

export type GetAllObnlsQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllObnlsQuery = { __typename?: 'Query', getAllObnls: { __typename?: 'PaginatedObnls', lastEvaluated?: any | null, obnls: Array<{ __typename?: 'Obnl', id: string, name: string, cause: string, logo?: string | null, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null }> } };

export type GetAllObnlsByCauseQueryVariables = Exact<{
  cause: Scalars['String']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllObnlsByCauseQuery = { __typename?: 'Query', getAllObnlsByCause: { __typename?: 'PaginatedObnls', lastEvaluated?: any | null, obnls: Array<{ __typename?: 'Obnl', id: string, name: string, cause: string, logo?: string | null, description?: { __typename?: 'NullableText', en?: string | null, es?: string | null, fr?: string | null } | null }> } };

export type CreateOrganizationMutationVariables = Exact<{
  organization: OrganizationInput;
}>;


export type CreateOrganizationMutation = { __typename?: 'Mutation', createOrganization: { __typename?: 'CreateOrganizationResponse', invitationId: string, organization: { __typename?: 'Organization', id: string, name: string, description?: string | null, stripeAccountId: string, stripeAccountLinked: boolean } } };

export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  organization: OrganizationInput;
}>;


export type UpdateOrganizationMutation = { __typename?: 'Mutation', updateOrganization: { __typename?: 'Organization', id: string, name: string, description?: string | null } };

export type GetOrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationQuery = { __typename?: 'Query', getOrganization?: { __typename?: 'Organization', id: string, name: string, description?: string | null, stripeAccountLinked: boolean, stripeAccountId: string, validatedByMovon: boolean } | null };

export type GetAllOrganizationsForUserQueryVariables = Exact<{
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllOrganizationsForUserQuery = { __typename?: 'Query', getAllOrganizationsForUser: { __typename?: 'PaginatedOrganizations', lastEvaluated?: any | null, organizations: Array<{ __typename?: 'Organization', id: string, name: string, description?: string | null, stripeAccountLinked: boolean, stripeAccountId: string, validatedByMovon: boolean }> } };

export type GetOrganizationAccountLinkQueryVariables = Exact<{
  stripeAccountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type GetOrganizationAccountLinkQuery = { __typename?: 'Query', getOrganizationAccountLink?: string | null };

export type GetOrganizationStripeDashboardLinkQueryVariables = Exact<{
  stripeAccountId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
}>;


export type GetOrganizationStripeDashboardLinkQuery = { __typename?: 'Query', getOrganizationStripeDashboardLink?: string | null };

export type GetOrganizationDetailsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationDetailsQuery = { __typename?: 'Query', getOrganizationDetails?: { __typename?: 'OrganizationDetails', name: string, description?: string | null } | null };

export type GetOrganizationStripeAccountQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationStripeAccountQuery = { __typename?: 'Query', getOrganizationStripeAccount?: any | null };

export type GetOrganizationStripeBalanceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationStripeBalanceQuery = { __typename?: 'Query', getOrganizationStripeBalance?: any | null };

export type GetOrganizationStripePayoutsQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;


export type GetOrganizationStripePayoutsQuery = { __typename?: 'Query', getOrganizationStripePayouts?: any | null };

export type ValidateTicketMutationVariables = Exact<{
  waitingLineId: Scalars['UUID']['input'];
  ticketId: Scalars['UUID']['input'];
}>;


export type ValidateTicketMutation = { __typename?: 'Mutation', validateTicket: boolean };

export type GetAllWaitingLineTicketsQueryVariables = Exact<{
  waitingLineId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllWaitingLineTicketsQuery = { __typename?: 'Query', getAllWaitingLineTickets: { __typename?: 'PaginatedTickets', lastEvaluated?: any | null, tickets: Array<{ __typename?: 'Ticket', id: string, status: TicketStatus, transactionId: string, queueType?: QueueType | null, expiresAt: string, displayId: number, eventId: string, waitingLineId: string, contactInfo?: { __typename?: 'ContactInfo', type: ContactInfoType, value?: string | null } | null }> } };

export type GetTicketQueryVariables = Exact<{
  waitingLineId: Scalars['UUID']['input'];
  ticketId: Scalars['UUID']['input'];
}>;


export type GetTicketQuery = { __typename?: 'Query', getTicket: { __typename?: 'Ticket', id: string, status: TicketStatus, expiresAt: string, eventId: string, waitingLineId: string, displayId: number } };

export type GetSlugAvailabilityQueryVariables = Exact<{
  fields: GetSlugAvailabilityInput;
}>;


export type GetSlugAvailabilityQuery = { __typename?: 'Query', getSlugAvailability: boolean };

export type CreateUserMutationVariables = Exact<{
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  locale: Scalars['String']['input'];
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string } };

export type SendNewEmailValidationMutationVariables = Exact<{
  locale: Scalars['String']['input'];
}>;


export type SendNewEmailValidationMutation = { __typename?: 'Mutation', sendNewEmailValidation: { __typename?: 'User', id: string } };

export type HasOrganizationsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasOrganizationsQuery = { __typename?: 'Query', hasOrganizations: boolean };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, channel?: CommunicationChannel | null, confirmedAccount: boolean, email: string } | null };

export type ChangeWaitingLineStatusMutationVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  fields: UpdateWaitingLineStatusInput;
}>;


export type ChangeWaitingLineStatusMutation = { __typename?: 'Mutation', changeWaitingLineStatus?: void | null };

export type CreateWaitingLineMutationVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  fields: WaitingLineInput;
}>;


export type CreateWaitingLineMutation = { __typename?: 'Mutation', createWaitingLine: { __typename?: 'WaitingLine', id: string } };

export type UpdateWaitingLineMutationVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
  fields: WaitingLineUpdateInput;
}>;


export type UpdateWaitingLineMutation = { __typename?: 'Mutation', updateWaitingLine: { __typename?: 'WaitingLine', id: string } };

export type DeleteWaitingLineMutationVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  id: Scalars['UUID']['input'];
}>;


export type DeleteWaitingLineMutation = { __typename?: 'Mutation', deleteWaitingLine?: void | null };

export type MoveTicketsToCalledQueueMutationVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type MoveTicketsToCalledQueueMutation = { __typename?: 'Mutation', moveTicketsToCalledQueue: Array<{ __typename?: 'Ticket', displayId: number, id: string }> };

export type GetAllEventWaitingLinesQueryVariables = Exact<{
  eventId: Scalars['UUID']['input'];
  start?: InputMaybe<Scalars['JSON']['input']>;
}>;


export type GetAllEventWaitingLinesQuery = { __typename?: 'Query', getAllEventWaitingLines: { __typename?: 'PaginatedWaitingLines', lastEvaluated?: any | null, waitingLines: Array<{ __typename?: 'WaitingLine', id: string, status: WaitingLineStatus, waitingLineType: WaitingLineType, usedTickets: number, quantity?: number | null, limitedQuantity: boolean, maxGroupSize: number, price: number, activatedTickets: number, paidTickets: number, isFreemium: boolean, callSize?: number | null, name: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, description: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, message?: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } | null }> } };

export type GetWaitingLineQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLineQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', id: string, status: WaitingLineStatus, waitingLineType: WaitingLineType, maxGroupSize: number, price: number, currency: string, quantity?: number | null, isFreemium: boolean, callSize?: number | null, usedTickets: number, limitedQuantity: boolean, activatedTickets: number, statementDescriptor: string, name: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, description: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null }, productDescription: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } } | null };

export type GetWaitingLineOperatingInfoQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLineOperatingInfoQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', status: WaitingLineStatus, metadata: { __typename?: 'Queue', depth: number } } | null };

export type GetWaitingLineStatusInfosQueryVariables = Exact<{
  id: Scalars['UUID']['input'];
}>;


export type GetWaitingLineStatusInfosQuery = { __typename?: 'Query', getWaitingLine?: { __typename?: 'WaitingLine', id: string, status: WaitingLineStatus, message?: { __typename?: 'NullableText', fr?: string | null, en?: string | null, es?: string | null } | null } | null };


export const ChangePasswordDocument = gql`
    mutation changePassword($password: String!, $newPassword: String!) @api(name: auth) {
  changePassword(password: $password, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const LoginDocument = gql`
    mutation login($username: String!, $password: String!, $invitationToken: String) @api(name: auth) {
  login(
    username: $username
    password: $password
    invitationToken: $invitationToken
  ) {
    authToken
    user {
      id
      confirmedAccount
    }
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *      invitationToken: // value for 'invitationToken'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ValidateEmailDocument = gql`
    mutation validateEmail($token: String!) @api(name: auth) {
  validateEmail(token: $token)
}
    `;
export type ValidateEmailMutationFn = Apollo.MutationFunction<ValidateEmailMutation, ValidateEmailMutationVariables>;

/**
 * __useValidateEmailMutation__
 *
 * To run a mutation, you first call `useValidateEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailMutation, { data, loading, error }] = useValidateEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useValidateEmailMutation(baseOptions?: Apollo.MutationHookOptions<ValidateEmailMutation, ValidateEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateEmailMutation, ValidateEmailMutationVariables>(ValidateEmailDocument, options);
      }
export type ValidateEmailMutationHookResult = ReturnType<typeof useValidateEmailMutation>;
export type ValidateEmailMutationResult = Apollo.MutationResult<ValidateEmailMutation>;
export type ValidateEmailMutationOptions = Apollo.BaseMutationOptions<ValidateEmailMutation, ValidateEmailMutationVariables>;
export const RefreshAuthDocument = gql`
    mutation refreshAuth($invitationId: String) @api(name: auth) {
  refreshAuth(invitationId: $invitationId) {
    authToken
    user {
      id
      confirmedAccount
    }
  }
}
    `;
export type RefreshAuthMutationFn = Apollo.MutationFunction<RefreshAuthMutation, RefreshAuthMutationVariables>;

/**
 * __useRefreshAuthMutation__
 *
 * To run a mutation, you first call `useRefreshAuthMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshAuthMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshAuthMutation, { data, loading, error }] = useRefreshAuthMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useRefreshAuthMutation(baseOptions?: Apollo.MutationHookOptions<RefreshAuthMutation, RefreshAuthMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshAuthMutation, RefreshAuthMutationVariables>(RefreshAuthDocument, options);
      }
export type RefreshAuthMutationHookResult = ReturnType<typeof useRefreshAuthMutation>;
export type RefreshAuthMutationResult = Apollo.MutationResult<RefreshAuthMutation>;
export type RefreshAuthMutationOptions = Apollo.BaseMutationOptions<RefreshAuthMutation, RefreshAuthMutationVariables>;
export const RequestResetPasswordDocument = gql`
    mutation requestResetPassword($username: String!, $locale: String!) @api(name: auth) {
  requestResetPassword(username: $username, locale: $locale)
}
    `;
export type RequestResetPasswordMutationFn = Apollo.MutationFunction<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;

/**
 * __useRequestResetPasswordMutation__
 *
 * To run a mutation, you first call `useRequestResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetPasswordMutation, { data, loading, error }] = useRequestResetPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useRequestResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>(RequestResetPasswordDocument, options);
      }
export type RequestResetPasswordMutationHookResult = ReturnType<typeof useRequestResetPasswordMutation>;
export type RequestResetPasswordMutationResult = Apollo.MutationResult<RequestResetPasswordMutation>;
export type RequestResetPasswordMutationOptions = Apollo.BaseMutationOptions<RequestResetPasswordMutation, RequestResetPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($token: String!, $newPassword: String!) @api(name: auth) {
  resetPassword(token: $token, newPassword: $newPassword)
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const LogoutDocument = gql`
    mutation logout @api(name: auth) {
  logout
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const LoginOrganizationDocument = gql`
    mutation loginOrganization($invitationId: String!) @api(name: auth) {
  loginOrganization(invitationId: $invitationId) {
    authToken
    user {
      id
      confirmedAccount
    }
  }
}
    `;
export type LoginOrganizationMutationFn = Apollo.MutationFunction<LoginOrganizationMutation, LoginOrganizationMutationVariables>;

/**
 * __useLoginOrganizationMutation__
 *
 * To run a mutation, you first call `useLoginOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginOrganizationMutation, { data, loading, error }] = useLoginOrganizationMutation({
 *   variables: {
 *      invitationId: // value for 'invitationId'
 *   },
 * });
 */
export function useLoginOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<LoginOrganizationMutation, LoginOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginOrganizationMutation, LoginOrganizationMutationVariables>(LoginOrganizationDocument, options);
      }
export type LoginOrganizationMutationHookResult = ReturnType<typeof useLoginOrganizationMutation>;
export type LoginOrganizationMutationResult = Apollo.MutationResult<LoginOrganizationMutation>;
export type LoginOrganizationMutationOptions = Apollo.BaseMutationOptions<LoginOrganizationMutation, LoginOrganizationMutationVariables>;
export const GetCauseDocument = gql`
    query getCause($id: String!) {
  getCause(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetCauseQuery__
 *
 * To run a query within a React component, call `useGetCauseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCauseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCauseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCauseQuery(baseOptions: Apollo.QueryHookOptions<GetCauseQuery, GetCauseQueryVariables> & ({ variables: GetCauseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCauseQuery, GetCauseQueryVariables>(GetCauseDocument, options);
      }
export function useGetCauseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCauseQuery, GetCauseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCauseQuery, GetCauseQueryVariables>(GetCauseDocument, options);
        }
export function useGetCauseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCauseQuery, GetCauseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCauseQuery, GetCauseQueryVariables>(GetCauseDocument, options);
        }
export type GetCauseQueryHookResult = ReturnType<typeof useGetCauseQuery>;
export type GetCauseLazyQueryHookResult = ReturnType<typeof useGetCauseLazyQuery>;
export type GetCauseSuspenseQueryHookResult = ReturnType<typeof useGetCauseSuspenseQuery>;
export type GetCauseQueryResult = Apollo.QueryResult<GetCauseQuery, GetCauseQueryVariables>;
export const GetAllCausesDocument = gql`
    query getAllCauses($start: JSON) {
  getAllCauses(start: $start) {
    causes {
      id
      name
      hasObnls
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllCausesQuery__
 *
 * To run a query within a React component, call `useGetAllCausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCausesQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllCausesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllCausesQuery, GetAllCausesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllCausesQuery, GetAllCausesQueryVariables>(GetAllCausesDocument, options);
      }
export function useGetAllCausesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllCausesQuery, GetAllCausesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllCausesQuery, GetAllCausesQueryVariables>(GetAllCausesDocument, options);
        }
export function useGetAllCausesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllCausesQuery, GetAllCausesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllCausesQuery, GetAllCausesQueryVariables>(GetAllCausesDocument, options);
        }
export type GetAllCausesQueryHookResult = ReturnType<typeof useGetAllCausesQuery>;
export type GetAllCausesLazyQueryHookResult = ReturnType<typeof useGetAllCausesLazyQuery>;
export type GetAllCausesSuspenseQueryHookResult = ReturnType<typeof useGetAllCausesSuspenseQuery>;
export type GetAllCausesQueryResult = Apollo.QueryResult<GetAllCausesQuery, GetAllCausesQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($event: EventCreationInput!) {
  createEvent(fields: $event) {
    id
    userId
    name {
      en
      es
      fr
    }
    description {
      en
      es
      fr
    }
    location
    startTime
    endTime
    timezone
    organizationId
    image
    languages
    allowObnlChoice
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      event: // value for 'event'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation updateEvent($id: String!, $event: EventUpdateInput!) {
  updateEvent(id: $id, fields: $event) {
    id
    userId
    name {
      en
      es
      fr
    }
    description {
      en
      es
      fr
    }
    location
    startTime
    endTime
    timezone
    status
    organizationId
    image
    languages
    allowObnlChoice
  }
}
    `;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      event: // value for 'event'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($id: String!) {
  deleteEvent(id: $id)
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const PublishEventDocument = gql`
    mutation publishEvent($id: String!) {
  publishEvent(id: $id) {
    id
    status
  }
}
    `;
export type PublishEventMutationFn = Apollo.MutationFunction<PublishEventMutation, PublishEventMutationVariables>;

/**
 * __usePublishEventMutation__
 *
 * To run a mutation, you first call `usePublishEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishEventMutation, { data, loading, error }] = usePublishEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePublishEventMutation(baseOptions?: Apollo.MutationHookOptions<PublishEventMutation, PublishEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishEventMutation, PublishEventMutationVariables>(PublishEventDocument, options);
      }
export type PublishEventMutationHookResult = ReturnType<typeof usePublishEventMutation>;
export type PublishEventMutationResult = Apollo.MutationResult<PublishEventMutation>;
export type PublishEventMutationOptions = Apollo.BaseMutationOptions<PublishEventMutation, PublishEventMutationVariables>;
export const ChangeEventVisibilityDocument = gql`
    mutation changeEventVisibility($id: String!) {
  changeEventVisibility(id: $id) {
    id
    status
  }
}
    `;
export type ChangeEventVisibilityMutationFn = Apollo.MutationFunction<ChangeEventVisibilityMutation, ChangeEventVisibilityMutationVariables>;

/**
 * __useChangeEventVisibilityMutation__
 *
 * To run a mutation, you first call `useChangeEventVisibilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEventVisibilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEventVisibilityMutation, { data, loading, error }] = useChangeEventVisibilityMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeEventVisibilityMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEventVisibilityMutation, ChangeEventVisibilityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEventVisibilityMutation, ChangeEventVisibilityMutationVariables>(ChangeEventVisibilityDocument, options);
      }
export type ChangeEventVisibilityMutationHookResult = ReturnType<typeof useChangeEventVisibilityMutation>;
export type ChangeEventVisibilityMutationResult = Apollo.MutationResult<ChangeEventVisibilityMutation>;
export type ChangeEventVisibilityMutationOptions = Apollo.BaseMutationOptions<ChangeEventVisibilityMutation, ChangeEventVisibilityMutationVariables>;
export const ChangeEventShowObnlChoiceDocument = gql`
    mutation changeEventShowObnlChoice($id: String!) {
  changeEventShowObnlChoice(id: $id) {
    id
    allowObnlChoice
  }
}
    `;
export type ChangeEventShowObnlChoiceMutationFn = Apollo.MutationFunction<ChangeEventShowObnlChoiceMutation, ChangeEventShowObnlChoiceMutationVariables>;

/**
 * __useChangeEventShowObnlChoiceMutation__
 *
 * To run a mutation, you first call `useChangeEventShowObnlChoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEventShowObnlChoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEventShowObnlChoiceMutation, { data, loading, error }] = useChangeEventShowObnlChoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChangeEventShowObnlChoiceMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEventShowObnlChoiceMutation, ChangeEventShowObnlChoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEventShowObnlChoiceMutation, ChangeEventShowObnlChoiceMutationVariables>(ChangeEventShowObnlChoiceDocument, options);
      }
export type ChangeEventShowObnlChoiceMutationHookResult = ReturnType<typeof useChangeEventShowObnlChoiceMutation>;
export type ChangeEventShowObnlChoiceMutationResult = Apollo.MutationResult<ChangeEventShowObnlChoiceMutation>;
export type ChangeEventShowObnlChoiceMutationOptions = Apollo.BaseMutationOptions<ChangeEventShowObnlChoiceMutation, ChangeEventShowObnlChoiceMutationVariables>;
export const GetEventDocument = gql`
    query getEvent($id: String!) {
  getEvent(id: $id) {
    id
    userId
    name {
      en
      es
      fr
    }
    description {
      en
      es
      fr
    }
    location
    startTime
    endTime
    timezone
    status
    organizationId
    image
    languages
    allowObnlChoice
    shortUrl
  }
}
    `;

/**
 * __useGetEventQuery__
 *
 * To run a query within a React component, call `useGetEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventQuery(baseOptions: Apollo.QueryHookOptions<GetEventQuery, GetEventQueryVariables> & ({ variables: GetEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
      }
export function useGetEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export function useGetEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventQuery, GetEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventQuery, GetEventQueryVariables>(GetEventDocument, options);
        }
export type GetEventQueryHookResult = ReturnType<typeof useGetEventQuery>;
export type GetEventLazyQueryHookResult = ReturnType<typeof useGetEventLazyQuery>;
export type GetEventSuspenseQueryHookResult = ReturnType<typeof useGetEventSuspenseQuery>;
export type GetEventQueryResult = Apollo.QueryResult<GetEventQuery, GetEventQueryVariables>;
export const GetAllEventsForUserDocument = gql`
    query getAllEventsForUser($start: JSON) {
  getAllEventsForUser(start: $start) {
    events {
      id
      userId
      name {
        en
        es
        fr
      }
      description {
        en
        es
        fr
      }
      location
      startTime
      endTime
      timezone
      status
      organizationId
      image
      languages
      allowObnlChoice
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllEventsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllEventsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsForUserQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllEventsForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>(GetAllEventsForUserDocument, options);
      }
export function useGetAllEventsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>(GetAllEventsForUserDocument, options);
        }
export function useGetAllEventsForUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>(GetAllEventsForUserDocument, options);
        }
export type GetAllEventsForUserQueryHookResult = ReturnType<typeof useGetAllEventsForUserQuery>;
export type GetAllEventsForUserLazyQueryHookResult = ReturnType<typeof useGetAllEventsForUserLazyQuery>;
export type GetAllEventsForUserSuspenseQueryHookResult = ReturnType<typeof useGetAllEventsForUserSuspenseQuery>;
export type GetAllEventsForUserQueryResult = Apollo.QueryResult<GetAllEventsForUserQuery, GetAllEventsForUserQueryVariables>;
export const GetAllEventsForOrganizationDocument = gql`
    query getAllEventsForOrganization($organizationId: String!, $start: JSON) {
  getAllEventsForOrganization(organizationId: $organizationId, start: $start) {
    events {
      id
      userId
      name {
        en
        es
        fr
      }
      description {
        en
        es
        fr
      }
      location
      startTime
      endTime
      timezone
      status
      organizationId
      image
      languages
      allowObnlChoice
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllEventsForOrganizationQuery__
 *
 * To run a query within a React component, call `useGetAllEventsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllEventsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables> & ({ variables: GetAllEventsForOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>(GetAllEventsForOrganizationDocument, options);
      }
export function useGetAllEventsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>(GetAllEventsForOrganizationDocument, options);
        }
export function useGetAllEventsForOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>(GetAllEventsForOrganizationDocument, options);
        }
export type GetAllEventsForOrganizationQueryHookResult = ReturnType<typeof useGetAllEventsForOrganizationQuery>;
export type GetAllEventsForOrganizationLazyQueryHookResult = ReturnType<typeof useGetAllEventsForOrganizationLazyQuery>;
export type GetAllEventsForOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetAllEventsForOrganizationSuspenseQuery>;
export type GetAllEventsForOrganizationQueryResult = Apollo.QueryResult<GetAllEventsForOrganizationQuery, GetAllEventsForOrganizationQueryVariables>;
export const GetEventStripeTransactionsDocument = gql`
    query getEventStripeTransactions($id: String!) {
  getEventStripeTransactions(id: $id)
}
    `;

/**
 * __useGetEventStripeTransactionsQuery__
 *
 * To run a query within a React component, call `useGetEventStripeTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventStripeTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventStripeTransactionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEventStripeTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables> & ({ variables: GetEventStripeTransactionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>(GetEventStripeTransactionsDocument, options);
      }
export function useGetEventStripeTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>(GetEventStripeTransactionsDocument, options);
        }
export function useGetEventStripeTransactionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>(GetEventStripeTransactionsDocument, options);
        }
export type GetEventStripeTransactionsQueryHookResult = ReturnType<typeof useGetEventStripeTransactionsQuery>;
export type GetEventStripeTransactionsLazyQueryHookResult = ReturnType<typeof useGetEventStripeTransactionsLazyQuery>;
export type GetEventStripeTransactionsSuspenseQueryHookResult = ReturnType<typeof useGetEventStripeTransactionsSuspenseQuery>;
export type GetEventStripeTransactionsQueryResult = Apollo.QueryResult<GetEventStripeTransactionsQuery, GetEventStripeTransactionsQueryVariables>;
export const AddObnlToEventDocument = gql`
    mutation addObnlToEvent($eventId: String!, $obnlId: String!) {
  addObnlToEvent(eventId: $eventId, obnlId: $obnlId)
}
    `;
export type AddObnlToEventMutationFn = Apollo.MutationFunction<AddObnlToEventMutation, AddObnlToEventMutationVariables>;

/**
 * __useAddObnlToEventMutation__
 *
 * To run a mutation, you first call `useAddObnlToEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObnlToEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObnlToEventMutation, { data, loading, error }] = useAddObnlToEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      obnlId: // value for 'obnlId'
 *   },
 * });
 */
export function useAddObnlToEventMutation(baseOptions?: Apollo.MutationHookOptions<AddObnlToEventMutation, AddObnlToEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddObnlToEventMutation, AddObnlToEventMutationVariables>(AddObnlToEventDocument, options);
      }
export type AddObnlToEventMutationHookResult = ReturnType<typeof useAddObnlToEventMutation>;
export type AddObnlToEventMutationResult = Apollo.MutationResult<AddObnlToEventMutation>;
export type AddObnlToEventMutationOptions = Apollo.BaseMutationOptions<AddObnlToEventMutation, AddObnlToEventMutationVariables>;
export const RemoveObnlFromEventDocument = gql`
    mutation removeObnlFromEvent($eventId: String!, $obnlId: String!) {
  removeObnlFromEvent(eventId: $eventId, obnlId: $obnlId)
}
    `;
export type RemoveObnlFromEventMutationFn = Apollo.MutationFunction<RemoveObnlFromEventMutation, RemoveObnlFromEventMutationVariables>;

/**
 * __useRemoveObnlFromEventMutation__
 *
 * To run a mutation, you first call `useRemoveObnlFromEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveObnlFromEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeObnlFromEventMutation, { data, loading, error }] = useRemoveObnlFromEventMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      obnlId: // value for 'obnlId'
 *   },
 * });
 */
export function useRemoveObnlFromEventMutation(baseOptions?: Apollo.MutationHookOptions<RemoveObnlFromEventMutation, RemoveObnlFromEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveObnlFromEventMutation, RemoveObnlFromEventMutationVariables>(RemoveObnlFromEventDocument, options);
      }
export type RemoveObnlFromEventMutationHookResult = ReturnType<typeof useRemoveObnlFromEventMutation>;
export type RemoveObnlFromEventMutationResult = Apollo.MutationResult<RemoveObnlFromEventMutation>;
export type RemoveObnlFromEventMutationOptions = Apollo.BaseMutationOptions<RemoveObnlFromEventMutation, RemoveObnlFromEventMutationVariables>;
export const GetAllObnlsIdsForEventDocument = gql`
    query getAllObnlsIdsForEvent($eventId: String!, $start: JSON) {
  getAllObnlsIdsForEvent(eventId: $eventId, start: $start) {
    obnls
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllObnlsIdsForEventQuery__
 *
 * To run a query within a React component, call `useGetAllObnlsIdsForEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllObnlsIdsForEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllObnlsIdsForEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllObnlsIdsForEventQuery(baseOptions: Apollo.QueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables> & ({ variables: GetAllObnlsIdsForEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
      }
export function useGetAllObnlsIdsForEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
        }
export function useGetAllObnlsIdsForEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>(GetAllObnlsIdsForEventDocument, options);
        }
export type GetAllObnlsIdsForEventQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventQuery>;
export type GetAllObnlsIdsForEventLazyQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventLazyQuery>;
export type GetAllObnlsIdsForEventSuspenseQueryHookResult = ReturnType<typeof useGetAllObnlsIdsForEventSuspenseQuery>;
export type GetAllObnlsIdsForEventQueryResult = Apollo.QueryResult<GetAllObnlsIdsForEventQuery, GetAllObnlsIdsForEventQueryVariables>;
export const CreateInvitationDocument = gql`
    mutation createInvitation($invitation: InvitationInput!) {
  createInvitation(fields: $invitation) {
    userId
    events {
      eventId
      role
    }
    organizationId
    status
    id
  }
}
    `;
export type CreateInvitationMutationFn = Apollo.MutationFunction<CreateInvitationMutation, CreateInvitationMutationVariables>;

/**
 * __useCreateInvitationMutation__
 *
 * To run a mutation, you first call `useCreateInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvitationMutation, { data, loading, error }] = useCreateInvitationMutation({
 *   variables: {
 *      invitation: // value for 'invitation'
 *   },
 * });
 */
export function useCreateInvitationMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvitationMutation, CreateInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvitationMutation, CreateInvitationMutationVariables>(CreateInvitationDocument, options);
      }
export type CreateInvitationMutationHookResult = ReturnType<typeof useCreateInvitationMutation>;
export type CreateInvitationMutationResult = Apollo.MutationResult<CreateInvitationMutation>;
export type CreateInvitationMutationOptions = Apollo.BaseMutationOptions<CreateInvitationMutation, CreateInvitationMutationVariables>;
export const DeleteInvitationDocument = gql`
    mutation deleteInvitation($id: String!, $organizationId: String!, $eventId: String!) {
  deleteInvitation(id: $id, organizationId: $organizationId, eventId: $eventId)
}
    `;
export type DeleteInvitationMutationFn = Apollo.MutationFunction<DeleteInvitationMutation, DeleteInvitationMutationVariables>;

/**
 * __useDeleteInvitationMutation__
 *
 * To run a mutation, you first call `useDeleteInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvitationMutation, { data, loading, error }] = useDeleteInvitationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useDeleteInvitationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvitationMutation, DeleteInvitationMutationVariables>(DeleteInvitationDocument, options);
      }
export type DeleteInvitationMutationHookResult = ReturnType<typeof useDeleteInvitationMutation>;
export type DeleteInvitationMutationResult = Apollo.MutationResult<DeleteInvitationMutation>;
export type DeleteInvitationMutationOptions = Apollo.BaseMutationOptions<DeleteInvitationMutation, DeleteInvitationMutationVariables>;
export const LinkInvitationToUserDocument = gql`
    mutation linkInvitationToUser($token: UUID!) {
  linkInvitationToUser(token: $token) {
    organizationId
    eventId
    isPublished
    invitationId
  }
}
    `;
export type LinkInvitationToUserMutationFn = Apollo.MutationFunction<LinkInvitationToUserMutation, LinkInvitationToUserMutationVariables>;

/**
 * __useLinkInvitationToUserMutation__
 *
 * To run a mutation, you first call `useLinkInvitationToUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkInvitationToUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkInvitationToUserMutation, { data, loading, error }] = useLinkInvitationToUserMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useLinkInvitationToUserMutation(baseOptions?: Apollo.MutationHookOptions<LinkInvitationToUserMutation, LinkInvitationToUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LinkInvitationToUserMutation, LinkInvitationToUserMutationVariables>(LinkInvitationToUserDocument, options);
      }
export type LinkInvitationToUserMutationHookResult = ReturnType<typeof useLinkInvitationToUserMutation>;
export type LinkInvitationToUserMutationResult = Apollo.MutationResult<LinkInvitationToUserMutation>;
export type LinkInvitationToUserMutationOptions = Apollo.BaseMutationOptions<LinkInvitationToUserMutation, LinkInvitationToUserMutationVariables>;
export const GetInvitationDocument = gql`
    query getInvitation($id: String!, $organizationId: String!) {
  getInvitation(id: $id, organizationId: $organizationId) {
    userId
    userEmail
    events {
      eventId
      role
    }
    status
  }
}
    `;

/**
 * __useGetInvitationQuery__
 *
 * To run a query within a React component, call `useGetInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationQuery({
 *   variables: {
 *      id: // value for 'id'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetInvitationQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables> & ({ variables: GetInvitationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
      }
export function useGetInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export function useGetInvitationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvitationQuery, GetInvitationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvitationQuery, GetInvitationQueryVariables>(GetInvitationDocument, options);
        }
export type GetInvitationQueryHookResult = ReturnType<typeof useGetInvitationQuery>;
export type GetInvitationLazyQueryHookResult = ReturnType<typeof useGetInvitationLazyQuery>;
export type GetInvitationSuspenseQueryHookResult = ReturnType<typeof useGetInvitationSuspenseQuery>;
export type GetInvitationQueryResult = Apollo.QueryResult<GetInvitationQuery, GetInvitationQueryVariables>;
export const GetInvitationByIdDocument = gql`
    query getInvitationById($id: String!) {
  getInvitationById(id: $id) {
    userId
    userEmail
    events {
      eventId
      role
    }
    status
    organizationId
  }
}
    `;

/**
 * __useGetInvitationByIdQuery__
 *
 * To run a query within a React component, call `useGetInvitationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvitationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvitationByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvitationByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvitationByIdQuery, GetInvitationByIdQueryVariables> & ({ variables: GetInvitationByIdQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>(GetInvitationByIdDocument, options);
      }
export function useGetInvitationByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>(GetInvitationByIdDocument, options);
        }
export function useGetInvitationByIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>(GetInvitationByIdDocument, options);
        }
export type GetInvitationByIdQueryHookResult = ReturnType<typeof useGetInvitationByIdQuery>;
export type GetInvitationByIdLazyQueryHookResult = ReturnType<typeof useGetInvitationByIdLazyQuery>;
export type GetInvitationByIdSuspenseQueryHookResult = ReturnType<typeof useGetInvitationByIdSuspenseQuery>;
export type GetInvitationByIdQueryResult = Apollo.QueryResult<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>;
export const GetAllInvitationsForEventDocument = gql`
    query getAllInvitationsForEvent($organizationId: String!, $eventId: String!, $start: JSON) {
  getAllInvitationsForEvent(
    organizationId: $organizationId
    eventId: $eventId
    start: $start
  ) {
    lastEvaluated
    invitations {
      userId
      userEmail
      events {
        eventId
        role
      }
      status
      organizationId
      id
      invalidEmail
    }
  }
}
    `;

/**
 * __useGetAllInvitationsForEventQuery__
 *
 * To run a query within a React component, call `useGetAllInvitationsForEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitationsForEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitationsForEventQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllInvitationsForEventQuery(baseOptions: Apollo.QueryHookOptions<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables> & ({ variables: GetAllInvitationsForEventQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>(GetAllInvitationsForEventDocument, options);
      }
export function useGetAllInvitationsForEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>(GetAllInvitationsForEventDocument, options);
        }
export function useGetAllInvitationsForEventSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>(GetAllInvitationsForEventDocument, options);
        }
export type GetAllInvitationsForEventQueryHookResult = ReturnType<typeof useGetAllInvitationsForEventQuery>;
export type GetAllInvitationsForEventLazyQueryHookResult = ReturnType<typeof useGetAllInvitationsForEventLazyQuery>;
export type GetAllInvitationsForEventSuspenseQueryHookResult = ReturnType<typeof useGetAllInvitationsForEventSuspenseQuery>;
export type GetAllInvitationsForEventQueryResult = Apollo.QueryResult<GetAllInvitationsForEventQuery, GetAllInvitationsForEventQueryVariables>;
export const GetAllInvitationsForUserDocument = gql`
    query getAllInvitationsForUser($start: JSON) {
  getAllInvitationsForUser(start: $start) {
    lastEvaluated
    invitations {
      userId
      userEmail
      events {
        eventId
        role
      }
      status
      organizationId
      id
    }
  }
}
    `;

/**
 * __useGetAllInvitationsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllInvitationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitationsForUserQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllInvitationsForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>(GetAllInvitationsForUserDocument, options);
      }
export function useGetAllInvitationsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>(GetAllInvitationsForUserDocument, options);
        }
export function useGetAllInvitationsForUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>(GetAllInvitationsForUserDocument, options);
        }
export type GetAllInvitationsForUserQueryHookResult = ReturnType<typeof useGetAllInvitationsForUserQuery>;
export type GetAllInvitationsForUserLazyQueryHookResult = ReturnType<typeof useGetAllInvitationsForUserLazyQuery>;
export type GetAllInvitationsForUserSuspenseQueryHookResult = ReturnType<typeof useGetAllInvitationsForUserSuspenseQuery>;
export type GetAllInvitationsForUserQueryResult = Apollo.QueryResult<GetAllInvitationsForUserQuery, GetAllInvitationsForUserQueryVariables>;
export const GetAllAcceptedInvitationsForUserDocument = gql`
    query getAllAcceptedInvitationsForUser($start: JSON) {
  getAllAcceptedInvitationsForUser(start: $start) {
    lastEvaluated
    invitations {
      userId
      userEmail
      events {
        eventId
        role
      }
      status
      organizationId
      id
    }
  }
}
    `;

/**
 * __useGetAllAcceptedInvitationsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllAcceptedInvitationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAcceptedInvitationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAcceptedInvitationsForUserQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllAcceptedInvitationsForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>(GetAllAcceptedInvitationsForUserDocument, options);
      }
export function useGetAllAcceptedInvitationsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>(GetAllAcceptedInvitationsForUserDocument, options);
        }
export function useGetAllAcceptedInvitationsForUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>(GetAllAcceptedInvitationsForUserDocument, options);
        }
export type GetAllAcceptedInvitationsForUserQueryHookResult = ReturnType<typeof useGetAllAcceptedInvitationsForUserQuery>;
export type GetAllAcceptedInvitationsForUserLazyQueryHookResult = ReturnType<typeof useGetAllAcceptedInvitationsForUserLazyQuery>;
export type GetAllAcceptedInvitationsForUserSuspenseQueryHookResult = ReturnType<typeof useGetAllAcceptedInvitationsForUserSuspenseQuery>;
export type GetAllAcceptedInvitationsForUserQueryResult = Apollo.QueryResult<GetAllAcceptedInvitationsForUserQuery, GetAllAcceptedInvitationsForUserQueryVariables>;
export const GetAllInvitationsForOrganizationDocument = gql`
    query getAllInvitationsForOrganization($organizationId: String!, $start: JSON) {
  getAllInvitationsForOrganization(organizationId: $organizationId, start: $start) {
    lastEvaluated
    invitations {
      userId
      userEmail
      events {
        eventId
        role
      }
      status
      id
    }
  }
}
    `;

/**
 * __useGetAllInvitationsForOrganizationQuery__
 *
 * To run a query within a React component, call `useGetAllInvitationsForOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInvitationsForOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInvitationsForOrganizationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllInvitationsForOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables> & ({ variables: GetAllInvitationsForOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>(GetAllInvitationsForOrganizationDocument, options);
      }
export function useGetAllInvitationsForOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>(GetAllInvitationsForOrganizationDocument, options);
        }
export function useGetAllInvitationsForOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>(GetAllInvitationsForOrganizationDocument, options);
        }
export type GetAllInvitationsForOrganizationQueryHookResult = ReturnType<typeof useGetAllInvitationsForOrganizationQuery>;
export type GetAllInvitationsForOrganizationLazyQueryHookResult = ReturnType<typeof useGetAllInvitationsForOrganizationLazyQuery>;
export type GetAllInvitationsForOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetAllInvitationsForOrganizationSuspenseQuery>;
export type GetAllInvitationsForOrganizationQueryResult = Apollo.QueryResult<GetAllInvitationsForOrganizationQuery, GetAllInvitationsForOrganizationQueryVariables>;
export const GetObnlDocument = gql`
    query getObnl($id: String!) {
  getObnl(id: $id) {
    id
    name
    cause
    description {
      en
      es
      fr
    }
    logo
  }
}
    `;

/**
 * __useGetObnlQuery__
 *
 * To run a query within a React component, call `useGetObnlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetObnlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetObnlQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetObnlQuery(baseOptions: Apollo.QueryHookOptions<GetObnlQuery, GetObnlQueryVariables> & ({ variables: GetObnlQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
      }
export function useGetObnlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetObnlQuery, GetObnlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
        }
export function useGetObnlSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetObnlQuery, GetObnlQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetObnlQuery, GetObnlQueryVariables>(GetObnlDocument, options);
        }
export type GetObnlQueryHookResult = ReturnType<typeof useGetObnlQuery>;
export type GetObnlLazyQueryHookResult = ReturnType<typeof useGetObnlLazyQuery>;
export type GetObnlSuspenseQueryHookResult = ReturnType<typeof useGetObnlSuspenseQuery>;
export type GetObnlQueryResult = Apollo.QueryResult<GetObnlQuery, GetObnlQueryVariables>;
export const GetAllObnlsDocument = gql`
    query getAllObnls($start: JSON) {
  getAllObnls(start: $start) {
    obnls {
      id
      name
      cause
      description {
        en
        es
        fr
      }
      logo
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllObnlsQuery__
 *
 * To run a query within a React component, call `useGetAllObnlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllObnlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllObnlsQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllObnlsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllObnlsQuery, GetAllObnlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllObnlsQuery, GetAllObnlsQueryVariables>(GetAllObnlsDocument, options);
      }
export function useGetAllObnlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllObnlsQuery, GetAllObnlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllObnlsQuery, GetAllObnlsQueryVariables>(GetAllObnlsDocument, options);
        }
export function useGetAllObnlsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllObnlsQuery, GetAllObnlsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllObnlsQuery, GetAllObnlsQueryVariables>(GetAllObnlsDocument, options);
        }
export type GetAllObnlsQueryHookResult = ReturnType<typeof useGetAllObnlsQuery>;
export type GetAllObnlsLazyQueryHookResult = ReturnType<typeof useGetAllObnlsLazyQuery>;
export type GetAllObnlsSuspenseQueryHookResult = ReturnType<typeof useGetAllObnlsSuspenseQuery>;
export type GetAllObnlsQueryResult = Apollo.QueryResult<GetAllObnlsQuery, GetAllObnlsQueryVariables>;
export const GetAllObnlsByCauseDocument = gql`
    query getAllObnlsByCause($cause: String!, $start: JSON) {
  getAllObnlsByCause(cause: $cause, start: $start) {
    obnls {
      id
      name
      cause
      description {
        en
        es
        fr
      }
      logo
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllObnlsByCauseQuery__
 *
 * To run a query within a React component, call `useGetAllObnlsByCauseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllObnlsByCauseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllObnlsByCauseQuery({
 *   variables: {
 *      cause: // value for 'cause'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllObnlsByCauseQuery(baseOptions: Apollo.QueryHookOptions<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables> & ({ variables: GetAllObnlsByCauseQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>(GetAllObnlsByCauseDocument, options);
      }
export function useGetAllObnlsByCauseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>(GetAllObnlsByCauseDocument, options);
        }
export function useGetAllObnlsByCauseSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>(GetAllObnlsByCauseDocument, options);
        }
export type GetAllObnlsByCauseQueryHookResult = ReturnType<typeof useGetAllObnlsByCauseQuery>;
export type GetAllObnlsByCauseLazyQueryHookResult = ReturnType<typeof useGetAllObnlsByCauseLazyQuery>;
export type GetAllObnlsByCauseSuspenseQueryHookResult = ReturnType<typeof useGetAllObnlsByCauseSuspenseQuery>;
export type GetAllObnlsByCauseQueryResult = Apollo.QueryResult<GetAllObnlsByCauseQuery, GetAllObnlsByCauseQueryVariables>;
export const CreateOrganizationDocument = gql`
    mutation createOrganization($organization: OrganizationInput!) {
  createOrganization(fields: $organization) {
    organization {
      id
      name
      description
      stripeAccountId
      stripeAccountLinked
    }
    invitationId
  }
}
    `;
export type CreateOrganizationMutationFn = Apollo.MutationFunction<CreateOrganizationMutation, CreateOrganizationMutationVariables>;

/**
 * __useCreateOrganizationMutation__
 *
 * To run a mutation, you first call `useCreateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrganizationMutation, { data, loading, error }] = useCreateOrganizationMutation({
 *   variables: {
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useCreateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, options);
      }
export type CreateOrganizationMutationHookResult = ReturnType<typeof useCreateOrganizationMutation>;
export type CreateOrganizationMutationResult = Apollo.MutationResult<CreateOrganizationMutation>;
export type CreateOrganizationMutationOptions = Apollo.BaseMutationOptions<CreateOrganizationMutation, CreateOrganizationMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($id: String!, $organization: OrganizationInput!) {
  updateOrganization(id: $id, fields: $organization) {
    id
    name
    description
  }
}
    `;
export type UpdateOrganizationMutationFn = Apollo.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, options);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = Apollo.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = Apollo.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const GetOrganizationDocument = gql`
    query getOrganization($id: String!) {
  getOrganization(id: $id) {
    id
    name
    description
    stripeAccountLinked
    stripeAccountId
    validatedByMovon
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables> & ({ variables: GetOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export function useGetOrganizationSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationSuspenseQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetAllOrganizationsForUserDocument = gql`
    query getAllOrganizationsForUser($start: JSON) {
  getAllOrganizationsForUser(start: $start) {
    organizations {
      id
      name
      description
      stripeAccountLinked
      stripeAccountId
      validatedByMovon
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllOrganizationsForUserQuery__
 *
 * To run a query within a React component, call `useGetAllOrganizationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganizationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganizationsForUserQuery({
 *   variables: {
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllOrganizationsForUserQuery(baseOptions?: Apollo.QueryHookOptions<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>(GetAllOrganizationsForUserDocument, options);
      }
export function useGetAllOrganizationsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>(GetAllOrganizationsForUserDocument, options);
        }
export function useGetAllOrganizationsForUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>(GetAllOrganizationsForUserDocument, options);
        }
export type GetAllOrganizationsForUserQueryHookResult = ReturnType<typeof useGetAllOrganizationsForUserQuery>;
export type GetAllOrganizationsForUserLazyQueryHookResult = ReturnType<typeof useGetAllOrganizationsForUserLazyQuery>;
export type GetAllOrganizationsForUserSuspenseQueryHookResult = ReturnType<typeof useGetAllOrganizationsForUserSuspenseQuery>;
export type GetAllOrganizationsForUserQueryResult = Apollo.QueryResult<GetAllOrganizationsForUserQuery, GetAllOrganizationsForUserQueryVariables>;
export const GetOrganizationAccountLinkDocument = gql`
    query getOrganizationAccountLink($stripeAccountId: String!, $organizationId: String!) {
  getOrganizationAccountLink(
    stripeAccountId: $stripeAccountId
    organizationId: $organizationId
  )
}
    `;

/**
 * __useGetOrganizationAccountLinkQuery__
 *
 * To run a query within a React component, call `useGetOrganizationAccountLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationAccountLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationAccountLinkQuery({
 *   variables: {
 *      stripeAccountId: // value for 'stripeAccountId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationAccountLinkQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables> & ({ variables: GetOrganizationAccountLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>(GetOrganizationAccountLinkDocument, options);
      }
export function useGetOrganizationAccountLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>(GetOrganizationAccountLinkDocument, options);
        }
export function useGetOrganizationAccountLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>(GetOrganizationAccountLinkDocument, options);
        }
export type GetOrganizationAccountLinkQueryHookResult = ReturnType<typeof useGetOrganizationAccountLinkQuery>;
export type GetOrganizationAccountLinkLazyQueryHookResult = ReturnType<typeof useGetOrganizationAccountLinkLazyQuery>;
export type GetOrganizationAccountLinkSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationAccountLinkSuspenseQuery>;
export type GetOrganizationAccountLinkQueryResult = Apollo.QueryResult<GetOrganizationAccountLinkQuery, GetOrganizationAccountLinkQueryVariables>;
export const GetOrganizationStripeDashboardLinkDocument = gql`
    query getOrganizationStripeDashboardLink($stripeAccountId: String!, $organizationId: String!) {
  getOrganizationStripeDashboardLink(
    stripeAccountId: $stripeAccountId
    organizationId: $organizationId
  )
}
    `;

/**
 * __useGetOrganizationStripeDashboardLinkQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStripeDashboardLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStripeDashboardLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStripeDashboardLinkQuery({
 *   variables: {
 *      stripeAccountId: // value for 'stripeAccountId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useGetOrganizationStripeDashboardLinkQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables> & ({ variables: GetOrganizationStripeDashboardLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>(GetOrganizationStripeDashboardLinkDocument, options);
      }
export function useGetOrganizationStripeDashboardLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>(GetOrganizationStripeDashboardLinkDocument, options);
        }
export function useGetOrganizationStripeDashboardLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>(GetOrganizationStripeDashboardLinkDocument, options);
        }
export type GetOrganizationStripeDashboardLinkQueryHookResult = ReturnType<typeof useGetOrganizationStripeDashboardLinkQuery>;
export type GetOrganizationStripeDashboardLinkLazyQueryHookResult = ReturnType<typeof useGetOrganizationStripeDashboardLinkLazyQuery>;
export type GetOrganizationStripeDashboardLinkSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationStripeDashboardLinkSuspenseQuery>;
export type GetOrganizationStripeDashboardLinkQueryResult = Apollo.QueryResult<GetOrganizationStripeDashboardLinkQuery, GetOrganizationStripeDashboardLinkQueryVariables>;
export const GetOrganizationDetailsDocument = gql`
    query getOrganizationDetails($id: String!) {
  getOrganizationDetails(id: $id) {
    name
    description
  }
}
    `;

/**
 * __useGetOrganizationDetailsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables> & ({ variables: GetOrganizationDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>(GetOrganizationDetailsDocument, options);
      }
export function useGetOrganizationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>(GetOrganizationDetailsDocument, options);
        }
export function useGetOrganizationDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>(GetOrganizationDetailsDocument, options);
        }
export type GetOrganizationDetailsQueryHookResult = ReturnType<typeof useGetOrganizationDetailsQuery>;
export type GetOrganizationDetailsLazyQueryHookResult = ReturnType<typeof useGetOrganizationDetailsLazyQuery>;
export type GetOrganizationDetailsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationDetailsSuspenseQuery>;
export type GetOrganizationDetailsQueryResult = Apollo.QueryResult<GetOrganizationDetailsQuery, GetOrganizationDetailsQueryVariables>;
export const GetOrganizationStripeAccountDocument = gql`
    query getOrganizationStripeAccount($id: String!) {
  getOrganizationStripeAccount(id: $id)
}
    `;

/**
 * __useGetOrganizationStripeAccountQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStripeAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStripeAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStripeAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationStripeAccountQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables> & ({ variables: GetOrganizationStripeAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>(GetOrganizationStripeAccountDocument, options);
      }
export function useGetOrganizationStripeAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>(GetOrganizationStripeAccountDocument, options);
        }
export function useGetOrganizationStripeAccountSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>(GetOrganizationStripeAccountDocument, options);
        }
export type GetOrganizationStripeAccountQueryHookResult = ReturnType<typeof useGetOrganizationStripeAccountQuery>;
export type GetOrganizationStripeAccountLazyQueryHookResult = ReturnType<typeof useGetOrganizationStripeAccountLazyQuery>;
export type GetOrganizationStripeAccountSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationStripeAccountSuspenseQuery>;
export type GetOrganizationStripeAccountQueryResult = Apollo.QueryResult<GetOrganizationStripeAccountQuery, GetOrganizationStripeAccountQueryVariables>;
export const GetOrganizationStripeBalanceDocument = gql`
    query getOrganizationStripeBalance($id: String!) {
  getOrganizationStripeBalance(id: $id)
}
    `;

/**
 * __useGetOrganizationStripeBalanceQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStripeBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStripeBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStripeBalanceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationStripeBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables> & ({ variables: GetOrganizationStripeBalanceQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>(GetOrganizationStripeBalanceDocument, options);
      }
export function useGetOrganizationStripeBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>(GetOrganizationStripeBalanceDocument, options);
        }
export function useGetOrganizationStripeBalanceSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>(GetOrganizationStripeBalanceDocument, options);
        }
export type GetOrganizationStripeBalanceQueryHookResult = ReturnType<typeof useGetOrganizationStripeBalanceQuery>;
export type GetOrganizationStripeBalanceLazyQueryHookResult = ReturnType<typeof useGetOrganizationStripeBalanceLazyQuery>;
export type GetOrganizationStripeBalanceSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationStripeBalanceSuspenseQuery>;
export type GetOrganizationStripeBalanceQueryResult = Apollo.QueryResult<GetOrganizationStripeBalanceQuery, GetOrganizationStripeBalanceQueryVariables>;
export const GetOrganizationStripePayoutsDocument = gql`
    query getOrganizationStripePayouts($id: String!) {
  getOrganizationStripePayouts(id: $id)
}
    `;

/**
 * __useGetOrganizationStripePayoutsQuery__
 *
 * To run a query within a React component, call `useGetOrganizationStripePayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationStripePayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationStripePayoutsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrganizationStripePayoutsQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables> & ({ variables: GetOrganizationStripePayoutsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>(GetOrganizationStripePayoutsDocument, options);
      }
export function useGetOrganizationStripePayoutsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>(GetOrganizationStripePayoutsDocument, options);
        }
export function useGetOrganizationStripePayoutsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>(GetOrganizationStripePayoutsDocument, options);
        }
export type GetOrganizationStripePayoutsQueryHookResult = ReturnType<typeof useGetOrganizationStripePayoutsQuery>;
export type GetOrganizationStripePayoutsLazyQueryHookResult = ReturnType<typeof useGetOrganizationStripePayoutsLazyQuery>;
export type GetOrganizationStripePayoutsSuspenseQueryHookResult = ReturnType<typeof useGetOrganizationStripePayoutsSuspenseQuery>;
export type GetOrganizationStripePayoutsQueryResult = Apollo.QueryResult<GetOrganizationStripePayoutsQuery, GetOrganizationStripePayoutsQueryVariables>;
export const ValidateTicketDocument = gql`
    mutation validateTicket($waitingLineId: UUID!, $ticketId: UUID!) {
  validateTicket(waitingLineId: $waitingLineId, ticketId: $ticketId)
}
    `;
export type ValidateTicketMutationFn = Apollo.MutationFunction<ValidateTicketMutation, ValidateTicketMutationVariables>;

/**
 * __useValidateTicketMutation__
 *
 * To run a mutation, you first call `useValidateTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateTicketMutation, { data, loading, error }] = useValidateTicketMutation({
 *   variables: {
 *      waitingLineId: // value for 'waitingLineId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useValidateTicketMutation(baseOptions?: Apollo.MutationHookOptions<ValidateTicketMutation, ValidateTicketMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ValidateTicketMutation, ValidateTicketMutationVariables>(ValidateTicketDocument, options);
      }
export type ValidateTicketMutationHookResult = ReturnType<typeof useValidateTicketMutation>;
export type ValidateTicketMutationResult = Apollo.MutationResult<ValidateTicketMutation>;
export type ValidateTicketMutationOptions = Apollo.BaseMutationOptions<ValidateTicketMutation, ValidateTicketMutationVariables>;
export const GetAllWaitingLineTicketsDocument = gql`
    query getAllWaitingLineTickets($waitingLineId: UUID!, $start: JSON) {
  getAllWaitingLineTickets(waitingLineId: $waitingLineId, start: $start) {
    tickets {
      id
      status
      transactionId
      queueType
      expiresAt
      displayId
      eventId
      waitingLineId
      contactInfo {
        type
        value
      }
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllWaitingLineTicketsQuery__
 *
 * To run a query within a React component, call `useGetAllWaitingLineTicketsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWaitingLineTicketsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWaitingLineTicketsQuery({
 *   variables: {
 *      waitingLineId: // value for 'waitingLineId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllWaitingLineTicketsQuery(baseOptions: Apollo.QueryHookOptions<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables> & ({ variables: GetAllWaitingLineTicketsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>(GetAllWaitingLineTicketsDocument, options);
      }
export function useGetAllWaitingLineTicketsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>(GetAllWaitingLineTicketsDocument, options);
        }
export function useGetAllWaitingLineTicketsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>(GetAllWaitingLineTicketsDocument, options);
        }
export type GetAllWaitingLineTicketsQueryHookResult = ReturnType<typeof useGetAllWaitingLineTicketsQuery>;
export type GetAllWaitingLineTicketsLazyQueryHookResult = ReturnType<typeof useGetAllWaitingLineTicketsLazyQuery>;
export type GetAllWaitingLineTicketsSuspenseQueryHookResult = ReturnType<typeof useGetAllWaitingLineTicketsSuspenseQuery>;
export type GetAllWaitingLineTicketsQueryResult = Apollo.QueryResult<GetAllWaitingLineTicketsQuery, GetAllWaitingLineTicketsQueryVariables>;
export const GetTicketDocument = gql`
    query getTicket($waitingLineId: UUID!, $ticketId: UUID!) {
  getTicket(waitingLineId: $waitingLineId, ticketId: $ticketId) {
    id
    status
    expiresAt
    eventId
    waitingLineId
    displayId
  }
}
    `;

/**
 * __useGetTicketQuery__
 *
 * To run a query within a React component, call `useGetTicketQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketQuery({
 *   variables: {
 *      waitingLineId: // value for 'waitingLineId'
 *      ticketId: // value for 'ticketId'
 *   },
 * });
 */
export function useGetTicketQuery(baseOptions: Apollo.QueryHookOptions<GetTicketQuery, GetTicketQueryVariables> & ({ variables: GetTicketQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, options);
      }
export function useGetTicketLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTicketQuery, GetTicketQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, options);
        }
export function useGetTicketSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetTicketQuery, GetTicketQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetTicketQuery, GetTicketQueryVariables>(GetTicketDocument, options);
        }
export type GetTicketQueryHookResult = ReturnType<typeof useGetTicketQuery>;
export type GetTicketLazyQueryHookResult = ReturnType<typeof useGetTicketLazyQuery>;
export type GetTicketSuspenseQueryHookResult = ReturnType<typeof useGetTicketSuspenseQuery>;
export type GetTicketQueryResult = Apollo.QueryResult<GetTicketQuery, GetTicketQueryVariables>;
export const GetSlugAvailabilityDocument = gql`
    query getSlugAvailability($fields: GetSlugAvailabilityInput!) {
  getSlugAvailability(fields: $fields)
}
    `;

/**
 * __useGetSlugAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetSlugAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSlugAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSlugAvailabilityQuery({
 *   variables: {
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useGetSlugAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables> & ({ variables: GetSlugAvailabilityQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>(GetSlugAvailabilityDocument, options);
      }
export function useGetSlugAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>(GetSlugAvailabilityDocument, options);
        }
export function useGetSlugAvailabilitySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>(GetSlugAvailabilityDocument, options);
        }
export type GetSlugAvailabilityQueryHookResult = ReturnType<typeof useGetSlugAvailabilityQuery>;
export type GetSlugAvailabilityLazyQueryHookResult = ReturnType<typeof useGetSlugAvailabilityLazyQuery>;
export type GetSlugAvailabilitySuspenseQueryHookResult = ReturnType<typeof useGetSlugAvailabilitySuspenseQuery>;
export type GetSlugAvailabilityQueryResult = Apollo.QueryResult<GetSlugAvailabilityQuery, GetSlugAvailabilityQueryVariables>;
export const CreateUserDocument = gql`
    mutation createUser($email: String!, $password: String!, $locale: String!) {
  createUser(email: $email, password: $password, locale: $locale) {
    id
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const SendNewEmailValidationDocument = gql`
    mutation sendNewEmailValidation($locale: String!) {
  sendNewEmailValidation(locale: $locale) {
    id
  }
}
    `;
export type SendNewEmailValidationMutationFn = Apollo.MutationFunction<SendNewEmailValidationMutation, SendNewEmailValidationMutationVariables>;

/**
 * __useSendNewEmailValidationMutation__
 *
 * To run a mutation, you first call `useSendNewEmailValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNewEmailValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNewEmailValidationMutation, { data, loading, error }] = useSendNewEmailValidationMutation({
 *   variables: {
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useSendNewEmailValidationMutation(baseOptions?: Apollo.MutationHookOptions<SendNewEmailValidationMutation, SendNewEmailValidationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNewEmailValidationMutation, SendNewEmailValidationMutationVariables>(SendNewEmailValidationDocument, options);
      }
export type SendNewEmailValidationMutationHookResult = ReturnType<typeof useSendNewEmailValidationMutation>;
export type SendNewEmailValidationMutationResult = Apollo.MutationResult<SendNewEmailValidationMutation>;
export type SendNewEmailValidationMutationOptions = Apollo.BaseMutationOptions<SendNewEmailValidationMutation, SendNewEmailValidationMutationVariables>;
export const HasOrganizationsDocument = gql`
    query hasOrganizations {
  hasOrganizations
}
    `;

/**
 * __useHasOrganizationsQuery__
 *
 * To run a query within a React component, call `useHasOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasOrganizationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasOrganizationsQuery(baseOptions?: Apollo.QueryHookOptions<HasOrganizationsQuery, HasOrganizationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HasOrganizationsQuery, HasOrganizationsQueryVariables>(HasOrganizationsDocument, options);
      }
export function useHasOrganizationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HasOrganizationsQuery, HasOrganizationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HasOrganizationsQuery, HasOrganizationsQueryVariables>(HasOrganizationsDocument, options);
        }
export function useHasOrganizationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<HasOrganizationsQuery, HasOrganizationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HasOrganizationsQuery, HasOrganizationsQueryVariables>(HasOrganizationsDocument, options);
        }
export type HasOrganizationsQueryHookResult = ReturnType<typeof useHasOrganizationsQuery>;
export type HasOrganizationsLazyQueryHookResult = ReturnType<typeof useHasOrganizationsLazyQuery>;
export type HasOrganizationsSuspenseQueryHookResult = ReturnType<typeof useHasOrganizationsSuspenseQuery>;
export type HasOrganizationsQueryResult = Apollo.QueryResult<HasOrganizationsQuery, HasOrganizationsQueryVariables>;
export const GetUserDocument = gql`
    query getUser {
  getUser {
    id
    channel
    confirmedAccount
    email
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export function useGetUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserSuspenseQueryHookResult = ReturnType<typeof useGetUserSuspenseQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const ChangeWaitingLineStatusDocument = gql`
    mutation changeWaitingLineStatus($eventId: UUID!, $id: UUID!, $fields: UpdateWaitingLineStatusInput!) {
  changeWaitingLineStatus(eventId: $eventId, id: $id, fields: $fields)
}
    `;
export type ChangeWaitingLineStatusMutationFn = Apollo.MutationFunction<ChangeWaitingLineStatusMutation, ChangeWaitingLineStatusMutationVariables>;

/**
 * __useChangeWaitingLineStatusMutation__
 *
 * To run a mutation, you first call `useChangeWaitingLineStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeWaitingLineStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeWaitingLineStatusMutation, { data, loading, error }] = useChangeWaitingLineStatusMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useChangeWaitingLineStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeWaitingLineStatusMutation, ChangeWaitingLineStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeWaitingLineStatusMutation, ChangeWaitingLineStatusMutationVariables>(ChangeWaitingLineStatusDocument, options);
      }
export type ChangeWaitingLineStatusMutationHookResult = ReturnType<typeof useChangeWaitingLineStatusMutation>;
export type ChangeWaitingLineStatusMutationResult = Apollo.MutationResult<ChangeWaitingLineStatusMutation>;
export type ChangeWaitingLineStatusMutationOptions = Apollo.BaseMutationOptions<ChangeWaitingLineStatusMutation, ChangeWaitingLineStatusMutationVariables>;
export const CreateWaitingLineDocument = gql`
    mutation createWaitingLine($eventId: UUID!, $fields: WaitingLineInput!) {
  createWaitingLine(eventId: $eventId, fields: $fields) {
    id
  }
}
    `;
export type CreateWaitingLineMutationFn = Apollo.MutationFunction<CreateWaitingLineMutation, CreateWaitingLineMutationVariables>;

/**
 * __useCreateWaitingLineMutation__
 *
 * To run a mutation, you first call `useCreateWaitingLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWaitingLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWaitingLineMutation, { data, loading, error }] = useCreateWaitingLineMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useCreateWaitingLineMutation(baseOptions?: Apollo.MutationHookOptions<CreateWaitingLineMutation, CreateWaitingLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWaitingLineMutation, CreateWaitingLineMutationVariables>(CreateWaitingLineDocument, options);
      }
export type CreateWaitingLineMutationHookResult = ReturnType<typeof useCreateWaitingLineMutation>;
export type CreateWaitingLineMutationResult = Apollo.MutationResult<CreateWaitingLineMutation>;
export type CreateWaitingLineMutationOptions = Apollo.BaseMutationOptions<CreateWaitingLineMutation, CreateWaitingLineMutationVariables>;
export const UpdateWaitingLineDocument = gql`
    mutation updateWaitingLine($eventId: UUID!, $id: UUID!, $fields: WaitingLineUpdateInput!) {
  updateWaitingLine(eventId: $eventId, id: $id, fields: $fields) {
    id
  }
}
    `;
export type UpdateWaitingLineMutationFn = Apollo.MutationFunction<UpdateWaitingLineMutation, UpdateWaitingLineMutationVariables>;

/**
 * __useUpdateWaitingLineMutation__
 *
 * To run a mutation, you first call `useUpdateWaitingLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWaitingLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWaitingLineMutation, { data, loading, error }] = useUpdateWaitingLineMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      id: // value for 'id'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useUpdateWaitingLineMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWaitingLineMutation, UpdateWaitingLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWaitingLineMutation, UpdateWaitingLineMutationVariables>(UpdateWaitingLineDocument, options);
      }
export type UpdateWaitingLineMutationHookResult = ReturnType<typeof useUpdateWaitingLineMutation>;
export type UpdateWaitingLineMutationResult = Apollo.MutationResult<UpdateWaitingLineMutation>;
export type UpdateWaitingLineMutationOptions = Apollo.BaseMutationOptions<UpdateWaitingLineMutation, UpdateWaitingLineMutationVariables>;
export const DeleteWaitingLineDocument = gql`
    mutation deleteWaitingLine($eventId: UUID!, $id: UUID!) {
  deleteWaitingLine(eventId: $eventId, id: $id)
}
    `;
export type DeleteWaitingLineMutationFn = Apollo.MutationFunction<DeleteWaitingLineMutation, DeleteWaitingLineMutationVariables>;

/**
 * __useDeleteWaitingLineMutation__
 *
 * To run a mutation, you first call `useDeleteWaitingLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWaitingLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWaitingLineMutation, { data, loading, error }] = useDeleteWaitingLineMutation({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWaitingLineMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWaitingLineMutation, DeleteWaitingLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWaitingLineMutation, DeleteWaitingLineMutationVariables>(DeleteWaitingLineDocument, options);
      }
export type DeleteWaitingLineMutationHookResult = ReturnType<typeof useDeleteWaitingLineMutation>;
export type DeleteWaitingLineMutationResult = Apollo.MutationResult<DeleteWaitingLineMutation>;
export type DeleteWaitingLineMutationOptions = Apollo.BaseMutationOptions<DeleteWaitingLineMutation, DeleteWaitingLineMutationVariables>;
export const MoveTicketsToCalledQueueDocument = gql`
    mutation moveTicketsToCalledQueue($id: UUID!) {
  moveTicketsToCalledQueue(id: $id) {
    displayId
    id
  }
}
    `;
export type MoveTicketsToCalledQueueMutationFn = Apollo.MutationFunction<MoveTicketsToCalledQueueMutation, MoveTicketsToCalledQueueMutationVariables>;

/**
 * __useMoveTicketsToCalledQueueMutation__
 *
 * To run a mutation, you first call `useMoveTicketsToCalledQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveTicketsToCalledQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveTicketsToCalledQueueMutation, { data, loading, error }] = useMoveTicketsToCalledQueueMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMoveTicketsToCalledQueueMutation(baseOptions?: Apollo.MutationHookOptions<MoveTicketsToCalledQueueMutation, MoveTicketsToCalledQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MoveTicketsToCalledQueueMutation, MoveTicketsToCalledQueueMutationVariables>(MoveTicketsToCalledQueueDocument, options);
      }
export type MoveTicketsToCalledQueueMutationHookResult = ReturnType<typeof useMoveTicketsToCalledQueueMutation>;
export type MoveTicketsToCalledQueueMutationResult = Apollo.MutationResult<MoveTicketsToCalledQueueMutation>;
export type MoveTicketsToCalledQueueMutationOptions = Apollo.BaseMutationOptions<MoveTicketsToCalledQueueMutation, MoveTicketsToCalledQueueMutationVariables>;
export const GetAllEventWaitingLinesDocument = gql`
    query getAllEventWaitingLines($eventId: UUID!, $start: JSON) {
  getAllEventWaitingLines(eventId: $eventId, start: $start) {
    waitingLines {
      id
      name {
        fr
        en
        es
      }
      description {
        fr
        en
        es
      }
      status
      message {
        fr
        en
        es
      }
      waitingLineType
      usedTickets
      quantity
      limitedQuantity
      maxGroupSize
      price
      activatedTickets
      paidTickets
      isFreemium
      callSize
    }
    lastEvaluated
  }
}
    `;

/**
 * __useGetAllEventWaitingLinesQuery__
 *
 * To run a query within a React component, call `useGetAllEventWaitingLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventWaitingLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventWaitingLinesQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *      start: // value for 'start'
 *   },
 * });
 */
export function useGetAllEventWaitingLinesQuery(baseOptions: Apollo.QueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables> & ({ variables: GetAllEventWaitingLinesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
      }
export function useGetAllEventWaitingLinesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
        }
export function useGetAllEventWaitingLinesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>(GetAllEventWaitingLinesDocument, options);
        }
export type GetAllEventWaitingLinesQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesQuery>;
export type GetAllEventWaitingLinesLazyQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesLazyQuery>;
export type GetAllEventWaitingLinesSuspenseQueryHookResult = ReturnType<typeof useGetAllEventWaitingLinesSuspenseQuery>;
export type GetAllEventWaitingLinesQueryResult = Apollo.QueryResult<GetAllEventWaitingLinesQuery, GetAllEventWaitingLinesQueryVariables>;
export const GetWaitingLineDocument = gql`
    query getWaitingLine($id: UUID!) {
  getWaitingLine(id: $id) {
    id
    name {
      fr
      en
      es
    }
    description {
      fr
      en
      es
    }
    productDescription {
      fr
      en
      es
    }
    status
    waitingLineType
    maxGroupSize
    price
    currency
    quantity
    isFreemium
    callSize
    usedTickets
    limitedQuantity
    activatedTickets
    statementDescriptor
  }
}
    `;

/**
 * __useGetWaitingLineQuery__
 *
 * To run a query within a React component, call `useGetWaitingLineQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLineQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLineQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables> & ({ variables: GetWaitingLineQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
      }
export function useGetWaitingLineLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
        }
export function useGetWaitingLineSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLineQuery, GetWaitingLineQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLineQuery, GetWaitingLineQueryVariables>(GetWaitingLineDocument, options);
        }
export type GetWaitingLineQueryHookResult = ReturnType<typeof useGetWaitingLineQuery>;
export type GetWaitingLineLazyQueryHookResult = ReturnType<typeof useGetWaitingLineLazyQuery>;
export type GetWaitingLineSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLineSuspenseQuery>;
export type GetWaitingLineQueryResult = Apollo.QueryResult<GetWaitingLineQuery, GetWaitingLineQueryVariables>;
export const GetWaitingLineOperatingInfoDocument = gql`
    query getWaitingLineOperatingInfo($id: UUID!) {
  getWaitingLine(id: $id) {
    status
    metadata {
      depth
    }
  }
}
    `;

/**
 * __useGetWaitingLineOperatingInfoQuery__
 *
 * To run a query within a React component, call `useGetWaitingLineOperatingInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLineOperatingInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLineOperatingInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLineOperatingInfoQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables> & ({ variables: GetWaitingLineOperatingInfoQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>(GetWaitingLineOperatingInfoDocument, options);
      }
export function useGetWaitingLineOperatingInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>(GetWaitingLineOperatingInfoDocument, options);
        }
export function useGetWaitingLineOperatingInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>(GetWaitingLineOperatingInfoDocument, options);
        }
export type GetWaitingLineOperatingInfoQueryHookResult = ReturnType<typeof useGetWaitingLineOperatingInfoQuery>;
export type GetWaitingLineOperatingInfoLazyQueryHookResult = ReturnType<typeof useGetWaitingLineOperatingInfoLazyQuery>;
export type GetWaitingLineOperatingInfoSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLineOperatingInfoSuspenseQuery>;
export type GetWaitingLineOperatingInfoQueryResult = Apollo.QueryResult<GetWaitingLineOperatingInfoQuery, GetWaitingLineOperatingInfoQueryVariables>;
export const GetWaitingLineStatusInfosDocument = gql`
    query getWaitingLineStatusInfos($id: UUID!) {
  getWaitingLine(id: $id) {
    id
    status
    message {
      fr
      en
      es
    }
  }
}
    `;

/**
 * __useGetWaitingLineStatusInfosQuery__
 *
 * To run a query within a React component, call `useGetWaitingLineStatusInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWaitingLineStatusInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWaitingLineStatusInfosQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWaitingLineStatusInfosQuery(baseOptions: Apollo.QueryHookOptions<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables> & ({ variables: GetWaitingLineStatusInfosQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>(GetWaitingLineStatusInfosDocument, options);
      }
export function useGetWaitingLineStatusInfosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>(GetWaitingLineStatusInfosDocument, options);
        }
export function useGetWaitingLineStatusInfosSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>(GetWaitingLineStatusInfosDocument, options);
        }
export type GetWaitingLineStatusInfosQueryHookResult = ReturnType<typeof useGetWaitingLineStatusInfosQuery>;
export type GetWaitingLineStatusInfosLazyQueryHookResult = ReturnType<typeof useGetWaitingLineStatusInfosLazyQuery>;
export type GetWaitingLineStatusInfosSuspenseQueryHookResult = ReturnType<typeof useGetWaitingLineStatusInfosSuspenseQuery>;
export type GetWaitingLineStatusInfosQueryResult = Apollo.QueryResult<GetWaitingLineStatusInfosQuery, GetWaitingLineStatusInfosQueryVariables>;