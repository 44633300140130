import { Grid } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { SmallTitle } from 'styles/styles';

import PayoutCard from './PayoutCard';

interface Total {
  [currency: string]: number;
}

interface PayoutCardsProps {
  total: Total;
  balances: Record<string, number> | Record<string, { available: number; pending: number }>;
}

function PayoutCards(props: PayoutCardsProps) {
  const { total, balances } = props;

  return (
    <>
      <SmallTitle>
        <FormattedMessage id="funds_in_stripe_account" />
      </SmallTitle>
      <Grid container spacing={2} justifyContent="center">
        <Grid item>
          <PayoutCard
            balances={total}
            title="payouts_total_volume"
            tooltip="payouts_total_volume_tooltip"
            type="total"
          />
        </Grid>
        <Grid item>
          <PayoutCard
            balances={balances}
            title="payouts_available_funds"
            tooltip="payouts_available_funds_tooltip"
            type="available"
          />
        </Grid>
        <Grid item>
          <PayoutCard
            balances={balances}
            title="payouts_pending_funds"
            tooltip="payouts_pending_funds_tooltip"
            type="pending"
          />
        </Grid>
      </Grid>
    </>
  );
}

export default PayoutCards;
