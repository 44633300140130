import {
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import FormButtons from 'components/FormFields/FormButtons';
import { FormRadio, FormRadioGroupField } from 'components/FormFields/FormRadioGroupField';
import { ChangeWaitingLineStatusMutationVariables, UpdateWaitingLineStatusInput, WaitingLineStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { NeutralOutlinedButton } from 'styles/styles';

import TranslationsTabs from '../../../../Forms/TranslationsTabs';
import {
  ExplanationLabel,
  Section,
  Separator,
  StatusExplanationField,
} from './styles';

export type FormData = Omit<ChangeWaitingLineStatusMutationVariables, 'eventId' | 'id'>;
interface WaitingListStatusSelectorFormProps {
  loading: boolean
  onCancel: () => void
  onSubmit: SubmitHandler<any>
}

function WaitingLineStatusSelectorForm(props: WaitingListStatusSelectorFormProps) {
  const {
    loading,
    onCancel,
    onSubmit,
  } = props;

  const event = useEvent();

  const [lang, setLang] = useState<string>(event.languages[0] || Languages.EN);
  const [tabIndex, setTabIndex] = useState<number>(0);

  const handleChange = useCallback((_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
    setLang(event.languages[newValue]);
  }, [event.languages]);

  const { formatMessage } = useIntl();

  const {
    watch,
    formState,
    setValue,
    resetField,
  } = useFormContext<UpdateWaitingLineStatusInput>();

  const { errors } = formState;

  const status = watch('status');

  const [initialStatus] = useState(status);
  useEffect(() => {
    if (status !== initialStatus) {
      resetField('message');
      setValue('message', {
        en: '',
        fr: '',
        es: '',
      });
    }
  }, [status, setValue, initialStatus, resetField, event.languages]);

  return (
    <>
      <DialogTitle>
        <FormattedMessage id="waiting_line_change_status" />
      </DialogTitle>
      <DialogContent>
        <Section>
          <FormRadioGroupField<UpdateWaitingLineStatusInput>
            name="status"
            rules={{ required: true }}
          >
            {Object.values(WaitingLineStatus).map((possibleStatus) => (
              <FormRadio
                key={possibleStatus}
                color="secondary"
                label={<FormattedMessage id={`waiting_line_status_${possibleStatus}`} />}
                value={possibleStatus}
              />
            ))}
          </FormRadioGroupField>
        </Section>
        <Separator />
        <Section>
          <ExplanationLabel>
            <FormattedMessage id="waiting_line_status_explanation" />
          </ExplanationLabel>
          <TranslationsTabs
            languages={event.languages as Languages[]}
            formState={formState}
            handleChange={handleChange}
            errors={errors}
            tabIndex={tabIndex}
          />
          <StatusExplanationField
            rows={3}
            multiline
            key={lang}
            name={`message.${lang}`}
            placeholder={formatMessage({ id: 'waiting_line_status_explanation_placeholder' })}
            rules={{
              maxLength: 200,
              validate: (value) => (value ? value.length <= 200 : undefined),
            }}
          />
        </Section>
      </DialogContent>
      <DialogActions>
        <NeutralOutlinedButton
          type="button"
          disabled={loading}
          onClick={onCancel}
        >
          <FormattedMessage id="cancel" />
        </NeutralOutlinedButton>
        <FormButtons
          noGrid
          submit={onSubmit}
        />
      </DialogActions>
    </>
  );
}

export default WaitingLineStatusSelectorForm;
