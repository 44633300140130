import styled from 'styled-components';
import { Text } from 'styles/styles';

export const SectionGrid = styled.div`
  border: 1px solid rgba(140 130 115 / 23%);
  border-radius: 10px;
  padding: 16px;
  position: relative;
  overflow: visible;
  margin: 8px 0;
`;

export const SectionLabel = styled(Text)`
  position: absolute;
  transform: translateY(-125%);
  padding: 0 8px;
  margin-left: -8px;
  background-color: ${({ theme }) => theme.palette.background.default};
`;
