import { Grid } from '@mui/material';
import EmailErrorIcon from 'assets/images/auth/icon_email_error.svg';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import { useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  RoundCircleBackground,
  RoundCircleImage,
  TextButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import AuthForm from './AuthForm';

function ConfirmEmailError() {
  const { loggedIn, confirmedAccount } = useContext(AuthenticatorContext);
  const [, setLocation] = useLocation();

  useEffect(() => {
    if (loggedIn && confirmedAccount) {
      setLocation('~/');
    }
  }, [confirmedAccount, loggedIn, setLocation]);

  return (
    <AuthForm>
      <Grid container alignContent="center" textAlign="center">
        <Grid container item m="32px" gap="16px">
          <Grid item xs={12}>
            <RoundCircleBackground>
              <RoundCircleImage src={EmailErrorIcon} />
            </RoundCircleBackground>
          </Grid>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="unable_to_verify_email" />
            </Title>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" marginBottom="16px">
          <Grid item xs={8}>
            <FormattedMessage id="check_email_not_altered" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TextButton color="secondary" onClick={() => setLocation('~/')}>
            <FormattedMessage id="go_back_to_login" />
          </TextButton>
        </Grid>
      </Grid>
    </AuthForm>
  );
}

export default ConfirmEmailError;
