import { ApolloProvider } from '@apollo/client';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Authenticator from 'components/Authenticator/Authenticator';
import FeedbackProvider from 'components/Feedback/FeedbackProvider';
import apolloClient from 'lib/graphql/apolloClient';
import LanguageProvider from 'providers/i18n/LanguageProvider';
import OrganizationProvider from 'providers/organization/OrganizationProvider';
import TitleProvider from 'providers/title/TitleProvider';
import InviteTokenProvider from 'providers/token/InviteTokenProvider';
import Routes from 'routes/Routes';

import AppThemeProvider from '../styles/AppThemeProvider';

const queryClient = new QueryClient();

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <StyledEngineProvider injectFirst>
        <LanguageProvider>
          <AppThemeProvider>
            <CssBaseline enableColorScheme />
            <QueryClientProvider client={queryClient}>
              <TitleProvider>
                <Authenticator>
                  <OrganizationProvider>
                    <InviteTokenProvider>
                      <FeedbackProvider>
                        <Routes />
                      </FeedbackProvider>
                    </InviteTokenProvider>
                  </OrganizationProvider>
                </Authenticator>
              </TitleProvider>
            </QueryClientProvider>
          </AppThemeProvider>
        </LanguageProvider>
      </StyledEngineProvider>
    </ApolloProvider>
  );
}

export default App;
