import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import QRCode from 'react-qr-code';
import styled from 'styled-components';
import { ResponsiveCloudImg } from 'styles/styles';

export const InformationContainer = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  border-radius: 1rem;
  margin: 2rem 0;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const EventTitle = styled(Typography)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  margin: 0.5rem 0;
`;

export const EventAddress = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  margin: 0.5rem 0;
`;

export const EventDate = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  opacity: 0.5;
  margin: 0.5rem 0;
`;

export const PublishProgress = styled(LinearProgress)`
  width: 70%;
  height: 25px;
  border-radius: 15px;
  background: ${({ theme }) => theme.palette.text.disabled};

  & .${linearProgressClasses.bar1Determinate} {
    background-color: ${({ theme }) => theme.palette.secondary.main};
    border-radius: 15px;
  }
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
  text-align: left;
  margin: 1rem;
`;

export const ProgressionCircle = styled.div`
  min-height: 25px;
  min-width: 25px;
  border-radius: 50%;
  border: 2px solid black;
  margin-right: 1rem;
`;

export const CheckContainer = styled.div`
  height: 25px;
  width: 25px;
  min-width: 25px;
  min-height: 25px;
  border-radius: 50%;
  background: #74c365;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
`;

export const ReviewContainer = styled.div`
  background: ${({ theme }) => theme.palette.info.dark};
  color: ${({ theme }) => theme.palette.text.primary};
  width: auto;
  position: relative;
  top: -12px;
  border-radius: 5px;
  display: inline-block;
  z-index: 20;
  padding: 0 1rem;
  background-clip:  padding-box;
`;

export const PublishingContainer = styled.div`
  margin-top: 2rem;
`;

export const PublishedDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 1rem;
  border: 1px solid ${({ theme }) => theme.palette.divider};
`;

export const ImageContainer = styled.div`
  height: 200px;
  margin: 1rem;
`;

export const EventImage = styled(ResponsiveCloudImg)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  align-self: center;
`;

export const NextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1.5rem;
`;

export const DetailsEventTitle = styled(Typography)`
  font-size: 18px;
  color: ${({ theme }) => theme.palette.text.primary};
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const DetailsAddress = styled(Typography)`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const UnpublishText = styled.span`
  font-size: 18px;
  font-weight: 700;
  margin: 0 2rem;
`;

export const WhatsNext = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

export const WhatsNextText = styled(Typography)`
  font-size: 1.75rem;
  font-weight: 700;
  margin-left: 1rem;
`;

export const Step = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 120px;
  border-radius: 1rem;
  margin: 1rem 0;
  border: 1px solid ${({ theme }) => theme.palette.divider};
  background: ${({ theme }) => theme.palette.divider};
`;

export const ClickableStep = styled(Step)`
  height: 80px;
  min-height: 80px;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.palette.secondary.main};
    cursor: pointer;
  }
`;

export const StepInstructions = styled.div`
  font-size: 16px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: 700;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
`;

export const NextIcon = styled(FontAwesomeIcon)`
  padding: 0 0.5rem;
`;

export const QR = styled(QRCode)`
  max-height: 150px;
  max-width: 150px;
`;

export const QRContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
`;

export const EventLink = styled.a`
  font-size: 14px;
  color: ${({ theme }) => theme.palette.secondary.main};
  margin: 1rem;
  cursor: pointer;
  text-align: center;

  &:hover {
    text-decoration: underline;
  }
`;

export const StepTitle = styled(StepInstructions)`
  margin: 1rem 0;
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const QRCodeContainer = styled.div`
  height: 175px;
  width: 175px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin-bottom: 1rem;
`;
