/* eslint-disable import/prefer-default-export */
import Search from 'components/Search';
import { styled } from 'styled-components';

export const SearchBar = styled(Search)`
  margin-top: 1.5rem;

  @media (width <= 600px) {
    margin-top: 0;
  }
`;
