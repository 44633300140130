import { createDonutChartOptions } from 'lib/agCharts/charts';
import { currencies } from 'lib/constants/currencies';
import { WaitingLine } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ChartCard from '../ChartCard';

interface SalesQueueCardProps {
  balanceTransactions: BalanceTransactionSplits[];
  waitingLines: WaitingLine[];
  defaultLocale: Languages;
}

function SalesQueueCard(props: SalesQueueCardProps) {
  const { balanceTransactions, waitingLines, defaultLocale } = props;
  const intl = useIntl();

  const salesQueueData = useMemo(() => {
    if (!balanceTransactions || balanceTransactions.length === 0) return [];
    const transactionsByWaitingLine = new Map<string, { [currency: string]: number }>();

    balanceTransactions.forEach((transaction) => {
      const { waitingLineId } = transaction.metadata;
      if (!transactionsByWaitingLine.has(waitingLineId)) {
        transactionsByWaitingLine.set(waitingLineId, {});
      }
      const transactionsByCurrency = transactionsByWaitingLine.get(waitingLineId)!;

      const { currency } = transaction;
      const amount = transaction.metadata.splits.organization;

      if (!transactionsByCurrency[currency]) transactionsByCurrency[currency] = 0;
      transactionsByCurrency[currency] += amount;
    });

    return Array.from(transactionsByWaitingLine.entries()).flatMap(
      ([waitingLineId, transactionsByCurrency]) => {
        const waitingLine = waitingLines.find((line) => line.id === waitingLineId);
        if (!waitingLine) return [];

        return Object.entries(transactionsByCurrency).map(([code, amount]) => {
          const currency = currencies.find((cur) => cur.code === code.toUpperCase());
          const symbol = currency ? currency.symbol : '$';
          return {
            asset: waitingLine.name[intl.locale as Languages] || waitingLine.name[defaultLocale],
            amount: (amount as number) / 100,
            unit: symbol,
          };
        });
      },
    );
  }, [balanceTransactions, waitingLines, intl.locale, defaultLocale]);

  const salesQueueDonutOptions = createDonutChartOptions(salesQueueData, intl.locale, intl.formatMessage({ id: 'your_balance' }));

  return (
    <ChartCard title="total_sales_queue" chartOptions={salesQueueDonutOptions} />
  );
}

export default SalesQueueCard;
