import { faBank } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';

import {
  FakeText, StatementContainer, StatementDescriptor, StatementDescriptorPrice,
} from './styles';

function StatementDescriptorExplanationCard(props: { statementDescriptor?: string }) {
  const { statementDescriptor } = props;
  return (
    <StatementContainer variant="outlined">
      <Grid container item alignItems="center" gap={2} sx={{ borderBottom: '1px solid black', paddingBottom: '5px' }}>
        <FontAwesomeIcon icon={faBank} />
        <FakeText $width={100} />
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <FakeText $width={90} />
        $3.14
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <StatementDescriptor>
          {`MOVON* ${statementDescriptor || ''}`}
        </StatementDescriptor>
        <StatementDescriptorPrice>
          $20.00
        </StatementDescriptorPrice>
      </Grid>
      <Grid container alignItems="center" justifyContent="space-between">
        <FakeText $width={110} />
        $16.18
      </Grid>
    </StatementContainer>
  );
}

export default StatementDescriptorExplanationCard;
