import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextFieldProps } from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import { useMemo } from 'react';
import {
  FieldValues,
  RegisterOptions,
  useController,
  ValidationRule,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormField, { FormFieldProps } from '../FormField';
import { QuantityButton } from '../styles';
import { ControlsLayout, NoControllerTextField } from './styles';

type CustomMUITextFieldProps = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name' | 'inputProps' | 'type'>;

interface FormNumberFieldProps extends FormFieldProps, CustomMUITextFieldProps {
  minimum?: ValidationRule<number>
  maximum?: ValidationRule<number>
  innerFieldLabel?: string
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'min' | 'max'>
}

function FormNumberFieldWithControls(props: FormNumberFieldProps) {
  const {
    helperTextId,
    labelId,
    maximum,
    minimum = 0,
    name,
    required,
    innerFieldLabel,
    fieldDescription,
    rules = {},
    ...textFieldProps
  } = props;
  const intl = useIntl();

  const newRules = useMemo(
    () => ({ min: minimum, max: maximum, ...rules }),
    [minimum, maximum, rules],
  );
  const { field, formState } = useController({ name, rules: { required, ...newRules } });
  const error = useFieldFormError(name);

  return (
    <FormField
      name={name}
      helperTextId={helperTextId}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <ControlsLayout>
        <QuantityButton
          color="secondary"
          onClick={() => field.onChange(Number(field.value) - 1)}
          $disabled={field.value <= minimum}
        >
          <FontAwesomeIcon icon={faMinus} />
        </QuantityButton>
        <NoControllerTextField
          size="small"
          value={field.value || formState.defaultValues?.[name]}
          placeholder={innerFieldLabel ? intl.formatMessage({ id: innerFieldLabel }) : ''}
          onChange={(event) => {
            const { value } = event.target;
            field.onChange(value === '' ? minimum : +value);
          }}
          onBlur={field.onBlur}
          ref={field.ref}
          error={Boolean(error?.type) || false}
          type="number"
          inputProps={{ min: minimum, max: maximum }}
          {...textFieldProps}
        />
        <QuantityButton
          color="secondary"
          onClick={() => field.onChange(Number(field.value) + 1)}
          $disabled={maximum !== undefined && field.value >= maximum}
        >
          <FontAwesomeIcon icon={faPlus} />
        </QuantityButton>
      </ControlsLayout>
    </FormField>
  );
}

export default FormNumberFieldWithControls;
