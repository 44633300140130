import { faChevronDown, faChevronRight, faDisplay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip } from '@mui/material';
import { ListCard, ListCardSection, ListCardTitle } from 'components/ListCard';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { TicketStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import settings from 'lib/settings';
import EventContext from 'providers/event/EventContext';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

import WaitingLineStatusSelector from '../components/WaitingLineStatusSelector';
import WaitingLineTicketList, { TicketRowItem } from '../components/WaitingLineTicketList/WaitingLineTicketList';
import {
  BackgroundlessIconButton,
  BigTicketsSold,
  CollapseSection,
  ExpendablePaper,
  FullWidthCollapse,
  LeftGrid,
  ListCardSectionWithMargin,
  NoBorderButton,
  ProgressDiv,
  ProgressText,
  ProgressTicketBar,
  RowContainer,
  ScannedContainer,
  TicketIcon,
  TicketsLeft,
  UsedTickets,
} from '../styles';
import { CenteredGrid } from '../WaitingLineForm/SelectWaitingLineType/components/styles';

interface WaitingLineOperatingCardDesktopProps {
  tickets: TicketRowItem[];
  showOperations: boolean;
  ticketIcon: string;
  waitingLine: EventWaitingLine;
  setShowOperations: React.Dispatch<React.SetStateAction<boolean>>;
  onRefetch: () => void | Promise<void>;
  searchValue?: string;
  handleWaitingLinesRefetch: () => void | Promise<void>;
  onCallTickets: () => void | Promise<void>;
}

function WaitingLineOperatingCardDesktop(props: WaitingLineOperatingCardDesktopProps) {
  const {
    tickets,
    showOperations,
    ticketIcon,
    waitingLine,
    setShowOperations,
    searchValue,
    onRefetch,
    handleWaitingLinesRefetch,
    onCallTickets,
  } = props;

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);

  const eventLanguage = event.languages[0] as Languages;

  const validatedTickets = useMemo(
    () => tickets.filter((ticket) => ticket.status === TicketStatus.Validated),
    [tickets],
  );

  const progressValue = useMemo(() => (
    validatedTickets.length / tickets.length) * 100, [validatedTickets, tickets]);

  return (
    <ExpendablePaper elevation={showOperations ? 4 : 0} $expended={showOperations}>
      <ListCard spacing={4}>
        <ListCardSection xs>
          <TicketIcon src={ticketIcon} />
          <ListCardTitle title={waitingLine.name[language] || waitingLine.name[eventLanguage] || ''} />
        </ListCardSection>
        {waitingLine.isFreemium && (
          <ListCardSection>
            <Tooltip placement="top" arrow title={<FormattedMessage id="event_view_display" />}>
              <NoBorderButton
                onClick={() => window.open(`${settings.displayUrl}/${waitingLine.id}`)}
                color="secondary"
                size="medium"
              >
                <FontAwesomeIcon icon={faDisplay} />
              </NoBorderButton>
            </Tooltip>
          </ListCardSection>
        )}
        <ListCardSection>
          <WaitingLineStatusSelector
            waitingLine={waitingLine}
            handleRefetch={handleWaitingLinesRefetch}
          />
        </ListCardSection>
        <ListCardSectionWithMargin>
          <BackgroundlessIconButton
            onClick={() => setShowOperations((current) => !current)}
            color="primary"
            size="small"
            disableRipple
            disableFocusRipple
            disableTouchRipple
          >
            <FontAwesomeIcon icon={showOperations ? faChevronDown : faChevronRight} />
          </BackgroundlessIconButton>
        </ListCardSectionWithMargin>
        <CollapseSection xs={12}>
          <FullWidthCollapse
            in={showOperations}
            mountOnEnter
            unmountOnExit
          >
            <ScannedContainer>
              <Grid container>
                <CenteredGrid item xs={waitingLine.isFreemium ? 6 : 12}>
                  <RowContainer>
                    <ProgressDiv>
                      <ProgressText>
                        <FormattedMessage id="operator_tickets" />
                      </ProgressText>
                      <ProgressTicketBar
                        variant="determinate"
                        value={progressValue}
                      />
                    </ProgressDiv>
                    <UsedTickets>
                      <BigTicketsSold>
                        {validatedTickets.length}
                      </BigTicketsSold>
                      <TicketsLeft>
                        {`/ ${tickets.length}`}
                      </TicketsLeft>
                    </UsedTickets>
                  </RowContainer>
                </CenteredGrid>
                {waitingLine.isFreemium && (
                  <LeftGrid item xs={6}>
                    <ContainedButton
                      color="secondary"
                      onClick={onCallTickets}
                    >
                      <FormattedMessage id="operator_tickets_call" values={{ callSize: waitingLine.callSize }} />
                    </ContainedButton>
                  </LeftGrid>
                )}
              </Grid>
            </ScannedContainer>
            <WaitingLineTicketList
              tickets={tickets}
              handleRefetch={onRefetch}
              searchValue={searchValue}
              isFreemium={waitingLine.isFreemium}
              waitingLineType={waitingLine.waitingLineType}
            />
          </FullWidthCollapse>
        </CollapseSection>
      </ListCard>
    </ExpendablePaper>
  );
}

export default WaitingLineOperatingCardDesktop;
