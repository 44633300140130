import { faCircleExclamation, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import { GetAllInvitationForEventItem } from 'lib/graphql/entities/invitation/types';
import {
  InvitationStatusEnum,
  useDeleteInvitationMutation,
  useGetAllInvitationsForEventLazyQuery,
} from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import useOrganization from 'providers/organization/useOrganization';
import { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContainedLoadingButton } from 'styles/styles';

import { EmailContainer, ErrorIcon } from './styles';

function InvitationsTable() {
  const event = useEvent();
  const intl = useIntl();
  const feedback = useFeedback();

  const { organizationId } = useOrganization();

  const [getAllInvitationsForEventQuery] = useGetAllInvitationsForEventLazyQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['getAllInvitationsForEvent'],
    queryFn: async ({ pageParam }: { pageParam: string | undefined }) => {
      const result = await getAllInvitationsForEventQuery({
        fetchPolicy: 'network-only',
        variables: {
          eventId: event.id,
          organizationId: organizationId || '',
          start: pageParam,
        },
      });
      return result.data?.getAllInvitationsForEvent;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const invitations = useMemo(() => (
    data?.pages.flatMap(
      (page) => page?.invitations || [],
    ) || []
  ), [data]);

  const [deleteInvitation, { loading: deleteLoading }] = useDeleteInvitationMutation();

  const queryClient = useQueryClient();
  const handleDelete = useCallback(async (item: {
    userEmail: string, organizationId: string, id: string
  }) => {
    try {
      await deleteInvitation({
        variables: {
          organizationId: item.organizationId,
          eventId: event.id,
          id: item.id,
        },
      });
      queryClient.invalidateQueries({ queryKey: ['getAllInvitationsForEvent'], exact: true });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_invitation_success" />),
        severity: 'success',
      });
    } catch (err: any) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_invitation_error" />),
        severity: 'error',
      });
    }
  }, [deleteInvitation, event.id, feedback, queryClient]);

  const columns = useMemo<GridColDef<GetAllInvitationForEventItem>[]>(() => [
    {
      field: 'userEmail',
      flex: 3,
      resizable: false,
      headerName: intl.formatMessage({ id: 'email' }),
      renderCell: ({ row, value }) => (
        <EmailContainer>
          {value}
          {row.invalidEmail && row.status !== InvitationStatusEnum.Accepted && (
            <Tooltip title={<FormattedMessage id="invitation_invalid_email_tooltip" />}>
              <ErrorIcon icon={faCircleExclamation} size="lg" />
            </Tooltip>
          )}
        </EmailContainer>
      ),
    },
    {
      field: 'role',
      flex: 1,
      resizable: false,
      headerName: intl.formatMessage({ id: 'role' }),
      valueGetter: (value, row) => {
        const eventAccess = row.events.find(
          ({ eventId }: { eventId: string }) => eventId === event.id,
        );

        if (eventAccess) {
          return intl.formatMessage({ id: `role_${eventAccess.role.toLowerCase()}` });
        }
        return '';
      },
    },
    {
      field: 'status',
      flex: 1,
      resizable: false,
      headerName: intl.formatMessage({ id: 'status' }),
      renderCell: (params) => (
        <Chip
          label={<FormattedMessage id={`invitation_status_${params.value.toLowerCase()}`} />}
          color={params.value === InvitationStatusEnum.Accepted ? 'success' : 'default'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: intl.formatMessage({ id: 'actions' }),
      flex: 1,
      resizable: false,
      renderCell: (params) => (
        <ContainedLoadingButton
          key={params.row.userId}
          color="error"
          loading={deleteLoading}
          onClick={() => handleDelete(params.row)}
          startIcon={<FontAwesomeIcon icon={faTrash} />}
        >
          <FormattedMessage id="delete" />
        </ContainedLoadingButton>
      ),
    },
  ], [intl, event.id, deleteLoading, handleDelete]);

  return (
    <Loader isLoading={isLoading}>
      <div>
        <DataGrid
          autoHeight
          loading={isLoading}
          columns={columns}
          rows={invitations || []}
          getRowId={(row) => row.id}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 10 },
            },
          }}
        />
      </div>
    </Loader>
  );
}

export default InvitationsTable;
