import { Grid } from '@mui/material';
import styled from 'styled-components';

interface SectionProps {
  $isCentered?: boolean;
  $isRight?: boolean;
  $isLeft?: boolean;
}

export const PaddedGrid = styled(Grid)`
  padding: 16px;
`;

export const Section = styled.div<SectionProps>`
  display: flex;
  align-items: center;
  gap: 16px;
  height: 100%;

  ${({ $isCentered }) => $isCentered && `
    justify-content: space-around;
  `}

  ${({ $isRight }) => $isRight && `
    justify-content: flex-end;
  `}

  ${({ $isLeft }) => $isLeft && `
    justify-content: flex-start;
  `}
`;

export const SpaceBetweenSection = styled(Section)`
  justify-content: space-between;
  gap: 0;
  
  ${({ $isCentered }) => $isCentered && `
    justify-content: center;
  `}
`;

export const Title = styled.p`
  margin: 0;
  width: 300px;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (width <= 600px) {
    max-width: 140px;
  }
`;

export const ClickableCard = styled.a`
  display: flex;
  cursor: pointer;

  &:hover {
    background-color: rgba(0 0 0 / 5%);
  }

  &:active {
    background-color: rgba(0 0 0 / 10%);
  }
`;
