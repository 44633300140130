import { createContext } from 'react';

export interface ITitleContext {
  pushTitle: () => string
  updateText: (id: string, text: string) => void
  popTitle: (id: string) => void
}

export const defaultTitleContext: ITitleContext = {
  pushTitle: () => 'invalid',
  updateText: () => {},
  popTitle: () => {},
};

export const TitleContext = createContext(defaultTitleContext);
