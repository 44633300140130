import { faMoneyBillWave } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import Loader from 'components/Loading/Loader';
import AccountLinkButton from 'components/Stripe/Accounts/AccountLinkButton/AccountLinkButton';
import StripeDashboardButton from 'components/Stripe/Accounts/DashboardButton/StripeDashboardButton';
import { Organization, useGetOrganizationStripeAccountQuery } from 'lib/graphql/graphql';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Stripe from 'stripe';

import {
  CenteredIcon,
  RoundCircleBackground,
  SectionContainer,
  StripeDescription,
  StripeSection,
} from './styles';

interface StripeAccountBannerProps {
  organization: Organization;
  onRefetch: () => void;
}

function StripeAccountBanner(props: StripeAccountBannerProps) {
  const { organization, onRefetch } = props;
  const theme = useTheme();

  const { data, loading } = useGetOrganizationStripeAccountQuery({
    variables: {
      id: organization.id,
    },
    skip: !organization.id,
  });

  const stripeAccount = data?.getOrganizationStripeAccount as Stripe.Account | undefined;

  const canReceivePayments = organization?.stripeAccountLinked && stripeAccount?.payouts_enabled;

  useEffect(() => {
    if (!canReceivePayments) {
      const refetchQuery = () => onRefetch();
      window.addEventListener('focus', refetchQuery);
      return () => window.removeEventListener('focus', refetchQuery);
    }
    return undefined;
  }, [canReceivePayments, onRefetch]);

  let titleId;
  switch (true) {
    case organization?.stripeAccountLinked && !stripeAccount?.payouts_enabled:
      titleId = 'stripe_support_linked';
      break;
    case organization?.stripeAccountLinked && stripeAccount?.payouts_enabled:
      titleId = 'stripe_support_linked_payouts_enabled';
      break;
    default:
      titleId = 'stripe_support';
  }

  return (
    <Loader isLoading={loading}>
      <SectionContainer>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item lg={1} xs={12} display="flex" justifyContent="center">
            <RoundCircleBackground>
              <CenteredIcon>
                <FontAwesomeIcon icon={faMoneyBillWave} size="3x" color={theme.palette.secondary.main} />
              </CenteredIcon>
            </RoundCircleBackground>
          </Grid>
          <Grid item lg={7.5} xs={12}>
            <StripeSection>
              <FormattedMessage id={titleId} />
              <StripeDescription>
                <FormattedMessage id={organization?.stripeAccountLinked ? 'stripe_support_description_linked' : 'stripe_support_description'} />
              </StripeDescription>
            </StripeSection>
          </Grid>
          <Grid item lg={3.5} xs={12} display="flex" alignItems="center" justifyContent="center">
            {!canReceivePayments ? (
              <AccountLinkButton
                organization={organization}
                canReceivePayments={canReceivePayments || false}
              />
            ) : (
              <StripeDashboardButton organization={organization} />
            )}
          </Grid>
        </Grid>
      </SectionContainer>
    </Loader>
  );
}

export default StripeAccountBanner;
