import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, inputBaseClasses } from '@mui/material';
import FormTextField from 'components/FormFields/FormTextField';
import styled from 'styled-components';

export const ShortUrlTextField = styled(FormTextField)`

& > .${inputBaseClasses.root} {
  padding-left: 0;
}
`;

export const ShortUrlAdornment = styled(InputAdornment)`
  background-color: ${({ theme }) => theme.palette.grey[200]};
  height: 2.5em;
  max-height: 2.5em;
  margin-right: 0.2em;
  padding: 0 0.5em;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
  border-right: 1px solid rgb(0 0 0 / 23%);
`;

export const ShortUrlAdornmentContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0 0.2em;
  color: ${({ theme }) => theme.palette.text.primary};
  font-size: 0.9rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.error.main};
  cursor: default;
`;

export const SuccessIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.success.main};
  cursor: default;
`;

export const DevWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  place-content: flex-start flex-start;
`;
