import { CardContent } from '@mui/material';
import styled from 'styled-components';

export const PayoutCardContainer = styled.div`
  background: #ececec;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const PayoutCardContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const Payouts = styled.div`
  text-align: right;
`;

export const PayoutCurrency = styled.div`
  font-weight: bold;
`;

export const PayoutCardTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  gap: 0.25rem;
`;

export const IconTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: help;
  }
`;
