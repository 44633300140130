import { faSearch, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { useIntl } from 'react-intl';

export interface SearchProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  className?: string;
}

function Search(props: SearchProps) {
  const { searchValue, setSearchValue, className } = props;
  const intl = useIntl();

  return (
    <TextField
      className={className}
      id="search"
      size="small"
      variant="outlined"
      value={searchValue}
      onChange={(e) => setSearchValue(e.target.value)}
      placeholder={intl.formatMessage({ id: 'search' })}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <FontAwesomeIcon icon={faSearch} />
          </InputAdornment>
        ),
        endAdornment: (
          searchValue && (
          <InputAdornment position="end">
            <IconButton
              onClick={() => setSearchValue('')}
              edge="end"
            >
              <FontAwesomeIcon icon={faTimes} />
            </IconButton>
          </InputAdornment>
          )
        ),
      }}
    />
  );
}

export default Search;
