import GenericTabs from 'components/GenericTabs/GenericTabs';
import { CustomTab, TabLabel } from 'components/GenericTabs/styles';
import { Languages } from 'lib/i18n/i18n';
import React, { SyntheticEvent, useMemo } from 'react';
import { FieldErrors, FieldValues, FormState } from 'react-hook-form';
import { useIntl } from 'react-intl';

interface TranslationsTabsProps {
  errors: FieldErrors
  tabIndex: number
  formState: FormState<FieldValues>
  handleChange: (event: React.SyntheticEvent, newValue: number) => void
  languages: Languages[]
}

function TranslationsTabs(props: TranslationsTabsProps) {
  const intl = useIntl();

  const {
    errors,
    formState,
    tabIndex,
    handleChange,
    languages,
  } = props;

  const hasErrors: { [key in Languages]: boolean } = useMemo(() => {
    if (errors && Object.keys(errors).length > 0) {
      return (languages)?.reduce(
        (acc: { [key in Languages]: boolean }, language: Languages) => {
          const nameError = errors?.name as FieldErrors<FieldValues>;
          const descriptionError = errors?.description as FieldErrors<FieldValues>;
          const messageError = errors?.message as FieldErrors<FieldValues>;
          if (!!nameError?.[language]
            || !!descriptionError?.[language]
            || !!messageError?.[language]) {
            acc[language] = true;
            handleChange({} as SyntheticEvent, languages.indexOf(language));
          }
          return acc;
        },
        { [Languages.EN]: false, [Languages.FR]: false, [Languages.ES]: false },
      );
    }
    return { [Languages.EN]: false, [Languages.FR]: false, [Languages.ES]: false };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, formState, languages]);

  return (
    <GenericTabs value={tabIndex} onChange={handleChange}>
      {languages?.map((language, index) => {
        const hasError = hasErrors[language as Languages];
        return (
          <CustomTab
            key={language}
            $hasError={hasError}
            label={(
              <TabLabel $hasError={hasError}>
                {intl.formatMessage({ id: `language_${language}` })
                + (index === 0 ? intl.formatMessage({ id: 'language_default' }) : '')}
              </TabLabel>
            )}
          />
        );
      })}
    </GenericTabs>
  );
}

export default TranslationsTabs;
