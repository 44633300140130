import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Tooltip } from '@mui/material';
import { AgChartOptions } from 'ag-charts-community';
import { AgCharts } from 'ag-charts-react';
import { FormattedMessage } from 'react-intl';
import { SmallTitle } from 'styles/styles';

import { ReportCard, ReportCardContent, ReportCardTooltip } from './styles';

interface ChartCardProps {
  chartOptions: AgChartOptions;
  title: string;
  tooltip?: string;
}

function ChartCard(props: ChartCardProps) {
  const { chartOptions, title, tooltip } = props;

  return (
    <ReportCard>
      <Grid container item gap={1}>
        <ReportCardContent>
          <Grid container item gap={1}>
            <SmallTitle>
              <FormattedMessage id={title} />
            </SmallTitle>
            {tooltip && (
              <Tooltip arrow title={(<FormattedMessage id={tooltip} />)}>
                <ReportCardTooltip>
                  <FontAwesomeIcon icon={faInfoCircle} />
                </ReportCardTooltip>
              </Tooltip>
            )}
          </Grid>
          <AgCharts options={chartOptions} />
        </ReportCardContent>
      </Grid>
    </ReportCard>
  );
}

export default ChartCard;
