import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardActions, CardContent } from '@mui/material';
import styled from 'styled-components';

export const CauseOrganizationCardContainer = styled(Card).attrs({
  variant: 'outlined',
})`
  width: 550px;
  padding: 0.5rem;
  border-radius: 2rem;
`;

export const CauseOrganizationCardActions = styled(CardActions)`
  justify-content: flex-end;
`;

export const OrganizationLogo = styled.img.attrs({
  alt: '',
})`
  width: 110px;
  height: 100%;
  object-fit: contain;
`;

export const OrganizationName = styled.div`
  margin: 0;
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
`;

export const OrganizationDescription = styled.div`
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  font-size: 0.8rem;
`;

export const CauseIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const ObnlTitle = styled.div`
  font-weight: bold;
`;

export const ObnlDescription = styled.div`
  font-size: 0.8rem;
`;

export const CauseOptionCardContainer = styled(Card)`
  width: 550px;
`;

export const CauseOptionCardContent = styled(CardContent)`
  height: 265px;
`;

export const Label = styled.span`
  flex: 1;
`;
