import { ApolloError } from '@apollo/client';
import { RoleEnum } from 'lib/graphql/graphql';
import { createContext } from 'react';

export interface IAuthenticatorContext {
  loggedIn: boolean
  confirmAccount: () => Promise<void>
  confirmedAccount: boolean
  login: (username: string, password: string, invitationToken?: string) => Promise<void>
  logout: () => Promise<void>
  error?: ApolloError
  firstBoot: boolean
  setFirstBoot: (firstBoot: boolean) => void
  performingLogin: boolean
  userId: string
  role: RoleEnum | null;
  loginToOrganization: (invitationId: string) => Promise<boolean>
}

export const DefaultAuthenticatorContext: IAuthenticatorContext = {
  loggedIn: false,
  confirmAccount: async () => {},
  confirmedAccount: false,
  login: async () => {},
  logout: async () => {},
  performingLogin: false,
  userId: '',
  firstBoot: true,
  setFirstBoot: () => {},
  role: null,
  loginToOrganization: async () => false,
};

const AuthenticatorContext = createContext(DefaultAuthenticatorContext);
export default AuthenticatorContext;
