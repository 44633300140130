import { ButtonGroup } from '@mui/material';
import styled from 'styled-components';
import { ContainedButton } from 'styles/styles';

export const RoundedButtonGroup = styled(ButtonGroup)`
  overflow: hidden;
  border-radius: 0.5em;
`;

export const GroupedButton = styled(ContainedButton).attrs(() => ({
  whileTap: undefined,
}))`
  border-radius: 0;
  text-transform: none;
`;
