import { faChevronLeft, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import waitingLineImage from 'assets/images/waitingLine/icon_dashboard_waiting_line.svg';
import CreateWindow from 'components/CreateWindow/CreateWindow';
import { CreateWindowGrid } from 'components/CreateWindow/styles';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import { EventStatus } from 'lib/graphql/graphql';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import { FormattedMessage } from 'react-intl';
import {
  ContainedButton,
  NeutralOutlinedButton,
  Separator,
  StickySteps,
  StickyStepsContent,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import WaitingLinesList from './WaitingLinesList';

function EventWaitingLines() {
  useEventDocumentTitle('waiting_lines');
  const event = useEvent();
  const [, setLocation] = useLocation();

  return (
    <PageLayout>
      <Grid container direction="column" height="100%">
        <Grid container direction="column" flexGrow={1}>
          <Title>
            <FormattedMessage id="waiting_lines" />
          </Title>
          <CreateWindowGrid>
            <Grid container wrap="nowrap" gap={1}>
              <EnsureRole requiredRole="ADMIN">
                <CreateWindow
                  image={waitingLineImage}
                  newTitle="waiting_line_new"
                  addTitle="waiting_line_new_add"
                  createTitle="create_waiting_line"
                  location="/waitingLines/create"
                />
              </EnsureRole>
            </Grid>
          </CreateWindowGrid>
          <WaitingLinesList />
        </Grid>
        {event.status === EventStatus.Draft && (
          <EnsureRole requiredRole="ADMIN">
            <StickySteps>
              <Separator />
              <StickyStepsContent>
                <NeutralOutlinedButton
                  onClick={() => setLocation('/edit')}
                  startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                >
                  <FormattedMessage id="previous_step" />
                </NeutralOutlinedButton>
                <ContainedButton
                  color="secondary"
                  onClick={() => setLocation('/causes')}
                  endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                >
                  <FormattedMessage id="next_step" />
                </ContainedButton>
              </StickyStepsContent>
            </StickySteps>
          </EnsureRole>
        )}
      </Grid>
    </PageLayout>
  );
}

export default EventWaitingLines;
