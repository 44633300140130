import { useQueryClient } from '@tanstack/react-query';
import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { useCreateWaitingLineMutation, WaitingLineInput, WaitingLineType } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import WaitingLineForm from './WaitingLineForm';

function CreateWaitingLine() {
  useEventDocumentTitle('create_waiting_line');
  const intl = useIntl();
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const event = useEvent();

  const methods = useCustomForm<WaitingLineInput>({
    defaultValues: {
      currency: 'CAD',
      price: 2,
      quantity: 100,
      isFreemium: false,
      callSize: 5,
      limitedQuantity: false,
      maxGroupSize: 1,
      waitingLineType: WaitingLineType.Gold,
    },
    mode: 'onChange',
    trim: true,
  });

  const { setError } = methods;

  const [createWaitingLine] = useCreateWaitingLineMutation();
  const queryClient = useQueryClient();

  const onSubmit: SubmitHandler<WaitingLineInput> = async (formData) => {
    try {
      await createWaitingLine({
        variables: {
          eventId: event.id,
          fields: {
            ...formData,
            limitedQuantity: formData.limitedQuantity && !formData.isFreemium,
            quantity: (formData.limitedQuantity && !formData.isFreemium) ? formData.quantity : null,
          },
        },
      });

      queryClient.removeQueries({ queryKey: ['waitingLines'], exact: true });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="create_waiting_line_success" />),
        severity: 'success',
      });

      setLocation('/waitingLines');
    } catch (error) {
      const errorMessage = (error as Error).message;

      const regex = /The (.+?) field must have a value for the languages: (.+)/;
      const match = errorMessage.match(regex);

      if (match) {
        const field = match[1];
        const languages = match[2].split(',').map((lang) => lang.trim()) as Languages[];
        const languagesWithErrors: string[] = [];

        languages.forEach((language: Languages) => {
          const translatedLanguage = intl.formatMessage({ id: `language_${language}` });

          setError(`${field}.${language}` as keyof WaitingLineInput, { message: 'field_required' });

          languagesWithErrors.push(translatedLanguage);
        });

        const concatenatedLanguages = languagesWithErrors.join(', ');

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_error_missing_translation" values={{ languages: concatenatedLanguages, count: languagesWithErrors.length }} />),
          severity: 'error',
        });
      } else {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="create_waiting_line_error" />),
          severity: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <WaitingLineForm
        title="create"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}

export default CreateWaitingLine;
