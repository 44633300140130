import StripeLandingPage from 'components/Stripe/StripeLandingPage';
import ProtectedRoute from 'lib/access/ProtectedRoute';
import { useHasOrganizationsQuery } from 'lib/graphql/graphql';
import Dashboard from 'modules/Dashboard/Dashboard';
import EventCauses from 'modules/Events/EventCauses/EventCauses';
import EventCausesSelection from 'modules/Events/EventCauses/EventCausesSelection';
import EventFinancialReport from 'modules/Events/EventFinancialReport/EventFinancialReport';
import CreateEvent from 'modules/Events/EventForm/CreateEvent';
import UpdateEvent from 'modules/Events/EventForm/UpdateEvent';
import EventOperator from 'modules/Events/EventOperator/EventOperator';
import EventPublish from 'modules/Events/EventPublish/EventPublish';
import SideViewManager from 'modules/Events/EventSideView/SideViewManager';
import EventUsers from 'modules/Events/EventUsers/EventUsers';
import InviteUser from 'modules/Events/EventUsers/InvitationForm/InvitationForm';
import EventWaitingLines from 'modules/Events/EventWaitingLines/EventWaitingLines';
import CreateWaitingLine from 'modules/Events/EventWaitingLines/WaitingLineForm/CreateWaitingLine';
import UpdateWaitingLine from 'modules/Events/EventWaitingLines/WaitingLineForm/UpdateWaitingLine';
import UpdateOrganization from 'modules/Organizations/OrganizationForm/UpdateOrganization';
import OrganizationSelector from 'modules/OrganizationSelector/OrganizationSelector';
import EventPayouts from 'modules/Payouts/Payouts';
import Profile from 'modules/Profile/Profile';
import EventProvider from 'providers/event/EventProvider';
import useOrganization from 'providers/organization/useOrganization';
import WaitingLineProvider from 'providers/waitingLine/WaitingLineProvider';
import RouteNotFound from 'routes/RouteNotFound';
import { Route, Switch } from 'wouter';

export default function OrganizationSwitch() {
  const { organizationId } = useOrganization();

  const { data } = useHasOrganizationsQuery();
  const hasOrganizations = Boolean(data?.hasOrganizations);

  return (
    <Switch>
      {hasOrganizations && (
        <Route path="/profile" component={Profile} />
      )}
      <ProtectedRoute requiredRole="ADMIN" path="/organization/:organizationId/edit" component={UpdateOrganization} />
      <Route path="/organization/:organizationId/linked" component={StripeLandingPage} />
      {organizationId ? (
        <>
          <ProtectedRoute requiredRole="STAFF" path="/" component={Dashboard} />
          <ProtectedRoute requiredRole="ADMIN" path="/events/create" component={CreateEvent} />
          <ProtectedRoute requiredRole="ADMIN" path="/payouts" component={EventPayouts} />
          <ProtectedRoute requiredRole="STAFF" path="/event/:eventId" nest>
            <EventProvider>
              <SideViewManager>
                <Switch>
                  <ProtectedRoute requiredRole="STAFF" path="/edit" component={UpdateEvent} />
                  <ProtectedRoute requiredRole="STAFF" path="/waitingLines" component={EventWaitingLines} />
                  <ProtectedRoute requiredRole="ADMIN" path="/waitingLines/create" component={CreateWaitingLine} />
                  <ProtectedRoute requiredRole="STAFF" path="/waitingLine/:waitingLineId" nest>
                    <WaitingLineProvider>
                      <ProtectedRoute requiredRole="STAFF" path="/edit" component={UpdateWaitingLine} />
                    </WaitingLineProvider>
                  </ProtectedRoute>
                  <ProtectedRoute requiredRole="STAFF" path="/operations" component={EventOperator} />
                  <ProtectedRoute requiredRole="STAFF" path="/causes" component={EventCauses} />
                  <ProtectedRoute requiredRole="ADMIN" path="/causes/selection" component={EventCausesSelection} />
                  <ProtectedRoute requiredRole="ADMIN" path="/users" component={EventUsers} />
                  <ProtectedRoute requiredRole="ADMIN" path="/users/invite" component={InviteUser} />
                  <ProtectedRoute requiredRole="STAFF" path="/publish" component={EventPublish} />
                  <ProtectedRoute requiredRole="ADMIN" path="/financials" component={EventFinancialReport} />
                  <Route component={RouteNotFound} />
                </Switch>
              </SideViewManager>
            </EventProvider>
          </ProtectedRoute>
          <Route component={RouteNotFound} />
        </>
      ) : (
        <Route component={OrganizationSelector} />
      )}
    </Switch>
  );
}
