import { PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';

import { SectionGrid, SectionLabel } from './styles';

interface FormSectionProps {
  label?: string
}

function FormSection(props: PropsWithChildren<FormSectionProps>) {
  const { children, label } = props;

  return (
    <SectionGrid>
      {label && (
        <SectionLabel>
          <FormattedMessage id={label} />
        </SectionLabel>
      )}
      {children}
    </SectionGrid>
  );
}

export default FormSection;
