import {
  ChangeHandler,
  FieldValues,
  useForm,
  UseFormProps,
  UseFormRegister,
  UseFormReturn,
} from 'react-hook-form';

export interface UseCustomFormProps<TFieldValues extends FieldValues, TContext>
  extends UseFormProps<TFieldValues, TContext> {
  trim?: boolean;
}

export function useCustomForm<T extends FieldValues = FieldValues, U = any>(props?:
UseCustomFormProps<T, U>): UseFormReturn<T, U> {
  const methods = useForm<T>(props);

  const customRegister : UseFormRegister<T> = (name, options) => {
    const field = methods.register(name, options);

    const customOnChange: ChangeHandler = async (event) => {
      const { target } = event;

      if (props?.trim && typeof target.value === 'string' && target.value) {
        target.value = target.value.trim();
      }

      field.onChange(event);
    };

    return { ...field, onChange: customOnChange };
  };

  return {
    ...methods,
    control: { ...methods.control, register: customRegister },
    register: customRegister,
  };
}
