import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import useFeedback from 'components/Feedback/useFeedback';
import { useLinkInvitationToUserMutation } from 'lib/graphql/graphql';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import OrganizationContext from '../organization/OrganizationContext';
import InviteTokenContext from './InviteTokenContext';

export interface InviteTokenProviderProps {
  children: JSX.Element
}

interface DecodedToken {
  organizationId: string
  email: string
  token: string
}

function InviteTokenProvider(props: InviteTokenProviderProps) {
  const { children } = props;
  const { loggedIn, userId } = useContext(AuthenticatorContext);
  const { setOrganizationId, setInvitationId } = useContext(OrganizationContext);
  const [linkInvitationToUser] = useLinkInvitationToUserMutation();
  const [, setLocation] = useLocation();

  const [inviteToken, setInviteToken] = useState<string | undefined>(undefined);
  const [userEmail, setUserEmail] = useState<string | undefined>(undefined);
  const [urlParams, setUrlParams] = useState<any>({});
  const feedback = useFeedback();

  useEffect(() => {
    const tokenId = urlParams.invitation;
    if (tokenId) {
      setInviteToken(tokenId);
    }
  }, [urlParams.invitation]);

  useEffect(() => {
    const querySearch = window.location.search;
    const queryParameters = new URLSearchParams(querySearch);

    setUrlParams(
      Array.from(queryParameters.entries()).reduce((acc, entry) => {
        const [key, value] = entry;
        return {
          ...acc,
          [key]: value,
        };
      }, {}),
    );
  }, []);

  useEffect(() => {
    if (inviteToken) {
      try {
        const decodedInviteToken: DecodedToken = JSON.parse(atob(inviteToken.split('.')[1]));
        setUserEmail(decodedInviteToken.email);
      } catch (error) {
        console.error('Error decoding invite token:', error);
      }
    }
  }, [inviteToken]);

  useEffect(() => {
    const linkInvitation = async () => {
      if (loggedIn && userId && inviteToken) {
        const decodedInviteToken: DecodedToken = JSON.parse(atob(inviteToken.split('.')[1]));
        try {
          const result = await linkInvitationToUser({
            variables: {
              token: decodedInviteToken.token,
            },
          });
          setOrganizationId(result.data?.linkInvitationToUser?.organizationId || null);
          setInvitationId(result.data?.linkInvitationToUser?.invitationId || null);
          setUserEmail(decodedInviteToken.email);

          if (result.data?.linkInvitationToUser) {
            if (result.data?.linkInvitationToUser?.isPublished) {
              setLocation(`/event/${result.data?.linkInvitationToUser.eventId}/operations`);
            } else {
              setLocation(`/event/${result.data?.linkInvitationToUser.eventId}/edit`);
            }
            setInviteToken(undefined);

            feedback({
              anchor: { horizontal: 'right', vertical: 'bottom' },
              content: (<FormattedMessage id="accept_invitation_success" />),
              severity: 'success',
            });
          }
        } catch (error) {
          console.error('Error linking invitation to user:', error);
        }
      }
    };

    linkInvitation();
  }, [
    feedback,
    inviteToken,
    linkInvitationToUser,
    loggedIn,
    setInvitationId,
    setLocation,
    setOrganizationId,
    userId,
  ]);

  const contextValue = useMemo(() => ({
    inviteToken,
    setInviteToken,
    userEmail,
  }), [inviteToken, userEmail]);

  return (
    <InviteTokenContext.Provider value={contextValue}>
      {children}
    </InviteTokenContext.Provider>
  );
}

export default InviteTokenProvider;
