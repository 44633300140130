import { useIntl } from 'react-intl';

import useDocumentTitle from './useDocumentTitle';

function useFormattedDocumentTitle(id: string) {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id }));
}

export default useFormattedDocumentTitle;
