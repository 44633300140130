import { useMemo } from 'react';
import { FieldError, useFormContext } from 'react-hook-form';

const getNestedError = (errors: any, path: string) => path.split('.').reduce((acc, part) => acc && acc[part], errors);

function useFieldFormError(name: string) {
  const { formState } = useFormContext();

  const fieldError = useMemo(
    () => getNestedError(formState.errors, name) as FieldError,
    [formState, name],
  );

  return fieldError;
}

export default useFieldFormError;
