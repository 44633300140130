import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, useTheme } from '@mui/material';
import { useGetOrganizationQuery } from 'lib/graphql/graphql';
import { FormattedMessage } from 'react-intl';
import { Centered, Title } from 'styles/styles';
import { useParams } from 'wouter';

import PageLayout from '../PageLayout';
import { OrganizationName, QuitPage } from './styles';

function StripeLandingPage() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const theme = useTheme();

  const { data } = useGetOrganizationQuery({
    variables: {
      id: organizationId!,
    },
  });

  const organization = data?.getOrganization;

  return (
    <PageLayout>
      <Centered>
        <Grid container justifyContent="center" textAlign="center" rowGap="16px">
          <Grid item xs={12}>
            <FontAwesomeIcon icon={faCircleCheck} size="4x" color={theme.palette.success.main} />
          </Grid>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="stripe_congratulations" />
            </Title>
          </Grid>
          <Grid item xs={12}>
            <FormattedMessage id="stripe_landing_linked" values={{ organization: <OrganizationName>{organization?.name}</OrganizationName> }} />
          </Grid>
          <Grid item xs={12}>
            <QuitPage>
              <FormattedMessage id="stripe_close_window" />
            </QuitPage>
          </Grid>
        </Grid>
      </Centered>
    </PageLayout>
  );
}

export default StripeLandingPage;
