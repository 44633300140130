import { Card } from '@mui/material';
import styled from 'styled-components';
import { AppTypeBackground } from 'styles/AppThemeProvider';

export const StatementContainer = styled(Card)`
  font-size: 14px;
  padding: 10px;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const FakeText = styled.div<{ $width: number }>`
  height: 10px;
  width: ${(props) => props.$width}px;
  background: ${({ theme }) => {
    // TODO: Fix theme one day
    const background = theme.palette.background as AppTypeBackground;
    return background.darker;
  }};
`;

export const StatementDescriptor = styled.div`
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: uppercase;
`;

export const StatementDescriptorPrice = styled.div`
  color: ${({ theme }) => theme.palette.text.primary};
`;
