import { Tooltip } from '@mui/material';

import { Title } from './styles';

interface ListCardTitleProps {
  title: string
}

function ListCardTitle(props: ListCardTitleProps) {
  const { title } = props;

  return (
    <Title>
      <Tooltip title={title} placement="top" arrow>
        <span>
          {title}
        </span>
      </Tooltip>
    </Title>
  );
}

export default ListCardTitle;
