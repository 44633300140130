import {
  Grid,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import { WaitingLine } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { DateTime } from 'luxon';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { RoundedSelect } from 'styles/styles';

interface BalanceTransactionsPickerProps {
  balanceTransactions: BalanceTransactionSplits[];
  waitingLines: WaitingLine[];
  defaultLocale: Languages;
  onTransactionsChange: (transactions: BalanceTransactionSplits[]) => void;
}

function BalanceTransactionsPicker(props: BalanceTransactionsPickerProps) {
  const {
    balanceTransactions,
    waitingLines,
    defaultLocale,
    onTransactionsChange,
  } = props;

  const intl = useIntl();
  const { locale } = intl as { locale: Languages };

  const [selectedTimeRange, setSelectedTimeRange] = useState('all');
  const [selectedWaitingLine, setSelectedWaitingLine] = useState('all_waiting_lines');

  const handleTimeRangeChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedTimeRange(e.target.value as string);
  };

  const handleWaitingLineChange = (e: SelectChangeEvent<unknown>) => {
    setSelectedWaitingLine(e.target.value as string);
  };

  const filteredTransactions = useMemo(() => {
    let filtered = balanceTransactions;

    if (selectedTimeRange !== 'all') {
      const now = DateTime.now();
      filtered = filtered.filter((transaction) => {
        const transactionDate = DateTime.fromSeconds(transaction.created);
        switch (selectedTimeRange) {
          case 'today':
            return transactionDate.hasSame(now, 'day');
          case 'yesterday':
            return transactionDate.hasSame(now.minus({ days: 1 }), 'day');
          case 'last_week':
            return transactionDate >= now.minus({ weeks: 1 });
          case 'last_month':
            return transactionDate >= now.minus({ months: 1 });
          default:
            return true;
        }
      });
    }

    if (selectedWaitingLine !== 'all_waiting_lines') {
      filtered = filtered.filter(
        (transaction) => transaction.metadata.waitingLineId === selectedWaitingLine,
      );
    }

    return filtered;
  }, [balanceTransactions, selectedTimeRange, selectedWaitingLine]);

  useEffect(() => {
    onTransactionsChange(filteredTransactions);
  }, [filteredTransactions, onTransactionsChange]);

  return (
    <Grid container direction="row" gap={2} alignItems="center" mb={1}>
      <Grid item>
        <RoundedSelect value={selectedTimeRange} onChange={handleTimeRangeChange}>
          <MenuItem value="all">
            <FormattedMessage id="filter_all" />
          </MenuItem>
          <MenuItem value="today">
            <FormattedMessage id="filter_today" />
          </MenuItem>
          <MenuItem value="yesterday">
            <FormattedMessage id="filter_yesterday" />
          </MenuItem>
          <MenuItem value="last_week">
            <FormattedMessage id="filter_last_week" />
          </MenuItem>
          <MenuItem value="last_month">
            <FormattedMessage id="filter_last_month" />
          </MenuItem>
        </RoundedSelect>
      </Grid>
      <Grid item>
        <RoundedSelect value={selectedWaitingLine} onChange={handleWaitingLineChange}>
          <MenuItem value="all_waiting_lines">
            <FormattedMessage id="filter_all_waiting_lines" />
          </MenuItem>
          {waitingLines.map((line) => (
            <MenuItem key={line.id} value={line.id}>
              {line.name[locale] || line.name[defaultLocale]}
            </MenuItem>
          ))}
        </RoundedSelect>
      </Grid>
    </Grid>
  );
}

export default BalanceTransactionsPicker;
