/**
 * Determine if text should be white or black.
 *
 * @param {string | undefined} bkgColor hexadecimal color string
 * @return {string} white or black
 */
function getTextColor(bkgColor: string | undefined): string {
  if (!bkgColor) return 'white';

  // Expand shorthand hex color to full form
  const hex = bkgColor.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => r + r + g + g + b + b);
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) return 'white';

  const [r, g, b] = result.slice(1).map((value) => parseInt(value, 16));

  // Calculate luminance and determine text color
  const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
  return luminance > 186 ? 'black' : 'white';
}

export default getTextColor;
