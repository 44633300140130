import { createContext } from 'react';

export interface IInviteTokenContext {
  inviteToken: string | undefined
  setInviteToken: (newToken: string | undefined) => void
  userEmail: string | undefined
}

export const DefaultInviteTokenContext: IInviteTokenContext = {
  inviteToken: undefined,
  setInviteToken: () => {},
  userEmail: undefined,
};

const InviteTokenContext = createContext(DefaultInviteTokenContext);

export default InviteTokenContext;
