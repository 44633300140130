import { SwitchProps } from '@mui/material';
import { useCallback } from 'react';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import FormField, { FormFieldProps } from '../FormField';
import { GroupedButton, RoundedButtonGroup } from './styles';

type CustomSwitchProps = Omit<SwitchProps, 'onChange' | 'onBlur' | 'inputRef' | 'checked' | 'required' | 'name' | 'inputProps' | 'type' | 'defaultChecked'>;

interface FormToggleFieldProps extends FormFieldProps, CustomSwitchProps {
  leftLabel: string
  rightLabel: string
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'min' | 'max'>
  sideBySide?: boolean
}

function FormToggleFields(props: FormToggleFieldProps) {
  const {
    labelId,
    leftLabel,
    name,
    rules,
    sideBySide = false,
    fieldDescription,
    helperTextId,
    rightLabel,
    required,
  } = props;

  const { field } = useController({ name, rules });
  const { onChange, value, ...otherProps } = field;

  const changeValue = useCallback((newValue: boolean) => {
    onChange({ target: { name, value: newValue } });
  }, [name, onChange]);

  return (
    <FormField
      name={name}
      labelId={labelId}
      sideBySide={sideBySide}
      required={required}
      helperTextId={helperTextId}
      fieldDescription={fieldDescription}
    >
      <RoundedButtonGroup {...otherProps}>
        <GroupedButton
          color={value ? 'inherit' : 'secondary'}
          onClick={() => changeValue(false)}
        >
          <FormattedMessage id={leftLabel} />
        </GroupedButton>
        <GroupedButton
          color={!value ? 'inherit' : 'secondary'}
          onClick={() => changeValue(true)}
        >
          <FormattedMessage id={rightLabel} />
        </GroupedButton>
      </RoundedButtonGroup>
    </FormField>
  );
}

export default FormToggleFields;
