import Loader from 'components/Loading/Loader';
import { useGetInvitationByIdLazyQuery } from 'lib/graphql/graphql';
import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import AuthenticatorContext from '../../components/Authenticator/AuthenticatorContext';
import OrganizationContext, { IOrganizationContext } from './OrganizationContext';

export interface OrganizationProviderProps {
  children: JSX.Element
}

export default function OrganizationProvider(props: OrganizationProviderProps) {
  const { children } = props;
  const { loginToOrganization, role, userId } = useContext(AuthenticatorContext);

  const defaultInvitation = useMemo(() => localStorage.getItem('invitationId'), []);
  const [invitationId, setInvitationId] = useState<string | null>(defaultInvitation);
  const [organizationId, setOrganizationId] = useState<string | null>(null);

  const [getInvitation] = useGetInvitationByIdLazyQuery();

  const { loggedIn } = useContext(AuthenticatorContext);

  useEffect(() => {
    const logOrganizationIn = async () => {
      if (invitationId && loggedIn) {
        const success = await loginToOrganization(invitationId);
        if (success) {
          const invitation = await getInvitation({
            variables: {
              id: invitationId,
            },
          });
          const orgId = invitation.data?.getInvitationById?.organizationId;
          setOrganizationId(orgId!);
          localStorage.setItem('invitationId', invitationId);
        } else {
          setInvitationId(null);
          setOrganizationId(null);
        }
      }
    };

    logOrganizationIn();
  }, [organizationId, loginToOrganization, loggedIn, invitationId, getInvitation, userId]);

  useEffect(() => {
    if (invitationId === null) {
      setOrganizationId(null);
      localStorage.removeItem('invitationId');
    }
  }, [invitationId]);

  const contextValue = useMemo<IOrganizationContext>(() => ({
    organizationId,
    setOrganizationId,
    invitationId,
    setInvitationId,
  }), [invitationId, organizationId]);

  return (
    <Loader isLoading={Boolean(organizationId) && role === null} isFullPage>
      <OrganizationContext.Provider value={contextValue}>
        {children}
      </OrganizationContext.Provider>
    </Loader>
  );
}
