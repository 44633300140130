import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { useUpdateWaitingLineMutation, WaitingLineUpdateInput } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import useWaitingLine from 'providers/waitingLine/useWaitingLine';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import WaitingLineForm from './WaitingLineForm';

function UpdateWaitingLine() {
  useEventDocumentTitle('update_waiting_line');
  const intl = useIntl();
  const feedback = useFeedback();
  const [updateWaitingLine] = useUpdateWaitingLineMutation();
  const event = useEvent();
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    __typename,
    waitingLineType,
    status,
    ...waitingLineData
  } = useWaitingLine();
  const [, setLocation] = useLocation();

  const methods = useCustomForm<WaitingLineUpdateInput>({
    defaultValues: {
      ...waitingLineData,
      currency: waitingLineData.currency.toUpperCase(),
      quantity: waitingLineData.quantity || 100,
      waitingLineType,
    },
    trim: true,
  });

  const { setError } = methods;

  const onSubmit: SubmitHandler<WaitingLineUpdateInput> = async (formData: any) => {
    const {
      id,
      usedTickets,
      name,
      description,
      productDescription,
      ...fields
    } = formData;
    // Remove __typename from the objects
    const { __typename: nameTypename, ...names } = name;
    const { __typename: eventTypename, ...descriptions } = description;
    const { __typename: waitingLineTypename, ...productDescriptions } = productDescription;

    try {
      await updateWaitingLine({
        variables: {
          eventId: event.id,
          id,
          fields: {
            ...fields,
            name: names,
            quantity: fields.limitedQuantity ? fields.quantity : null,
            description: descriptions,
            productDescription: productDescriptions,
          },
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="update_waiting_line_success" />),
        severity: 'success',
      });

      setLocation(`~/event/${event.id}/waitingLines`);
    } catch (error) {
      const errorMessage = (error as Error).message;

      const regex = /The (.+?) field must have a value for the languages: (.+)/;
      const match = errorMessage.match(regex);

      if (match) {
        const field = match[1];
        const languages = match[2].split(',').map((lang) => lang.trim()) as Languages[];
        const languagesWithErrors: string[] = [];

        languages.forEach((language: Languages) => {
          const translatedLanguage = intl.formatMessage({ id: `language_${language}` });

          setError(`${field}.${language}` as keyof WaitingLineUpdateInput, { message: 'field_required' });

          languagesWithErrors.push(translatedLanguage);
        });

        const concatenatedLanguages = languagesWithErrors.join(', ');

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_error_missing_translation" values={{ languages: concatenatedLanguages, count: languagesWithErrors.length }} />),
          severity: 'error',
        });
      } else {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_waiting_line_error" />),
          severity: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <WaitingLineForm
        title="update"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}

export default UpdateWaitingLine;
