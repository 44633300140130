import { ApolloError, useApolloClient } from '@apollo/client';
import { useQueryClient } from '@tanstack/react-query';
import Loader from 'components/Loading/Loader';
import { AuthenticatedApolloClient } from 'lib/graphql/apolloClient';
import {
  LoginMutation,
  RefreshAuthMutation,
  RoleEnum,
  useLoginMutation,
  useLoginOrganizationMutation,
  useLogoutMutation,
  useRefreshAuthMutation,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import AuthenticatorContext, { IAuthenticatorContext } from './AuthenticatorContext';

const REFRESH_INTERVAL = 14.5 * 60 * 1000;

interface DecodedToken {
  events: Record<string, string>
  exp: number
  iat: number
  organization: string
  type: any
  userId: string
}

function resetStorage() {
  const savedLanguage = localStorage.getItem('language') as Languages;
  const savedFirstBoot = localStorage.getItem('firstBoot');
  localStorage.clear();
  localStorage.setItem('language', savedLanguage);
  localStorage.setItem('firstBoot', savedFirstBoot as string);
}

export interface AuthenticatorProps {
  children: JSX.Element
}

type Payload = RefreshAuthMutation['refreshAuth'] | LoginMutation['login'];
type PayloadWithoutToken = Omit<Payload, 'authToken'>;

function Authenticator(props: AuthenticatorProps) {
  const { children } = props;

  const refreshHandle = useRef<number>();
  const apolloClient = useApolloClient() as AuthenticatedApolloClient;
  const queryClient = useQueryClient();

  const [login] = useLoginMutation();
  const [logout] = useLogoutMutation();
  const [refresh] = useRefreshAuthMutation();
  const [loginOrganization] = useLoginOrganizationMutation();

  const [loggedIn, setLoggedIn] = useState(false);
  const [firstBoot, setFirstBootState] = useState<boolean>(() => {
    const storedFirstBoot = localStorage.getItem('firstBoot');
    return storedFirstBoot !== null ? storedFirstBoot === 'true' : true;
  });
  const setFirstBoot = (value: boolean) => {
    setFirstBootState(value);
    localStorage.setItem('firstBoot', value.toString());
  };
  const [error, setError] = useState<ApolloError>();
  const [performingLogin, setPerformingLogin] = useState(true);
  const [role, setRole] = useState<RoleEnum | null>(null);
  const [authPayload, setAuthPayload] = useState<PayloadWithoutToken>();

  const [userId, setUserId] = useState<string>('');
  const [confirmedAccount, setConfirmedAccount] = useState(false);

  const reset = useCallback(() => {
    queryClient.clear();
    apolloClient.clearStore();
    resetStorage();
  }, [apolloClient, queryClient]);

  const refreshAuth = useCallback(async () => {
    try {
      const invitationId = localStorage.getItem('invitationId');
      const payload = await refresh({
        variables: {
          invitationId,
        },
      });
      return payload;
    } catch (err) {
      console.error(err);
      return undefined;
    }
  }, [refresh]);

  const stopRefreshLoop = useCallback(() => {
    clearInterval(refreshHandle.current);
  }, []);

  const setAuthToken = useCallback((authToken: string | undefined) => {
    apolloClient.setToken(authToken);
  }, [apolloClient]);

  const handleAuthPayload = useCallback((payload: Payload) => {
    const { authToken, ...payloadData } = payload;
    setAuthToken(authToken);
    setAuthPayload(payloadData);
  }, [setAuthToken]);

  const startRefreshLoop = useCallback(() => {
    refreshHandle.current = window.setInterval(async () => {
      const payload = await refreshAuth();
      if (payload?.data) {
        handleAuthPayload(payload.data.refreshAuth);
      } else {
        stopRefreshLoop();
        setLoggedIn(false);
      }
    }, REFRESH_INTERVAL);
  }, [handleAuthPayload, refreshAuth, stopRefreshLoop]);

  const performLogin = useCallback(async (
    username: string,
    password: string,
    invitationToken?: string,
  ) => {
    setPerformingLogin(true);
    setError(undefined);
    try {
      const payload = await login({ variables: { username, password, invitationToken } });
      if (payload.data) {
        startRefreshLoop();
        handleAuthPayload(payload.data.login);
        setLoggedIn(true);
        setUserId(payload.data.login.user.id);
        setConfirmedAccount(payload.data.login.user.confirmedAccount);
      }
    } catch (err) {
      setError(err as ApolloError);
      throw err;
    } finally {
      setPerformingLogin(false);
      setFirstBoot(true);
    }
  }, [handleAuthPayload, login, startRefreshLoop]);

  const performLogout = useCallback(async () => {
    await logout();
    reset();

    stopRefreshLoop();
    setLoggedIn(false);
    setAuthToken(undefined);
    setFirstBoot(true);
  }, [logout, reset, setAuthToken, stopRefreshLoop]);

  useEffect(() => {
    async function initialRefresh() {
      const payload = await refreshAuth();
      if (payload?.data) {
        startRefreshLoop();
        handleAuthPayload(payload.data.refreshAuth);
        setLoggedIn(true);
        setUserId(payload.data.refreshAuth.user.id);
        setConfirmedAccount(payload.data.refreshAuth.user.confirmedAccount);
      }
      setPerformingLogin(false);
    }

    initialRefresh();
  }, [handleAuthPayload, refreshAuth, startRefreshLoop]);

  const confirmAccount = useCallback(async () => {
    const payload = await refreshAuth();
    if (payload?.data) {
      setConfirmedAccount(payload.data.refreshAuth.user.confirmedAccount);
    }
  }, [refreshAuth]);

  const loginToOrganization = useCallback(async (invitationId: string) => {
    if (invitationId !== localStorage.getItem('invitationId')) {
      reset();
    }
    setRole(null);
    try {
      const result = await loginOrganization({
        variables: {
          invitationId,
        },
      });
      if (result.data?.loginOrganization) {
        setAuthToken(result.data?.loginOrganization.authToken);
        const decoded: DecodedToken = JSON.parse(atob(result.data?.loginOrganization.authToken.split('.')[1]));
        const { events } = decoded;
        if (events) {
          if (events.all) {
            setRole(RoleEnum.Admin);
          } else {
            const fullEvents = Object.entries(events).map(([eventId, tempRole]) => ({
              eventId,
              role: tempRole as RoleEnum,
            }));
            setRole(fullEvents[0].role);
          }
          return true;
        }
      }
    } catch (err) {
      console.error(err);
    }
    return false;
  }, [loginOrganization, reset, setAuthToken]);

  const authState = useMemo<IAuthenticatorContext>(() => ({
    authPayload,
    confirmAccount,
    confirmedAccount,
    error,
    firstBoot,
    loggedIn,
    login: performLogin,
    logout: performLogout,
    performingLogin,
    setFirstBoot,
    userId,
    role,
    loginToOrganization,
  }), [
    authPayload,
    confirmAccount,
    confirmedAccount,
    error,
    firstBoot,
    loggedIn,
    performingLogin,
    performLogin,
    performLogout,
    userId,
    role,
    loginToOrganization,
  ]);

  return (
    <Loader isLoading={performingLogin} isFullPage>
      <AuthenticatorContext.Provider value={authState}>
        {children}
      </AuthenticatorContext.Provider>
    </Loader>
  );
}

export default Authenticator;
