import { Grid } from '@mui/material';
import EmailIcon from 'assets/images/auth/icon_email.svg';
import { FormattedMessage } from 'react-intl';
import {
  RoundCircleBackground,
  RoundCircleImage,
  TextButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import AuthForm from './AuthForm';
import { ActionButton } from './styles';

interface ConfirmForgotPasswordProps {
  onClick: () => void;
}

function ConfirmForgotPassword(props: ConfirmForgotPasswordProps) {
  const { onClick } = props;
  const [, setLocation] = useLocation();

  return (
    <AuthForm>
      <Grid container alignContent="center" textAlign="center">
        <Grid container item m="32px" gap="16px">
          <Grid item xs={12}>
            <RoundCircleBackground>
              <RoundCircleImage src={EmailIcon} />
            </RoundCircleBackground>
          </Grid>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="check_your_email" />
            </Title>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" marginBottom="16px">
          <Grid item xs={6}>
            <FormattedMessage id="sent_password_recover_instructions" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormattedMessage id="did_not_get_it" />
          {' '}
          <TextButton color="secondary" onClick={onClick}>
            <FormattedMessage id="try_again" />
          </TextButton>
        </Grid>
        <Grid item xs={12} paddingTop="5rem">
          <ActionButton
            type="submit"
            color="secondary"
            onClick={() => setLocation('~/')}
          >
            <FormattedMessage id="return_to_login" />
          </ActionButton>
        </Grid>
      </Grid>
    </AuthForm>
  );
}

export default ConfirmForgotPassword;
