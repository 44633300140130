import { PropsWithChildren } from 'react';

import useValidateRole, { Roles } from './useValidateRole';

interface ValidateRoleProps {
  requiredRole: Roles
}

function EnsureRole(props: PropsWithChildren<ValidateRoleProps>) {
  const { children, requiredRole } = props;

  const validateRole = useValidateRole();

  if (validateRole(requiredRole)) return children;
  return null;
}

export default EnsureRole;
