import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';

import { ITitleContext, TitleContext } from './TitleContext';

const DEFAULT_TITLE_STACK = [{
  text: 'MovOn.VIP',
  id: 'default',
}];

interface Title {
  text: string
  id: string
}

function TitleProvider(props: PropsWithChildren) {
  const { children } = props;

  const [titleStack, setTitleStack] = useState<Title[]>(DEFAULT_TITLE_STACK);

  const pushTitle = useCallback<ITitleContext['pushTitle']>(() => {
    const id = uuid();
    setTitleStack((current) => [...current, { id, text: '' }]);
    return id;
  }, []);

  const updateText = useCallback<ITitleContext['updateText']>((id, text) => {
    setTitleStack((current) => {
      const index = current.findIndex((t) => t.id === id);
      if (index >= 0) {
        const newStack = [...current];
        newStack[index].text = text;
        return newStack;
      }
      return current;
    });
  }, []);

  const popTitle = useCallback<ITitleContext['popTitle']>((id) => {
    setTitleStack((current) => (
      current.filter((t) => t.id !== id)
    ));
  }, []);

  useEffect(() => {
    const title = titleStack.findLast((t) => Boolean(t.text));
    if (title) {
      document.title = title.text;
    }
  }, [titleStack]);

  const context = useMemo<ITitleContext>(() => ({
    pushTitle,
    popTitle,
    updateText,
  }), [popTitle, pushTitle, updateText]);

  return (
    <TitleContext.Provider value={context}>
      {children}
    </TitleContext.Provider>
  );
}

export default TitleProvider;
