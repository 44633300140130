import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import { motion } from 'framer-motion';
import styled from 'styled-components';

interface StatusColor {
  $color: string
}

interface LinearCountdownProps {
  $duration: number
  $color: string
}

export const AlertContainer = styled.div`
  width: 80%;
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.info.dark};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  align-items: center;
  margin: 2rem 0 1.5rem;
`;

export const ZeroSpaceContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 1em;
  gap: 0.5em;

  & {
    text-align: center;
  }
`;

export const IconContainer = styled.div<StatusColor>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: ${({ $color }) => $color};
  position: absolute;
  top: -25px;
`;

export const Icon = styled(FontAwesomeIcon)`
  color: white;
`;

export const LinearCountdown = styled(motion.div).attrs<LinearCountdownProps>(({ $duration }) => ({
  animate: { width: ['100%', '0%'] },
  transition: { duration: $duration, ease: 'linear' },
}))`
  height: 5px;
  align-self: flex-start;
  background: ${({ $color }) => $color};
  border-radius: 0 0 5px 5px;
`;

export const AlertButtonsContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  gap: 1em;
  margin: 0.25em;
  padding: 0 1em;
`;

export const QRAlertTitle = styled.h2<StatusColor>`
  color: ${({ $color }) => $color};
  font-size: 1.5em;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  text-transform: uppercase;
  margin: 0;
`;

export const WaitingLineName = styled(Typography)`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const QRAlertTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0.5em 0;
`;

export const TickerNumber = styled.span`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: ${({ theme }) => theme.palette.secondary.main};
`;
