import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Card,
  Grid,
  Tooltip,
} from '@mui/material';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import EnsureRole from 'lib/access/EnsureRole';
import useValidateRole from 'lib/access/useValidateRole';
import { SDG_ICONS } from 'lib/constants/icons';
import { GetObnlItem } from 'lib/graphql/entities/obnl/types';
import { useRemoveObnlFromEventMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteButton } from 'styles/styles';

import {
  CauseIcon,
  ObnlDescription,
  ObnlTitle,
} from './styles';

interface SupportedOrganizationCardProps {
  obnl: NonNullable<GetObnlItem>;
}

function SupportedOrganizationCard(props: SupportedOrganizationCardProps) {
  const { obnl } = props;

  const event = useEvent();
  const { language } = useContext(LanguageContext);
  const { refetchObnls } = event;
  const feedback = useFeedback();
  const [modalOpen, setModalOpen] = useState(false);
  const [removeObnlFromEvent] = useRemoveObnlFromEventMutation();

  const validateRole = useValidateRole();

  const handleDeleteObnl = async () => {
    if (!validateRole('ADMIN')) return;

    // TODO: Add business logic around deleting an OBNL from an event (frontend+backend)
    if (event.obnls && (event.obnls.length - 1 > 0)) {
      await removeObnlFromEvent({
        variables: {
          eventId: event.id!,
          obnlId: obnl.id,
        },
      });
      refetchObnls();
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_obnl_success" />),
        severity: 'success',
      });
    } else {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_obnl_error" />),
        severity: 'error',
      });
    }
    setModalOpen(false);
  };

  const eventLanguage = event.languages?.[0] as Languages;
  const description = obnl.description?.[language] || obnl.description?.[eventLanguage] || '';

  return (
    <>
      <Card variant="outlined">
        <Grid
          container
          direction="row"
          flexWrap="nowrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid
            container
            flexGrow={1}
            display="flex"
            flexWrap="nowrap"
            alignItems="center"
          >
            <Box margin={3}>
              <Tooltip arrow placement="left" title={<FormattedMessage id={`obnl_${obnl.cause}_description`} />}>
                <CauseIcon icon={SDG_ICONS[obnl.cause]} size="lg" />
              </Tooltip>
            </Box>
            <Grid container direction="column">
              <Grid item>
                <ObnlTitle>
                  {obnl.name}
                </ObnlTitle>
              </Grid>
              <Grid item>
                <ObnlDescription>
                  {description}
                </ObnlDescription>
              </Grid>
            </Grid>
          </Grid>
          <EnsureRole requiredRole="ADMIN">
            <Grid item>
              <DeleteButton onClick={() => setModalOpen(true)}>
                <FontAwesomeIcon icon={faTrash} size="lg" />
              </DeleteButton>
            </Grid>
          </EnsureRole>
        </Grid>
      </Card>
      <ConfirmModal
        title="delete_confirm"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleDeleteObnl}
        closeText="cancel"
        confirmColor="error"
        confirmText="delete"
      >
        <FormattedMessage id="causes_obnl_dialog_delete_warning" />
      </ConfirmModal>
    </>
  );
}

export default SupportedOrganizationCard;
