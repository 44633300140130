/* cSpell:language en */
/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": true}] */

import { WaitingLineStatus } from 'lib/graphql/graphql';

export default {
  accept: 'Accept',
  accept_invitation_error: 'An error occurred while accepting the invitation',
  accept_invitation_success: 'Invitation accepted successfully',
  account: 'Account',
  account_needed: 'An account is required to accept this invitation',
  actions: 'Actions',

  add: 'Add',
  add_obnl_error: 'An error occurred while adding the organization',
  add_supported_organization: 'Add a supported organization',

  apply: 'Apply',

  automatic_timezone_failed: 'An error occurred during automatic timezone retrieval, please select manually',

  avatar_organization_create: 'Create an organization',
  avatar_organization_edit: 'Edit organization',
  avatar_organization_manage: 'Manage my organizations',

  average_wait_time: 'Average wait time',

  back: 'Back',
  back_to_dashboard: 'Back to dashboard',
  back_to_events: 'Back to events',

  balance: 'Balance',

  cancel: 'Cancel',
  causes: 'Causes',
  causes_obnl_dialog_add_another: 'Do you want to add another organization to support?',
  causes_obnl_dialog_delete_warning: 'This organization will no longer be supported by your event',
  causes_obnl_dialog_subheader: 'It is now one of the organizations you support through the sale of MovOn.VIP passes.',
  causes_obnl_dialog_title: 'Organization added successfully',
  change_password_error: 'An error occurred while changing your password',
  change_password_incorrect_password: 'Your current password is incorrect',
  change_password_link: 'Change password?',
  change_password_success: 'Your password was changed successfully',
  change_password_title: 'Change password',
  change_visibility_confirm_hidden: 'Are you sure you want to hide this event?',
  change_visibility_error: 'An error occurred while updating the event status',
  change_visibility_success: 'Event status updated successfully',
  change_visibility_to_hidden: 'Event will no longer be visible to visitors and they will no longer be able to purchase passes.',
  check_email_not_altered: 'Please make sure your email client has not altered the URL in any way or you haven\'t already verified the email address.',
  check_your_email: 'Check your email',
  choose_for_me: 'Choose for me',
  choose_obnl_for_me: 'Choose an organization for me',
  choose_obnl_to_support: 'Choose an organization to support',
  choose_one_goal_from_sdg_description: 'Choose one goal from the Sustainable Development Goals adopted by the United Nations. You can add more later.',
  choose_one_goal_from_sdg_title: 'Choose a goal',
  clear: 'Clear',
  click_link_email_to_confirm: 'Please click on the link in the email we just sent you to confirm your email address.',
  collapse: 'Collapse',
  confirm_password: 'Confirm password',
  confirm_your_email: 'Confirm your email',
  control_number: 'Control number',
  create_event: 'Create an event',
  create_event_description: 'Enter event description',
  create_event_details: 'Add your event details & create VIP waiting lines',
  create_event_error: 'An error occurred while creating the event',
  create_event_name: 'Enter event title',
  create_event_short_description: 'Enter a short description about your event',
  create_event_success: 'Event created successfully',
  create_invitation_error_already_exists: 'An invitation already exists for this email',
  create_invitation_success: 'Invitation created successfully',
  create_organization: 'Create an organization',
  create_organization_description: 'Enter a short description that will be visible in the list of organizations',
  create_organization_language: 'Select the language that will be used by default for the fields',
  create_organization_name: 'Enter full organization name',
  create_organization_success: 'Organization created successfully',
  create_waiting_line: 'Create a queue',
  create_waiting_line_error: 'An error occurred while creating the queue',
  create_waiting_line_success: 'Queue created successfully',
  current_time_slot: 'Current time slot',

  dashboard: 'Dashboard',
  dashboard_begin_here: 'Begin here',
  date: 'Date',
  date_field_next_month: 'Next month',
  date_field_open_next_view: 'Open next view',
  date_field_open_previous_view: 'Open previous view',
  date_field_previous_month: 'Previous month',
  delete: 'Delete',
  delete_confirm: 'Are you sure you want to delete?',
  delete_event_success: 'Event deleted successfully',
  delete_invitation_error: 'An error occurred while deleting the invitation',
  delete_invitation_success: 'Invitation deleted successfully',
  delete_irreversible: 'This action is irreversible',
  delete_obnl_error: 'You must support at least one organization, please add another one first if you wish to remove it',
  delete_obnl_success: 'Organization deleted successfully',
  delete_organization_success: 'Organization deleted successfully',
  delete_waiting_line_success: 'Queue deleted successfully',
  description: 'Description',
  did_not_get_it: 'Did not get it?',
  dont_have_an_account: 'Don\'t have an account? ',

  edit: 'Edit',
  email: 'Email',
  enter_confirm_password: 'Enter your password again',
  enter_email: 'Enter your email',
  enter_new_password: 'Enter your new password again',
  enter_password: 'Enter your password',
  errors_in_form: 'One or more errors are present in the form, please correct them before continuing',
  event: 'Event',
  event_begin: 'Start of event',
  event_begin_title: 'When does your event start?',
  event_create: 'Create a new event',
  event_date_invalid: 'Invalid date',
  event_end: 'End of event',
  event_end_error: 'The end of the event must be later than its start',
  event_end_error_future: 'The end of the event must be in the future',
  event_end_title: 'When does your event end?',
  event_ends: 'Ends',
  event_image: 'Event image',
  event_image_description: 'Upload an image that represents your event (16:9 ratio recommended)',
  event_information: 'Event information',
  event_languages: 'Event languages',
  event_languages_description: 'Languages in which the informations will be shown. The first one will be the default language.',
  event_location: 'Event location',
  event_location_placeholder: 'Start typing address...',
  event_media_browse: 'Browse',
  event_media_drag: 'Drag & drop file or',
  event_media_file_invalid_type: 'Invalid file type, must be JPG, JPEG or PNG',
  event_media_supported: 'Supported files: JPG and PNG',
  event_my_events: 'My events',
  event_name: 'Event name',
  event_no_events: 'No events to show',
  event_not_found: 'Event not found',
  event_payment: 'Payment configuration',
  event_publish_again: 'You must publish the event again to make it visible to visitors.',
  event_publish_invite: 'Invite staff members to manage your event',
  event_publish_next: 'What\'s next?',
  event_publish_no_causes: 'You must select at least one cause to publish the event',
  event_publish_no_waiting_lines: 'You must create at least one queue to publish the event',
  event_publish_not_validated_by_movon: 'Your organization is not validated by MovOn.VIP',
  event_publish_operate: 'View and manage pass scans and sales',
  event_publish_set_display: 'Set up the display for your waiting lines',
  event_published: 'Your event is published!',
  event_qr_copied: 'Link copied successfully',
  event_qr_copy_error: 'An error occurred while copying the link',
  event_qr_download: 'Download QR code',
  event_qr_download_error: 'An error occurred while downloading the QR code',
  event_qr_downloaded: 'QR code downloaded successfully',
  event_share_link: 'Share the link to your event',
  event_short_url_description: 'Enter an easy to type URL for your event',
  event_starts: 'Starts',
  event_status_draft: 'Draft',
  event_status_hidden: 'Hidden',
  event_status_published: 'Published',
  event_support: 'Support configuration',
  event_timezone: 'Event timezone',
  event_type: 'Event type',
  event_unpublish: 'Unpublish',
  event_update: 'Event information',
  event_view_display: 'View display',
  events: 'Events',
  expand: 'Expand',
  explanations: 'Explanations',

  field_decimals: 'The value must have a maximum of two decimal places',
  field_format: 'Invalid format (use a point to separate decimals)',
  field_max_length_default: 'The text exceeds the character limit',
  field_min_default: 'The minimum value is 1',
  field_minimum_price: 'The minimum price is 2.00',
  field_positive: 'The value must be positive',
  field_required: 'This field is required',
  filter_all: 'All',
  filter_all_status: 'All status',
  filter_all_waiting_lines: 'All queues',
  filter_last_month: 'Last month',
  filter_last_week: 'Last week',
  filter_no_balance: 'No balance with current filters',
  filter_no_payout: 'No payout with current filters',
  filter_today: 'Today',
  filter_yesterday: 'Yesterday',
  filters: 'Filters',
  financial_report: 'Financial report',
  finish: 'Finish',
  forgot_password: 'Forgot password?',
  funds_in_stripe_account: 'Funds in your Stripe account',

  general_close: 'Close',
  general_date_time_picker_title: 'Select date and time',
  general_lorem_ipsum_short: 'Lorem ipsum',
  general_no: 'No',
  general_permission_error: 'You do not have permission to perform this action',
  general_remaining: 'Remaining',
  general_time_from: 'From',
  general_time_sales_end: 'Sales end',
  general_time_sales_start: 'Sales start',
  general_time_to: 'To',
  general_update: 'Update',
  general_yes: 'Yes',

  go_back_to_login: 'Go back to login',

  have_an_account: 'Have an account? ',

  information: 'Information',
  invalid_credentials: 'Invalid credentials',
  invalid_email: 'Email must contain an "@" followed by the domain and its extension',

  invitation_invalid_email_tooltip: 'This address is invalid and therefor this invitation cannot be accepted. Check that the address is correct before creating a new invitation. You can delete this invitation.',
  invitation_status_accepted: 'Accepted',
  invitation_status_pending: 'Pending',
  invitations: 'Invitations',
  invite: 'Invite',
  invite_user: 'Invite a staff member',

  language_default: ' (default)',
  language_en: 'English',
  language_es: 'Spanish',
  language_fr: 'French',

  loading: 'Loading',
  login: 'Login',
  logout: 'Logout',

  mediakit_branding_guide: 'Branding Guide',
  mediakit_download: 'Download',
  mediakit_download_kit: 'Download your media kit',
  mediakit_download_zip: 'Download (zip)',
  mediakit_file_size: '{size} kb',
  mediakit_logos: 'Logos',
  mediakit_number_files: '{number} files',
  mediakit_number_pictures: '{number} pictures',
  mediakit_operating: 'Operating Guide',
  mediakit_posters: 'Posters',
  mediakit_qr: 'QR Codes',
  mediakit_subtitle: 'Download everything you need to make your event a success',
  mediakit_view_files: 'View all files',
  mediakit_view_pictures: 'View all pictures',

  name: 'Name',
  new_email_error: 'An error occurred while sending the email, please try again',
  new_email_sent: 'A new email has been sent to you, please check your inbox',
  new_event: 'New event',
  new_password: 'New password',
  next: 'Next',
  next_step: 'Next step',
  next_time_slot: 'Next time slot',
  no_invitations: 'No invitations.',
  no_obnl_available_error: 'You have already added all available organizations, you can\'t add any more',
  no_supported_organizations_found: 'No organizations found.',

  objectives_and_organizations: 'Objectives and organizations',
  obnl_already_selected: 'Organization already selected',
  obnl_cities: 'Sustainable cities and communities',
  obnl_cities_description: 'Make cities inclusive, safe, and sustainable.',
  obnl_climate: 'Climate action',
  obnl_climate_description: 'Take urgent action to combat climate.',
  obnl_consumption: 'Responsible consumption and production',
  obnl_consumption_description: 'Ensure sustainable consumption and production patterns.',
  obnl_education: 'Quality education',
  obnl_education_description: 'Provide inclusive and equitable education.',
  obnl_energy: 'Affordable and clean energy',
  obnl_energy_description: 'Ensure access to affordable, reliable and sustainable energy.',
  obnl_gender: 'Gender equality',
  obnl_gender_description: 'Achieve gender equality and empower all women and girls.',
  obnl_health: 'Good health and well-being',
  obnl_health_description: 'Ensure healthy lives and promote well-being for all at all ages.',
  obnl_hunger: 'Zero hunger',
  obnl_hunger_description: 'Achieve food security and improved nutrition.',
  obnl_industry: 'Industry, innovation, and infrastructure',
  obnl_industry_description: 'Build resilient infrastructure and foster innovation.',
  obnl_inequalities: 'Reduced inequalities',
  obnl_inequalities_description: 'Reduce inequality within and among countries.',
  obnl_life_land: 'Life on land',
  obnl_life_land_description: 'Protect, restore and promote sustainable use of terrestrial ecosystems.',
  obnl_partnerships: 'Partnerships for the goals',
  obnl_partnerships_description: 'Strengthen the global partnerships for sustainable development.',
  obnl_peace: 'Peace, justice, and strong institutions',
  obnl_peace_description: 'Promote peaceful and inclusive societies.',
  obnl_poverty: 'No poverty',
  obnl_poverty_description: 'End poverty in all its forms everywhere.',
  obnl_water: 'Clean water and sanitation',
  obnl_water_description: 'Ensure availability and sustainable management of water and sanitation for all.',
  obnl_water_life: 'Life below water',
  obnl_water_life_description: 'Conserve and sustainably use the oceans, seas and marine resources.',
  obnl_work: 'Decent work and economic growth',
  obnl_work_description: 'Promote inclusive and sustainable economic growth.',
  obnls_breakdown: 'Breakdown by Supported Organizations',
  operating_invalid_waiting_line: 'Do you still want to process this pass?',
  operating_mixed_validation: 'Do you still want to validate the {number} {count, plural, one {pass} other {passes}} that have not been validated?',
  operating_multiple_codes_detected: 'Multiple QR codes detected',
  operating_ticket_already_used: 'Already been redeemed',
  operating_ticket_confirmation: 'Confirmation',
  operating_ticket_generic_error: 'An error occurred while processing the pass',
  operating_ticket_mixed: 'This QR code contains passes that have already been validated',
  operating_ticket_not_found: 'The pass was not found',
  operating_ticket_not_valid: 'Not valid',
  operating_ticket_wrong_waiting_line: 'Wrong queue',
  operating_unknown_error: 'Unrecognized QR code format',
  operations: 'Operations',
  operator: 'Operator',
  operator_description: 'Scan passes and manage your event.',
  operator_tickets: 'Passes scanned',
  operator_tickets_call: 'Call next {callSize} visitors',
  operator_tickets_called: 'New passes called successfully',
  operator_tickets_error: 'An error happened while calling the next visitors',
  operator_tickets_no_ticket: 'No passes to call',

  organization: 'Organization',
  organization_create: 'Create an organization',
  organization_language: 'Default language',
  organization_name: 'Organization name',
  organization_none: 'No organization to show. Create one or be invited to one to start.',
  organization_not_found: 'Organization not found',
  organization_update: 'Organization information',
  organizations: 'Organizations',

  page_forbidden_description: 'You are not allowed to access this page.',
  page_forbidden_title: 'Error 403',

  page_not_found_description: 'The page you tried to access does not exist, please check the URL.',
  page_not_found_title: 'Error 404',

  pass_percentage_donated_to_cause: 'With every pass your visitors purchase, a percentage of the cost will be donated to the causes you chose.',

  password: 'Password',
  password_too_short: 'Password must be at least 8 characters long',
  passwords_do_not_match: 'Passwords do not match',

  payout: 'Payout',
  payout_amount: 'Payout amount',
  payout_arrival_date: 'Expected delivery date',
  payout_created_date: 'Issue date',
  payout_in_days: 'In {days, plural, one {# day} other {# days}}',
  payout_status_canceled: 'Canceled',
  payout_status_failed: 'Failed',
  payout_status_in_transit: 'In transit',
  payout_status_paid: 'Paid',
  payout_status_pending: 'Pending',
  payout_today: 'Today',
  payout_tomorrow: 'Tomorrow',
  payout_yesterday: 'Yesterday',
  payouts: 'Payouts',
  payouts_available_funds: 'Funds available',
  payouts_available_funds_tooltip: 'This amount represents the funds that are eligible for transfers, you can at any time make a request to receive these funds using the button below. Please note that this process may take a few days.',
  payouts_no_funds: 'No sales yet',
  payouts_pending_funds: 'Funds soon available',
  payouts_pending_funds_tooltip: 'This amount represents funds currently being processed and are not eligible for transfer. Once these funds become available, you will see the amount in the "Funds available" section.',
  payouts_to_bank_account: 'Payouts to your bank account',
  payouts_total_volume: 'Total volume since registration',
  payouts_total_volume_tooltip: 'This amount represents your total share of sales (excluding refunds) for all of your events since the creation of your account.',

  please_try_with_new_cause: 'Please try again with a new cause.',
  position: 'Position',
  previous: 'Previous',
  previous_step: 'Previous step',
  price: 'Price',

  privacy_policy: 'Privacy policy',
  privacy_policy_1_content: 'MovOn.VIP (the "Site") is operated by Novom Interactive (the "Operator"). This privacy policy (the "Policy") describes how the Operator collects, uses and discloses your personal information. By accessing and using the Site, you agree to be bound by this Policy. If you do not agree to this Policy, you should not access or use the Site.',
  privacy_policy_1_title: '1. Introduction',
  privacy_policy_2_content: 'The Operator collects the following personal information from you:'
    + '<ul>'
    + '<li><b>Personal information you voluntarily provide</b>: This includes your email address, telephone number, password and other information you provide when you create an account on the Site or when you use certain features of the Site.</li>'
    + '<li><b>Personal information collected automatically</b>: When you use the Site, the Operator automatically collects certain personal information about you, such as your IP address, Web browser and operating system. This information is used to improve the Site\'s performance and for analysis purposes.</li>'
    + '<li><b>Personal information from third parties</b>: The Operator may not collect personal information about you from third parties, such as social networks.</li>'
    + '</ul>',
  privacy_policy_2_title: '2. Collection of Personal Information',
  privacy_policy_3_content: 'The Operator uses your personal information for the following purposes:'
    + '<ul>'
    + '<li><b>To provide you with the Site\'s services</b>: The Operator uses your Personal Information to provide you with the Site\'s services, such as creating an account, publishing content, and communicating with other users.</li>'
    + '<li><b>To improve the Site</b>: The Operator uses your personal information to improve the Site and its services.</li>'
    + '<li><b>To contact you</b>: The Operator may use your personal information to contact you about the Site or your accounts.</li>'
    + '<li><b>For Marketing Purposes</b>: The Operator may use your personal information to send you marketing communications, such as thank you courriels and satisfaction surveys. In all communications, the unsubscribe process is simple and accessible.</li>'
    + '</ul>',
  privacy_policy_3_title: '3. Use of personal information',
  privacy_policy_4_content: 'The Operator may disclose your personal information to the following third parties:'
    + '<ul>'
    + '<li><b>Third Party Service Providers</b>: The Operator may disclose your Personal Information to third party service providers who assist the Operator in operating the Site, such as web hosting providers and payment service providers.</li>'
    + '<li><b>Governmental Authorities</b>: The Operator may disclose your personal information to governmental authorities if required to do so by law or if the Operator believes in good faith that disclosure is necessary to protect its rights or the rights of third parties.</li>'
    + '</ul>',
  privacy_policy_4_title: '4. Disclosure of personal information',
  privacy_policy_5_content: 'You may exercise the following rights with respect to your personal information:'
    + '<ul>'
    + '<li><b>Access to your personal information</b>: You have the right to access your personal information held by the Operator.</li>'
    + '<li><b>Correction of your personal information</b>: You have the right to have inaccurate or incomplete personal information held by the Operator corrected.</li>'
    + '<li><b>Deleting your personal information</b>: You have the right to request that the Operator delete your personal information.</li>'
    + '<li><b>Limiting the processing of your personal information</b>: You have the right to request that the Operator limit the processing of your personal information.</li>'
    + '<li><b>Objection to the processing of your personal information</b>: You have the right to object to the processing of your personal information by the Operator.</li>'
    + '</ul>'
    + 'To exercise your rights, please contact Eric Foley at <mailto>support@movon.vip</mailto>.',
  privacy_policy_5_title: '5. Your choices',
  privacy_policy_6_content: 'The Operator takes reasonable security measures to protect your personal information against loss, theft, misuse, unauthorized disclosure, access, alteration or destruction.',
  privacy_policy_6_title: '6. Security of personal information',
  privacy_policy_7_content: 'The Operator will retain your personal information for as long as necessary to fulfill the purposes for which it was collected, or for such longer period as may be required or permitted by law.',
  privacy_policy_7_title: '7. Retention of Personal Information',
  privacy_policy_8_content: 'The Operator may amend this Policy at any time by posting the amended Policy on the Site. Changes to the Policy will be effective immediately upon posting. You are responsible for regularly reviewing the Policy to ensure that you are aware of any changes.',
  privacy_policy_8_title: '8. Changes to the Policy',
  privacy_policy_9_content: 'If you have any questions regarding this Policy, please contact Eric Foley at <mailto>support@movon.vip</mailto>.',
  privacy_policy_9_title: '9. Contact',

  profile: 'Profile',
  publish: 'Publish',
  publish_event: 'Publish event',
  publish_event_error: 'An error occurred while publishing the event',
  publish_event_step_1: 'Fill your informations',
  publish_event_step_2: 'Create at least one queue',
  publish_event_step_3: 'Select your causes',
  publish_event_step_4: 'Have linked a Stripe account in the organization to receive payouts (optional)',
  publish_event_step_5: 'Have validated your MovOn.VIP organization.',
  publish_event_success: 'Event published successfully',
  publish_review_info: 'Review your information',

  qr_reader: 'QR reader',
  qr_reader_instructions: 'Center the QR code within the box',
  qr_reader_pass_left: '{nbr} left in this queue',
  qr_reader_recent_scans: 'My recent scans',
  qr_reader_scanned_passes: '{nbr} pass',

  refresh: 'Refresh',

  resend_email: 'Resend email',

  reset_password: 'Reset password',
  reset_password_error: 'An error occurred while resetting the password. Please try again or request a new password reset link.',

  return_to_dashboard: 'Return to dashboard',
  return_to_events: 'Return to events',
  return_to_login: 'Return to login',
  return_to_organizations: 'Return to organizations',
  return_to_users: 'Return to staff members',
  return_to_waiting_lines: 'Return to queues',
  role: 'Role',
  role_admin: 'Admin',
  role_staff: 'Staff',

  sale_price: 'Sale price',
  sales_breakdown: 'Sales Breakdown',

  save_changes: 'Save changes',
  search: 'Search',
  select: 'Select',
  select_goal_and_organization: 'Select a goal and an organization that speaks to you',
  select_tickets_types: 'What types of passes do you want to create for the main queue?',
  send: 'Send',
  sent_password_recover_instructions: 'We have sent password recover instructions to your email.',
  settings: 'Settings',
  short_url: 'Short URL',
  short_url_available: 'This short URL is available',
  short_url_dev_warning: 'Development mode is enabled',
  short_url_dev_warning_checkbox: 'Enable short links anyway for testing purposes',
  short_url_dev_warning_description: 'Short links are disabled by default in development to avoid reaching the URL shortening API limits.',
  short_url_taken: 'This short URL is already taken',
  show_supported_organizations: 'Having only one selected organization will skip the organization selection step when buying a pass and use this one by default.',
  sign_up: 'Sign up',
  start_selection: 'Start selection',
  status: 'Status',

  steps_to_redeem_pass: 'Describe the steps or procedure required for visitors to redeem their pass',

  stripe_account_not_linked_error: 'A Stripe account is not linked to this organization, please link one to publish events.',
  stripe_already_linked: 'Stripe account already linked',
  stripe_close_window: 'You may now close this tab.',
  stripe_congratulations: 'Congratulations!',
  stripe_dashboard: 'Stripe dashboard',
  stripe_fees: 'Stripe fees',
  stripe_landing_linked: 'Your Stripe account is now linked to the organization {organization}',
  stripe_link_account: 'Link to a Stripe account',
  stripe_need_info: 'Complete your account',
  stripe_not_linked: 'Stripe account not linked',
  stripe_support: 'A Stripe account is required.',
  stripe_support_description: 'As long as you haven\'t linked a Stripe account, you won\'t be able to receive your share of the profits from pass sales. Visitors will still be able to buy passes.',
  stripe_support_description_linked: 'The money from the sale of passes will be collected by MovOn.VIP then your profits will be deposited directly into your Stripe account.',
  stripe_support_linked: 'Your Stripe account is linked but some information is still missing before receiving your first payout.',
  stripe_support_linked_payouts_enabled: 'Your Stripe account is linked and ready to receive payouts',

  suggest_random_organization: 'We will suggest a random organization',

  support: 'Support',
  support_all_causes: 'Support all causes',
  supported_organizations: 'Supported Organizations',
  sustainable_development_goals: 'Sustainable development goals',

  taxes: 'Taxes',

  terms_conditions: 'Terms & Conditions',
  terms_conditions_1_content: 'Welcome to the MovOn.VIP web application (the "Site"). The Site is operated by Novom Interactive (the "Operator"). By accessing and using the Site, you agree to be bound by these Terms of Use (the "Terms"). If you do not agree to these Terms, you must not access or use the Site.',
  terms_conditions_1_title: '1. Introduction',
  terms_conditions_2_content: 'MovOn.VIP is committed to complying with Quebec\'s Bill 25 and the General Data Protection Regulation (GDPR) with respect to the collection, use and disclosure of your personal information. For more information on our data protection practices, please see our Privacy Policy.',
  terms_conditions_2_title: '2. Compliance with Law 25 and the GDPR',
  terms_conditions_3_content: 'MovOn.VIP will never sell your personal information to third parties. We may share your personal information with third-party service providers who help us operate the Site, but we will do so only to the extent necessary to provide those services.',
  terms_conditions_3_title: '3. No sale of data to third parties',
  terms_conditions_4_content: 'All content on the Site, including text, images, video and software, is protected by copyright and other intellectual property rights of the Operator or its licensors. You may not use the content of the Site without the prior written consent of the Operator.',
  terms_conditions_4_title: '4. Intellectual property rights',
  terms_conditions_5_content: 'MovOn.VIP grants you a non-exclusive, non-transferable license to use the Site. You are not authorized to use the Site for illegal or harmful purposes, or to violate the rights of the Operator or third parties.',
  terms_conditions_5_title: '5. Authorization granted to users',
  terms_conditions_6_content: 'MovOn.VIP shall not be liable for any direct, indirect, incidental, special or consequential damages, including loss of profits, goodwill or data, arising out of the use or inability to use the Site, even if MovOn.VIP has been advised of the possibility of such damages.',
  terms_conditions_6_title: '6. Limitation of liability',
  terms_conditions_7_content: 'These Terms and Conditions shall be governed by and construed in accordance with the laws of the Province of Quebec. Any dispute arising out of or in connection with these Terms shall be subject to the exclusive jurisdiction of the competent courts of the Province of Quebec.',
  terms_conditions_7_title: '7. Applicable law and jurisdiction',
  terms_conditions_8_content: 'MovOn.VIP may modify these Terms at any time by posting the modified Terms on the Site. The modified Terms will come into effect as soon as they are published. You are responsible for regularly reviewing the Terms to ensure that you are aware of any changes.',
  terms_conditions_8_title: '8. Changes to the Terms',
  terms_conditions_9_content: 'The French version of these Terms shall prevail over any other translation.',
  terms_conditions_9_title: '9. Language of translation',
  terms_conditions_10_content: 'If you have any questions regarding these Terms, please contact MovOn.VIP at <mailto>support@movon.vip</mailto>.',
  terms_conditions_10_title: '10. Contact',
  terms_conditions_copyright: '© {year} MovOn.VIP',

  ticket_id: 'Pass ID',

  ticket_more_information: 'More information',
  ticket_no_communication_address: 'No communication address',

  ticket_operation_called: 'Called',
  ticket_operation_display_id: 'Pass',
  ticket_operation_status: 'Status',
  ticket_operation_transaction_id: 'Transaction',

  ticket_send_sms: 'Send SMS',
  ticket_status: 'Status',

  ticket_status_ACTIVATED: 'Sold',
  ticket_status_CANCELED: 'Canceled',
  ticket_status_PENDING: 'Pending payment',
  ticket_status_REFUNDED: 'Refunded',
  ticket_status_RESERVED: 'Reserved',
  ticket_status_VALIDATED: 'Used',

  ticket_transaction_id: 'Transaction ID',

  ticket_validate: 'Mark as used',
  ticket_validate_body: 'Do you want to mark the pass <b>#{displayId}</b> as used?',
  ticket_validated: 'Pass <b>#{displayId}</b> is now marked as used',

  tickets_begin: 'Start of passes sales',
  tickets_begin_title: 'When do passes sales start?',
  tickets_end: 'End of passes sales',
  tickets_end_title: 'When do passes sales end?',
  tickets_for_main_waiting_line: 'Passes for the main queue',

  total: 'Total',
  total_payouts: 'Total Paid Payouts',
  total_sales: 'Total Sales',
  total_sales_queue: 'Breakdown of your Balance by Queue',
  total_transactions: 'Total Transactions',

  transaction: 'Transaction',
  transactions: 'Transactions',

  translations: 'Translations',

  try_again: 'Try again',

  unable_to_verify_email: 'We were unable to verify your email address',

  unsaved_form_quit_confirmation_body: 'Are you sure you want to quit without saving?',
  unsaved_form_quit_confirmation_title: 'You have unsaved changes',

  update_error_missing_translation: 'One or more translations are missing for {count, plural, one {this language} other {theses languages}}: {languages}',
  update_event: 'Update an event',
  update_event_error: 'An error occurred while updating the event',
  update_event_success: 'Event updated successfully',
  update_organization: 'Update an organization',
  update_organization_success: 'Organization updated successfully',
  update_waiting_line: 'Update queue',
  update_waiting_line_error: 'An error occurred while updating the queue',
  update_waiting_line_success: 'Queue updated successfully',
  upload_image: 'Upload image',
  user_already_exists: 'A user with this email address already exists',
  user_invite_form_subtitle: 'The staff member will receive an invitation email to join your organization. If they do not have an account, they will need to create one using this email address.',
  users: 'Staff members',
  users_invites_subtitle: 'Invite staff members who will be able to access the queue operations.',

  view_details: 'View details',
  view_payouts: 'View payouts',
  view_payouts_details: 'View payouts details, dates and amounts',
  view_payouts_history: 'View payouts history',

  waiting_line: 'Queue',
  waiting_line_access_GOLD: 'Immediate access',
  waiting_line_access_SILVER: 'Accelerated access',
  waiting_line_call_size: 'Number of people called per block',
  waiting_line_call_size_description: 'Set the maximum number of people that will be called at the same time',
  waiting_line_change_status: 'Change queue status',
  waiting_line_check_operations: 'Check operations',
  waiting_line_description_gold: 'The VIP Gold pass gives priority access.',
  waiting_line_description_silver: 'The VIP Silver pass allows buyers to keep their place in line without having to physically stay there.',
  waiting_line_form_currency: 'Currency',
  waiting_line_form_currency_description: 'Currency in which the passes will be sold',
  waiting_line_form_end: 'To',
  waiting_line_form_limited_tickets: 'Limited quantity of passes available',
  waiting_line_form_max_group_size: 'Passes per transaction limit',
  waiting_line_form_max_group_size_helper_text: 'Customers will be able to purchase up to this number of passes in a single transaction',
  waiting_line_form_name: 'Queue name',
  waiting_line_form_price: 'Price',
  waiting_line_form_price_description: 'Determine a price for each pass',
  waiting_line_form_quantity: 'Quantity',
  waiting_line_form_start: 'From',
  waiting_line_form_statement_descriptor: 'Statement descriptor',
  waiting_line_form_statement_descriptor_description: 'The statement descriptor will appear on your customers\' bank statements',
  waiting_line_form_statement_descriptor_pattern: 'Can only contain alphanumeric characters and spaces',
  waiting_line_form_step_1: 'Select a queue type',
  waiting_line_form_step_2: 'Queue information',
  waiting_line_form_when: 'Pass availability',
  waiting_line_hide_operations: 'Hide operations',
  waiting_line_instructions: 'Queue instructions',
  waiting_line_is_virtual: 'Virtual queue (includes sale of skip-the-line)',
  waiting_line_management: 'Queue management',
  waiting_line_minimum_tickets: 'The minimum quantity of passes must be equal to or greater than the quantity of passes reserved ({usedTickets})',
  waiting_line_new: 'New queue',
  waiting_line_new_add: 'Add a new VIP queue to your event',
  waiting_line_not_found: 'Queue not found',
  waiting_line_pricing: 'Access type',
  waiting_line_pricing_call_params: 'Call block parameters',
  waiting_line_pricing_description: 'Choose either a VIP paid access or a free access with paid options that grant additional benefits',
  waiting_line_pricing_free_and_paid: 'Freemium (Free with paid options)',
  waiting_line_pricing_paid_only: 'VIP (Paid)',
  waiting_line_pricing_paid_passes: 'Paid passes',
  waiting_line_product_description: 'Pass description',
  waiting_line_product_description_description: 'Describe the pass and its benefits',
  waiting_line_quantity: 'Enter the quantity of passes that will be available',
  waiting_line_schedule_error_after_start: 'The end of the schedule must be later than its beginning',
  waiting_line_schedule_error_before_end: 'The start of the schedule must be before its end',
  waiting_line_schedule_error_end: 'The end of the schedule must be before or while the event ends',
  waiting_line_schedule_error_start: 'The start of the schedule must be while or after the event starts',
  [`waiting_line_status_${WaitingLineStatus.Active}`]: 'Active',
  [`waiting_line_status_${WaitingLineStatus.Inactive}`]: 'Closed',
  [`waiting_line_status_${WaitingLineStatus.Hidden}`]: 'Hidden',
  waiting_line_status_explanation: 'Explanation',
  waiting_line_status_explanation_placeholder: 'Explain the status change',
  waiting_line_tickets: 'Queue passes',
  waiting_line_tickets_free: 'Free passes',
  waiting_line_tickets_scanned: 'Passes scanned',
  waiting_line_tickets_sold: 'Passes sold',
  waiting_line_type: 'Queue type',
  waiting_line_type_GOLD: 'VIP Gold',
  waiting_line_type_SILVER: 'VIP Silver',
  waiting_line_type_create: 'Create a \n {type} queue',
  waiting_lines: 'Queues',

  your_balance: 'Your Balance',
};
