import { Grid } from '@mui/material';
import styled from 'styled-components';

export const LoadingContainer = styled(Grid).attrs({
  container: true,
})`
  gap: 1rem;
  margin: 1rem;
  align-items: center;
`;

export const MapDiv = styled.div`
  height: 300px;
  width: 100%;
  border-radius: 10px;
  margin: 1rem 0;

  & a.mapboxgl-ctrl-logo {
    user-select: none;
    pointer-events: none;
  }
`;
