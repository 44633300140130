import styled from 'styled-components';

export const RoundedCard = styled.div`
  background-color: ${({ theme }) => (theme.palette.background.paper)};
  border-radius: 1em;
  width: 100%;
  height: 100%;
  padding: 0.5em 0;
  margin-bottom: 1.5em;
  border: 1px solid ${({ theme }) => (theme.palette.divider)};
`;

export const ItemContainer = styled.div<{ $withDivider?: boolean }>`
  display: flex;
  width: 100%;
  padding: 1em 0;
  justify-content: space-between;
  ${({ $withDivider, theme }) => ($withDivider && `border-bottom: 1px solid ${(theme.palette.divider)};`)}
`;

export const ItemContentContainer = styled.div`
  display: flex;
  padding: 0 1.5em;
  align-items: center;
  flex: 1;
`;
