import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import FormButtons from 'components/FormFields/FormButtons';
import FormTextField from 'components/FormFields/FormTextField';
import PageLayout from 'components/PageLayout';
import { useCustomForm } from 'lib/form/useCustomForm';
import {
  InvitationInput,
  RoleEnum,
  useCreateInvitationMutation,
} from 'lib/graphql/graphql';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import useOrganization from 'providers/organization/useOrganization';
import { useContext, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormGrid, NeutralOutlinedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

function InviteUser() {
  useEventDocumentTitle('invite_user');
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const event = useEvent();
  const { organizationId } = useOrganization();
  const { language } = useContext(LanguageContext);

  const defaultValues = useMemo(() => ({
    userId: '',
    event: {
      eventId: event?.id || '',
      role: RoleEnum.Staff,
    },
    organizationId: organizationId || '',
  }), [event?.id, organizationId]);

  // TODO: Handle loading & errors
  const [createInvitation] = useCreateInvitationMutation();

  const onSubmit: SubmitHandler<InvitationInput> = async (formData) => {
    try {
      await createInvitation({
        variables: {
          invitation: {
            userEmail: formData.userEmail,
            organizationId: organizationId || '',
            event: {
              eventId: event?.id || '',
              role: RoleEnum.Staff,
            },
            language,
          },
        },
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="create_invitation_error_already_exists" />),
        severity: 'error',
      });
      return;
    }

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="create_invitation_success" />),
      severity: 'success',
    });

    setLocation('/users');
  };

  const formControls = useCustomForm({ defaultValues, mode: 'onChange', trim: true });

  return (
    <PageLayout>
      <FormProvider {...formControls}>
        <NeutralOutlinedButton
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={() => setLocation('/users')}
        >
          <FormattedMessage id="return_to_users" />
        </NeutralOutlinedButton>
        <FormGrid container direction="column" gap={2}>
          <Title>
            <FormattedMessage id="invite_user" />
          </Title>
          <Grid container direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="user_invite_form_subtitle" />
            </Typography>
          </Grid>
          <FormTextField
            required
            labelId="email"
            name="userEmail"
            type="email"
            placeholder="example@domain.com"
            rules={{
              required: true,
              pattern: {
                value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/i,
                message: 'invalid_email',
              },
            }}
          />
          { /* TODO: #6757 ADD Select dropdown for future roles */ }
        </FormGrid>
        <FormButtons submit={onSubmit} saveTextId="invite" />
      </FormProvider>
    </PageLayout>
  );
}

export default InviteUser;
