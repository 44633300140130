import {
  enUS, esES, frFR, Localization,
} from '@mui/material/locale';
import { enUS as gridEnUS, esES as gridEsES, frFR as gridFrFR } from '@mui/x-data-grid/locales';
import { Localization as GridLocalization } from '@mui/x-data-grid/utils/getGridLocalization';
import { Languages } from 'lib/i18n/i18n';

const locales: Record<Languages, (Localization | GridLocalization)[]> = {
  [Languages.FR]: [
    frFR,
    gridFrFR,
  ],
  [Languages.EN]: [
    enUS,
    gridEnUS,
  ],
  [Languages.ES]: [
    esES,
    gridEsES,
  ],
};

export default locales;
