import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import useFeedback from 'components/Feedback/useFeedback';
import { ItemContainer, ItemContentContainer } from 'components/Invitations/styles';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import {
  RoleEnum,
  useDeleteInvitationMutation,
  useGetEventQuery,
} from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useCallback, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteButton } from 'styles/styles';

export interface InvitationItemProps {
  item: {
    eventId: string;
    role: string;
    organizationId: string;
    userId: string;
    userEmail: string;
    id: string;
  };
}

function InvitationItem(props: InvitationItemProps) {
  const { item } = props;
  const feedback = useFeedback();
  const { language } = useContext(LanguageContext);

  const [deleteInvitation, { loading: deleteLoading }] = useDeleteInvitationMutation();

  const { data: eventData, loading: eventLoading } = useGetEventQuery({
    variables: {
      id: item.eventId,
    },
  });

  const event = eventData?.getEvent;
  const queryClient = useQueryClient();

  const handleDelete = useCallback(async (eventId: string) => {
    try {
      await deleteInvitation({
        variables: {
          organizationId: item.organizationId,
          eventId,
          id: item.id,
        },
      });
      queryClient.invalidateQueries({ queryKey: ['getAllInvitationsForUser'], exact: true });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_invitation_success" />),
        severity: 'success',
      });
    } catch (error: any) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="delete_invitation_error" />),
        severity: 'error',
      });
    }
  }, [deleteInvitation, feedback, item.id, item.organizationId, queryClient]);

  if (eventLoading || deleteLoading) {
    return (
      <ItemContainer>
        <CenteredSpinner />
      </ItemContainer>
    );
  }

  if (!event) {
    return null;
  }

  const eventLanguage = event.languages[0] as Languages;

  return (
    <ItemContainer $withDivider>
      <ItemContentContainer>
        {event.name[language] || event.name[eventLanguage] || ''}
      </ItemContentContainer>
      <ItemContentContainer>
        <FormattedMessage id={`role_${item.role.toLowerCase()}`} />
      </ItemContentContainer>
      <ItemContentContainer>
        {item.role !== RoleEnum.Admin && (
          <DeleteButton onClick={() => handleDelete(item.eventId)}>
            <FontAwesomeIcon icon={faTrash} size="lg" />
          </DeleteButton>
        )}
      </ItemContentContainer>
    </ItemContainer>
  );
}

export default InvitationItem;
