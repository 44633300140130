import { AdvancedImage, responsive } from '@cloudinary/react';
import { LoadingButton } from '@mui/lab';
import {
  Button as MUIButton,
  Grid,
  Select,
  Typography,
} from '@mui/material';
import { outlinedInputClasses } from '@mui/material/OutlinedInput';
import { selectClasses } from '@mui/material/Select';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { AppTypeBackground } from './AppThemeProvider';

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const RightAligned = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const Content = styled.div`
  margin: 1em auto;
`;

export const CenteredContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100svh;
`;

export const Text = styled(Typography)`
  color: ${({ theme }) => theme.palette.text.primary};
`;

export const Title = styled(Typography).attrs({
  variant: 'h4',
})`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const MobileTitle = styled(Typography).attrs({
  variant: 'h5',
})`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Subtitle = styled.span`
  font-size: 1.75rem;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
`;

export const SmallTitle = styled(Typography).attrs({
  variant: 'h6',
})`
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const ErrorMessage = styled(Text)`
  color: ${({ theme }) => theme.palette.error.main};
  margin: 0.5em 0;
`;

export const HiddenInput = styled.input`
  visibility: collapse;
  position: absolute;
`;

export const StickySteps = styled.div`
  bottom: 0;
  width: 100%;
  z-index: 100;
  position: sticky;
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  background: ${({ theme }) => theme.palette.background.default};
`;

export const StickyStepsContent = styled(Grid).attrs({
  container: true,
  item: true,
})`
  gap: 2rem;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-end;

`;

export const BaseButtonCSS = css`
  border-radius: 50px;
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContainedButton = styled(motion(MUIButton)).attrs({
  whileTap: {
    scale: 0.9,
    opacity: 0.9,
  },
  variant: 'contained',
  disableElevation: true,
})`
  ${BaseButtonCSS}
  padding: 0.5rem 1.5rem;
`;

export const ContainedLoadingButton = styled(motion(LoadingButton)).attrs({
  whileTap: {
    scale: 0.9,
    opacity: 0.9,
  },
  variant: 'contained',
  disableElevation: true,
})`
  ${BaseButtonCSS}
  padding: 0.5rem 1.5rem;
`;

export const OutlinedButton = styled(motion(MUIButton)).attrs({
  whileTap: {
    scale: 0.9,
    opacity: 0.9,
  },
  variant: 'outlined',
  disableElevation: true,
})`
  ${BaseButtonCSS}
  display: flex;
  border: 2px solid;
  padding: 0.5rem 1rem;

  &:hover {
    border: 2px solid;
  }
`;

export const NeutralOutlinedButton = styled(OutlinedButton)`
  border: 2px solid #eaeaec;
  color: black;

  &:hover {
    border-width: 2px;
    border-color: #eaeaec;
  }
`;

export const TextButton = styled(MUIButton).attrs({
  variant: 'text',
  disableRipple: true,
})`
  padding: 0;
  ${BaseButtonCSS}
  border-radius: 0;
  user-select: text;
  text-align: center;
  text-decoration: underline;

  &:hover {
    background-color: transparent;
    text-decoration: none;
  }
`;

export const FormGrid = styled(Grid)`
  padding: 1rem 0;
`;

export const FormLabel = styled(Text)`
  font-size: 16px;
  font-weight: 600;
`;

export const FormDescription = styled(Text)`
  font-size: 13px;
  opacity: 0.7;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Separator = styled.div`
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.palette.divider};
  border-radius: 2px;
  padding: 0 1rem;
  margin: 1rem 0;
`;

export const RoundedSelect = styled(Select).attrs({
  variant: 'outlined',
  IconComponent: GridExpandMoreIcon,
})`
  height: 50px;
  margin: 10px 0;
  padding: 0 20px;
  min-width: 200px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.secondary.main};

  & .${selectClasses.icon} {
    color: ${({ theme }) => theme.palette.secondary.main};
    margin-right: 1rem;
  }

  & .${outlinedInputClasses.notchedOutline},
  &.${outlinedInputClasses.focused} .${outlinedInputClasses.notchedOutline},
  &:hover .${outlinedInputClasses.notchedOutline} {
    border-radius: 2rem;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.secondary.main};
    border: 2px solid ${({ theme }) => theme.palette.secondary.main};
  }
`;

export const DeleteButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0 2rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.6;
`;

export const InfoIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2rem;
  color: ${({ theme }) => theme.palette.text.secondary};
  opacity: 0.6;
`;

export const RoundCircleBackground = styled.div`
  background-color: #c8d4d4;
  border-radius: 50%;
  display: inline-block;
  width: 80px;
  height: 80px;
  padding: 10px;
`;

export const RoundCircleImage = styled.img.attrs({
  alt: '',
})`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const PreviewText = styled.div`
  width: 95%;
  align-self: center;
  border-radius: 10px;
  background: ${({ theme }) => {
    const background = theme.palette.background as AppTypeBackground;
    return background.dark;
  }};
  opacity: 0.5;
  padding: 6px 10px;
  font-size: 14px;
`;

interface BreadcrumbProps {
  $isClickable?: boolean;
}

export const Breadcrumb = styled(Typography)<BreadcrumbProps>`
  font-weight: bold;

  ${({ $isClickable }) => $isClickable && `
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  `}
`;

export const BreadcrumbsContainer = styled.div`
  margin-bottom: 20px;
`;

export const ResponsiveCloudImg = styled(AdvancedImage).attrs({
  plugins: [responsive({ steps: [400, 800, 1200] })],
})`
  user-select: auto;
`;
