import Settings from './Settings';

const settings: Settings = {
  appUrl: 'https://app.movon.vip',
  cloudinary: {
    cloudName: 'novom-networks',
    projectName: 'MovOnVIP',
  },
  displayUrl: 'https://display.movon.vip',
  mapboxToken: 'pk.eyJ1Ijoibm92b20taW50ZXJhY3RpdmUiLCJhIjoiY2x2MnFzaXY5MGt2YzJscGM5MG15eGJmOCJ9.zHktKgLYVCQCDuKOVuf5jQ',
  mapsApiKey: 'AIzaSyCKaYhYCVXoJnlp-QLStQsoItEgt7YMy88',
  graphql: {
    url: 'https://data.movon.vip/graphql',
  },
  logLevel: 'DEBUG',
  requireMovonValidationForPublish: false,
  support: {
    en: 'https://support.movon.vip/en/hc/3673856758',
    es: 'https://support.movon.vip/es/hc/3673856758',
    fr: 'https://support.movon.vip/fr/hc/3673856758',
  },
  urlShortener: {
    rootUrl: 'https://go.movon.vip/',
  },
};

export default settings;
