import {
  AppBar as MUIAppBar,
  Button,
  Menu,
  Skeleton,
} from '@mui/material';
import styled from 'styled-components';

export const Layout = styled.main`
  margin-top: ${({ theme }) => theme.mixins.toolbar.minHeight}px;
  height: ${({ theme }) => `calc(100svh - ${theme.mixins.toolbar.minHeight}px)`};
  display: flex;
`;

export const MainLayout = styled.div`
  overflow: auto;
  height: 100%;
  width: 100%;
  display: flex;
`;

export const AppBar = styled(MUIAppBar)`
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const AppLogoContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
  }
`;

export const AppLogo = styled.img.attrs({
  alt: 'MovOn.VIP',
})`
  width: 90px;
  height: auto;
  user-select: none;
`;

export const AvatarButton = styled(Button).attrs({
  disableRipple: true,
  variant: 'text',
})`
  text-transform: none;
  color: inherit;
`;

export const AvatarSkeletonText = styled(Skeleton).attrs({
  variant: 'text',
})`
  margin: 0 0.5rem;
`;

export const ChevronIcon = styled.div`
  margin-left: 0.5rem;
  color: ${({ theme }) => theme.palette.primary.light};
`;

export const AvatarMenu = styled(Menu).attrs({
  id: 'menu-avatar',
  keepMounted: true,
  anchorOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'right',
  },
})`
  margin-top: 45px;
`;
