import { useInfiniteQuery } from '@tanstack/react-query';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import useValidateRole from 'lib/access/useValidateRole';
import { useGetAllEventWaitingLinesLazyQuery } from 'lib/graphql/graphql';
import useIsMobile from 'lib/useIsMobile';
import useEventDocumentTitle from 'modules/Events/useEventDocumentTitle';
import useEvent from 'providers/event/useEvent';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'wouter';

import EventOperationsDesktop from './EventOperations.Desktop';
import EventOperationsMobile from './EventOperations.Mobile';

function EventOperations() {
  useEventDocumentTitle('operations');
  const event = useEvent();
  const [getAllWaitingLinesQuery] = useGetAllEventWaitingLinesLazyQuery();
  const [, setLocation] = useLocation();

  const [searchValue, setSearchValue] = useState<string>('');
  const isMobile = useIsMobile();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['waitingLines'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllWaitingLinesQuery({
        fetchPolicy: 'network-only',
        variables: { eventId: event.id, start: pageParam },
      });
      return result.data;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage) => lastPage?.getAllEventWaitingLines?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const waitingLines = useMemo(() => {
    if (data && !isLoading) {
      if (!data.pages[0]?.getAllEventWaitingLines) {
        return [];
      }
      const tempWaitingLines = data?.pages.flatMap(
        (page) => page?.getAllEventWaitingLines.waitingLines || [],
      ) || [];
      return tempWaitingLines;
    }
    return [];
  }, [isLoading, data]);

  const validateRole = useValidateRole();

  useEffect(() => {
    if (data?.pages[0]?.getAllEventWaitingLines && !isLoading) {
      if (waitingLines.length === 0) {
        if (validateRole('ADMIN')) {
          setLocation('/waitingLines/create');
        } else {
          setLocation('/waitingLines');
        }
      }
    }
  });

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  if (isLoading) return <CenteredSpinner />;
  if (error) return <div>TODO</div>;

  if (isMobile) {
    return (
      <EventOperationsMobile
        waitingLines={waitingLines}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        handleWaitingLinesRefetch={handleRefetch}
      />
    );
  }

  return (
    <EventOperationsDesktop
      waitingLines={waitingLines}
      searchValue={searchValue}
      setSearchValue={setSearchValue}
      handleWaitingLinesRefetch={handleRefetch}
    />
  );
}

export default EventOperations;
