import {
  Tab,
  tabClasses,
  Tabs,
  tabsClasses,
} from '@mui/material';
import styled from 'styled-components';

export const StyledTabs = styled(Tabs)`
  border-bottom: 2px solid ${({ theme }) => theme.palette.divider};
  width: 100%;

  & .${tabClasses.root} {
    text-transform: none;
    font-size: 1rem;
    font-weight: 500;
    color: ${({ theme }) => theme.palette.text.primary};
    padding-bottom: 1.1em;
  }

  & .${tabsClasses.indicator} {
    display: flex;
    justify-content: center;
    background-color: transparent;
  }
`;

export const IndicatorSpan = styled.span`
  height: 0.5em;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  transform: translateY(-0.3em);
`;

interface TabProps {
  $hasError?: boolean;
}

export const CustomTab = styled(Tab)<TabProps>`
  &.${tabClasses.root} {
    ${({ $hasError, theme }) => $hasError && `
      color: ${theme.palette.error.main};
      background-color: rgba(255, 0, 0, 0.2);
    `}
  }
`;

interface TabLabelProps {
  $hasError?: boolean;
}

export const TabLabel = styled.span<TabLabelProps>`
  display: flex;
  color: inherit;

  ${({ $hasError, theme }) => $hasError && `
    color: ${theme.palette.error.main};
  `}
`;
