import { Grid, MenuItem, SelectChangeEvent } from '@mui/material';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { Languages } from 'lib/i18n/i18n';
import WaitingLineOperatingCard from 'modules/Events/EventWaitingLines/WaitingLineOperatingCard/WaitingLineOperatingCard';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { FullWidthSelect, SelectContainer } from '../../styles';

interface EventOperationsMobileProps {
  searchValue: string;
  waitingLines: EventWaitingLine[];
  setSearchValue: (value: string) => void;
  handleWaitingLinesRefetch: () => void | Promise<void>;
}

export default function EventOperationsMobile(props: EventOperationsMobileProps) {
  const {
    searchValue,
    waitingLines,
    setSearchValue,
    handleWaitingLinesRefetch,
  } = props;

  const event = useEvent();
  const { language } = useContext(LanguageContext);

  const [
    selectedWaitingLineId,
    setSelectedWaitingLineId,
  ] = useState<string | null>(waitingLines[0]?.id || null);
  const [waitingLine, setWaitingLine] = useState<EventWaitingLine | null>(waitingLines[0] || null);

  const handleWaitingLineChange = useCallback((e: SelectChangeEvent<unknown>) => {
    setSelectedWaitingLineId(e.target.value as string);
  }, [setSelectedWaitingLineId]);

  useEffect(() => {
    const selectedWaitingLine = waitingLines.find((q) => q.id === selectedWaitingLineId);
    setWaitingLine(selectedWaitingLine || null);
  }, [waitingLines, selectedWaitingLineId, setWaitingLine]);

  const eventLanguage = event.languages[0] as Languages;

  return (
    <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
      <Grid container direction="column" gap={1} marginTop="1rem" marginBottom="1rem" alignItems="center">
        <SelectContainer>
          <FullWidthSelect value={waitingLine?.id} onChange={handleWaitingLineChange}>
            {waitingLines.map((waitingLineOption) => (
              <MenuItem key={waitingLineOption.id} value={waitingLineOption.id}>
                {waitingLineOption.name[language] || waitingLineOption.name[eventLanguage] || ''}
              </MenuItem>
            ))}
          </FullWidthSelect>
        </SelectContainer>
        {waitingLine && (
          <WaitingLineOperatingCard
            waitingLine={waitingLine}
            key={waitingLine.id}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            isMobile
            handleWaitingLinesRefetch={handleWaitingLinesRefetch}
          />
        )}
      </Grid>
    </Grid>
  );
}
