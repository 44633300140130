import { Grid } from '@mui/material';
import { SubmitHandler, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ContainedLoadingButton } from 'styles/styles';
import { useLocation } from 'wouter';

interface FormButtonsProps {
  location?: string;
  submit: SubmitHandler<any>;
  noGrid?: boolean;
  saveTextId?: string;
}

function FormButtons(props: FormButtonsProps) {
  const {
    location,
    noGrid,
    saveTextId,
    submit,
  } = props;
  const { trigger, handleSubmit, formState } = useFormContext();
  const [, setLocation] = useLocation();

  const handleFinish = async (validates?: string | string[]) => {
    if (!formState.isSubmitting) {
      const isValid = await trigger(validates);
      if (!isValid) {
        return;
      }

      await handleSubmit(submit)();
      if (location) {
        setLocation(location);
      }
    }
  };

  const button = (
    <ContainedLoadingButton
      onClick={() => handleFinish()}
      color="secondary"
      loading={formState.isSubmitting}
    >
      <FormattedMessage id={saveTextId || 'save_changes'} />
    </ContainedLoadingButton>
  );

  return noGrid ? (button) : (
    <Grid container justifyContent="flex-end" gap={2} marginY={2}>
      {button}
    </Grid>
  );
}

export default FormButtons;
