import { TextField, TextFieldProps } from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormField, { FormFieldProps } from './FormField';
import { CharacterCount } from './styles';

type CustomMUITextFieldProps = Omit<TextFieldProps, 'onChange' | 'onBlur' | 'ref' | 'error' | 'required' | 'name'>;

export interface FormTextFieldProps extends FormFieldProps, CustomMUITextFieldProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  innerFieldLabel?: string
}

function FormTextField(props: FormTextFieldProps) {
  const {
    name,
    rules = {},
    helperTextId,
    labelId,
    required,
    innerFieldLabel,
    fieldDescription,
    ...textFieldProps
  } = props;
  const intl = useIntl();

  const { field, formState } = useController({ name, rules: { required, ...rules } });
  const error = useFieldFormError(name);

  return (
    <FormField
      name={name}
      helperTextId={helperTextId}
      labelId={labelId}
      required={required}
      fieldDescription={fieldDescription}
    >
      <TextField
        size="small"
        defaultValue={field.value || formState.defaultValues?.[name] || ''}
        fullWidth
        onChange={field.onChange}
        onBlur={field.onBlur}
        ref={field.ref}
        placeholder={innerFieldLabel ? intl.formatMessage({ id: innerFieldLabel }) : ''}
        error={Boolean(error?.type) || false}
        {...textFieldProps}
      />
      {rules.maxLength && (
        <CharacterCount>
          {`${field.value?.length || 0}/${rules.maxLength}`}
        </CharacterCount>
      )}
    </FormField>
  );
}

export default FormTextField;
