import { GetEventItem } from 'lib/graphql/entities/event/types';
import { EventStatus } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { createContext } from 'react';

export type IEventContext = Omit<NonNullable<GetEventItem>, 'startTime' | 'endTime'> & {
  startTime: DateTime;
  endTime: DateTime;
  hasWaitingLines: boolean;
  obnls: string[];
  refetchObnls: () => void;
};

export const DefaultEventContext: IEventContext = {
  id: '',
  name: {
    en: '',
    fr: '',
    es: '',
  },
  endTime: DateTime.now(),
  location: '',
  startTime: DateTime.now(),
  timezone: '',
  userId: '',
  status: EventStatus.Draft,
  organizationId: '',
  image: '',
  hasWaitingLines: false,
  obnls: [],
  refetchObnls: () => {},
  languages: [],
  allowObnlChoice: false,
};

const EventContext = createContext<IEventContext>(DefaultEventContext);

export default EventContext;
