import {
  Avatar as MUIAvatar,
  Card,
  Chip,
  Grid,
  Paper,
} from '@mui/material';
import styled from 'styled-components';

export const ProfileCard = styled(Card).attrs({
  variant: 'outlined',
})`
  min-width: 225px;
  max-width: 600px;
  margin: 1rem 0;
  padding: 12px;
`;

export const AvatarContainer = styled(Grid).attrs({
  item: true,
  xs: 12,
  sm: 4,
})`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const Avatar = styled(MUIAvatar)`
  background: ${({ theme }) => theme.palette.primary.light};
  width: 88px;
  height: 88px;
`;

export const FullWidthPaper = styled(Paper)`
  width: 100%;
`;

export const EndSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
  margin-right: 1rem;
`;

export const StyledChip = styled(Chip)`
  margin: 0.5rem 0;
`;
