import { PropsWithChildren } from 'react';

import Header from './Header';
import { Layout, MainLayout } from './styles';

function Navigation({ children }: PropsWithChildren) {
  return (
    <>
      <Header />
      <Layout>
        <MainLayout>
          {children}
        </MainLayout>
      </Layout>
    </>
  );
}

export default Navigation;
