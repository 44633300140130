import { createElement } from 'react';
import RouteForbidden from 'routes/RouteForbidden';
import {
  DefaultParams,
  Path,
  Route,
  RouteProps,
} from 'wouter';

import useValidateRole, { Roles } from './useValidateRole';

interface ProtectedRouteProps<
T extends DefaultParams | undefined = undefined,
RoutePath extends Path = Path,
> extends RouteProps<T, RoutePath> {
  requiredRole: Roles
}

function ProtectedRoute<
T extends DefaultParams | undefined = undefined,
RoutePath extends Path = Path,
>(props: ProtectedRouteProps<T, RoutePath>) {
  const {
    component,
    children,
    path,
    requiredRole,
    ...routeProps
  } = props;

  const validateRole = useValidateRole();

  const authorized = validateRole(requiredRole);

  return (
    <Route path={path} {...routeProps}>
      <>
        {authorized && (
          children || createElement(component || 'div')
        )}
        {!authorized && (
          <RouteForbidden />
        )}
      </>
    </Route>
  );
}

export default ProtectedRoute;
