import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DialogProps, IconButton } from '@mui/material';
import { PropsWithChildren } from 'react';

import { CornerCloseContainer, StyledDialog } from './styles';

interface CustomDialogProps extends DialogProps {
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'cornerCloseButton') => void
}

function CustomDialog(props: PropsWithChildren<CustomDialogProps>) {
  const { children, ...dialogProps } = props;

  return (
    <StyledDialog {...dialogProps}>
      {dialogProps.onClose && (
        <CornerCloseContainer>
          <IconButton onClick={(e) => dialogProps.onClose?.(e, 'cornerCloseButton')}>
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </CornerCloseContainer>
      )}
      {children}
    </StyledDialog>
  );
}

export default CustomDialog;
