import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import styled from 'styled-components';

export const ChevronButton = styled(Button)`
  margin: 2rem 2rem 2rem 0.5rem;
  color: ${({ theme }) => theme.palette.text.secondary};

  &:hover {
    background-color: transparent;
  }
`;

export const ChevronIcon = styled(FontAwesomeIcon)`
  font-size: small !important;
`;
