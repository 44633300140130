import useFeedback from 'components/Feedback/useFeedback';
import PageLayout from 'components/PageLayout';
import { OrganizationInput, useCreateOrganizationMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import useOrganization from 'providers/organization/useOrganization';
import { useMemo } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import OrganizationForm from './OrganizationForm';

function CreateOrganization() {
  useFormattedDocumentTitle('organization_create');
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const { setInvitationId, setOrganizationId } = useOrganization();

  const defaultValues = useMemo(() => ({
    name: '',
    description: '',
  }), []);

  const [createOrganization] = useCreateOrganizationMutation();

  const onSubmit: SubmitHandler<OrganizationInput> = async (formData) => {
    // TODO: Handle loading & errors

    const { data } = await createOrganization({
      variables: {
        organization: {
          name: formData.name,
          description: formData.description,
        },
      },
    });

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="create_organization_success" />),
      severity: 'success',
    });

    const { invitationId, organization } = data?.createOrganization || {};

    // Automatically set the organization in the context after creation
    if (organization && invitationId) {
      setInvitationId(invitationId);
      setOrganizationId(organization.id);
    }

    setLocation('~/');
  };

  return (
    <PageLayout>
      <OrganizationForm
        title="create"
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      />
    </PageLayout>
  );
}

export default CreateOrganization;
