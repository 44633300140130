import { Grid } from '@mui/material';
import {
  GridColDef,
  gridPageCountSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from '@mui/x-data-grid';
import { GetAllWaitingLineTicketsItem } from 'lib/graphql/entities/tickets/types';
import {
  WaitingLineType,
} from 'lib/graphql/graphql';
import { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import {
  BorderlessFooterDataGrid,
  WaitingLineListPagination,
} from '../../styles';
import {
  getActionsColumn,
  getCalledColumn,
  getDisplayIdColumn,
  getStatusColumn,
  getTransactionIdColumn,
} from './Columns/columns';

export type TicketRowItem = Omit<GetAllWaitingLineTicketsItem, 'displayId'> & { displayId: string, statusDisplay: string };

interface WaitingLineTicketListProps {
  tickets: TicketRowItem[];
  searchValue?: string;
  waitingLineType: WaitingLineType;
  handleRefetch: () => void | Promise<void>;
  isMobile?: boolean;
  isFreemium: boolean;
}

function CustomPagination() {
  const apiRef = useGridApiContext();
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const { setPage } = apiRef.current;
  return (
    <WaitingLineListPagination
      count={pageCount}
      onChange={(_, page) => {
        setPage(page - 1);
      }}
    />
  );
}

function getDesktopColumns(
  intl: ReturnType<typeof useIntl>,
  isFreemium: boolean,
  waitingLineType: WaitingLineType,
  handleRefetch: () => void | Promise<void>,
) {
  if (isFreemium) {
    return [
      getDisplayIdColumn(intl),
      getTransactionIdColumn(intl),
      getStatusColumn(intl),
      getCalledColumn(intl),
      getActionsColumn(waitingLineType, handleRefetch, false),
    ];
  }
  return [
    getDisplayIdColumn(intl),
    getTransactionIdColumn(intl),
    getStatusColumn(intl),
    getActionsColumn(waitingLineType, handleRefetch, false),
  ];
}

export default function WaitingLineTicketList(props: WaitingLineTicketListProps) {
  const {
    searchValue,
    waitingLineType,
    isMobile,
    isFreemium,
    tickets,
    handleRefetch,
  } = props;

  const intl = useIntl();
  const apiRef = useGridApiRef();

  useEffect(() => {
    apiRef.current.setQuickFilterValues(searchValue ? searchValue.split(' ') : []);
  }, [searchValue, apiRef]);

  const columns: GridColDef[] = useMemo(() => (
    isMobile ? [
      {
        ...getDisplayIdColumn(intl),
        flex: 0.5,
      },
      {
        ...getStatusColumn(intl),
        flex: 0.4,
      },
      {
        ...getActionsColumn(waitingLineType, handleRefetch, true),
        flex: 0.1,
      },
    ] : getDesktopColumns(intl, isFreemium, waitingLineType, handleRefetch)
  ), [isMobile, intl, waitingLineType, handleRefetch, isFreemium]);

  return (
    <Grid container bgcolor="white" gap={2} width="100%">
      <BorderlessFooterDataGrid
        apiRef={apiRef}
        autoHeight
        rows={tickets || []}
        rowHeight={isMobile ? 40 : 52}
        columnHeaderHeight={isMobile ? 40 : 52}
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        disableRowSelectionOnClick
        columns={columns}
        slots={{
          pagination: CustomPagination,
        }}
        slotProps={{
          toolbar: {
            csvOptions: {
              disableToolbarButton: true,
            },
            printOptions: { disableToolbarButton: true },
          },
        }}
        initialState={{
          pagination: {
            paginationModel: { pageSize: isMobile ? 5 : 25 },
          },
        }}
      />
    </Grid>
  );
}
