import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { Drawer, Grid } from '@mui/material';
import useIsMobile from 'lib/useIsMobile';
import { useState } from 'react';

import EventSideView from './EventSideView';
import { DrawerButton, DrawerIcon } from './styles';

interface SideViewManagerProps {
  children: React.ReactNode;
}

function SideViewManager(props: SideViewManagerProps) {
  const { children } = props;
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isMobile = useIsMobile();

  if (isMobile) {
    return (
      <>
        <Grid container>
          <DrawerButton onClick={() => setDrawerOpen(true)}>
            <DrawerIcon icon={faAngleRight} size="xl" />
          </DrawerButton>
          {children}
        </Grid>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <EventSideView
            isMobile={isMobile}
            setDrawerOpen={setDrawerOpen}
          />
        </Drawer>
      </>
    );
  }

  return (
    <EventSideView
      isMobile={isMobile}
      setDrawerOpen={setDrawerOpen}
    >
      {children}
    </EventSideView>
  );
}

export default SideViewManager;
