import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';

export const CreateWindowContainer = styled.div`
  height: auto;
  width: 550px;
  background: #ececec;
  border-radius: 20px;
  margin: 1rem 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CreateWindowGrid = styled(Grid)`
  overflow-x: auto;
  margin-bottom: 1rem;
`;

export const CreateWindowContent = styled(Grid)`
  display: flex;
  flex-grow: 1;
  text-align: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
`;

export const ImageContainer = styled.div`
  margin: 1rem 0 0.5rem;
`;

export const NewTitle = styled(Typography)`
  font-size: 20px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
  color: black;
`;

export const AddTitle = styled(Typography)`
  font-size: ${({ theme }) => theme.typography.body2.fontSize};
  color: black;
  width: 80%;
  text-align: center;
`;
