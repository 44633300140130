import { TitleContext } from 'providers/title/TitleContext';
import { useContext, useEffect, useState } from 'react';

function useDocumentTitle(title: string, loading = false) {
  const { popTitle, pushTitle, updateText } = useContext(TitleContext);

  const [id, setId] = useState<string | null>(null);

  useEffect(() => {
    const titleId = pushTitle();
    setId(titleId);
    return () => {
      popTitle(titleId);
    };
  }, [popTitle, pushTitle]);

  useEffect(() => {
    if (!loading && id) {
      updateText(id, `${title} - MovOn.VIP`);
    }
    return undefined;
  }, [id, loading, title, updateText]);
}

export default useDocumentTitle;
