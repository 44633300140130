import { Grid, GridProps, RegularBreakpoints } from '@mui/material';
import { PropsWithChildren } from 'react';

import { Section, SpaceBetweenSection } from './styles';

interface ListCardSectionProps extends GridProps, RegularBreakpoints {
  className?: string
  isMobile?: boolean
  isCentered?: boolean
  isRight?: boolean
  isLeft?: boolean
}

function ListCardSection(props: PropsWithChildren<ListCardSectionProps>) {
  const {
    children,
    isRight,
    isLeft,
    isMobile,
    isCentered,
    ...gridProps
  } = props;

  return (
    <Grid item {...gridProps}>
      {isMobile ? (
        <SpaceBetweenSection $isCentered={isCentered}>
          {children}
        </SpaceBetweenSection>
      ) : (
        <Section
          $isCentered={isCentered}
          $isRight={isRight}
          $isLeft={isLeft}
        >
          {children}
        </Section>
      )}
    </Grid>
  );
}

export default ListCardSection;
