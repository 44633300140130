import { Grid } from '@mui/material';
import styled from 'styled-components';

export const LimitDesktopWidth = styled(Grid)`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding-top: 0.5rem;


  @media only screen and (width > 1200px) {
    width: 20vw;
  }
`;

export const FullPage = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`;

export const ScannerContainer = styled(Grid)<{ $boxBorderLength?: number }>`
  aspect-ratio: 1;
  width: 80%;

  & svg {
    overflow: visible;
    border-width: 3em !important;

    :nth-child(1) {
      d: ${({ $boxBorderLength }) => (`path("M ${$boxBorderLength ?? 23} 0 L 0 0 L 0 ${$boxBorderLength ?? 23}")`)};
      transform: translate(-1px, -1px);
    }

    :nth-child(2) {
      d: ${({ $boxBorderLength }) => (`path("M 0 ${100 - ($boxBorderLength ?? 23)} L 0 100 L ${$boxBorderLength ?? 23} 100")`)};
      transform: translate(-1px, 1px);
    }

    :nth-child(3) {
      d: ${({ $boxBorderLength }) => (`path("M ${100 - ($boxBorderLength ?? 23)} 100 L 100 100 L 100 ${100 - ($boxBorderLength ?? 23)}")`)};
      transform: translate(1px, 1px);
    }

    :nth-child(4) {
      d: ${({ $boxBorderLength }) => (`path("M 100 ${$boxBorderLength ?? 23} L 100 0 L ${100 - ($boxBorderLength ?? 23)} 0")`)};
      transform: translate(1px, -1px);
    }
  }

  & path {
    stroke: ${({ theme }) => theme.palette.secondary.main};
    stroke-width: 2;
  }

  @media screen and (width < 600px) {
    width: 60%;
  }
`;

export const ItemPassInfo = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1em;
  font-weight: ${({ theme }) => theme.typography.fontWeightLight};
  align-items: center;
`;
