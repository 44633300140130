import { outlinedInputClasses, Typography } from '@mui/material';
import FormTextField from 'components/FormFields/FormTextField';
import styled from 'styled-components';
import { OutlinedButton } from 'styles/styles';

export const SelectorButton = styled(OutlinedButton)`
  gap: 8px;
  text-transform: capitalize;
  font-weight: bold;
`;

export const StatusLabel = styled(Typography)`
  font-size: 16px;
  font-weight: ${({ theme }) => theme.typography.fontWeightBold};
`;

export const Section = styled.div`
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.text.disabled};
`;

export const ExplanationLabel = styled.p`
  margin: 0;
  font-weight: bold;
`;

export const StatusExplanationField = styled(FormTextField)`
  & .${outlinedInputClasses.input} {
    padding: 8px 0;
  }
`;
