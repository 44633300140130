import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import {
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { RouterMiddlewareContext } from './InterceptableRouter';

const DEFAULT_ACTIONS = {
  accept: () => {},
  deny: () => {},
};

function UnsavedFormQuitConfirmation() {
  const { addMiddleware, removeMiddleware } = useContext(RouterMiddlewareContext);
  const { formState } = useFormContext();

  const { isDirty, isSubmitSuccessful, isSubmitting } = formState;

  const [open, setOpen] = useState(false);
  const [actions, setActions] = useState(DEFAULT_ACTIONS);

  const shouldPreventQuit = isDirty && !isSubmitSuccessful && !isSubmitting;

  useEffect(() => {
    if (shouldPreventQuit) {
      const middleware = addMiddleware(() => (
        new Promise<boolean>((resolve) => {
          setOpen(true);
          setActions({
            accept: () => resolve(true),
            deny: () => {
              resolve(false);
              setOpen(false);
              setActions(DEFAULT_ACTIONS);
            },
          });
        })
      ));
      return () => {
        removeMiddleware(middleware);
      };
    }
    return undefined;
  }, [addMiddleware, shouldPreventQuit, removeMiddleware]);

  const handleBackButtonEvent = useCallback((e: PopStateEvent) => {
    e.preventDefault();
    setOpen(true);
    setActions({
      accept: () => {
        window.removeEventListener('popstate', handleBackButtonEvent);
        // -2 because we pushed a dummy entry before
        window.history.go(-2);
      },
      deny: () => {
        setOpen(false);
        setActions(DEFAULT_ACTIONS);
      },
    });
    // eslint-disable-next-line no-restricted-globals
    history.pushState(null, document.title, location.href);
  }, []);

  const handleBeforeUnloadEvent = useCallback((e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = ''; // Chrome requires returnValue to be set
  }, []);

  useEffect(() => {
    // Push a "dummy" entry onto the history stack before we start listening for 'popstate' events
    if (shouldPreventQuit) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(null, document.title, location.href);

      window.addEventListener('popstate', handleBackButtonEvent);
      window.addEventListener('beforeunload', handleBeforeUnloadEvent);
      return () => {
        window.removeEventListener('popstate', handleBackButtonEvent);
        window.removeEventListener('beforeunload', handleBeforeUnloadEvent);
      };
    }
    return undefined;
  }, [handleBackButtonEvent, handleBeforeUnloadEvent, shouldPreventQuit]);

  return (
    <ConfirmModal
      open={open}
      title="unsaved_form_quit_confirmation_title"
      onClose={actions.deny}
      closeText="general_no"
      onConfirm={actions.accept}
      confirmText="general_yes"
    >
      <FormattedMessage id="unsaved_form_quit_confirmation_body" />
    </ConfirmModal>
  );
}

export default UnsavedFormQuitConfirmation;
