import { ChipProps, Typography } from '@mui/material';
import { EventStatus } from 'lib/graphql/graphql';
import { FormattedMessage } from 'react-intl';

import { StatusContainer, StatusLabel } from '../../modules/Events/styles';

interface EventStatusChipProps {
  status: EventStatus
}

const statusColor: { [key in EventStatus] : ChipProps['color'] | 'text.secondary' } = {
  [EventStatus.Draft]: 'primary',
  [EventStatus.Hidden]: 'text.secondary',
  [EventStatus.Published]: 'secondary',
};

function EventStatusChip(props: EventStatusChipProps) {
  const { status } = props;
  return (
    <StatusContainer>
      <Typography color="textSecondary">
        <FormattedMessage id="status" />
      </Typography>
      <StatusLabel color={statusColor[status]}>
        <FormattedMessage id={`event_status_${status.toLocaleLowerCase()}`} />
      </StatusLabel>
    </StatusContainer>
  );
}

export default EventStatusChip;
