import { Grid } from '@mui/material';
import { SDG_ICONS } from 'lib/constants/icons';
import { FormattedMessage } from 'react-intl';

import {
  CauseCardContainer,
  CauseCardDescription,
  CauseCardIcon,
  CauseCardTitle,
} from '../Events/styles';

interface CauseCardProps {
  title: string;
  isActive: boolean;
  onClick: () => void;
}

function CauseCard(props: CauseCardProps) {
  const { title, isActive, onClick } = props;

  return (
    <CauseCardContainer
      onClick={onClick}
      $isActive={isActive}
    >
      <Grid container alignItems="center" margin={2}>
        <Grid item p={1}>
          <CauseCardIcon icon={SDG_ICONS[title]} size="xl" />
        </Grid>
        <Grid item xs marginLeft="8px">
          <Grid container direction="column">
            <Grid item>
              <CauseCardTitle>
                <FormattedMessage id={`obnl_${title}`} />
              </CauseCardTitle>
            </Grid>
            <Grid item>
              <CauseCardDescription>
                <FormattedMessage id={`obnl_${title}_description`} />
              </CauseCardDescription>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </CauseCardContainer>
  );
}

export default CauseCard;
