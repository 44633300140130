import { ApolloError } from '@apollo/client';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import useFeedback from 'components/Feedback/useFeedback';
import FormPasswordField from 'components/FormFields/FormPasswordField';
import { ChangePasswordMutationVariables, useChangePasswordMutation } from 'lib/graphql/graphql';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

type ChangePasswordFormData = ChangePasswordMutationVariables & {
  confirmPassword: string
};

interface ChangePasswordFormProps {
  onClose: () => void
}

function ChangePasswordForm(props: ChangePasswordFormProps) {
  const { onClose } = props;

  const feedback = useFeedback();
  const formControl = useForm<ChangePasswordFormData>({ mode: 'onTouched' });
  const {
    handleSubmit,
    watch,
  } = formControl;

  const passwordWatch = watch('newPassword');
  const [changePassword] = useChangePasswordMutation();

  const onSubmit: SubmitHandler<ChangePasswordFormData> = useCallback(async (formData) => {
    try {
      await changePassword({
        variables: {
          password: formData.password,
          newPassword: formData.newPassword,
        },
      });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: <FormattedMessage id="change_password_success" />,
        severity: 'success',
      });
      onClose();
    } catch (err) {
      const error = err as ApolloError;
      if (error.message === 'Incorrect password') {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: <FormattedMessage id="change_password_incorrect_password" />,
          severity: 'error',
        });
      } else {
        console.log(error);
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: <FormattedMessage id="change_password_error" />,
          severity: 'error',
        });
      }
    }
  }, [changePassword, feedback, onClose]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formControl}>
        <DialogTitle>
          <FormattedMessage id="change_password_title" />
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormPasswordField
                name="password"
                labelId="password"
                autoComplete="password"
                innerFieldLabel="enter_password"
                size="medium"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormPasswordField
                name="newPassword"
                labelId="new_password"
                autoComplete="new-password"
                innerFieldLabel="enter_new_password"
                rules={{ validate: (value: string) => value.length >= 8 || 'password_too_short' }}
                size="medium"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <FormPasswordField
                name="confirmPassword"
                labelId="confirm_password"
                innerFieldLabel="enter_confirm_password"
                rules={{ validate: (value: string) => (value) === passwordWatch || 'passwords_do_not_match' }}
                size="medium"
                required
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <ContainedButton type="button" onClick={onClose}>
            <FormattedMessage id="cancel" />
          </ContainedButton>
          <ContainedButton type="submit" color="secondary">
            <FormattedMessage id="save_changes" />
          </ContainedButton>
        </DialogActions>
      </FormProvider>
    </form>
  );
}

export default ChangePasswordForm;
