import { useInfiniteQuery } from '@tanstack/react-query';
import goldTicketIcon from 'assets/images/waitingLine/GoldTicketIcon.svg';
import silverTicketIcon from 'assets/images/waitingLine/SilverTicketIcon.svg';
import useFeedback from 'components/Feedback/useFeedback';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import {
  TicketStatus,
  useGetAllWaitingLineTicketsLazyQuery,
  useMoveTicketsToCalledQueueMutation,
  WaitingLineType,
} from 'lib/graphql/graphql';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { TicketRowItem } from '../components/WaitingLineTicketList/WaitingLineTicketList';
import WaitingLineOperatingCardDesktop from './WaitingLineOperatingCard.Desktop';
import WaitingLineOperatingCardMobile from './WaitingLineOperatingCard.Mobile';

interface WaitingLineOperatingCardProps {
  waitingLine: EventWaitingLine
  searchValue?: string
  isMobile?: boolean
  setSearchValue: (value: string) => void
  handleWaitingLinesRefetch: () => void | Promise<void>
}

function WaitingLineOperatingCard(props: WaitingLineOperatingCardProps) {
  const {
    waitingLine,
    searchValue,
    setSearchValue,
    isMobile,
    handleWaitingLinesRefetch,
  } = props;

  const [showOperations, setShowOperations] = useState(false);
  const feedback = useFeedback();

  const [moveTicketsToCalledQueue] = useMoveTicketsToCalledQueueMutation({
    variables: {
      id: waitingLine.id,
    },
  });

  const ticketIcon = waitingLine.waitingLineType === WaitingLineType.Gold
    ? goldTicketIcon : silverTicketIcon;
  const intl = useIntl();

  const [getWaitingLineTickets] = useGetAllWaitingLineTicketsLazyQuery();

  const {
    data,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useInfiniteQuery({
    getNextPageParam: (lastPage) => lastPage?.lastEvaluated,
    initialPageParam: undefined,
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getWaitingLineTickets({
        fetchPolicy: 'network-only',
        variables: {
          waitingLineId: waitingLine.id,
          start: pageParam,
        },
      });
      return result.data?.getAllWaitingLineTickets;
    },
    queryKey: ['getWaitingLineTickets', { waitingLineId: waitingLine.id }],
  });

  useEffect(() => {
    if (hasNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage]);

  const tickets = useMemo<TicketRowItem[] | undefined>(() => data?.pages.flatMap((page) => (
    page?.tickets.map((item) => ({
      ...item,
      displayId: item.displayId.toString().padStart(5, '0'),
      statusDisplay: intl.formatMessage({ id: `ticket_status_${item.status}` }),
    })) || [])), [data, intl]);

  const handleRefetch = useCallback(() => {
    refetch();
  }, [refetch]);

  const onCallTickets = async () => {
    const uncalledTickets = tickets!.some((ticket) => ticket.status === TicketStatus.Activated);
    if (uncalledTickets) {
      try {
        await moveTicketsToCalledQueue();
        handleRefetch();

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="operator_tickets_called" />),
          severity: 'success',
        });
      } catch (error) {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="operator_tickets_error" />),
          severity: 'error',
        });
      }
    } else {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="operator_tickets_no_ticket" />),
        severity: 'error',
      });
    }
  };

  if (isMobile) {
    return (
      <WaitingLineOperatingCardMobile
        ticketIcon={ticketIcon}
        waitingLine={waitingLine}
        tickets={tickets || []}
        onRefetch={handleRefetch}
        searchValue={searchValue || ''}
        setSearchValue={setSearchValue}
        handleWaitingLinesRefetch={handleWaitingLinesRefetch}
        onCallTickets={onCallTickets}
      />
    );
  }

  return (
    <WaitingLineOperatingCardDesktop
      showOperations={showOperations}
      ticketIcon={ticketIcon}
      waitingLine={waitingLine}
      tickets={tickets || []}
      onRefetch={handleRefetch}
      setShowOperations={setShowOperations}
      searchValue={searchValue}
      handleWaitingLinesRefetch={handleWaitingLinesRefetch}
      onCallTickets={onCallTickets}
    />
  );
}

export default WaitingLineOperatingCard;
