import { Typography } from '@mui/material';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import { Languages } from 'lib/i18n/i18n';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useEventQrReader } from '../../provider';
import { ItemPassInfo } from '../../styles';
import {
  LeftInWaitingLine,
  QRInfoContainer,
  QRInfoRow,
  RecentScanItem,
  RecentScanList,
  RecentScansDisplay,
} from './styles';

interface LastReadQrsProps {
  scanning: boolean;
}

function LastReadQrs(props: LastReadQrsProps) {
  const { scanning } = props;
  const { recentScans } = useEventQrReader();
  const intl = useIntl();
  const event = useEvent();
  const { language } = useContext(LanguageContext);

  return (
    <QRInfoContainer>
      <QRInfoRow>
        {scanning ? (
          <CenteredSpinner />
        ) : (
          <Typography>
            <FormattedMessage id="qr_reader_instructions" />
          </Typography>
        )}
      </QRInfoRow>
      <RecentScansDisplay>
        <Typography fontWeight="bold">
          <FormattedMessage id="qr_reader_recent_scans" />
        </Typography>
        <RecentScanList>
          {recentScans.toReversed().map((scan) => (
            <RecentScanItem>
              <div>
                {scan.waitingLineName[language]
                ?? scan.waitingLineName[event.languages[0] as Languages]}
              </div>
              <ItemPassInfo>
                <div>
                  <FormattedMessage id="qr_reader_scanned_passes" values={{ nbr: scan.tickets }} />
                </div>
                <LeftInWaitingLine label={intl.formatMessage({ id: 'qr_reader_pass_left' }, { nbr: scan.remainingTickets })} />
              </ItemPassInfo>
            </RecentScanItem>
          ))}
        </RecentScanList>
      </RecentScansDisplay>
    </QRInfoContainer>
  );
}

export default LastReadQrs;
