import {
  Card,
  CardContent,
  Chip,
  Grid,
  Tooltip,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { currenciesMap } from 'lib/constants/currencies';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Stripe from 'stripe';
import { SmallTitle } from 'styles/styles';

import BankTransfersPicker from './Pickers/BankTransfersPicker';
import { PayoutCurrency, Payouts } from './styles';

interface BankTransfersCardProps {
  payouts: Stripe.Payout[];
}

function BankTransfersCard(props: BankTransfersCardProps) {
  const { payouts } = props;
  const intl = useIntl();

  const columns: GridColDef[] = useMemo(() => [
    {
      headerName: intl.formatMessage({ id: 'payout_created_date' }),
      field: 'created',
      flex: 1,
      renderCell: (params) => {
        const date = DateTime.fromSeconds(params.value).setZone('UTC').startOf('day');
        const now = DateTime.now().setZone('UTC');
        const diff = Math.ceil(date.diff(now, 'days').days);
        let relativeTime = '';

        if (diff === -1) {
          relativeTime = intl.formatMessage({ id: 'payout_yesterday' });
        } else if (diff === 0) {
          relativeTime = intl.formatMessage({ id: 'payout_today' });
        }

        return (
          <Tooltip arrow title={relativeTime ? date.toFormat('D') : ''}>
            <span>{relativeTime || date.toFormat('D')}</span>
          </Tooltip>
        );
      },
    },
    {
      headerName: intl.formatMessage({ id: 'payout' }),
      field: 'id',
      flex: 1,
    },
    {
      headerName: intl.formatMessage({ id: 'status' }),
      field: 'status',
      flex: 1,
      renderCell: (params) => {
        const status = params.value;

        const statusColor: Record<string, 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning'> = {
          canceled: 'error',
          failed: 'error',
          in_transit: 'info',
          pending: 'warning',
          paid: 'success',
        };

        return (
          <Chip
            label={<FormattedMessage id={`payout_status_${status}`} />}
            color={statusColor[status]}
          />
        );
      },
    },
    {
      headerName: intl.formatMessage({ id: 'payout_arrival_date' }),
      field: 'arrival_date',
      flex: 2,
      renderCell: (params) => {
        const date = DateTime.fromSeconds(params.value).setZone('UTC');
        const now = DateTime.now().setZone('UTC');
        const diff = Math.ceil(date.diff(now, 'days').days);
        let relativeTime = '';

        if (diff === -1) {
          relativeTime = intl.formatMessage({ id: 'payout_yesterday' });
        } else if (diff === 0) {
          relativeTime = intl.formatMessage({ id: 'payout_today' });
        } else if (diff === 1) {
          relativeTime = intl.formatMessage({ id: 'payout_tomorrow' });
        } else if (diff > 1) {
          relativeTime = intl.formatMessage({ id: 'payout_in_days' }, { days: diff });
        }

        return (
          <Tooltip arrow title={relativeTime ? date.toFormat('D') : ''}>
            <span>{relativeTime || date.toFormat('D')}</span>
          </Tooltip>
        );
      },
    },
    {
      headerName: intl.formatMessage({ id: 'payout_amount' }),
      field: 'amount',
      flex: 2,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (_, row) => {
        const { amount } = row;
        return amount;
      },
      valueFormatter: (_, row) => {
        const amount = (row.amount / 100).toFixed(2);
        const currency = row.currency ? row.currency.toUpperCase() : '';
        const symbol = currenciesMap[currency].symbol || '$';
        return `${amount}${symbol} ${currency}`;
      },
    },
  ], [intl]);

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const [filteredPayouts, setFilteredPayouts] = useState<Stripe.Payout[]>([]);

  const handleFilteredPayoutsChange = (
    newPayouts: Stripe.Payout[],
  ) => { setFilteredPayouts(newPayouts); };

  const totalAmountsByCurrency = useMemo(() => {
    if (!filteredPayouts) {
      return {};
    }

    return filteredPayouts
      .filter(({ status }) => status !== 'canceled' && status !== 'failed')
      .reduce((acc, { amount, currency }) => {
        const upperCurrency = currency.toUpperCase();
        acc[upperCurrency] = (acc[upperCurrency] || 0) + amount;
        return acc;
      }, {} as Record<string, number>);
  }, [filteredPayouts]);

  return (
    <Card>
      <CardContent>
        <SmallTitle>
          <FormattedMessage id="payouts_to_bank_account" />
        </SmallTitle>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <BankTransfersPicker
              payouts={payouts}
              onPayoutsChange={handleFilteredPayoutsChange}
            />
          </Grid>
          <Grid item alignContent="center" ml="auto">
            {filteredPayouts && Object.keys(filteredPayouts).length > 0 ? (
              <Payouts>
                <FormattedMessage id="total_payouts" />
                {' '}
                {Object.entries(totalAmountsByCurrency).map(([currency, totalAmount]) => {
                  const { symbol = '$', decimalDigits = 2 } = currenciesMap[currency] || {};
                  return (
                    <PayoutCurrency key={currency}>
                      {`${(totalAmount / 100).toFixed(decimalDigits)}${symbol} ${currency}`}
                    </PayoutCurrency>
                  );
                })}
              </Payouts>
            ) : (
              <Payouts>
                <FormattedMessage id="filter_no_payout" />
              </Payouts>
            )}
          </Grid>
        </Grid>
        <DataGrid
          rows={filteredPayouts}
          columns={columns}
          pagination
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[10, 20, 50]}
        />
      </CardContent>
    </Card>
  );
}

export default BankTransfersCard;
