import { faDisplay } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Paper, Tooltip } from '@mui/material';
import { ListCardSection, ListCardTitle } from 'components/ListCard';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { TicketStatus } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import settings from 'lib/settings';
import { SearchBar } from 'modules/Events/EventOperator/components/EventOperations/styles';
import EventContext from 'providers/event/EventContext';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

import WaitingLineStatusSelector from '../components/WaitingLineStatusSelector';
import WaitingLineTicketList, { TicketRowItem } from '../components/WaitingLineTicketList/WaitingLineTicketList';
import {
  CenteredProgressTop,
  ColumnTickets,
  CurrentTicketsSold,
  LeftGrid,
  MobileListCard,
  MobileWaitingLineTitleContainer,
  NoBorderButton,
  ProgressBarContainer,
  ProgressText,
  TicketIcon,
  TicketsLeft,
  TicketsNumber,
  WaitingLineOperationsInfoContainer,
  WaitingLineTicketListContainer,
} from '../styles';

interface WaitingLineOperatingCardProps {
  ticketIcon: string;
  waitingLine: EventWaitingLine;
  searchValue: string;
  setSearchValue: (value: string) => void;
  tickets: TicketRowItem[];
  handleWaitingLinesRefetch: () => void | Promise<void>;
  onRefetch: () => void | Promise<void>;
  onCallTickets: () => void | Promise<void>;
}

function WaitingLineOperatingCardMobile(props: WaitingLineOperatingCardProps) {
  const {
    ticketIcon,
    waitingLine,
    searchValue,
    setSearchValue,
    tickets,
    handleWaitingLinesRefetch,
    onRefetch,
    onCallTickets,
  } = props;

  const event = useContext(EventContext);
  const { language } = useContext(LanguageContext);

  const scannedTickets = useMemo(() => (
    tickets.filter((ticket) => ticket.status === TicketStatus.Validated)
  ), [tickets]);

  const eventLanguage = event.languages[0] as Languages;

  return (
    <Paper elevation={4}>
      <MobileListCard spacing={2}>
        <ListCardSection isMobile sm>
          <MobileWaitingLineTitleContainer>
            <TicketIcon src={ticketIcon} />
            <ListCardTitle title={waitingLine.name[language] || waitingLine.name[eventLanguage] || ''} />
          </MobileWaitingLineTitleContainer>
          {waitingLine.isFreemium && (
            <Tooltip placement="top" arrow title={<FormattedMessage id="event_view_display" />}>
              <NoBorderButton
                onClick={() => window.open(`${settings.displayUrl}/${waitingLine.id}`)}
                color="secondary"
                size="small"
              >
                <FontAwesomeIcon icon={faDisplay} />
              </NoBorderButton>
            </Tooltip>
          )}
          <WaitingLineStatusSelector
            waitingLine={waitingLine}
            handleRefetch={handleWaitingLinesRefetch}
          />
        </ListCardSection>
        <ListCardSection>
          <WaitingLineOperationsInfoContainer>
            <Grid container>
              <Grid item xs={waitingLine.isFreemium ? 5 : 12}>
                <ColumnTickets>
                  <ProgressBarContainer>
                    <CenteredProgressTop>
                      <ProgressText>
                        <FormattedMessage id="waiting_line_tickets_scanned" />
                      </ProgressText>
                    </CenteredProgressTop>
                  </ProgressBarContainer>
                  <TicketsNumber>
                    <CurrentTicketsSold>
                      {scannedTickets.length}
                    </CurrentTicketsSold>
                    <TicketsLeft>
                      {` / ${tickets.length}`}
                    </TicketsLeft>
                  </TicketsNumber>
                </ColumnTickets>
              </Grid>
              {waitingLine.isFreemium && (
                <LeftGrid item xs={7}>
                  <ContainedButton
                    color="secondary"
                    onClick={onCallTickets}
                  >
                    <FormattedMessage id="operator_tickets_call" values={{ callSize: waitingLine.callSize }} />
                  </ContainedButton>
                </LeftGrid>
              )}
            </Grid>
          </WaitingLineOperationsInfoContainer>
        </ListCardSection>
        <ListCardSection>
          <WaitingLineTicketListContainer>
            <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
            <WaitingLineTicketList
              tickets={tickets}
              handleRefetch={onRefetch}
              searchValue={searchValue}
              waitingLineType={waitingLine.waitingLineType}
              isFreemium={waitingLine.isFreemium}
              isMobile
            />
          </WaitingLineTicketListContainer>
        </ListCardSection>
      </MobileListCard>
    </Paper>
  );
}

export default WaitingLineOperatingCardMobile;
