import { Card, CardContent, Grid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { currenciesMap } from 'lib/constants/currencies';
import { WaitingLine } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { DateTime } from 'luxon';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SmallTitle } from 'styles/styles';

import ObnlsBreakdownCard from './Cards/ObnlsBreakdownCard';
import SalesQueueCard from './Cards/SalesQueueCard';
import BalanceTransactionsPicker from './Pickers/BalanceTransactionsPicker';
import { BalanceCurrency, Balances } from './styles';

interface BalanceTransactionsCardProps {
  balanceTransactions: BalanceTransactionSplits[];
  waitingLines: WaitingLine[];
  defaultLocale: Languages;
}

function BalanceTransactionsCard(props: BalanceTransactionsCardProps) {
  const {
    balanceTransactions,
    waitingLines,
    defaultLocale,
  } = props;
  const intl = useIntl();
  const { language } = useContext(LanguageContext);

  const [filteredTransactions, setFilteredTransactions] = useState<BalanceTransactionSplits[]>([]);

  const handleFilteredTransactionsChange = (
    transactions: BalanceTransactionSplits[],
  ) => { setFilteredTransactions(transactions); };

  const currentBalances = useMemo(() => {
    const balances: Record<string, number> = {};
    filteredTransactions.forEach((transaction) => {
      const currency = transaction.currency.toUpperCase();
      if (!balances[currency]) {
        balances[currency] = 0;
      }
      balances[currency] += transaction.metadata.splits.organization;
    });
    return balances;
  }, [filteredTransactions]);

  const columns: GridColDef[] = useMemo(() => [
    {
      headerName: intl.formatMessage({ id: 'date' }),
      field: 'created',
      flex: 1,
      valueFormatter: (params) => DateTime.fromSeconds(params).setZone('UTC').setLocale(language).toFormat('D'),
    },
    {
      headerName: intl.formatMessage({ id: 'transaction' }),
      field: 'transactionId',
      flex: 2,
      valueGetter: (_, row) => {
        const { transactionId } = row.metadata;
        return transactionId;
      },
    },
    {
      headerName: intl.formatMessage({ id: 'waiting_line' }),
      field: 'metadata',
      flex: 1,
      valueGetter: (value) => {
        const { waitingLineId } = value;
        const waitingLine = waitingLines.find((line) => line.id === waitingLineId);
        if (!waitingLine) return '';
        return waitingLine.name[language] || waitingLine.name[defaultLocale];
      },
    },
    {
      headerName: intl.formatMessage({ id: 'sale_price' }),
      field: 'sale_price',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (_, row) => {
        const { amount } = row;
        return amount;
      },
      valueFormatter: (_, row) => {
        const amount = (row.amount / 100).toFixed(2);
        const currency = row.currency ? row.currency.toUpperCase() : '';
        const symbol = currenciesMap[currency].symbol || '$';
        return `${amount}${symbol} ${currency}`;
      },
    },
    {
      headerName: intl.formatMessage({ id: 'stripe_fees' }),
      field: 'stripe_fees',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (_, row) => {
        const { stripe: stripeFees } = row.metadata.splits;
        return stripeFees;
      },
      valueFormatter: (_, row) => {
        const { stripe: stripeFees } = row.metadata.splits;
        const amount = (stripeFees / 100).toFixed(2);
        const currency = row.currency ? row.currency.toUpperCase() : '';
        const symbol = currenciesMap[currency].symbol || '$';
        return `${amount}${symbol} ${currency}`;
      },
    },
    {
      headerName: intl.formatMessage({ id: 'taxes' }),
      field: 'taxes',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (_, row) => {
        const { taxes } = row.metadata.splits;
        return taxes;
      },
      valueFormatter: (_, row) => {
        const { taxes } = row.metadata.splits;
        const amount = (taxes / 100).toFixed(2);
        const currency = row.currency ? row.currency.toUpperCase() : '';
        const symbol = currenciesMap[currency].symbol || '$';
        return `${amount}${symbol} ${currency}`;
      },
    },
    {
      headerName: intl.formatMessage({ id: 'balance' }),
      field: 'balance',
      flex: 1,
      headerAlign: 'right',
      align: 'right',
      valueGetter: (_, row) => {
        const { organization } = row.metadata.splits;
        return organization;
      },
      renderCell: (params) => {
        const { organization } = params.row.metadata.splits;
        const amount = (organization / 100).toFixed(2);
        const currency = params.row.currency ? params.row.currency.toUpperCase() : '';
        const symbol = currenciesMap[currency].symbol || '$';
        return (
          <b>{`${amount}${symbol} ${currency}`}</b>
        );
      },
    },
  ], [defaultLocale, intl, language, waitingLines]);

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <SmallTitle>
            <FormattedMessage id="transactions" />
          </SmallTitle>
          <BalanceTransactionsPicker
            balanceTransactions={balanceTransactions}
            waitingLines={waitingLines}
            defaultLocale={defaultLocale}
            onTransactionsChange={handleFilteredTransactionsChange}
          />
          <Grid container gap={2} justifyContent="space-around">
            <Grid item>
              <ObnlsBreakdownCard balanceTransactions={filteredTransactions} />
            </Grid>
            <Grid item>
              <SalesQueueCard
                balanceTransactions={filteredTransactions}
                waitingLines={waitingLines}
                defaultLocale={defaultLocale}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" gap={2} alignItems="center">
            <Grid item ml="auto" p={2}>
              {currentBalances && Object.keys(currentBalances).length > 0 ? (
                <Balances>
                  <FormattedMessage id="your_balance" />
                  {' '}
                  {Object.entries(currentBalances).map(([currency, amount], index) => {
                    const symbol = currenciesMap[currency.toUpperCase()].symbol || '$';
                    return (
                      <BalanceCurrency key={currency}>
                        {`${(amount / 100).toFixed(2)}${symbol} ${currency}`}
                        {index < Object.entries(currentBalances).length - 1 && ' | '}
                      </BalanceCurrency>
                    );
                  })}
                </Balances>
              ) : (
                <FormattedMessage id="filter_no_balance" />
              )}
            </Grid>
          </Grid>
          <DataGrid
            rows={filteredTransactions}
            columns={columns}
            pagination
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            pageSizeOptions={[10, 20, 50]}
          />
        </CardContent>
      </Card>
    </Grid>
  );
}

export default BalanceTransactionsCard;
