import { createDonutChartOptions } from 'lib/agCharts/charts';
import { useGetAllObnlsQuery } from 'lib/graphql/graphql';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import ChartCard from '../ChartCard';

interface ObnlsBreakdownCardProps {
  balanceTransactions: BalanceTransactionSplits[];
}

function ObnlsBreakdownCard(props: ObnlsBreakdownCardProps) {
  const { balanceTransactions } = props;
  const intl = useIntl();

  const { data } = useGetAllObnlsQuery();
  const obnls = useMemo(() => data?.getAllObnls.obnls || [], [data]);

  const obnlsAmountData = useMemo(() => {
    const aggregatedData = balanceTransactions.reduce((acc: any, transfer: any) => {
      const { obnlId } = transfer.metadata;
      if (!acc[obnlId]) {
        acc[obnlId] = 0;
      }
      acc[obnlId] += 1;
      return acc;
    }, {});

    return Object.entries(aggregatedData).map(([obnlId, count]) => {
      const obnl = obnls.find((o) => o.id === obnlId);
      return {
        asset: obnl ? obnl.name : obnlId,
        amount: count,
      };
    });
  }, [balanceTransactions, obnls]);

  const obnlDonutOptions = createDonutChartOptions(obnlsAmountData, intl.locale, intl.formatMessage({ id: 'transactions' }));

  return (
    <ChartCard title="obnls_breakdown" chartOptions={obnlDonutOptions} />
  );
}

export default ObnlsBreakdownCard;
