import { FullPageLoader } from 'components/Authenticator/styles';
import { PropsWithChildren } from 'react';

import CenteredSpinner from './CenteredSpinner';

interface LoaderProps extends PropsWithChildren {
  className?: string;
  isLoading: boolean;
  isFullPage?: boolean;
  size?: number | string;
}

function Loader(props: LoaderProps) {
  const {
    children,
    className,
    isLoading,
    isFullPage = false,
    size,
  } = props;

  if (isLoading) {
    return isFullPage ? (
      <FullPageLoader>
        <CenteredSpinner className={className} size={size} />
      </FullPageLoader>
    ) : (
      <CenteredSpinner className={className} size={size} />
    );
  }

  return children;
}

export default Loader;
