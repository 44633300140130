import { Typography } from '@mui/material';
import styled from 'styled-components';

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StatusLabel = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
`;

export const EventOperatorMobileHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
