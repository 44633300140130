import { faEyeSlash, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import { GetAllEventsForOrganizationItem } from 'lib/graphql/entities/event/types';
import { EventStatus, useChangeEventVisibilityMutation } from 'lib/graphql/graphql';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteButton, InfoIcon } from 'styles/styles';

interface EventHiddenButtonProps {
  event: NonNullable<GetAllEventsForOrganizationItem>;
}

function EventHiddenButton(props: EventHiddenButtonProps) {
  const { event } = props;
  const { id, status } = event;

  const feedback = useFeedback();

  const [modalOpen, setModalOpen] = useState(false);

  const queryClient = useQueryClient();
  const [changeEventVisibility, { loading }] = useChangeEventVisibilityMutation();
  const onChange = async () => {
    try {
      await changeEventVisibility({ variables: { id } });
      queryClient.invalidateQueries({ queryKey: ['events'], exact: true });
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="change_visibility_success" />),
        severity: 'success',
      });
    } catch (error) {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="change_visibility_error" />),
        severity: 'error',
      });
    }
    setModalOpen(false);
  };

  return (
    <>
      {status === EventStatus.Published && (
        <DeleteButton onClick={() => setModalOpen(true)}>
          <FontAwesomeIcon icon={faEyeSlash} size="lg" />
        </DeleteButton>
      )}
      {status === EventStatus.Hidden && (
        <Tooltip placement="top" arrow title={<FormattedMessage id="event_publish_again" />}>
          <InfoIcon>
            <FontAwesomeIcon icon={faInfoCircle} size="lg" />
          </InfoIcon>
        </Tooltip>
      )}
      <ConfirmModal
        title="change_visibility_confirm_hidden"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={() => onChange()}
        closeText="cancel"
        confirmColor="secondary"
        confirmText="general_yes"
        loading={loading}
      >
        <FormattedMessage id="change_visibility_to_hidden" />
      </ConfirmModal>
    </>
  );
}

export default EventHiddenButton;
