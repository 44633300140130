import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import { useGetAllEventWaitingLinesQuery, useGetAllObnlsIdsForEventQuery, useGetEventQuery } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'wouter';

import EventContext, { DefaultEventContext, IEventContext } from './EventContext';

export interface EventProviderProps {
  children: JSX.Element
}

export default function EventProvider(props: EventProviderProps) {
  const { children } = props;
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const { eventId } = useParams<{ eventId: string }>();
  const { data, loading } = useGetEventQuery({
    variables: {
      id: eventId,
    },
  });

  const { data: waitingLinesData, loading: waitingLinesLoading } = useGetAllEventWaitingLinesQuery({
    variables: {
      eventId,
    },
  });

  const {
    data: obnlsData,
    loading: obnlsLoading,
    refetch: refetchObnls,
  } = useGetAllObnlsIdsForEventQuery({
    variables: {
      eventId,
    },
  });

  const contextValue = useMemo<IEventContext>(() => {
    if (data?.getEvent) {
      return {
        ...data?.getEvent!,
        startTime: DateTime.fromISO(data.getEvent.startTime, { zone: 'utc' }),
        endTime: DateTime.fromISO(data.getEvent.endTime, { zone: 'utc' }),
        hasWaitingLines: waitingLinesData
          ? !!waitingLinesData.getAllEventWaitingLines.waitingLines.length : false,
        obnls: obnlsData ? obnlsData.getAllObnlsIdsForEvent.obnls : [],
        refetchObnls,
      };
    }
    return DefaultEventContext;
  }, [data, obnlsData, waitingLinesData, refetchObnls]);

  if (!loading && !waitingLinesLoading && !obnlsLoading && (!data || !data.getEvent)) {
    setLocation('~/');

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="event_not_found" />),
      severity: 'error',
    });
  }

  return (
    <Loader isLoading={loading}>
      <EventContext.Provider value={contextValue}>
        {children}
      </EventContext.Provider>
    </Loader>
  );
}
