import { Typography } from '@mui/material';
import styled from 'styled-components';

export const CenteredContainer = styled.div`
  flex: 1;
  padding: 1rem;
  display: flex;
  overflow: hidden;
  flex-direction: column;
`;

export const TermsContainer = styled.div`
  overflow-y: auto;
  padding: 1rem 0;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
`;

export const TitleTerms = styled(Typography)`
  font-size: 1.5em;
  font-weight: bold;
`;

export const CopyrightContainer = styled.div`
  text-align: center;
`;

export const TermContainer = styled.div`
  text-align: left;
  margin-right: 1rem;

  & + & {
    margin-top: 1rem;
  }
`;

export const TermTitle = styled(Typography)`
  font-weight: bold;
`;

export const TermContent = styled(Typography)`
  text-align: justify;
`;
