import { FormattedMessage } from 'react-intl';
import { Centered } from 'styles/styles';

import { ItemContainer, ItemContentContainer, RoundedCard } from './styles';

interface InvitationsTableProps {
  headers: string[];
  items: any[];
  ItemComponent: React.FC<{ item: any }>;
}

function InvitationsTable(props: InvitationsTableProps) {
  const { headers, items, ItemComponent } = props;

  return (
    <RoundedCard>
      <ItemContainer $withDivider>
        {headers.map((header) => (
          <ItemContentContainer key={header}>
            <FormattedMessage id={header} />
          </ItemContentContainer>
        ))}
      </ItemContainer>
      {items.map((item) => (
        <ItemComponent key={item.id} item={item} />
      ))}
      {items.length === 0 && (
        <ItemContainer>
          <ItemContentContainer>
            <Centered>
              <FormattedMessage id="no_invitations" />
            </Centered>
          </ItemContentContainer>
        </ItemContainer>
      )}
    </RoundedCard>
  );
}

export default InvitationsTable;
