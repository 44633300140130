import { faCog } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardHeader, IconButton } from '@mui/material';
import { ListCard, ListCardSection, ListCardTitle } from 'components/ListCard';
import { EventRole, RoleEnum, useGetOrganizationDetailsQuery } from 'lib/graphql/graphql';

interface OrganizationCardProps {
  organizationId: string
  invitationId: string
  handleEditOrganization: (organizationId: string, invitationId: string) => void
  handleOrganizationChange: (newInvitationId: string) => void
  events: EventRole[]
}

function OrganizationCard(props: OrganizationCardProps) {
  const {
    organizationId,
    invitationId,
    handleEditOrganization,
    handleOrganizationChange,
    events,
  } = props;

  const isAdmin = events.some((event) => event.role === RoleEnum.Admin);

  const { data, loading } = useGetOrganizationDetailsQuery({
    variables: {
      id: organizationId,
    },
    fetchPolicy: 'network-only',
  });

  if (loading) return null;
  const organization = data?.getOrganizationDetails;

  return (
    <ListCard
      key={organizationId}
      clickable
      onClick={() => handleOrganizationChange(invitationId)}
    >
      <ListCardSection flexGrow={1}>
        <CardHeader
          title={<ListCardTitle title={organization?.name || ''} />}
          subheader={organization?.description}
        />
      </ListCardSection>
      <ListCardSection flexGrow={1} isRight>
        <div>
          {isAdmin && (
            <IconButton
              onClick={(event) => {
                event.stopPropagation();
                handleEditOrganization(organizationId, invitationId);
              }}
            >
              <FontAwesomeIcon icon={faCog} />
            </IconButton>
          )}
        </div>
      </ListCardSection>
    </ListCard>
  );
}

export default OrganizationCard;
