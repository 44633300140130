import { CardContent, Grid, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ContainedLoadingButton } from 'styles/styles';

import {
  CauseOrganizationCardActions,
  CauseOrganizationCardContainer,
  OrganizationDescription,
  OrganizationLogo,
  OrganizationName,
} from './styles';

interface CauseOrganizationCardProps {
  logo?: string;
  name: string;
  description?: string;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}

function CauseOrganizationCard(props: CauseOrganizationCardProps) {
  const {
    logo,
    name,
    description = '',
    onClick,
    isLoading = false,
    disabled = false,
  } = props;

  return (
    <CauseOrganizationCardContainer>
      <CardContent>
        <Grid display="flex" height="120px">
          <Grid item>
            <OrganizationLogo src={logo} />
          </Grid>
          <Grid item flexGrow={1} paddingLeft="20px">
            <OrganizationName>{name}</OrganizationName>
            <OrganizationDescription>
              <Tooltip arrow title={description}>
                <div>
                  {description}
                </div>
              </Tooltip>
            </OrganizationDescription>
          </Grid>
        </Grid>
      </CardContent>
      {onClick && (
        <CauseOrganizationCardActions>
          <ContainedLoadingButton
            color="secondary"
            onClick={onClick}
            loading={isLoading}
            disabled={disabled}
          >
            <FormattedMessage id={disabled ? 'obnl_already_selected' : 'select'} />
          </ContainedLoadingButton>
        </CauseOrganizationCardActions>
      )}
    </CauseOrganizationCardContainer>
  );
}

export default CauseOrganizationCard;
