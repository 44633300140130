import { AdvancedImage } from '@cloudinary/react';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { RoundCircleBackground, RoundCircleImage } from 'styles/styles';

interface FormMediaFieldContainerProps {
  $isDragActive: boolean;
}

export const FormMediaFieldContainer = styled.div<FormMediaFieldContainerProps>`
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%23AAAAAAFF' stroke-width='5' stroke-dasharray='10%2c 25' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  background-color: #eeeeed;

  ${({ $isDragActive }) => $isDragActive && `
    background-color: #8e8e8e;
  `}
`;

export const FileError = styled(Typography)`
  color: ${({ theme }) => theme.palette.error.main};
  font-size: 16px;
`;

export const DragFileText = styled(Typography)`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  letter-spacing: 1px;
`;

export const BrowseText = styled(Typography)`
  font-size: 18px;
  font-weight: ${({ theme }) => theme.typography.fontWeightMedium};
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  text-decoration: underline;
  padding-left: 5px;
  letter-spacing: 1px;
`;

export const SupportedText = styled(Typography)`
  font-size: 16px;
  margin-bottom: 2rem;
`;

export const ImageContainer = styled.div`
  margin: 2rem 0 1rem;
`;

export const StyledRoundCircleBackground = styled(RoundCircleBackground)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Icon = styled(RoundCircleImage)`
  margin-bottom: -5px;
`;

export const FilePreview = styled.img`
  width: auto;
  height: 200px;
  margin: 2rem 0 1rem;
`;

export const StyledImage = styled(AdvancedImage)`
  width: auto;
  height: 15svh;
  margin: 2rem 0 1rem;
`;
