import { faQrcode, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@mui/material';
import goldTicketIcon from 'assets/images/waitingLine/GoldTicketIcon.svg';
import silverTicketIcon from 'assets/images/waitingLine/SilverTicketIcon.svg';
import { ListCard, ListCardSection, ListCardTitle } from 'components/ListCard';
import EnsureRole from 'lib/access/EnsureRole';
import { EventStatus, WaitingLineStatus, WaitingLineType } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, DeleteButton } from 'styles/styles';
import { useLocation } from 'wouter';

import WaitingLineStatusChip from './components/WaitingLineStatusChip';
import {
  CurrentTicketsSold,
  ProgressContainer,
  ProgressText,
  ProgressTicketBar,
  ProgressTop,
  SoldInfoContainer,
  SoldTicketContainer,
  TicketIcon,
  TicketsLeft,
  TicketsNumber,
} from './styles';

interface WaitingLineCardProps {
  waitingLineId: string;
  title: string;
  type: WaitingLineType;
  isFreemium: boolean;
  status: WaitingLineStatus;
  activatedTickets: number;
  paidTickets: number;
  totalTickets?: number | null;
  onDelete: () => void
}

function WaitingLineCard(props: WaitingLineCardProps) {
  const event = useEvent();
  const {
    waitingLineId,
    title,
    type,
    status,
    activatedTickets,
    totalTickets = null,
    paidTickets,
    isFreemium,
    onDelete,
  } = props;

  const [, setLocation] = useLocation();

  const { status: eventStatus } = useEvent();

  const ticketIcon = type === WaitingLineType.Gold ? goldTicketIcon : silverTicketIcon;
  const linearBar = useMemo(() => (
    totalTickets ? Math.min((activatedTickets / totalTickets) * 100, 100) : 0
  ), [activatedTickets, totalTickets]);

  const handleQR = () => {
    setLocation(`~/event/${event.id}/operations?tab=qr&waitingLine=${waitingLineId}`);
  };

  return (
    <ListCard spacing={0}>
      <Grid container direction="row">
        <ListCardSection xs={8} lg={3.5}>
          <TicketIcon src={ticketIcon} />
          <ListCardTitle title={title} />
        </ListCardSection>
        <ListCardSection flexGrow={1} isCentered>
          {totalTickets !== null ? (
            <ProgressContainer>
              <ProgressTop>
                <ProgressText>
                  <FormattedMessage id="waiting_line_tickets_sold" />
                </ProgressText>
                <TicketsNumber>
                  <CurrentTicketsSold>
                    {activatedTickets}
                  </CurrentTicketsSold>
                  <TicketsLeft>
                    {` / ${totalTickets}`}
                  </TicketsLeft>
                </TicketsNumber>
              </ProgressTop>
              <ProgressTicketBar
                variant="determinate"
                value={linearBar}
              />
            </ProgressContainer>
          ) : (
            <SoldTicketContainer>
              {isFreemium ? (
                <SoldInfoContainer>
                  <ProgressTop>
                    <ProgressText>
                      <FormattedMessage id="waiting_line_tickets_free" />
                    </ProgressText>
                    <CurrentTicketsSold>
                      {activatedTickets - paidTickets}
                    </CurrentTicketsSold>
                  </ProgressTop>
                  <ProgressTop>
                    <ProgressText>
                      <FormattedMessage id="waiting_line_tickets_sold" />
                    </ProgressText>
                    <CurrentTicketsSold>
                      {paidTickets}
                    </CurrentTicketsSold>
                  </ProgressTop>
                </SoldInfoContainer>
              ) : (
                <ProgressTop>
                  <ProgressText>
                    <FormattedMessage id="waiting_line_tickets_sold" />
                  </ProgressText>
                  <CurrentTicketsSold>
                    {activatedTickets}
                  </CurrentTicketsSold>
                </ProgressTop>
              )}
            </SoldTicketContainer>
          )}
        </ListCardSection>
        <ListCardSection flexGrow={1} padding={1}>
          <WaitingLineStatusChip status={status} />
        </ListCardSection>
        <ListCardSection flexGrow={1} isRight>
          {eventStatus === EventStatus.Draft && (
            <EnsureRole requiredRole="ADMIN">
              <DeleteButton onClick={() => onDelete()}>
                <FontAwesomeIcon icon={faTrash} size="lg" />
              </DeleteButton>
            </EnsureRole>
          )}
          <ContainedButton
            onClick={() => setLocation(`~/event/${event.id}/waitingLine/${waitingLineId}/edit`)}
            color="secondary"
          >
            <FormattedMessage id="edit" />
          </ContainedButton>
          {eventStatus === EventStatus.Published && (
            <ContainedButton
              onClick={handleQR}
              startIcon={<FontAwesomeIcon icon={faQrcode} />}
            >
              <FormattedMessage id="qr_reader" />
            </ContainedButton>
          )}
        </ListCardSection>
      </Grid>
    </ListCard>
  );
}

export default WaitingLineCard;
