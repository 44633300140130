import en from './en';
import es from './es';
import fr from './fr';

export enum Languages {
  EN = 'en',
  ES = 'es',
  FR = 'fr',
}

export default {
  en,
  es,
  fr,
};
