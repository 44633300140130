import 'mapbox-gl/dist/mapbox-gl.css';

import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CircularProgress,
  InputAdornment,
  MenuItem,
  TextField,
} from '@mui/material';
import useFieldFormError from 'lib/form/useFieldFormError';
import settings from 'lib/settings';
import mapboxgl from 'mapbox-gl';
import {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  FieldValues,
  RegisterOptions,
  useController,
  ValidationRule,
} from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import PlacesAutocomplete from 'react-places-autocomplete';

import FormField, { FormFieldProps } from '../FormField';
import { LoadingContainer, MapDiv } from './styles';

export interface FormAddressFieldProps extends FormFieldProps {
  name: string
  helperTextId?: string
  labelId: string
  required?: string | ValidationRule<boolean>
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  onSelectAddress?: (address: string) => void;
}

mapboxgl.accessToken = settings.mapboxToken;

function FormAddressField(props: FormAddressFieldProps) {
  const {
    name,
    rules = {},
    helperTextId,
    labelId,
    required,
    onSelectAddress,
  } = props;

  const { field, formState } = useController({ name, rules: { required, ...rules } });
  const error = useFieldFormError(name);
  const [value, setValue] = useState(field.value || formState.defaultValues?.[name] || '');
  const [position, setPosition] = useState<[number, number] | null>(null);
  const mapContainer = useRef<any>(null);
  const map = useRef<any>(null);
  const marker = useRef<any>(null);
  const intl = useIntl();

  const onChange = useCallback(
    (newValue: string) => {
      setValue(newValue);
      field.onChange(newValue);
    },
    [field],
  );

  useEffect(() => {
    if (map.current) return;
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      zoom: 1,
      center: [-71.900010, 45.398310],
      attributionControl: false,
      interactive: false,
    });

    if (field.value) {
      fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${field.value}&access_token=${mapboxgl.accessToken}`)
        .then((response) => response.json())
        .then((data) => {
          if (data.features.length > 0) {
            const coords = data.features[0].properties.coordinates;
            setPosition([coords.longitude, coords.latitude]);
          }
        });
    }
  }, [field.value]);

  useEffect(() => {
    if (!map.current || !position) return;
    if (marker.current) {
      marker.current.remove();
    }
    map.current.flyTo({
      center: [position[0], position[1]],
      zoom: 13,
    });

    marker.current = new mapboxgl.Marker()
      .setLngLat([position[0], position[1]])
      .addTo(map.current!);
  }, [position]);

  const handleSelect = async (address: string) => {
    onChange(address);

    fetch(`https://api.mapbox.com/search/geocode/v6/forward?q=${address}&access_token=${mapboxgl.accessToken}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.features.length > 0) {
          const coords = data.features[0].properties.coordinates;
          setPosition([coords.longitude, coords.latitude]);
          if (onSelectAddress) {
            onSelectAddress(address);
          }
        }
      });
  };

  return (
    <FormField
      name={name}
      helperTextId={helperTextId}
      labelId={labelId}
      required={required}
    >
      <PlacesAutocomplete
        value={value}
        onChange={onChange}
        onSelect={handleSelect}
      >
        {({
          getInputProps, suggestions, getSuggestionItemProps, loading,
        }) => (
          <div>
            <TextField
              fullWidth
              size="small"
              {...getInputProps({
                placeholder: intl.formatMessage({ id: 'event_location_placeholder' }),
                className: 'location-search-input',
              })}
              error={Boolean(error)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faSearch} />
                  </InputAdornment>
                ),
              }}
            />
            <div className="autocomplete-dropdown-container">
              {loading && (
                <LoadingContainer>
                  <CircularProgress color="secondary" size="1rem" />
                  <FormattedMessage id="loading" />
                  ...
                </LoadingContainer>
              )}
              {suggestions.length > 0 && (
                <Card variant="outlined">
                  {suggestions.map((suggestion) => (
                    <MenuItem {...getSuggestionItemProps(suggestion)}>
                      {suggestion.description}
                    </MenuItem>
                  ))}
                </Card>
              )}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <MapDiv
        ref={mapContainer}
      />
    </FormField>
  );
}

export default FormAddressField;
