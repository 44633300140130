import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import useFieldFormError from 'lib/form/useFieldFormError';
import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { FieldValues, RegisterOptions, useController } from 'react-hook-form';

import FormField, { FormFieldProps } from '../FormField';
import DateTimeHeader from './DateTimeHeader';

type CustomDateTimePickerProps = Omit<DateTimePickerProps<DateTime>, 'onChange' | 'value' | 'defaultValue' | 'name'>;
export interface FormDateFieldProps extends FormFieldProps, CustomDateTimePickerProps {
  rules?: Omit<RegisterOptions<FieldValues, string>, 'disabled' | 'valueAsNumber' | 'valueAsDate' | 'setValueAs'>
  size?: 'small' | 'medium';
}

function FormDateTimeField(props: FormDateFieldProps) {
  const {
    helperTextId,
    labelId,
    name,
    required,
    rules,
    size = 'small',
    ...dateTimePickerProps
  } = props;
  const { field } = useController({ name, rules: { required, ...rules } });

  const fieldError = useFieldFormError(name);

  const dateValue = useMemo(() => {
    if (field.value === null) return null;
    if (field.value.isLuxonDateTime) return field.value;
    return DateTime.fromISO(field.value);
  }, [field.value]);

  return (
    <FormField
      name={name}
      helperTextId={helperTextId}
      labelId={labelId}
      required={required}
    >
      <DateTimePicker
        ampm={false}
        onChange={field.onChange}
        value={dateValue}
        closeOnSelect={false}
        slots={{
          toolbar: DateTimeHeader,
        }}
        slotProps={{
          textField: {
            size,
            error: Boolean(fieldError),
            fullWidth: true,
          },
        }}
        {...dateTimePickerProps}
      />
    </FormField>
  );
}

export default FormDateTimeField;
