import { Languages } from 'lib/i18n/i18n';
import { createContext } from 'react';

export interface ILanguageContext {
  language: Languages
  setLanguage: (newLanguage: Languages) => void
}

export const DefaultLanguageContext: ILanguageContext = {
  language: Languages.EN,
  setLanguage: () => {},
};

const LanguageContext = createContext(DefaultLanguageContext);

export default LanguageContext;
