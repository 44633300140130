import { CardContent } from '@mui/material';
import styled from 'styled-components';

export const ReportCard = styled.div`
  height: 100%;
  width: 550px;
  background: #ececec;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ReportCardContent = styled(CardContent)`
  flex-grow: 1;
`;

export const ReportCardTooltip = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: help;
  }
`;

export const Balances = styled.div`
  text-align: right;
`;

export const BalanceCurrency = styled.div`
  font-weight: bold;
`;
