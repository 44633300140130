import {
  CardContent,
  FormLabel,
  Grid,
  TextField,
} from '@mui/material';
import { useInfiniteQuery } from '@tanstack/react-query';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import PageLayout from 'components/PageLayout';
import { useGetAllInvitationsForUserLazyQuery, useGetUserQuery } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Content,
  SmallTitle,
  TextButton,
  Title,
} from 'styles/styles';

import ChangePasswordForm from './ChangePasswordForm';
import InvitationCard from './InvitationCard';
import { Avatar, AvatarContainer, ProfileCard } from './styles';

function Profile() {
  // TODO: Create styled-components for some elements in this component
  useFormattedDocumentTitle('profile');

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);

  const [getAllInvitationsForUserQuery] = useGetAllInvitationsForUserLazyQuery();
  const { data: userData, loading } = useGetUserQuery();
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    error,
  } = useInfiniteQuery({
    queryKey: ['getAllInvitationsForUser'],
    queryFn: async ({ pageParam }: { pageParam?: string }) => {
      const result = await getAllInvitationsForUserQuery({
        fetchPolicy: 'network-only',
        variables: {
          start: pageParam,
        },
      });
      return result.data?.getAllInvitationsForUser;
    },
    initialPageParam: undefined,
    getNextPageParam: (lastPage: any) => lastPage?.lastEvaluated,
  });

  useEffect(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage]);

  const userEmail = userData?.getUser?.email;

  if (isLoading || loading) return <CenteredSpinner />;
  if (error) return <div>TODO</div>;

  // TODO: Pagination
  const invitations = data?.pages.flatMap(
    (page) => page?.invitations || [],
  ) || [];

  return (
    <PageLayout>
      <Content>
        <Title>
          <FormattedMessage id="profile" />
        </Title>
        <ProfileCard>
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={8}>
                <Grid item mb={1}>
                  <FormLabel>
                    <FormattedMessage id="email" />
                  </FormLabel>
                  <TextField
                    disabled
                    fullWidth
                    size="small"
                    value={userEmail}
                  />
                </Grid>
                <TextButton
                  size="small"
                  color="secondary"
                  onClick={() => setChangePasswordOpen(true)}
                >
                  <FormattedMessage id="change_password_link" />
                </TextButton>
              </Grid>
              <AvatarContainer>
                <Avatar>
                  {userEmail?.split(' ', 2).map((namePart) => (namePart[0])).join('').toUpperCase()}
                </Avatar>
              </AvatarContainer>
            </Grid>
          </CardContent>
        </ProfileCard>
        <SmallTitle marginBottom="1rem">
          <FormattedMessage id="invitations" />
        </SmallTitle>
        <Grid container direction="column" gap={2} alignItems="center">
          {invitations.map((invitation) => (
            <InvitationCard
              invitation={invitation}
              userEmail={userEmail!}
            />
          ))}
        </Grid>
      </Content>
      <CustomDialog
        open={changePasswordOpen}
        onClose={() => setChangePasswordOpen(false)}
      >
        <ChangePasswordForm
          onClose={() => setChangePasswordOpen(false)}
        />
      </CustomDialog>
    </PageLayout>
  );
}

export default Profile;
