import { DialogTitle } from '@mui/material';
import styled from 'styled-components';

export const Header = styled(DialogTitle)`
  font-weight: ${({ theme }) => theme.typography.fontWeightRegular};
  font-size: 1.5rem;
  text-align: center;
  padding: 1.5rem;
`;

export const Body = styled.div`
  border-top: 2px ${({ theme }) => theme.palette.secondary.main} ridge;
  padding: 1rem;
  text-align: center;
`;
