import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import {
  CheckContainer,
  ProgressionCircle,
} from '../styles';

interface CheckDotProps {
  isCompleted: boolean;
}

function CheckDot({ isCompleted }: CheckDotProps) {
  const theme = useTheme();

  if (isCompleted) {
    return (
      <CheckContainer>
        <FontAwesomeIcon
          icon={faCheck}
          size="sm"
          color={theme.palette.primary.contrastText}
        />
      </CheckContainer>
    );
  }

  return (
    <ProgressionCircle />
  );
}

export default CheckDot;
