import { DialogActions, DialogContent } from '@mui/material';
import CustomDialog from 'components/CustomDialog/CustomDialog';
import CenteredSpinner from 'components/Loading/CenteredSpinner';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

import { Body, Header } from './styles';

interface ConfirmModalProps {
  title: string
  open: boolean
  closeColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  closeText: string
  confirmColor?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  confirmText: string
  loading?: boolean
  onClose: () => void
  onConfirm: () => void
  children?: React.ReactNode
}

function ConfirmModal(props: ConfirmModalProps) {
  const {
    title,
    open,
    closeColor = 'primary',
    closeText,
    confirmColor = 'secondary',
    confirmText,
    loading = false,
    onClose,
    onConfirm,
    children,
  } = props;

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
    >
      <Header>
        <FormattedMessage id={title} />
      </Header>
      <DialogContent>
        <Body>
          {children}
        </Body>
      </DialogContent>
      <DialogActions>
        <ContainedButton
          disabled={loading}
          onClick={onClose}
          color={closeColor}
        >
          <FormattedMessage id={closeText} />
        </ContainedButton>
        <ContainedButton
          disabled={loading}
          onClick={onConfirm}
          color={confirmColor}
        >
          {loading ? (
            <CenteredSpinner size="1.5em" />
          ) : (
            <FormattedMessage id={confirmText} />
          )}
        </ContainedButton>
      </DialogActions>
    </CustomDialog>
  );
}

export default ConfirmModal;
