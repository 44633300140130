import { IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from '@mui/material';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import { CheckContainer, ListItemButton } from './styles';

interface DrawerItemProps {
  text: string;
  icon: IconDefinition;
  url: string;
  isDisabled?: boolean;
  urlKeys?: string[];
  isCompleted?: boolean;
  setDrawerOpen?: (open: boolean) => void;
}

function EventSideButton(props: DrawerItemProps) {
  const {
    text,
    icon,
    url,
    isDisabled = false,
    urlKeys = [],
    isCompleted = false,
    setDrawerOpen = null,
  } = props;

  const theme = useTheme();
  const [location, setLocation] = useLocation();

  const currUrl = window.location.pathname.split('/');

  const isActive = urlKeys.some((key) => currUrl.includes(key)) || location === url;

  const navigate = useCallback(() => {
    if (!isDisabled) {
      if (setDrawerOpen) {
        setDrawerOpen(false);
      }
      setLocation(url);
    }
  }, [isDisabled, setDrawerOpen, setLocation, url]);

  return (
    <ListItem key={`menu-item-${text}`} disablePadding onClick={navigate}>
      <ListItemButton
        $active={isActive}
        disabled={isDisabled}
      >
        <ListItemIcon>
          {isCompleted ? (
            <CheckContainer>
              <FontAwesomeIcon
                icon={faCheck}
                size="sm"
                color={theme.palette.primary.contrastText}
                fixedWidth
              />
            </CheckContainer>
          ) : (
            <FontAwesomeIcon
              icon={icon}
              size="xl"
              color={theme.palette.primary.main}
              fixedWidth
            />
          )}
        </ListItemIcon>
        <ListItemText primary={<FormattedMessage id={text} />} />
      </ListItemButton>
    </ListItem>
  );
}

export default EventSideButton;
