import { Grid } from '@mui/material';
import useIsMobile from 'lib/useIsMobile';
import { PropsWithChildren } from 'react';

function PageLayout({ children } : PropsWithChildren) {
  const isMobile = useIsMobile();

  return (
    <Grid container height="100%" width="100%" justifyContent="center">
      <Grid width="100%" maxWidth="1200px" item padding="0 1rem" paddingTop={isMobile ? '1rem' : '3rem'}>
        {children}
      </Grid>
    </Grid>
  );
}

export default PageLayout;
