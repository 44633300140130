import { faAdd } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Typography } from '@mui/material';
import PageLayout from 'components/PageLayout';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import InvitationsTable from './InvitationTable/InvitationsTable';

function EventUsers() {
  useEventDocumentTitle('users');
  const [, setLocation] = useLocation();

  return (
    <PageLayout>
      <Grid container justifyContent="space-between" alignItems="center" pb="1rem">
        <Grid item>
          <Title>
            <FormattedMessage id="users" />
          </Title>
          <Grid container direction="row" justifyContent="space-between" mb={2}>
            <Typography variant="body2" color="textSecondary">
              <FormattedMessage id="users_invites_subtitle" />
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <ContainedButton
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faAdd} />}
              onClick={() => setLocation('/users/invite')}
            >
              <FormattedMessage id="invite_user" />
            </ContainedButton>
          </Grid>
        </Grid>
      </Grid>
      <InvitationsTable />
    </PageLayout>
  );
}

export default EventUsers;
