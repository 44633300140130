import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import i18n, { Languages } from 'lib/i18n/i18n';
import { Settings } from 'luxon';
import {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';

import LanguageContext, { DefaultLanguageContext } from './LanguageContext';

export interface LanguageProviderProps {
  children: JSX.Element
}

function LanguageProvider(props: LanguageProviderProps) {
  const { children } = props;

  const defaultLanguage = useMemo(() => {
    const savedLanguage = localStorage.getItem('language') as Languages;
    if (savedLanguage) return savedLanguage;

    // Get the first part of the navigator language (e.g., 'en' from 'en-US')
    const navigatorLanguage = navigator.language.split(/[-_]/)[0] as Languages;

    // If the navigator language is in the list of supported languages, use it;
    // otherwise, use the language from DefaultLanguageContext
    const initialLanguage = Object.values(Languages).includes(navigatorLanguage)
      ? navigatorLanguage
      : DefaultLanguageContext.language;

    return initialLanguage;
  }, []);

  const [language, setLanguage] = useState<Languages>(defaultLanguage);

  const changeLanguage = useCallback((newLanguage: Languages) => {
    localStorage.setItem('language', newLanguage);
    setLanguage(newLanguage);
  }, []);

  useEffect(() => {
    Settings.defaultLocale = language;
    changeLanguage(language);
  }, [changeLanguage, language]);

  const contextValue = useMemo(() => ({
    language,
    setLanguage: changeLanguage,
  }), [changeLanguage, language]);

  return (
    <LanguageContext.Provider value={contextValue}>
      <IntlProvider locale={language} messages={i18n[language]}>
        <LocalizationProvider
          dateAdapter={AdapterLuxon}
          adapterLocale={language}
          localeText={
            {
              cancelButtonLabel: i18n[language].cancel,
              clearButtonLabel: i18n[language].clear,
              openNextView: i18n[language].date_field_open_next_view,
              openPreviousView: i18n[language].date_field_open_previous_view,
              nextMonth: i18n[language].date_field_next_month,
              previousMonth: i18n[language].date_field_previous_month,
            }
          }
        >
          {children}
        </LocalizationProvider>
      </IntlProvider>
    </LanguageContext.Provider>
  );
}

export default LanguageProvider;
