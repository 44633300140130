import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormButtons from 'components/FormFields/FormButtons';
import FormTextField from 'components/FormFields/FormTextField';
import { useCustomForm } from 'lib/form/useCustomForm';
import { OrganizationInput, useHasOrganizationsQuery } from 'lib/graphql/graphql';
import UnsavedFormQuitConfirmation from 'lib/routing/UnsavedFormQuitConfirmation';
import useOrganization from 'providers/organization/useOrganization';
import { useCallback } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FormGrid, NeutralOutlinedButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

interface OrganizationFormProps {
  children?: React.ReactNode;
  defaultValues: OrganizationInput;
  title: String;
  onSubmit: SubmitHandler<OrganizationInput>;
}

function OrganizationForm(props: OrganizationFormProps) {
  const {
    children,
    defaultValues,
    title,
    onSubmit,
  } = props;

  const [, setLocation] = useLocation();
  const { setOrganizationId, setInvitationId } = useOrganization();

  const { data } = useHasOrganizationsQuery();
  const hasOrganizations = Boolean(data?.hasOrganizations);

  const formControls = useCustomForm({ defaultValues, mode: 'onChange', trim: true });

  const goToOrganizations = useCallback(() => {
    setOrganizationId(null);
    setInvitationId(null);
    setLocation('~/');
  }, [setInvitationId, setLocation, setOrganizationId]);

  return (
    <FormProvider {...formControls}>
      <UnsavedFormQuitConfirmation />
      {hasOrganizations && (
        <NeutralOutlinedButton
          startIcon={<FontAwesomeIcon icon={faArrowLeft} />}
          onClick={goToOrganizations}
        >
          <FormattedMessage id="return_to_organizations" />
        </NeutralOutlinedButton>
      )}
      <FormGrid container direction="column" gap={2}>
        <Title>
          <FormattedMessage id={`organization_${title}`} />
        </Title>
        <FormTextField
          required
          name="name"
          labelId="organization_name"
          fieldDescription="create_organization_name"
          rules={{ maxLength: 50 }}
        />
        <FormTextField
          rows={3}
          multiline
          name="description"
          labelId="description"
          fieldDescription="create_organization_description"
          rules={{ maxLength: 300 }}
        />
      </FormGrid>
      {children}
      <FormButtons submit={onSubmit} />
    </FormProvider>
  );
}

export default OrganizationForm;
