import { Grid } from '@mui/material';
import EmailIcon from 'assets/images/auth/icon_email.svg';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import useFeedback from 'components/Feedback/useFeedback';
import { useSendNewEmailValidationMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import { useContext, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  RoundCircleBackground,
  RoundCircleImage,
  TextButton,
  Title,
} from 'styles/styles';
import { useLocation } from 'wouter';

import AuthForm from './AuthForm';
import { ActionButton } from './styles';

function ConfirmEmail() {
  useFormattedDocumentTitle('confirm_your_email');
  const {
    loggedIn,
    confirmedAccount,
    logout,
  } = useContext(AuthenticatorContext);
  const [, setLocation] = useLocation();
  const { locale } = useIntl();
  const feedback = useFeedback();

  const [sendNewEmailValidation, { loading }] = useSendNewEmailValidationMutation();

  useEffect(() => {
    if (loggedIn && confirmedAccount) {
      setLocation('~/');
    }
  }, [confirmedAccount, loggedIn, setLocation]);

  const handleResendEmail = async () => {
    try {
      await sendNewEmailValidation({ variables: { locale } });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="new_email_sent" />),
        severity: 'success',
      });
    } catch {
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="new_email_error" />),
        severity: 'error',
      });
    }
  };

  const returnToLogin = () => {
    logout();
    setLocation('~/');
  };

  return (
    <AuthForm>
      <Grid container alignContent="center" textAlign="center">
        <Grid container item m="32px" gap="16px">
          <Grid item xs={12}>
            <RoundCircleBackground>
              <RoundCircleImage src={EmailIcon} />
            </RoundCircleBackground>
          </Grid>
          <Grid item xs={12}>
            <Title>
              <FormattedMessage id="confirm_your_email" />
            </Title>
          </Grid>
        </Grid>
        <Grid container item justifyContent="center" marginBottom="16px">
          <Grid item xs={10}>
            <FormattedMessage id="click_link_email_to_confirm" />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormattedMessage id="did_not_get_it" />
          {' '}
          <TextButton disabled={loading} color="secondary" onClick={handleResendEmail}>
            <FormattedMessage id="resend_email" />
          </TextButton>
        </Grid>
        <Grid item xs={12} paddingTop="5rem">
          <ActionButton
            color="secondary"
            type="submit"
            onClick={returnToLogin}
          >
            <FormattedMessage id="return_to_login" />
          </ActionButton>
        </Grid>
      </Grid>
    </AuthForm>
  );
}

export default ConfirmEmail;
