import { Grid } from '@mui/material';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import WaitingLineOperatingCard from 'modules/Events/EventWaitingLines/WaitingLineOperatingCard/WaitingLineOperatingCard';

import { SearchBar } from './styles';

interface EventOperationsDesktopProps {
  searchValue: string;
  setSearchValue: (value: string) => void;
  waitingLines: EventWaitingLine[];
  handleWaitingLinesRefetch: () => void | Promise<void>;
}

export default function EventOperationsDesktop(props: EventOperationsDesktopProps) {
  const {
    searchValue,
    setSearchValue,
    waitingLines,
    handleWaitingLinesRefetch,
  } = props;

  return (
    <Grid container justifyContent="flex-end" alignItems="center" gap={1}>
      <SearchBar searchValue={searchValue} setSearchValue={setSearchValue} />
      <Grid container direction="column" gap={2} marginTop="1rem" marginBottom="1rem" alignItems="center">
        {waitingLines.map((waitingLine) => (
          <WaitingLineOperatingCard
            waitingLine={waitingLine}
            key={waitingLine.id}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            handleWaitingLinesRefetch={handleWaitingLinesRefetch}
          />
        ))}
      </Grid>
    </Grid>
  );
}
