import {
  faCheck,
  faExclamation,
  faX,
  IconDefinition,
} from '@fortawesome/pro-solid-svg-icons';

export type OperatingStatusCode =
'ERR_MULTIPLE' | // Multiple QR codes detected
'ERR_ALREADY_USED' | // All tickets are already validated
'ERR_NOT_FOUND' | // A ticket was not found
'ERR_UNKNOWN' | // The scanned QR code is not a ticket
'WARN_INVALID_WAITING_LINE' | // Scanned ticket is from a different waiting line
'SUCCESS' | // All tickets are now validated
'ERR_VALIDATION_MIXED' | // Some tickets are validated, some are not
'WARN_NOT_CALLED' | // Tickets are valid, but were not yet called (free)
'WARN_NOT_CALLED_PREMIUM'; // Tickets are valid, but were not yet called (premium)

type StatusCodeMap = {
  [code in OperatingStatusCode]: {
    title: string
    message: string
    severity: string
    icon: IconDefinition
    duration: number
  };
};

export const operatingStatusCodesMapping: StatusCodeMap = {
  ERR_ALREADY_USED: {
    title: 'operating_ticket_not_valid',
    message: 'operating_ticket_already_used',
    severity: 'error',
    icon: faX,
    duration: 5,
  },
  ERR_MULTIPLE: {
    title: 'operating_ticket_not_valid',
    message: 'operating_multiple_codes_detected',
    severity: 'error',
    icon: faX,
    duration: 5,
  },
  ERR_NOT_FOUND: {
    title: 'operating_ticket_not_valid',
    message: 'operating_ticket_not_found',
    severity: 'error',
    icon: faX,
    duration: 5,
  },
  ERR_UNKNOWN: {
    title: 'operating_ticket_not_valid',
    message: 'operating_unknown_error',
    severity: 'error',
    icon: faX,
    duration: 5,
  },
  WARN_INVALID_WAITING_LINE: {
    title: 'operating_ticket_wrong_waiting_line',
    message: 'operating_invalid_waiting_line',
    severity: 'warning',
    icon: faExclamation,
    duration: 10,
  },
  SUCCESS: {
    title: 'operating_ticket_confirmation',
    message: 'operating_validated',
    severity: 'success',
    icon: faCheck,
    duration: 5,
  },
  WARN_NOT_CALLED: {
    title: 'operating_ticket_not_called_title',
    message: 'operating_ticket_not_called_message',
    severity: 'warning',
    icon: faExclamation,
    duration: 5,
  },
  WARN_NOT_CALLED_PREMIUM: {
    title: 'operating_ticket_not_called_title',
    message: 'operating_ticket_not_called_premium_message',
    severity: 'warning',
    icon: faExclamation,
    duration: 5,
  },
  ERR_VALIDATION_MIXED: {
    title: 'operating_ticket_mixed',
    message: 'operating_mixed_validation',
    severity: 'warning',
    icon: faExclamation,
    duration: 30,
  },
};

export function getCodeInfo(code: OperatingStatusCode): StatusCodeMap[OperatingStatusCode] {
  return operatingStatusCodesMapping[code];
}
