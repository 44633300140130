import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const EmailContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const ErrorIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.error.main};
`;
