import { faSync } from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { QueueType, WaitingLineType } from 'lib/graphql/graphql';
import {
  CalledContainer,
  ContainerCheck,
  DisplayId,
  DisplayIdHeader,
  NotCalledContainer,
  NotCalledIcon,
  StatusCheck,
} from 'modules/Events/EventWaitingLines/styles';
import { FormattedMessage, useIntl } from 'react-intl';

import WaitingLineTicketMore, { TicketData } from '../../WaitingLineTicketMore';

export function getDisplayIdColumn(intl: ReturnType<typeof useIntl>): GridColDef {
  return {
    field: 'displayId',
    headerName: intl.formatMessage({ id: 'ticket_operation_display_id' }),
    renderHeader: () => (
      <DisplayIdHeader>
        <FormattedMessage id="ticket_operation_display_id" />
      </DisplayIdHeader>
    ),
    flex: 0.2,
    renderCell: (params) => {
      const { displayId } = params.row;
      return (
        <DisplayId>
          {displayId}
        </DisplayId>
      );
    },
    resizable: false,
  };
}

export function getTransactionIdColumn(intl: ReturnType<typeof useIntl>): GridColDef {
  return {
    field: 'transactionId',
    headerName: intl.formatMessage({ id: 'ticket_operation_transaction_id' }),
    flex: 0.5,
    resizable: false,
  };
}

export function getStatusColumn(intl: ReturnType<typeof useIntl>): GridColDef {
  return {
    field: 'statusDisplay',
    headerName: intl.formatMessage({ id: 'ticket_operation_status' }),
    flex: 0.2,
    resizable: false,
  };
}

export function getActionsColumn(
  waitingLineType: WaitingLineType,
  handleRefetch: () => void,
  isMobile: boolean,
): GridColDef {
  return {
    field: 'actions',
    renderHeader: () => (
      <IconButton onClick={handleRefetch} color="primary">
        <FontAwesomeIcon icon={faSync} size="sm" />
      </IconButton>
    ),
    flex: 0.1,
    type: 'actions',
    sortable: false,
    filterable: false,
    renderCell: (params) => {
      const { row } = params;
      return (
        <WaitingLineTicketMore
          waitingLineType={waitingLineType}
          ticket={row as TicketData}
          isMobile={isMobile}
          refetch={handleRefetch}
        />
      );
    },
    resizable: false,
  };
}

export function getCalledColumn(intl: ReturnType<typeof useIntl>): GridColDef {
  return {
    field: 'called',
    headerName: intl.formatMessage({ id: 'ticket_operation_called' }),
    flex: 0.1,
    resizable: false,
    renderCell: (params) => {
      const { queueType, statusDisplay } = params.row;

      return (
        <CalledContainer>
          {queueType === QueueType.Called || statusDisplay === intl.formatMessage({ id: 'ticket_status_VALIDATED' }) ? (
            <ContainerCheck>
              <StatusCheck
                icon={faCheck}
                size="sm"
                fixedWidth
              />
            </ContainerCheck>
          ) : (
            <NotCalledContainer>
              <NotCalledIcon
                icon={faXmark}
                size="xl"
                fixedWidth
              />
            </NotCalledContainer>
          )}
        </CalledContainer>
      );
    },
  };
}
