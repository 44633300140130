import { createContext } from 'react';

export interface IOrganizationContext {
  organizationId: string | undefined | null;
  setOrganizationId: (organization: string | null) => void;
  invitationId: string | undefined | null;
  setInvitationId: (organization: string | null) => void;
}

export const DefaultOrganizationContext: IOrganizationContext = {
  organizationId: undefined,
  setOrganizationId: () => {},
  invitationId: undefined,
  setInvitationId: () => {},
};

const OrganizationContext = createContext(DefaultOrganizationContext);

export default OrganizationContext;
