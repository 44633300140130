import { useGetOrganizationQuery } from 'lib/graphql/graphql';
import useDocumentTitle from 'lib/title/useDocumentTitle';
import useOrganization from 'providers/organization/useOrganization';
import { useIntl } from 'react-intl';

function useDashboardDocumentTitle() {
  const { organizationId } = useOrganization();
  const { formatMessage } = useIntl();

  const { data, loading } = useGetOrganizationQuery({ variables: { id: organizationId! } });

  const name = data?.getOrganization?.name || '';
  useDocumentTitle(`${formatMessage({ id: 'dashboard' })} - ${name}`, loading);
}

export default useDashboardDocumentTitle;
