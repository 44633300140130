import { GetTicketItem } from 'lib/graphql/entities/tickets/types';
import { EventWaitingLine } from 'lib/graphql/entities/waitingLines/types';
import { NullableText } from 'lib/graphql/graphql';
import { DateTime } from 'luxon';
import { createContext } from 'react';

import { QRAlertProps } from '../components/QRAlert/types';
import { OperatingStatusCode } from '../statusCodes';

export interface RecentScan {
  tickets: number;
  waitingLineName: NullableText;
  timestamp: DateTime;
  remainingTickets: number | 'unknown';
}

export type IQrReaderContext = {
  addRecentScan: (nbrOfTickets: number, waitingLineId: string, remainingTickets: number | 'unknown') => void;
  alertProps: QRAlertProps | null;
  closeAlert: () => void;
  operatingWaitingLine: string | undefined;
  waitingLines: EventWaitingLine[];
  recentScans: RecentScan[];
  setAlertProps: (
    status: OperatingStatusCode,
    tickets?: GetTicketItem[],
    remainingTickets?: number | 'unknown',
  ) => void;
  setOperatingWaitingLine: (waitingLineId: string) => void;
  validateTickets: (tickets: GetTicketItem[]) => Promise<void>;
};

export const DefaultQrReaderContext: IQrReaderContext = {
  addRecentScan: () => { },
  alertProps: null,
  closeAlert: () => { },
  operatingWaitingLine: '',
  waitingLines: [],
  recentScans: [],
  setAlertProps: () => { },
  setOperatingWaitingLine: () => { },
  validateTickets: async () => { },
};

const QrReaderContext = createContext<IQrReaderContext>(DefaultQrReaderContext);

export default QrReaderContext;
