import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import useFeedback from 'components/Feedback/useFeedback';
import { useCustomForm } from 'lib/form/useCustomForm';
import { EventCreationInput, useCreateEventMutation } from 'lib/graphql/graphql';
import { Languages } from 'lib/i18n/i18n';
import { LUXON_DEFAULT_TIMEZONE } from 'lib/maps/timezones';
import settings from 'lib/settings';
import { DateTime } from 'luxon';
import LanguageContext from 'providers/i18n/LanguageContext';
import useOrganization from 'providers/organization/useOrganization';
import { useContext, useMemo } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'wouter';

import useEventDocumentTitle from '../useEventDocumentTitle';
import EventForm from './EventForm';

const DEFAULT_LANGUAGES = [Languages.EN, Languages.FR];

function CreateEvent() {
  useEventDocumentTitle('event_create');
  const intl = useIntl();
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const { userId } = useContext(AuthenticatorContext);
  const { organizationId } = useOrganization();
  const { language } = useContext(LanguageContext);
  const { projectName, cloudName } = settings.cloudinary;

  const defaultValues = useMemo(() => {
    const today = DateTime.now().startOf('day');
    const inAWeek = today.plus({ days: 7 });
    const timezone = LUXON_DEFAULT_TIMEZONE;
    return {
      userId,
      name: {
        en: '',
        fr: '',
        es: '',
      },
      description: {
        en: '',
        fr: '',
        es: '',
      },
      location: '',
      startTime: today,
      endTime: inAWeek,
      timezone,
      organizationId: organizationId || '',
      languages: [language, ...DEFAULT_LANGUAGES.filter((lang) => lang !== language)],
      shortUrl: '',
    };
  }, [userId, organizationId, language]);

  const methods = useCustomForm<Omit<EventCreationInput, 'startTime' | 'endTime'> & { startTime: DateTime, endTime: DateTime }>({
    defaultValues,
    trim: true,
  });

  const { setError } = methods;

  // TODO: Handle loading & errors
  const [createEvent] = useCreateEventMutation();

  const onSubmit: SubmitHandler<EventCreationInput> = async (formData) => {
    const imageFormData = new FormData();
    imageFormData.append('file', formData.image);
    imageFormData.append('upload_preset', projectName);

    const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/upload`, {
      method: 'POST',
      body: imageFormData,
    });
    const responseData = await response.json();
    const imageUrl = responseData.public_id;

    try {
      const { data } = await createEvent({
        variables: {
          event: {
            ...formData,
            organizationId: organizationId || '',
            userId,
            image: imageUrl,
          },
        },
      });

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id="create_event_success" />),
        severity: 'success',
      });

      const eventId = data?.createEvent.id;
      setLocation(`/event/${eventId}/waitingLines`);
    } catch (error) {
      const errorMessage = (error as Error).message;

      const regex = /The (.+?) field must have a value for the languages: (.+)/;
      const match = errorMessage.match(regex);

      if (match) {
        const field = match[1];
        const languages = match[2].split(',').map((lang) => lang.trim()) as Languages[];
        const languagesWithErrors: string[] = [];

        languages.forEach((lang: Languages) => {
          const translatedLanguage = intl.formatMessage({ id: `language_${lang}` });

          setError(`${field}.${lang}` as keyof EventCreationInput, { message: 'field_required' });

          languagesWithErrors.push(translatedLanguage);
        });

        const concatenatedLanguages = languagesWithErrors.join(', ');

        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="update_error_missing_translation" values={{ languages: concatenatedLanguages, count: languagesWithErrors.length }} />),
          severity: 'error',
        });
      } else {
        feedback({
          anchor: { horizontal: 'right', vertical: 'bottom' },
          content: (<FormattedMessage id="create_event_error" />),
          severity: 'error',
        });
      }
    }
  };

  return (
    <FormProvider {...methods}>
      <EventForm
        title="create"
        onSubmit={onSubmit}
      />
    </FormProvider>
  );
}

export default CreateEvent;
