import { ApolloError } from '@apollo/client';
import {
  CardActions,
  CardContent,
  CardHeader,
  Grid,
} from '@mui/material';
import FormPasswordField from 'components/FormFields/FormPasswordField';
import { useResetPasswordMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ErrorMessage, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import AuthLayout from './AuthForm';
import { ActionButton } from './styles';

interface ResetPasswordInputs {
  password: string
  confirmPassword: string
}

function ResetPassword() {
  useFormattedDocumentTitle('reset_password');
  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token');
  const formControl = useForm<ResetPasswordInputs>({
    mode: 'onTouched',
  });
  const {
    handleSubmit,
    watch,
  } = formControl;

  const passwordWatch = watch('password');
  const [, setLocation] = useLocation();
  const [resetPassword] = useResetPasswordMutation();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit: SubmitHandler<ResetPasswordInputs> = useCallback(async ({
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) {
      return;
    }
    setLoading(true);
    try {
      await resetPassword({
        variables: {
          token: token ?? '',
          newPassword: password,
        },
      });
    } catch (e) {
      setError((e as ApolloError).message);
      setLoading(false);
      return;
    }
    setLoading(false);
    setLocation('~/');
  }, [setLocation, token, resetPassword]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formControl}>
        <AuthLayout>
          <Grid container direction="column" alignContent="center" gap={2}>
            <Grid container direction="column" width="30rem">
              <CardHeader
                title={(
                  <Title>
                    <FormattedMessage id="reset_password" />
                  </Title>
              )}
                sx={{ marginLeft: '0.5rem' }}
              />
              <CardContent>
                <Grid container gap={2}>
                  <FormPasswordField
                    name="password"
                    labelId="password"
                    autoComplete="new-password"
                    innerFieldLabel="enter_password"
                    rules={{ validate: (value: string) => value.length >= 8 || 'password_too_short' }}
                    size="medium"
                    required
                  />
                  <FormPasswordField
                    name="confirmPassword"
                    labelId="confirm_password"
                    innerFieldLabel="enter_confirm_password"
                    rules={{ validate: (value: string) => (value) === passwordWatch || 'passwords_do_not_match' }}
                    size="medium"
                    required
                  />
                  {error && (
                    <ErrorMessage>
                      <FormattedMessage id="reset_password_error" />
                    </ErrorMessage>
                  )}
                </Grid>
              </CardContent>
              <CardActions sx={{ justifyContent: 'center' }}>
                <ActionButton
                  color="secondary"
                  type="submit"
                  loading={loading}
                >
                  <FormattedMessage id="reset_password" />
                </ActionButton>
              </CardActions>
            </Grid>
          </Grid>
        </AuthLayout>
      </FormProvider>
    </form>
  );
}

export default ResetPassword;
