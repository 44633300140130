import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar as MUIAvatar,
  MenuItem,
  Skeleton,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import { useGetUserQuery, useHasOrganizationsQuery } from 'lib/graphql/graphql';
import useOrganization from 'providers/organization/useOrganization';
import {
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'wouter';

import {
  AvatarButton,
  AvatarMenu,
  AvatarSkeletonText,
  ChevronIcon,
} from './styles';

interface AvatarProps {
  hasMenu?: boolean;
  hasText?: boolean;
  style?: SxProps<Theme> | undefined,
}

function Avatar(props: AvatarProps) {
  const {
    hasMenu = false,
    hasText = false,
    style,
  } = props;
  const { logout, setFirstBoot } = useContext(AuthenticatorContext);

  const { data } = useHasOrganizationsQuery();
  const hasOrganizations = Boolean(data?.hasOrganizations);

  const { data: userData, loading: userLoading } = useGetUserQuery();

  const theme = useTheme();
  const [, setLocation] = useLocation();
  const { setInvitationId, setOrganizationId } = useOrganization();
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: theme.palette.primary.light,
        ...style,
      },
      children: name?.split(' ', 2).map((namePart) => (namePart[0])).join('').toUpperCase(),
    };
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleManageOrganizations = useCallback(() => {
    setInvitationId(null);
    setOrganizationId(null);
    setFirstBoot(false);
    setLocation('~/');
  }, [setFirstBoot, setInvitationId, setLocation, setOrganizationId]);

  const handleLogout = useCallback(async () => {
    await logout();
    setLocation('~/');
  }, [logout, setLocation]);

  // TODO: Use real name when available; for now, use first part of email
  const email = userData?.getUser?.email;
  const userEmail = useMemo(() => (email?.includes('@') ? email?.match(/^(.+)@/)?.[1] : email || '') || '', [email]);

  return (
    <>
      <AvatarButton onClick={handleOpenUserMenu} disabled={!hasMenu}>
        {userLoading || !userEmail ? (
          <>
            <Skeleton variant="circular" width={40} height={40} />
            {hasText && (
              <AvatarSkeletonText width={60} height={24} />
            )}
          </>
        ) : (
          <>
            <MUIAvatar {...stringAvatar(userEmail)} />
            {hasText && (
            <Typography textAlign="center" marginLeft="1rem">
              {userEmail}
            </Typography>
            )}
          </>
        )}
        {hasText && (
          <ChevronIcon>
            <FontAwesomeIcon icon={anchorElUser ? faChevronUp : faChevronDown} />
          </ChevronIcon>
        )}
      </AvatarButton>
      {hasMenu && (
        <AvatarMenu
          anchorEl={anchorElUser}
          open={Boolean(anchorElUser)}
          onClick={handleCloseUserMenu}
          onClose={handleCloseUserMenu}
        >
          {hasOrganizations && (
            <MenuItem onClick={() => { setLocation('~/profile'); }}>
              <FormattedMessage id="profile" />
            </MenuItem>
          )}
          {hasOrganizations && (
            <MenuItem onClick={() => { handleManageOrganizations(); }}>
              <FormattedMessage id="avatar_organization_manage" />
            </MenuItem>
          )}
          <MenuItem key="logout" onClick={() => { handleLogout(); }}>
            <FormattedMessage id="logout" />
          </MenuItem>
        </AvatarMenu>
      )}
    </>
  );
}

export default Avatar;
