import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@mui/material';
import styled from 'styled-components';

interface CauseCardContainerProps {
  $isActive: boolean;
}

export const CauseCardContainer = styled(Card).attrs({
  variant: 'outlined',
})<CauseCardContainerProps>`
  width: 525px;
  height: 70px;
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  cursor: pointer;

  ${({ theme, $isActive }) => $isActive && `
     background-color: #f0f0f0;
      border: 2px solid ${theme.palette.secondary.main};
  `}
`;

export const CauseCardTitle = styled.span`
  font-size: ${({ theme }) => theme.typography.body1.fontSize};
`;

export const CauseCardDescription = styled.span`
  display: flex;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: ${({ theme }) => theme.typography.caption.fontSize};
`;

export const CauseCardIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;
