import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, List, Typography } from '@mui/material';
import styled from 'styled-components';

export const GridCard = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.palette.divider};

  @media (width <= 1920px) {
    min-width: 22vw;
  }
`;

export const FlexGridCard = styled(GridCard)`
  display: flex;
  align-items: stretch;
`;

export const StyledList = styled(List)`
  width: 100%;
  overflow: auto;
  flex: 1;
`;

export const CenteredGrid = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const TicketImage = styled.img`
  width: 160px;
  height: 120px;
  padding: 1rem 0;
`;

export const WaitingLineTitle = styled(Typography)`
  font-size: 26px;
  font-weight: 900;
  text-align: center;
  padding: 0.5rem 0;
`;

export const WaitingLineDescription = styled(Typography)`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
`;

export const BulletPoint = styled(Grid)`
  width: 100%;
  flex: 1;
`;

export const BulletPointIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.palette.secondary.main};
`;

export const BreakingText = styled.p`
  @media only screen and (width < 1800px) {
    white-space: pre-line;
  }
`;

export const BreakWordText = styled.span`
  word-wrap: break-word;
`;

export const FullWidthText = styled.span`
  flex: 1;
`;
