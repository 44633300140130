import useFeedback from 'components/Feedback/useFeedback';
import Loader from 'components/Loading/Loader';
import { useGetWaitingLineQuery } from 'lib/graphql/graphql';
import useEvent from 'providers/event/useEvent';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useParams } from 'wouter';

import WaitingLineContext, { DefaultWaitingLineContext, IWaitingLineContext } from './WaitingLineContext';

export interface WaitingLineProviderProps {
  children: JSX.Element
}

export default function WaitingLineProvider(props: WaitingLineProviderProps) {
  const { children } = props;
  const feedback = useFeedback();
  const [, setLocation] = useLocation();
  const { waitingLineId } = useParams<{ waitingLineId: string }>();
  const event = useEvent();
  const { data, loading } = useGetWaitingLineQuery({
    variables: {
      id: waitingLineId,
    },
    fetchPolicy: 'network-only',
  });

  const contextValue = useMemo<IWaitingLineContext>(() => {
    if (data?.getWaitingLine) {
      return data.getWaitingLine;
    }
    return DefaultWaitingLineContext;
  }, [data?.getWaitingLine]);

  if (!loading && !(data && data.getWaitingLine)) {
    setLocation(`~/event/${event.id}/waitingLines`);

    feedback({
      anchor: { horizontal: 'right', vertical: 'bottom' },
      content: (<FormattedMessage id="waiting_line_not_found" />),
      severity: 'error',
    });
  }

  return (
    <Loader isLoading={loading}>
      <WaitingLineContext.Provider value={contextValue}>
        {children}
      </WaitingLineContext.Provider>
    </Loader>
  );
}
