import { createDonutChartOptions } from 'lib/agCharts/charts';
import { currencies } from 'lib/constants/currencies';
import { BalanceTransactionSplits } from 'lib/stripe/balanceTransactions';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import ChartCard from '../ChartCard';

interface SalesBreakdownCardProps {
  balanceTransactions: BalanceTransactionSplits[];
  organizationName: string;
}

function SalesBreakdownCard(props: SalesBreakdownCardProps) {
  const { balanceTransactions, organizationName } = props;
  const intl = useIntl();

  const getTotalSplit = useCallback((split: string) => balanceTransactions.reduce((acc, curr) => {
    const amount = curr.metadata.splits[split];
    const currency = curr.currency.toUpperCase();
    if (!acc[currency]) {
      acc[currency] = 0;
    }
    acc[currency] += amount;
    return acc;
  }, {} as Record<string, number>), [balanceTransactions]);

  const salesData = useMemo(() => {
    if (!balanceTransactions || Object.keys(balanceTransactions).length === 0) return [];

    const movonSales = getTotalSplit('movon');
    const obnlSales = getTotalSplit('obnls');
    const organizationSales = getTotalSplit('organization');
    const stripeFees = getTotalSplit('stripe');
    const taxes = getTotalSplit('taxes');

    const formatSalesData = (
      sales: Record<string, number>,
      label: string,
    ) => Object.entries(sales).map(([currency, amount]) => {
      const symbol = currencies.find((cur) => cur.code === currency)?.symbol || '$';
      return {
        asset: label,
        amount: amount / 100,
        unit: symbol,
      };
    });

    return [
      ...formatSalesData(organizationSales, organizationName),
      ...formatSalesData(movonSales, 'MovOn.VIP'),
      ...formatSalesData(obnlSales, intl.formatMessage({ id: 'supported_organizations' })),
      ...formatSalesData(stripeFees, 'Stripe'),
      ...formatSalesData(taxes, intl.formatMessage({ id: 'taxes' })),
    ];
  }, [balanceTransactions, getTotalSplit, organizationName, intl]);

  const salesDonutOptions = createDonutChartOptions(salesData, intl.locale, intl.formatMessage({ id: 'total_sales' }));

  return (
    <ChartCard title="sales_breakdown" chartOptions={salesDonutOptions} />
  );
}

export default SalesBreakdownCard;
