import { Chip } from '@mui/material';
import styled from 'styled-components';
import { AppTypeBackground } from 'styles/AppThemeProvider';

export const WaitingLineInfoDisplay = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => theme.palette.info.dark};
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  padding: 0.25em;
  margin: 0.5rem 0;
  justify-content: space-between;
  width: 100%;
`;

export const RecentScansDisplay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0.5em 0;
  flex: 2;
  min-height: calc(1.5em + 3 * 2em + 0.5em); /* title + 3 items + margin-bottom */
  width: 100%;
`;

export const RecentScanItem = styled.div`
  width: 83%;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 0.5em 1.5em;
  background: ${({ theme }) => {
    const background = theme.palette.background as AppTypeBackground;
    return background.dark;
  }};
  
  &:last-child {
    margin-bottom: 0.5em;
  }
`;

export const QRInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

export const QRInfoRow = styled.div`
  display: flex;
  gap: 1em;
  flex: 1;
  margin: 2em 0;
`;

export const LeftInWaitingLine = styled(Chip).attrs({
  color: 'success',
  variant: 'outlined',
})`
  font-size: 0.8em;
  height: 1.4em;
  border-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.secondary.main};
  border-radius: 5px;
`;

export const RecentScanList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 100%;
`;
