import { Select } from '@mui/material';
import { styled } from 'styled-components';

export const FullWidthSelect = styled(Select)`
  width: 85vw;
  height: 2.5rem;
`;

export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const MobileDescriptionContainer = styled.div`
  text-align: center;
`;
