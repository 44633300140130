import { CardActions } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ContainedButton, RoundCircleBackground, RoundCircleImage } from 'styles/styles';
import { useLocation } from 'wouter';

import {
  AddTitle,
  CreateWindowContainer,
  CreateWindowContent,
  ImageContainer,
  NewTitle,
} from './styles';

interface CreateWindowProps {
  image: string;
  newTitle: string;
  addTitle: string;
  createTitle: string;
  location: string;
}

function CreateWindow(props: CreateWindowProps) {
  const {
    image,
    newTitle,
    addTitle,
    createTitle,
    location,
  } = props;
  const [, setLocation] = useLocation();

  return (
    <CreateWindowContainer>
      <CreateWindowContent>
        <ImageContainer>
          <RoundCircleBackground>
            <RoundCircleImage src={image} />
          </RoundCircleBackground>
        </ImageContainer>
        <NewTitle>
          <FormattedMessage id={newTitle} />
        </NewTitle>
        <AddTitle>
          <FormattedMessage id={addTitle} />
        </AddTitle>
      </CreateWindowContent>
      <CardActions>
        <ContainedButton color="secondary" onClick={() => setLocation(location)}>
          <FormattedMessage id={createTitle} />
        </ContainedButton>
      </CardActions>
    </CreateWindowContainer>
  );
}

export default CreateWindow;
