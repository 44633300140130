import { DateTimePickerToolbarProps } from '@mui/x-date-pickers';
import { DateTime } from 'luxon';
import { FormattedMessage } from 'react-intl';

import { DateToolbar } from './styles';

function DateTimeHeader(props: DateTimePickerToolbarProps<DateTime>) {
  return (
    <DateToolbar
      {...props}
      hidden={false}
      views={['day', 'hours', 'minutes']}
      toolbarTitle={<FormattedMessage id="general_date_time_picker_title" />}
    />
  );
}

export default DateTimeHeader;
