import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardActions, Tooltip } from '@mui/material';
import { currenciesMap } from 'lib/constants/currencies';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ContainedButton } from 'styles/styles';

import {
  IconTooltip,
  PayoutCardContainer,
  PayoutCardContent,
  PayoutCardTitle,
} from './styles';

interface PayoutCardProps {
  title: string;
  tooltip?: string;
  balances: Record<string, number> | Record<string, { available: number; pending: number }>;
  type: 'total' | 'available' | 'pending';
  buttonText?: string;
  onClick?: () => void;
}

function PayoutCard(props: PayoutCardProps) {
  const {
    title,
    tooltip,
    balances,
    type,
    buttonText,
    onClick,
  } = props;

  const isButtonDisabled = useMemo(() => Object.keys(balances).every((currency) => {
    const value = balances[currency];
    if (typeof value === 'number') {
      return value === 0;
    }
    return value.available === 0 && value.pending === 0;
  }), [balances]);

  return (
    <PayoutCardContainer>
      <PayoutCardContent>
        <PayoutCardTitle>
          <FormattedMessage id={title} />
          <Tooltip placement="top" arrow title={(tooltip ? <FormattedMessage id={tooltip} /> : '')}>
            <IconTooltip>
              <FontAwesomeIcon icon={faInfoCircle} />
            </IconTooltip>
          </Tooltip>
        </PayoutCardTitle>
        {Object.keys(balances).length === 0 ? (
          <FormattedMessage id="payouts_no_funds" />
        ) : (
          Object.entries(balances).map(([currency, value]) => {
            let amount;
            switch (type) {
              case 'total':
                amount = value;
                break;
              case 'available':
                amount = value.available;
                break;
              case 'pending':
                amount = value.pending;
                break;
              default:
                amount = 0;
            }
            return (
              <div key={currency}>
                {`${(amount / 100).toFixed(currenciesMap[currency].decimalDigits)}${currenciesMap[currency].symbol} ${currency}`}
              </div>
            );
          })
        )}
      </PayoutCardContent>
      {buttonText && (
        <CardActions>
          <ContainedButton color="secondary" onClick={onClick} disabled={isButtonDisabled}>
            <FormattedMessage id={buttonText} />
          </ContainedButton>
        </CardActions>
      )}
    </PayoutCardContainer>
  );
}

export default PayoutCard;
