import {
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from '@mui/material';
import FormTextField from 'components/FormFields/FormTextField';
import { useRequestResetPasswordMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import { useCallback, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { TextButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import AuthLayout from './AuthForm';
import ConfirmForgotPassword from './ConfirmForgotPassword';
import { ActionButton } from './styles';

interface ForgotPasswordInputs {
  email: string
}

function ForgotPassword() {
  useFormattedDocumentTitle('reset_password');
  const formControl = useForm<ForgotPasswordInputs>();
  const { handleSubmit } = formControl;

  const [, setLocation] = useLocation();
  const { locale } = useIntl();

  const [requestPasswordRequest, { loading }] = useRequestResetPasswordMutation();
  const [hasSubmit, setHasSubmit] = useState(false);

  const onSubmit: SubmitHandler<ForgotPasswordInputs> = useCallback(async ({ email }) => {
    // const { data } = await requestPasswordRequest({ variables: { username: email, locale } });
    await requestPasswordRequest({ variables: { username: email, locale } });
    // TODO: return something (or not) ?
    setHasSubmit(true);
  }, [requestPasswordRequest, locale]);

  return (
    <>
      {!hasSubmit && (
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <FormProvider {...formControl}>
          <AuthLayout>
            <Container>
              <Grid container justifyContent="center" alignContent="center">
                <Grid container direction="column" width="30rem">
                  <CardHeader
                    title={(
                      <Title>
                        <FormattedMessage id="reset_password" />
                      </Title>
                    )}
                    subheader={(
                      <span>
                        <FormattedMessage id="have_an_account" />
                        {' '}
                        <TextButton color="secondary" onClick={() => setLocation('~/')}>
                          <FormattedMessage id="login" />
                        </TextButton>
                      </span>
                    )}
                  />
                  <CardContent>
                    <FormTextField
                      required
                      type="email"
                      size="medium"
                      innerFieldLabel="enter_email"
                      name="email"
                      autoComplete="email"
                      labelId="email"
                      rules={{
                        pattern: {
                          value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/i,
                          message: 'invalid_email',
                        },
                      }}
                    />
                  </CardContent>
                  <CardActions sx={{ justifyContent: 'center' }}>
                    <ActionButton
                      color="secondary"
                      type="submit"
                      loading={loading}
                    >
                      <FormattedMessage id="send" />
                    </ActionButton>
                  </CardActions>
                </Grid>
              </Grid>
            </Container>
          </AuthLayout>
        </FormProvider>
      </form>
      )}
      {!loading && hasSubmit && (
        <ConfirmForgotPassword onClick={() => setHasSubmit(false)} />
      )}
    </>
  );
}

export default ForgotPassword;
