import { Grid } from '@mui/material';
import eventImage from 'assets/images/event/icon_dashboard_event.svg';
import reportsImage from 'assets/images/event/icon_reports.svg';
import CreateWindow from 'components/CreateWindow/CreateWindow';
import { CreateWindowGrid } from 'components/CreateWindow/styles';
import PageLayout from 'components/PageLayout';
import EnsureRole from 'lib/access/EnsureRole';
import { FormattedMessage } from 'react-intl';
import { Title } from 'styles/styles';

import EventList from './EventList';
import useDashboardDocumentTitle from './useDashboardDocumentTitle';

function Dashboard() {
  useDashboardDocumentTitle();

  return (
    <PageLayout>
      <Title>
        <FormattedMessage id="dashboard" />
      </Title>
      <CreateWindowGrid>
        <Grid container wrap="nowrap" gap={1}>
          <EnsureRole requiredRole="ADMIN">
            <CreateWindow
              image={eventImage}
              newTitle="new_event"
              addTitle="create_event_details"
              createTitle="create_event"
              location="events/create"
            />
            <CreateWindow
              image={reportsImage}
              newTitle="view_payouts_history"
              addTitle="view_payouts_details"
              createTitle="view_payouts"
              location="/payouts"
            />
          </EnsureRole>
        </Grid>
      </CreateWindowGrid>
      <EventList />
    </PageLayout>
  );
}

export default Dashboard;
