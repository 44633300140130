import ci from '../settings/ci';
import development from '../settings/development';
import production from '../settings/production';
import Settings from '../settings/Settings';
import staging from '../settings/staging';

export const env = import.meta.env.MODE || 'development';

const settings: Settings = {
  ci,
  development,
  production,
  staging,
}[env]!;

export default settings;
