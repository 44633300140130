import { faBadgeCheck, faCircleInfo, faMessage } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import goldTicketIcon from 'assets/images/waitingLine/GoldTicketIcon.svg';
import silverTicketIcon from 'assets/images/waitingLine/SilverTicketIcon.svg';
import ConfirmModal from 'components/ConfirmModal/ConfirmModal';
import useFeedback from 'components/Feedback/useFeedback';
import {
  GetAllWaitingLineTicketsQuery,
  TicketStatus,
  useValidateTicketMutation,
  WaitingLineType,
} from 'lib/graphql/graphql';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  RoundedDrawer,
  TicketIcon,
  TicketMoreMenu,
  TicketMoreMenuDisplayId,
  TicketMoreMenuTextSection,
} from '../styles';

export type TicketData = GetAllWaitingLineTicketsQuery['getAllWaitingLineTickets']['tickets'][0];
export interface WaitingLineTicketMoreProps {
  waitingLineType: WaitingLineType;
  ticket: TicketData;
  isMobile: boolean;
  refetch?: () => void;
}

function boldText(text: React.ReactNode) {
  return <b>{text}</b>;
}

export default function WaitingLineTicketMore(props: WaitingLineTicketMoreProps) {
  const {
    waitingLineType,
    ticket,
    isMobile,
    refetch,
  } = props;
  const ticketIcon = waitingLineType === WaitingLineType.Gold ? goldTicketIcon : silverTicketIcon;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const openMenu = Boolean(anchorEl);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [validateTicket] = useValidateTicketMutation();
  const feedback = useFeedback();

  const handleClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMobile) {
      setOpenDrawer(true);
    } else {
      setAnchorEl(event.currentTarget);
    }
  }, [setAnchorEl, setOpenDrawer, isMobile]);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
    setOpenDrawer(false);
  }, [setAnchorEl, setOpenDrawer]);

  const handleValidateTicketModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const handleValidateTicket = useCallback(async () => {
    try {
      await validateTicket({
        variables: {
          waitingLineId: ticket.waitingLineId,
          ticketId: ticket.id,
        },
      });
      refetch?.();
      setModalOpen(false);
      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (
          <FormattedMessage
            id="ticket_validated"
            values={{
              displayId: ticket.displayId,
              b: boldText,
            }}
          />
        ),
        severity: 'success',
      });
    } catch (err) {
      const error = err as Error;
      let errorId = '';
      switch (error.message) {
        case 'Ticket already used':
          errorId = 'operating_ticket_already_used';
          break;
        case 'Invalid ticket':
          errorId = 'operating_unknown_error';
          break;
        case 'Ticket not found':
          errorId = 'operating_ticket_not_found';
          break;
        default:
          errorId = 'operating_ticket_generic_error';
          break;
      }

      feedback({
        anchor: { horizontal: 'right', vertical: 'bottom' },
        content: (<FormattedMessage id={errorId} />),
        severity: 'error',
      });
    }
  }, [validateTicket, ticket, feedback, setModalOpen, refetch]);

  const optionList = useMemo<React.ReactNode>(() => ([
    <TicketMoreMenu>
      <TicketIcon src={ticketIcon} />
      <TicketMoreMenuTextSection>
        <TicketMoreMenuDisplayId>
          {ticket?.displayId}
        </TicketMoreMenuDisplayId>
        {ticket?.contactInfo?.value || <FormattedMessage id="ticket_no_communication_address" />}
      </TicketMoreMenuTextSection>
    </TicketMoreMenu>,
    <Divider />,
    ticket.status === TicketStatus.Activated ? (
      <MenuItem
        onClick={handleValidateTicketModal}
      >
        <ListItemIcon>
          <FontAwesomeIcon icon={faBadgeCheck} size="sm" />
        </ListItemIcon>
        <ListItemText>
          <FormattedMessage id="ticket_validate" />
        </ListItemText>
      </MenuItem>
    ) : false,
    <MenuItem onClick={handleClose} disabled>
      <ListItemIcon>
        <FontAwesomeIcon icon={faCircleInfo} size="sm" />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="ticket_more_information" />
      </ListItemText>
    </MenuItem>,
    <MenuItem onClick={handleClose} disabled>
      <ListItemIcon>
        <FontAwesomeIcon icon={faMessage} size="sm" />
      </ListItemIcon>
      <ListItemText>
        <FormattedMessage id="ticket_send_sms" />
      </ListItemText>
    </MenuItem>,
  ].filter(Boolean)), [
    handleClose,
    handleValidateTicketModal,
    ticket?.contactInfo?.value,
    ticket?.displayId,
    ticketIcon,
    ticket?.status,
  ]);

  return (
    <>
      <IconButton onClick={handleClick}>
        <FontAwesomeIcon icon={faEllipsisVertical} size="sm" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
      >
        {optionList}
      </Menu>
      <RoundedDrawer
        anchor="bottom"
        open={openDrawer}
        onClose={handleClose}
      >
        {optionList}
      </RoundedDrawer>
      <ConfirmModal
        title="ticket_validate"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleValidateTicket}
        closeText="general_no"
        confirmText="general_yes"
      >
        <FormattedMessage id="ticket_validate_body" values={{ displayId: ticket.displayId, b: boldText }} />
      </ConfirmModal>
    </>
  );
}
