import useDocumentTitle from 'lib/title/useDocumentTitle';
import useEvent from 'providers/event/useEvent';
import LanguageContext from 'providers/i18n/LanguageContext';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

function useEventDocumentTitle(title: string) {
  const { name } = useEvent();
  const { language } = useContext(LanguageContext);
  const { formatMessage } = useIntl();

  const eventName = name[language];

  const documentTitle = eventName ? `${formatMessage({ id: title })} - ${eventName}` : formatMessage({ id: title });
  useDocumentTitle(documentTitle);
}

export default useEventDocumentTitle;
