import { Dialog, dialogClasses } from '@mui/material';
import styled from 'styled-components';

export const StyledDialog = styled(Dialog)`
  & .${dialogClasses.paper} {
    position: relative;
    border-radius: 16px;
    padding: 16px;
  }
`;

export const CornerCloseContainer = styled.div`
  position: absolute;
  margin: 16px;
  padding: 12px 0;
  top: 0;
  right: 0;
`;
