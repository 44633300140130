import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import Navigation from 'components/Navigation/Navigation';
import ConfirmEmail from 'modules/Auth/ConfirmEmail';
import ConfirmEmailLanding from 'modules/Auth/ConfirmEmailLanding';
import Login from 'modules/Auth/Login';
import CreateOrganization from 'modules/Organizations/OrganizationForm/CreateOrganization';
import { useContext } from 'react';
import { Redirect, Route, Switch } from 'wouter';

import OrganizationSwitch from './OrganizationSwitch';

export default function LoggedInSwitch() {
  const { loggedIn, confirmedAccount } = useContext(AuthenticatorContext);

  if (!loggedIn) {
    return <Login />;
  }

  if (!confirmedAccount) {
    return (
      <Switch>
        <Route path="/register/finalize" component={ConfirmEmailLanding} />
        <Route path="/register/confirm" component={ConfirmEmail} />
        <Route>
          <Redirect to="/register/confirm" />
        </Route>
      </Switch>
    );
  }

  return (
    <Navigation>
      <Switch>
        <Route path="/organizations/create" component={CreateOrganization} />
        <Route component={OrganizationSwitch} />
      </Switch>
    </Navigation>
  );
}
