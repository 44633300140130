import {
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
} from '@mui/material';
import AuthenticatorContext from 'components/Authenticator/AuthenticatorContext';
import FormPasswordField from 'components/FormFields/FormPasswordField';
import FormTextField from 'components/FormFields/FormTextField';
import { useCustomForm } from 'lib/form/useCustomForm';
import { useCreateUserMutation } from 'lib/graphql/graphql';
import useFormattedDocumentTitle from 'lib/title/useFormattedDocumentTitle';
import InviteTokenContext from 'providers/token/InviteTokenContext';
import { useCallback, useContext, useEffect } from 'react';
import { FormProvider, SubmitHandler } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { ErrorMessage, TextButton, Title } from 'styles/styles';
import { useLocation } from 'wouter';

import AuthLayout from './AuthForm';
import { AccountNeeded, ActionButton } from './styles';

interface RegisterInputs {
  email: string
  password: string
  confirmPassword: string
}

function Register() {
  useFormattedDocumentTitle('sign_up');
  const formControl = useCustomForm<RegisterInputs>({ mode: 'onTouched' });
  const { handleSubmit, watch, setValue } = formControl;
  const [, setLocation] = useLocation();
  const { locale } = useIntl();

  const { login, performingLogin } = useContext(AuthenticatorContext);
  const { inviteToken, userEmail } = useContext(InviteTokenContext);

  const [createUser, { error, loading }] = useCreateUserMutation();

  const passwordWatch = watch('password');

  useEffect(() => {
    if (inviteToken) {
      setValue('email', userEmail!);
    }
  }, [inviteToken, setValue, userEmail]);

  const onSubmit: SubmitHandler<RegisterInputs> = useCallback(async ({
    email,
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) return;

    try {
      await createUser({
        variables: {
          email,
          password,
          locale,
        },
      });

      await login(email, password, inviteToken);
      setLocation('~/register/confirm');
    } catch (err) {
      console.error(err);
    }
  }, [createUser, locale, login, inviteToken, setLocation]);

  return (
    <form noValidate onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...formControl}>
        <AuthLayout>
          <Container>
            <Grid container justifyContent="center" alignContent="center">
              <Grid container direction="column" width="30rem">
                <CardHeader
                  title={(
                    <Title>
                      <FormattedMessage id="sign_up" />
                    </Title>
                  )}
                  subheader={(
                    <span>
                      <FormattedMessage id="have_an_account" />
                      {' '}
                      <TextButton color="secondary" onClick={() => setLocation('~/')}>
                        <FormattedMessage id="login" />
                      </TextButton>
                    </span>
                  )}
                />
                <CardContent>
                  {inviteToken && (
                    <Grid container gap={2} marginBottom="1rem">
                      <AccountNeeded>
                        <span>
                          <FormattedMessage id="account_needed" />
                        </span>
                      </AccountNeeded>
                    </Grid>
                  )}
                  <Grid container gap={2}>
                    <FormTextField
                      labelId="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      innerFieldLabel="enter_email"
                      defaultValue={userEmail}
                      required
                      rules={{
                        pattern: {
                          value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/i,
                          message: 'invalid_email',
                        },
                      }}
                      size="medium"
                    />
                    <FormPasswordField
                      name="password"
                      labelId="password"
                      autoComplete="new-password"
                      innerFieldLabel="enter_password"
                      rules={{ validate: (value: string) => value.length >= 8 || 'password_too_short' }}
                      size="medium"
                      required
                    />
                    <FormPasswordField
                      name="confirmPassword"
                      labelId="confirm_password"
                      innerFieldLabel="enter_confirm_password"
                      rules={{ validate: (value: string) => (value) === passwordWatch || 'passwords_do_not_match' }}
                      size="medium"
                      required
                    />
                    {error && error.message === 'Password must be at least 8 characters' && (
                      <ErrorMessage>
                        <FormattedMessage id="password_too_short" />
                      </ErrorMessage>
                    )}
                    {error && error.message === 'User already exists' && (
                      <ErrorMessage>
                        <FormattedMessage id="user_already_exists" />
                      </ErrorMessage>
                    )}
                  </Grid>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <ActionButton
                    color="secondary"
                    loading={loading || performingLogin}
                    type="submit"
                  >
                    <FormattedMessage id="sign_up" />
                  </ActionButton>
                </CardActions>
              </Grid>
            </Grid>
          </Container>
        </AuthLayout>
      </FormProvider>
    </form>
  );
}

export default Register;
