import { getTimeZones, TimeZone } from '@vvo/tzdb';
import { DateTime } from 'luxon';
import settings from 'settings/ci';

export const LUXON_DEFAULT_TIMEZONE = DateTime.local().zoneName;

const timeZones = getTimeZones();

function mapTimeZone(timeZone: TimeZone) {
  const offset = timeZone.rawOffsetInMinutes;
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  const sign = offset < 0 ? '-' : '+';
  const label = `(GMT${sign}${hours}:${minutes < 10 ? '0' : ''}${minutes}) ${timeZone.name}`;
  return { value: timeZone.name, label, offset };
}

/**
 * Generates an array of timezones with their corresponding offsets.
 */
export const LUXON_TIMEZONES = [...timeZones].map(mapTimeZone).sort((a, b) => {
  if (a.offset !== b.offset) return a.offset - b.offset;
  return a.value.localeCompare(b.value);
});

export function getCoordinatesFromAddress(address: string): Promise<{ lat: number, lng: number }> {
  return new Promise((resolve, reject) => {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address }, (results, status) => {
      if (status === 'OK' && results) {
        const { location } = results[0].geometry;
        resolve({ lat: location.lat(), lng: location.lng() });
      } else {
        console.error(`Geocode was not successful for the following reason: ${status}`);
        reject();
      }
    });
  });
}

export async function getTimezoneFromLatLng(lat: number, lng: number): Promise<string | void> {
  const timestamp = Math.floor(Date.now() / 1000);
  const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${settings.mapsApiKey}`;

  try {
    const response = await fetch(url);
    const data = await response.json();
    if (data.status === 'OK') {
      const { timeZoneId } = data;
      return timeZones.find((tz) => tz.group.includes(timeZoneId))?.name || timeZoneId;
    }
    console.error(`Timezone API was not successful for the following reason: ${data.status}`);
    // Fallback to Luxon's default timezone
    return LUXON_DEFAULT_TIMEZONE;
  } catch (error) {
    console.error(`Error fetching timezone: ${error}`);
  }
  return undefined;
}
