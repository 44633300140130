import { Card, Typography } from '@mui/material';
import { styled } from 'styled-components';
import { AppTypeBackground } from 'styles/AppThemeProvider';

export const NoObnlsFound = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: center;
`;

export const AllowObnlCard = styled(Card)`
  background: ${({ theme }) => {
    const background = theme.palette.background as AppTypeBackground;
    return background.dark;
  }};
`;

export const ObnlText = styled(Typography)`
  font-size: 14px;
`;
