import { FormControl } from '@mui/material';
import styled from 'styled-components';
import { ContainedButton, Text } from 'styles/styles';

interface QuantityButtonProps {
  $disabled?: boolean;
}

export const FormInputContainer = styled.div`
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
`;

export const CharacterCount = styled(Text)`
  display: flex;
  opacity: 0.6;
  justify-content: flex-end;
  position: absolute;
  right: 0;
`;

export const QuantityButton = styled(ContainedButton)<QuantityButtonProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 0 1rem;
  font-size: large;
  border-radius: 0.5em;
  opacity: ${({ $disabled }) => ($disabled ? '0.5 !important' : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'auto')};
`;

export const StyledFormControl = styled(FormControl)`
  display: flex;
  flex-direction: row;
`;

export const SideFormInputContainer = styled.div`
  margin-left: 2rem;
`;

export const FormLabelContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
