import { Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

interface FormErrorsProps {
  error?: FieldError
}

function FormErrors(props: FormErrorsProps) {
  const { error } = props;

  if (error) {
    if (error.message) {
      return (
        <Typography component="span">
          <FormattedMessage id={error.message} />
        </Typography>
      );
    }
    switch (error.type) {
      case 'required':
        return (
          <Typography component="span">
            <FormattedMessage id="field_required" />
          </Typography>
        );
      case 'min':
        return (
          <Typography component="span">
            <FormattedMessage id="field_min_default" />
          </Typography>
        );
      case 'maxLength':
        return (
          <Typography component="span">
            <FormattedMessage id="field_max_length_default" />
          </Typography>
        );
      default:
        return null;
    }
  }
}

export default FormErrors;
